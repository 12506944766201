import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import moment from "moment";
import { apiURL, header } from "../../config/constant";

Vue.use(Vuex)

const reportModule = {
  namespaced: true,
  state: {
    expense: [],
    export: [],
    paied: [],
    income: [],
    topProduct: [],
    topStock: [],
    topSalePro: [],
    topImport: [],
    topExpense: [],
    topExport: [],
    stock: [],
    orderByDate: [],
    orderList: [],
    expenseList: [],
    exportList: [],
    production: [],
    length: true
  },
  mutations: {
    setExpense(state, pay) {
      state.expense = pay
    },
    setImport(state, pay) {
      state.paied = pay
    },
    setExport(state, pay) {
      state.export = pay
    },
    setOrder(state, incom) {
      state.income = incom
    },
    setTopProduct(state, pro) {
      state.topProduct = pro
    },
    setTopStock(state, pro) {
      state.topStock = pro
    },
    setTopSalePro(state, pro) {
      state.topSalePro = pro
    },
    setTopExpense(state, pay) {
      state.topExpense = pay
    },
    setTopExport(state, pay) {
      state.topExport = pay
    },
    setProduction(state, pro) {
      state.production = pro
    },
    setStock(state, raw) {
      state.stock = raw
    },
    setOrderByDate(state, pro) {
      state.orderByDate = pro
    },
    setOrderList(state, pro) {
      state.orderList = pro
    },
    setExpenseList(state, raw) {
      state.expenseList = raw
    },

    setExportList(state, raw) {
      state.exportList = raw
    },

    setLength(state, raw) {
      state.length = raw
    },
  },
  actions: {
    async getExpenseDay(context) {
      try {

        const resIncome = await axios.get(apiURL + `orders/report`, { headers: header })
        const resPay = await axios.get(apiURL + `expenses`, { headers: header })
        const current = new Date();
        var totalIncome = 0, totalPay = 0, date = [],
          payData = [],
          incomeData = []
        for (let index = 0; index < 30; index++) {
          const prior = new Date().setDate(current.getDate() - index);
          var sumInome = 0, sumPay = 0
          if ((resIncome.data.length > 0) && (resIncome.data.error) == undefined) {
            for (const key in resIncome.data) {
              const el = resIncome.data[key];
              var inomeDate = moment(String(new Date(prior).toJSON()
                .slice(0, 10)
                .replace(/-/g, "-"))).format("DD-MM-YYYY"),
                inomedataDate = moment(String(el.updatedAt)).format("DD-MM-YYYY")
              if ((inomeDate == inomedataDate) && (el.totalPayment != null)) {
                sumInome += parseFloat(el.totalPayment)
              }
            }
          }
          if ((resPay.data.length > 0) && (resPay.data.error) == undefined) {
            for (const key in resPay.data) {
              const el = resPay.data[key];
              var payDate = moment(String(new Date(prior).toJSON()
                .slice(0, 10)
                .replace(/-/g, "-"))).format("DD-MM-YYYY"),
                paydataDate = moment(String(el.updatedAt)).format("DD-MM-YYYY")
              if ((payDate == paydataDate) && (el.price != null) && (el.qty != null)) {
                sumPay += parseFloat(el.price) * parseInt(el.qty)
              }
            }
          }
          totalIncome += sumInome
          totalPay += sumPay
          incomeData.push(sumInome)
          payData.push(sumPay)
          date.push(new Date(prior).toJSON()
            .slice(0, 10)
            .replace(/-/g, "-"))
        }
        incomeData.reverse()
        payData.reverse()
        date.sort();
        var datacollection = {
          series: [
            {
              name: "ລາຍຈ່າຍ(ກີບ)",
              data: payData
            },
            {
              name: "ລາຍຮັບ(ກີບ)",
              data: incomeData
            }
          ],
          chartOptions: {
            chart: {
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              height: 350,
              type: 'line',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              },

            },
            colors: ['#901623', '#045736'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            grid: {
              borderColor: '#e7e7e7',
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            markers: {
              size: 3,
              colors: ["#FFFFFF"],
              strokeColor: "#AA00FF",
              strokeWidth: 3
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              }
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
            },
            legend: {
              show: true,
              position: 'top',
              horizontalAlign: 'right',
              floating: true
            },
          },
          totalIncome: totalIncome,
          totalPay: totalPay,
        };
        context.commit('setExpense', datacollection)
      } catch (e) {
        console.log(e);
      }
    },
    async getExpenseMonth(context) {
      try {
        const resIncome = await axios.get(apiURL + `orders/report`, { headers: header })
        const resPay = await axios.get(apiURL + `expenses`, { headers: header })
        var totalIncome = 0, totalPay = 0,
          incomeData = [],
          payData = [], date = [], aday = 0, sday = 0, y = 0,
          arrmonth = [
            "ມັງກອນ",
            "ກຸມພາ",
            "ມີນາ",
            "ເມສາ",
            "ພຶດສະພາ",
            "ມິຖຸນາ",
            "ກໍລະກົດ",
            "ສິງຫາ",
            "ກັນຍາ",
            "ຕຸລາ",
            "ພະຈິກ",
            "ທັນວາ"
          ]


        for (let indx = 0; indx < 12; indx++) {
          var month = '', mth = new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "-")
          if ((parseInt(mth.slice(5, 7)) - aday) > 0) {
            month = (parseInt(mth.slice(5, 7)) - aday).toString()
            aday = aday + 1
            y = new Date().getFullYear()
          } else {
            month = (12 - sday).toString()
            y = parseInt(new Date().getFullYear()) - 1
            sday = sday + 1
          }
          var month_year = y + " " + arrmonth[month - 1],
            nowDate = (y + "-" + (month < 10 ? '0' + month : month)).toString(), sumInome = 0, sumPay = 0
          if ((resIncome.data.length > 0) && (resIncome.data.error) == undefined) {
            for (const key in resIncome.data) {
              const el = resIncome.data[key];
              var incomedataDate = moment(String(el.updatedAt)).format("YYYY-MM")
              if ((nowDate == incomedataDate) && (el.totalPayment != null)) {
                sumInome += parseFloat(el.totalPayment)
              }
            }
          }
          if ((resPay.data.length > 0) && (resPay.data.error) == undefined) {
            for (const key in resPay.data) {
              const el = resPay.data[key];
              var paydataDate = moment(String(el.updatedAt)).format("YYYY-MM")
              if ((nowDate == paydataDate) && (el.price != null) && (el.qty != null)) {
                sumPay += parseFloat(el.price) * parseInt(el.qty)
              }
            }
          }
          totalIncome += sumInome
          totalPay += sumPay
          incomeData.push(sumInome)
          payData.push(sumPay)
          date.push(month_year)
        }
        incomeData.reverse()
        payData.reverse()
        date.reverse();
        var datacollection = {
          series: [
            {
              name: "ລາຍຈ່າຍ(ກີບ)",
              data: payData
            },
            {
              name: "ລາຍຮັບ(ກີບ)",
              data: incomeData
            }
          ],
          chartOptions: {
            chart: {
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              height: 350,
              type: 'line',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#901623', '#045736'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            grid: {
              borderColor: '#e7e7e7',
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            markers: {
              size: 3,
              colors: ["#FFFFFF"],
              strokeColor: "#AA00FF",
              strokeWidth: 3
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              }
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
            }
          },
          totalIncome: totalIncome,
          totalPay: totalPay,
        };
        context.commit('setExpense', datacollection)
      } catch (e) {
        console.log(e);
      }
    },
    async getExpenseYear(context) {
      try {
        const resIncome = await axios.get(apiURL + `orders/report`, { headers: header })
        const resPay = await axios.get(apiURL + `expenses`, { headers: header })
        var totalIncome = 0, totalPay = 0,
          incomeData = [],
          payData = [], date = []


        for (let index = 0; index < 5; index++) {
          var year = parseInt(new Date().getFullYear(), 10) - index,
            sumInome = 0, sumPay = 0
          if ((resIncome.data.length > 0) && (resIncome.data.error) == undefined) {
            for (const key in resIncome.data) {
              const el = resIncome.data[key];
              var incomedataDate = moment(String(el.updatedAt)).format("YYYY")
              if ((year == incomedataDate) && (el.totalPayment != null)) {
                sumInome += parseFloat(el.totalPayment)
              }
            }
          }
          if ((resPay.data.length > 0) && (resPay.data.error) == undefined) {
            for (const key in resPay.data) {
              const el = resPay.data[key];
              var paydataDate = moment(String(el.updatedAt)).format("YYYY")
              if ((year == paydataDate) && (el.price != null) && (el.qty != null)) {
                sumPay += parseFloat(el.price) * parseInt(el.qty)
              }
            }
          }
          totalIncome += sumInome
          totalPay += sumPay
          incomeData.push(sumInome)
          payData.push(sumPay)
          date.push(year)
        }
        incomeData.reverse();
        payData.reverse();
        date.reverse();
        var datacollection = {
          series: [
            {
              name: "ລາຍຈ່າຍ(ກີບ)",
              data: payData
            },
            {
              name: "ລາຍຮັບ(ກີບ)",
              data: incomeData
            }
          ],
          chartOptions: {
            chart: {
              height: 350,
              type: 'line',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#901623', '#045736'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            grid: {
              borderColor: '#e7e7e7',
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            markers: {
              size: 3,
              colors: ["#FFFFFF"],
              strokeColor: "#AA00FF",
              strokeWidth: 3
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              }
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
            }
          },
          totalIncome: totalIncome,
          totalPay: totalPay,
        };
        context.commit('setExpense', datacollection)
      } catch (e) {
        console.log(e);
      }
    },


    async getOrderDay(context) {
      try {
        const res = await axios.get(apiURL + `orders/report`, { headers: header })

        const current = new Date();
        var total = 0, date = [],
          orderData = []
        for (let index = 0; index < 30; index++) {
          const prior = new Date().setDate(current.getDate() - index);
          var sum = 0
          if (res.data.length > 0) {
            for (const key in res.data) {
              const el = res.data[key];
              var nowDate = moment(String(new Date(prior).toJSON()
                .slice(0, 10)
                .replace(/-/g, "-"))).format("DD-MM-YYYY"),
                dataDate = moment(String(el.updatedAt)).format("DD-MM-YYYY")
              if ((nowDate == dataDate) && (el.totalPayment != null)) {
                sum += parseFloat(el.totalPayment)
              }
            }
          }
          total += sum
          orderData.push(sum)
          date.push(new Date(prior).toJSON()
            .slice(0, 10)
            .replace(/-/g, "-"))
        }
        orderData.reverse()
        date.sort();
        var datacollection = {
          series: [
            {
              name: "ຍອດລາຍຮັບ",
              data: orderData
            },
          ],
          chartOptions: {
            chart: {
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              height: 350,
              type: 'line',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              },

            },
            colors: ['#045736'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            grid: {
              show: true,
              borderColor: '#a0a0a0',
              strokeDashArray: 1,
              position: 'back',
              xaxis: {
                lines: {
                  show: false
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              },
            },
            markers: {
              size: 3,
              colors: ["#FFFFFF"],
              strokeColor: "#00BCD4",
              strokeWidth: 3
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              }
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
            },
            legend: {
              show: true,
              position: 'top',
              horizontalAlign: 'right',
              floating: true
            },
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex }) {
                return '<div style="margin:10px">' +
                  "<span>ຍອດລາຍຮັບເປັນ : " + String(parseInt(series[seriesIndex][dataPointIndex])).replace(
                    /(.)(?=(\d{3})+$)/g,
                    "$1,") + '</span> ກີບ' +
                  '</div>'
              }
            }
          },
          total: total,
        };
        context.commit('setOrder', datacollection)
      } catch (e) {
        console.log(e);
      }
    },
    async getOrderMonth(context) {
      try {
        const res = await axios.get(apiURL + `orders/report`, { headers: header })
        var total = 0,
          orderData = [], date = [], aday = 0, sday = 0, y = 0,
          arrmonth = [
            "ມັງກອນ",
            "ກຸມພາ",
            "ມີນາ",
            "ເມສາ",
            "ພຶດສະພາ",
            "ມິຖຸນາ",
            "ກໍລະກົດ",
            "ສິງຫາ",
            "ກັນຍາ",
            "ຕຸລາ",
            "ພະຈິກ",
            "ທັນວາ"
          ]


        for (let indx = 0; indx < 12; indx++) {
          var month = '', mth = new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "-")
          if ((parseInt(mth.slice(5, 7)) - aday) > 0) {
            month = (parseInt(mth.slice(5, 7)) - aday).toString()
            aday = aday + 1
            y = new Date().getFullYear()
          } else {
            month = (12 - sday).toString()
            y = parseInt(new Date().getFullYear()) - 1
            sday = sday + 1
          }
          var month_year = y + " " + arrmonth[month - 1], nowDate = (y + "-" + (month < 10 ? '0' + month : month)).toString(), sum = 0
          if (res.data.length > 0) {
            for (const key in res.data) {
              const el = res.data[key];
              var dataDate = moment(String(el.updatedAt)).format("YYYY-MM")
              if ((nowDate == dataDate) && (el.totalPayment != null)) {
                sum += parseFloat(el.totalPayment)
              }
            }
          }
          total += sum
          orderData.push(sum)
          date.push(month_year)
        }
        orderData.reverse();
        date.reverse();
        var datacollection = {
          series: [
            {
              name: "ຍອດລາຍຮັບ",
              data: orderData
            },
          ],
          chartOptions: {
            chart: {
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              height: 350,
              type: 'line',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#045736'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            grid: {
              show: true,
              borderColor: '#a0a0a0',
              strokeDashArray: 1,
              position: 'back',
              xaxis: {
                lines: {
                  show: false
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              },
            },
            markers: {
              size: 3,
              colors: ["#FFFFFF"],
              strokeColor: "#00BCD4",
              strokeWidth: 3
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              }
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
            },
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex }) {
                return '<div style="margin:10px">' +
                  "<span>ຍອດລາຍຮັບເປັນ : " + String(parseInt(series[seriesIndex][dataPointIndex])).replace(
                    /(.)(?=(\d{3})+$)/g,
                    "$1,") + '</span> ກີບ' +
                  '</div>'
              }
            }
          },
          total: total,
        };
        context.commit('setOrder', datacollection)
      } catch (e) {
        console.log(e);
      }
    },
    async getOrderYear(context) {
      try {
        const res = await axios.get(apiURL + `orders/report`, { headers: header })
        var total = 0,
          orderData = [], date = []
        for (let index = 0; index < 5; index++) {
          var year = parseInt(new Date().getFullYear(), 10) - index
          var sum = 0
          if (res.data.length > 0) {
            for (const key in res.data) {
              const el = res.data[key];
              var dataDate = moment(String(el.updatedAt)).format("YYYY")
              if (((year).toString() == dataDate) && (el.totalPayment != null)) {
                sum += parseFloat(el.totalPayment)
              }
            }
          }
          total += sum
          orderData.push(sum)
          date.push(year)
        }
        orderData.reverse();
        date.reverse();

        var datacollection = {
          series: [
            {
              name: "ຍອດລາຍຮັບ",
              data: orderData
            },
          ],
          chartOptions: {
            chart: {
              height: 350,
              type: 'line',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#045736',],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            grid: {
              show: true,
              borderColor: '#a0a0a0',
              strokeDashArray: 1,
              position: 'back',
              xaxis: {
                lines: {
                  show: false
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              },
            },
            markers: {
              size: 3,
              colors: ["#FFFFFF"],
              strokeColor: "#00BCD4",
              strokeWidth: 3
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              }
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
            },
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex }) {
                return '<div style="margin:10px">' +
                  "<span>ຍອດລາຍຮັບເປັນ : " + String(parseInt(series[seriesIndex][dataPointIndex])).replace(
                    /(.)(?=(\d{3})+$)/g,
                    "$1,") + '</span> ກີບ' +
                  '</div>'
              }
            }
          },
          total: total,
        };
        context.commit('setOrder', datacollection)
      } catch (e) {
        console.log(e);
      }
    },


    async getImportDay(context) {
      try {
        const res = await axios.get(apiURL + `expenses`, { headers: header })
        const current = new Date();
        var total = 0, date = [],
          importData = []
        for (let index = 0; index < 30; index++) {
          const prior = new Date().setDate(current.getDate() - index);
          var sum = 0
          if (res.data.length > 0) {
            for (const key in res.data) {
              const el = res.data[key];
              var nowDate = moment(String(new Date(prior).toJSON()
                .slice(0, 10)
                .replace(/-/g, "-"))).format("DD-MM-YYYY"),
                dataDate = moment(String(el.updatedAt)).format("DD-MM-YYYY")
              if ((nowDate == dataDate) && (el.price != null) && (el.qty != null)) {
                sum += parseFloat(el.price) * parseInt(el.qty)
              }
            }
          }
          total += sum
          importData.push(sum)
          date.push(new Date(prior).toJSON()
            .slice(0, 10)
            .replace(/-/g, "-"))
        }
        date.sort();
        importData.reverse()
        var datacollection = {
          series: [
            {
              name: "ຍອດລາຍຈ່າຍ",
              data: importData
            },
          ],
          chartOptions: {
            chart: {
              stacked: false,
              height: 350,
              group: 'social',
              type: 'line',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#901623',],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            fill: {
              type: 'gradient',
              gradient: {
                enabled: false,
                shade: 'dark',
                type: 'vertical',
                shadeIntensity: 1,
                inverseColors: false,
                gradientToColors: ['#901623',],
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              },
            },
            grid: {
              show: true,
              borderColor: '#d0d0d0',
              strokeDashArray: 1,
              position: 'back',
              xaxis: {
                lines: {
                  show: false
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              },
            },
            markers: {
              size: 3,
              colors: ["#FFEBEE"],
              strokeColor: "#00BCD4",
              strokeWidth: 3
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              }
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            },
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex }) {
                return '<div style="margin:10px">' +
                  "<span>ຍອດລາຍຈ່າຍເປັນ : " + String(parseInt(series[seriesIndex][dataPointIndex])).replace(
                    /(.)(?=(\d{3})+$)/g,
                    "$1,") + '</span> ກີບ' +
                  '</div>'
              }
            }
          },
          total: total,
        };
        context.commit('setImport', datacollection)
      } catch (e) {
        console.log(e);
      }
    },
    async getImportMonth(context) {
      try {
        const res = await axios.get(apiURL + `expenses`, { headers: header })
        var total = 0,
          importData = [],
          date = [], aday = 0, sday = 0, y = 0,
          arrmonth = [
            "ມັງກອນ",
            "ກຸມພາ",
            "ມີນາ",
            "ເມສາ",
            "ພຶດສະພາ",
            "ມິຖຸນາ",
            "ກໍລະກົດ",
            "ສິງຫາ",
            "ກັນຍາ",
            "ຕຸລາ",
            "ພະຈິກ",
            "ທັນວາ"
          ]


        for (let indx = 0; indx < 12; indx++) {
          var month = '', mth = new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "-")
          if ((parseInt(mth.slice(5, 7)) - aday) > 0) {
            month = (parseInt(mth.slice(5, 7)) - aday).toString()
            aday = aday + 1
            y = new Date().getFullYear()
          } else {
            month = (12 - sday).toString()
            y = parseInt(new Date().getFullYear()) - 1
            sday = sday + 1
          }
          var month_year = y + " " + arrmonth[month - 1], nowDate = (y + "-" + (month < 10 ? '0' + month : month)).toString(), sum = 0
          if (res.data.length > 0) {
            for (const key in res.data) {
              const el = res.data[key];
              var dataDate = moment(String(el.updatedAt)).format("YYYY-MM")
              if ((nowDate == dataDate) && (el.price != null) && (el.qty != null)) {
                sum += parseFloat(el.price) * parseInt(el.qty)
              }
            }
          }
          total += sum
          importData.push(sum)
          date.push(month_year)
        }
        importData.reverse();
        date.reverse();
        var datacollection = {
          series: [
            {
              name: "ຍອດລາຍຈ່າຍ",
              data: importData
            },
          ],
          chartOptions: {
            chart: {
              stacked: false,
              height: 350,
              group: 'social',
              type: 'line',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#901623'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            fill: {
              type: 'gradient',
              gradient: {
                enabled: false,
                shade: 'dark',
                type: 'vertical',
                shadeIntensity: 1,
                inverseColors: true,
                gradientToColors: ['#901623'],
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              },
            },
            grid: {
              show: true,
              borderColor: '#d0d0d0',
              strokeDashArray: 1,
              position: 'back',
              xaxis: {
                lines: {
                  show: false
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              },
            },
            markers: {
              size: 3,
              colors: ["#FFEBEE"],
              strokeColor: "#00BCD4",
              strokeWidth: 3
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              }
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            },

            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex }) {
                return '<div style="margin:10px">' +
                  "<span>ຍອດລາຍຈ່າຍເປັນ : " + String(parseInt(series[seriesIndex][dataPointIndex])).replace(
                    /(.)(?=(\d{3})+$)/g,
                    "$1,") + '</span> ກີບ' +
                  '</div>'
              }
            }
          },
          total: total,
        };
        context.commit('setImport', datacollection)
      } catch (e) {
        console.log(e);
      }
    },
    async getImportYear(context) {
      const res = await axios.get(apiURL + `expenses`, { headers: header })
      try {
        var total = 0,
          importData = [],
          date = []


        for (let index = 0; index < 5; index++) {
          var year = parseInt(new Date().getFullYear(), 10), sum = 0
          if (res.data.length > 0) {
            for (const key in res.data) {
              const el = res.data[key];
              var dataDate = moment(String(el.updatedAt)).format("YYYY")
              if (((year - index).toString() == dataDate) && (el.price != null) && (el.qty != null)) {
                sum += parseFloat(el.price) * parseInt(el.qty)
              }
            }
          }
          total += sum
          importData.push(sum)
          date.push(year -= index)
        }
        importData.reverse();
        date.reverse();

        var datacollection = {
          series: [
            {
              name: "ຍອດລາຍຈ່າຍ",
              data: importData
            },
          ],
          chartOptions: {
            chart: {
              stacked: false,
              height: 350,
              group: 'social',
              type: 'line',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#901623'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            fill: {
              type: 'gradient',
              gradient: {
                enabled: false,
                shade: 'dark',
                type: 'vertical',
                shadeIntensity: 1,
                inverseColors: true,
                gradientToColors: ['#901623'],
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              },
            },
            grid: {
              show: true,
              borderColor: '#d0d0d0',
              strokeDashArray: 1,
              position: 'back',
              xaxis: {
                lines: {
                  show: false
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              },
            },
            markers: {
              size: 3,
              colors: ["#FFEBEE"],
              strokeColor: "#00BCD4",
              strokeWidth: 3
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              }
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            },

            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex }) {
                return '<div style="margin:10px">' +
                  "<span>ຍອດລາຍຈ່າຍເປັນ : " + String(parseInt(series[seriesIndex][dataPointIndex])).replace(
                    /(.)(?=(\d{3})+$)/g,
                    "$1,") + '</span> ກີບ' +
                  '</div>'
              }
            }
          },
          total: total,
        };
        context.commit('setImport', datacollection)
      } catch (e) {
        console.log(e);
      }
    },

    async getExportDay(context) {
      try {
        const res = await axios.get(apiURL + `rawdatas`, { headers: header })
        const current = new Date();
        var total = 0, date = [],
          exportData = []
        for (let index = 0; index < 30; index++) {
          const prior = new Date().setDate(current.getDate() - index);
          var sum = 0
          if ((res.data.length > 0) && (res.data.error) == undefined) {
            for (const key in res.data) {
              const el = res.data[key];
              var nowDate = moment(String(new Date(prior).toJSON()
                .slice(0, 10)
                .replace(/-/g, "-"))).format("DD-MM-YYYY"),
                dataDate = moment(String(el.updatedAt)).format("DD-MM-YYYY")
              if ((nowDate == dataDate) && (el.qty != null)) {
                sum += parseInt(el.qty)
              }
            }
          }
          total += sum
          exportData.push(sum)
          date.push(new Date(prior).toJSON()
            .slice(0, 10)
            .replace(/-/g, "-"))
        }
        exportData.reverse()
        date.sort();
        var datacollection = {
          series: [
            {
              name: "ວັດຖຸດິບ-ນໍາອອກ",
              data: exportData
            },
          ],
          chartOptions: {
            chart: {
              stacked: false,
              height: 350,
              group: 'social',
              type: 'line',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#901623'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            fill: {
              type: 'gradient',
              gradient: {
                enabled: false,
                shade: 'dark',
                type: 'vertical',
                shadeIntensity: 1,
                inverseColors: true,
                gradientToColors: ['#901623'],
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              },
            },
            grid: {
              show: true,
              borderColor: '#d0d0d0',
              strokeDashArray: 1,
              position: 'back',
              xaxis: {
                lines: {
                  show: false
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              },
            },
            markers: {
              size: 3,
              colors: ["#84FFFF"],
              strokeColor: "#2979FF",
              strokeWidth: 3
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              }
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            },

            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex }) {
                return '<div style="margin:10px">' +
                  "<span>ຈໍານວນວັດຖຸດິບ-ນໍາອອກເປັນ : " + String(parseInt(series[seriesIndex][dataPointIndex])).replace(
                    /(.)(?=(\d{3})+$)/g,
                    "$1,") + '</span>' +
                  '</div>'
              }
            }
          },
          total: total,
        };
        context.commit('setExport', datacollection)
      } catch (e) {
        console.log(e);
      }
    },
    async getExportMonth(context) {
      try {
        const res = await axios.get(apiURL + `rawdatas`, { headers: header })
        var total = 0,
          exportData = [], date = [], aday = 0, sday = 0, y = 0,
          arrmonth = [
            "ມັງກອນ",
            "ກຸມພາ",
            "ມີນາ",
            "ເມສາ",
            "ພຶດສະພາ",
            "ມິຖຸນາ",
            "ກໍລະກົດ",
            "ສິງຫາ",
            "ກັນຍາ",
            "ຕຸລາ",
            "ພະຈິກ",
            "ທັນວາ"
          ]


        for (let indx = 0; indx < 12; indx++) {
          var month = '', mth = new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "-")
          if ((parseInt(mth.slice(5, 7)) - aday) > 0) {
            month = (parseInt(mth.slice(5, 7)) - aday).toString()
            aday = aday + 1
            y = new Date().getFullYear()
          } else {
            month = (12 - sday).toString()
            y = parseInt(new Date().getFullYear()) - 1
            sday = sday + 1
          }
          var month_year = y + " " + arrmonth[month - 1], nowDate = (y + "-" + (month < 10 ? '0' + month : month)).toString(), sum = 0
          if ((res.data.length > 0) && (res.data.error) == undefined) {
            for (const key in res.data) {
              const el = res.data[key];
              var dataDate = moment(String(el.updatedAt)).format("YYYY-MM")
              if ((nowDate == dataDate) && (el.qty != null)) {
                sum += parseInt(el.qty)
              }
            }
          }
          total += sum
          exportData.push(sum)
          date.push(month_year)
        }
        exportData.reverse();
        date.reverse();
        var datacollection = {
          series: [
            {
              name: "ວັດຖຸດິບ-ນໍາອອກ",
              data: exportData
            },
          ],
          chartOptions: {
            chart: {
              stacked: false,
              height: 350,
              group: 'social',
              type: 'line',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#901623'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            fill: {
              type: 'gradient',
              gradient: {
                enabled: false,
                shade: 'dark',
                type: 'vertical',
                shadeIntensity: 1,
                inverseColors: true,
                gradientToColors: ['#901623'],
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              },
            },
            grid: {
              show: true,
              borderColor: '#d0d0d0',
              strokeDashArray: 1,
              position: 'back',
              xaxis: {
                lines: {
                  show: false
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              },
            },
            markers: {
              size: 3,
              colors: ["#84FFFF"],
              strokeColor: "#2979FF",
              strokeWidth: 3
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              }
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            },
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex }) {
                return '<div style="margin:10px">' +
                  "<span>ຈໍານວນວັດຖຸດິບ-ນໍາອອກເປັນ : " + String(parseInt(series[seriesIndex][dataPointIndex])).replace(
                    /(.)(?=(\d{3})+$)/g,
                    "$1,") + '</span>' +
                  '</div>'
              }
            }
          },
          total: total,
        };
        context.commit('setExport', datacollection)
      } catch (e) {
        console.log(e);
      }
    },
    async getExportYear(context) {
      try {

        const res = await axios.get(apiURL + `rawdatas`, { headers: header })
        var total = 0,
          exportData = [], date = []
        for (let index = 0; index < 5; index++) {
          var year = parseInt(new Date().getFullYear(), 10), sum = 0
          if ((res.data.length > 0) && (res.data.error) == undefined) {
            for (const key in res.data) {
              const el = res.data[key];
              var dataDate = moment(String(el.updatedAt)).format("YYYY")
              if (((year - index).toString() == dataDate) && (el.qty != null)) {
                sum += parseInt(el.qty)
              }
            }
          }
          total += sum
          exportData.push(sum)
          date.push(year -= index)
        }
        exportData.reverse();
        date.reverse();

        var datacollection = {
          series: [
            {
              name: "ວັດຖຸດິບ-ນໍາອອກ",
              data: exportData
            },
          ],
          chartOptions: {
            chart: {
              stacked: false,
              height: 350,
              group: 'social',
              type: 'line',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#901623'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            fill: {
              type: 'gradient',
              gradient: {
                enabled: false,
                shade: 'dark',
                type: 'vertical',
                shadeIntensity: 1,
                inverseColors: true,
                gradientToColors: ['#901623'],
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              },
            },
            grid: {
              show: true,
              borderColor: '#d0d0d0',
              strokeDashArray: 1,
              position: 'back',
              xaxis: {
                lines: {
                  show: false
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              },
            },
            markers: {
              size: 3,
              colors: ["#84FFFF"],
              strokeColor: "#2979FF",
              strokeWidth: 3
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              }
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            },
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex }) {
                return '<div style="margin:10px">' +
                  "<span>ຈໍານວນວັດຖຸດິບ-ນໍາອອກເປັນ : " + String(parseInt(series[seriesIndex][dataPointIndex])).replace(
                    /(.)(?=(\d{3})+$)/g,
                    "$1,") + '</span>' +
                  '</div>'
              }
            }
          },
          total: total,
        };
        context.commit('setExport', datacollection)
      } catch (e) {
        console.log(e);
      }
    },
    async getTopProduct(context, count) {
      try {
        var res = await
          axios.get(apiURL + `products`, { headers: header })
        var proName = [], qty = []
        if ((res.data.length > 0) && (res.data.error == undefined)) {
          var obj = res.data.sort((a, b) => (a.qty > b.qty) ? -1 : ((b.qty > a.qty) ? 1 : 0))
          if (res.data.length < count) {
            count = res.data.length
          }
          var idx = 0
          for (idx = 0; idx < count; idx++) {
            const el = obj[idx];
            proName.push(el.productName)
            qty.push(el.qty)
          }
        }
        var products = {
          series: [{
            name: "ຈໍານວນ(ຕຸກ) ",
            data: qty,
          }],
          chartOptions: {
            chart: {
              height: 200,
              type: 'bar',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              events: {
                click: function (chart, w, e) {
                  console.log(chart, w, e)
                }
              },
              toolbar: {
                show: false
              },
              zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: false,
                zoomedline: {
                  fill: {
                    color: '#90CAF9',
                    opacity: 0.4
                  },
                  stroke: {
                    color: '#0D47A1',
                    opacity: 0.4,
                    width: 1
                  }
                }
              }
            },
            colors: ['#2E3192', '#3949AB', '#7986CB', '#9FA8DA'],
            plotOptions: {
              bar: {
                columnWidth: '45%',
                distributed: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: false
            },
            xaxis: {
              categories: proName,
              labels: {
                style: {
                  colors: ['#000'],
                  fontSize: '18px',
                  fontFamily: 'NoTo Sans Lao'
                }
              }
            },
            responsive: [
              {
                breakpoint: 1000,
                options: {
                  plotOptions: {
                    bar: {
                      horizontal: false
                    }
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ],
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex }) {
                return '<div style="margin:10px">' +
                  "<span>ເປັນຈໍານວນ : " + String(parseInt(series[seriesIndex][dataPointIndex])).replace(
                    /(.)(?=(\d{3})+$)/g,
                    "$1,") + '</span> ຕຸກ' +
                  '</div>'
              }
            }
          }

        }
        context.commit('setTopProduct', products)


      } catch (e) {
        console.log(e);
      }
    },
    async getTopStock(context, count) {
      try {
        var res = await
          axios.get(apiURL + `stocks`, { headers: header })
        var proName = [], qty = []
        if ((res.data.length > 0) && (res.data.error == undefined)) {
          var obj = res.data.sort((a, b) => (a.qty > b.qty) ? -1 : ((b.qty > a.qty) ? 1 : 0))
          if (res.data.length < count) {
            count = res.data.length
          }
          var idx = 0
          for (idx = 0; idx < count; idx++) {
            const el = obj[idx];
            var rawName = (el.raw == null || el.raw == undefined) ? '' : el.raw.rawName
            proName.push(rawName)
            qty.push(el.qty)
          }
        }
        var products = {
          series: [{
            name: "ຈໍານວນ(ຕຸກ) ",
            data: qty,
          }],
          chartOptions: {
            chart: {
              height: 200,
              type: 'bar',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              events: {
                click: function (chart, w, e) {
                  console.log(chart, w, e)
                }
              },
              toolbar: {
                show: false
              },
              zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: false,
                zoomedline: {
                  fill: {
                    color: '#90CAF9',
                    opacity: 0.4
                  },
                  stroke: {
                    color: '#0D47A1',
                    opacity: 0.4,
                    width: 1
                  }
                }
              }
            },
            colors: ['#2E3192', '#3949AB', '#7986CB', '#9FA8DA'],
            plotOptions: {
              bar: {
                columnWidth: '45%',
                distributed: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: false
            },
            xaxis: {
              categories: proName,
              labels: {
                style: {
                  colors: ['#000'],
                  fontSize: '18px',
                  fontFamily: 'NoTo Sans Lao'
                }
              }
            },
            responsive: [
              {
                breakpoint: 1000,
                options: {
                  plotOptions: {
                    bar: {
                      horizontal: false
                    }
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ],
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex }) {
                return '<div style="margin:10px">' +
                  "<span>ເປັນຈໍານວນ : " + String(parseInt(series[seriesIndex][dataPointIndex])).replace(
                    /(.)(?=(\d{3})+$)/g,
                    "$1,") + '</span> ຕຸກ' +
                  '</div>'
              }
            }
          }

        }
        context.commit('setTopStock', products)

      } catch (e) {
        console.log(e);
      }
    },

    async getProduction(context) {
      try {
        var res = await
          axios.get(apiURL + `products`, { headers: header })
        var proName = [], qty = []
        if ((res.data.length > 0) && (res.data.error == undefined)) {
          var obj = res.data.sort((a, b) => (a.qty > b.qty) ? 1 : ((b.qty > a.qty) ? -1 : 0))

          for (const idx in obj) {
            const el = obj[idx];
            proName.push(el.productName)
            qty.push(el.qty)
          }
        }
        var products = {
          series: [{
            name: "ຜະລິດຕະພັນ",
            data: qty
          }],
          chartOptions: {
            chart: {
              type: 'bar',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              toolbar: {
                show: false
              }
            },
            plotOptions: {
              bar: {
                barHeight: '100%',
                distributed: true,
                horizontal: true,
                dataLabels: {
                  position: 'right',
                  fontFamily: 'NoTo Sans Lao'

                },
                rangeBarGroupRows: false,
                colors: {
                  ranges: [{
                    from: 0,
                    to: 0,
                    color: undefined
                  }],
                },
              },
            },
            colors: ['#2E3192', '#3949AB', '#7986CB', '#9FA8DA'],
            dataLabels: {
              enabled: true,
              textAnchor: 'start',
              style: {
                colors: ['#222'],
                fontSize: '20px',
                fontWeight: '100',
                fontFamily: 'NoTo Sans Lao',
              },
              formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + " :  " + String(parseInt(val)).replace(
                  /(.)(?=(\d{3})+$)/g,
                  "$1,"
                ) + " ຕຸກ"
              },
              offsetX: 0,
              dropShadow: {
                enabled: false
              }
            },
            stroke: {
              width: 1,
              colors: ['#fff']
            },
            grid: {
              show: true,
              borderColor: '#d0d0d0',
              strokeDashArray: 1,
              position: 'back',
              xaxis: {
                lines: {
                  show: false
                },
              },
              yaxis: {
                lines: {
                  show: true
                },
              },
            },
            xaxis: {
              categories: proName,
            },
            yaxis: {
              labels: {
                show: false
              },
            },
            legend: {
              show: false,
              position: 'top',
              horizontalAlign: 'center',
              floating: false,
              fontSize: '14px',
              fontFamily: 'NoTo Sans Lao',
              fontWeight: 400,
              labels: {
                colors: undefined,
                useSeriesColors: true
              },
              markers: {
                width: 12,
                height: 12,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 12,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0
              },
              itemMargin: {
                horizontal: 5,
                vertical: 0
              },
              onItemClick: {
                toggleDataSeries: true
              },
              onItemHover: {
                highlightDataSeries: true
              },
            },
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return '<div style="margin:10px">' +
                  '<span style="font-size:1.3rem"> ຈໍານວນ' + w.globals.labels[dataPointIndex] + '</span>' +

                  '<span style="font-size:1.3rem">' +
                  " : " + String(parseInt(series[seriesIndex][dataPointIndex])).replace(
                    /(.)(?=(\d{3})+$)/g,
                    "$1,") + ' ຕຸກ </span> ' +
                  '</div>'
              }
            }
          },
        }
        context.commit('setProduction', products)
      } catch (e) {
        console.log(e);
      }
    },
    async getStock(context) {
      try {
        var res = await
          axios.get(apiURL + `stocks`, { headers: header })
        var proName = [], qty = []
        if ((res.data.length > 0) && (res.data.error) == undefined) {
          var obj = res.data.sort((a, b) => (a.qty > b.qty) ? 1 : ((b.qty > a.qty) ? -1 : 0))

          for (const idx in obj) {
            const el = obj[idx];

            var rawName = (el.raw == null || el.raw == undefined) ? '' : el.raw.rawName
            proName.push(rawName)
            qty.push(el.qty)
          }
        }
        var products = {
          series: [{
            data: qty
          }],
          chartOptions: {
            chart: {
              type: 'bar',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              toolbar: {
                show: false
              }
            },
            plotOptions: {
              bar: {
                barHeight: '100%',
                distributed: true,
                horizontal: true,
                dataLabels: {
                  position: 'right',
                  fontFamily: 'NoTo Sans Lao'

                },
                rangeBarGroupRows: false,
                colors: {
                  ranges: [{
                    from: 0,
                    to: 0,
                    color: undefined
                  }],
                },
              },
            },
            colors: ['#2E3192', '#3949AB', '#7986CB', '#9FA8DA'],
            dataLabels: {
              enabled: true,
              textAnchor: 'start',
              style: {
                colors: ['#000'],
                fontWeight: '20',
                fontSize: '20px',
                fontFamily: 'NoTo Sans Lao'
              },
              formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + " :  " + String(parseInt(val)).replace(
                  /(.)(?=(\d{3})+$)/g,
                  "$1,"
                )
              },
              offsetX: 0,
              dropShadow: {
                enabled: false
              }
            },
            stroke: {
              width: 1,
              colors: ['#fff']
            },
            grid: {
              show: true,
              borderColor: '#d0d0d0',
              strokeDashArray: 1,
              position: 'back',
              xaxis: {
                lines: {
                  show: false
                },
              },
              yaxis: {
                lines: {
                  show: true
                },
              },
            },
            xaxis: {
              categories: proName,
            },
            yaxis: {
              labels: {
                show: false
              },
            },
            legend: {
              show: false,
              position: 'top',
              horizontalAlign: 'center',
              floating: false,
              fontSize: '14px',
              fontFamily: 'NoTo Sans Lao',
              fontWeight: 400,
              labels: {
                colors: undefined,
                useSeriesColors: true
              },
              markers: {
                width: 12,
                height: 12,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 12,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0
              },
              itemMargin: {
                horizontal: 5,
                vertical: 0
              },
              onItemClick: {
                toggleDataSeries: true
              },
              onItemHover: {
                highlightDataSeries: true
              },
            },
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return '<div style="margin:10px">' +
                  '<span style="font-size:1.3rem"> ຈໍານວນ' + w.globals.labels[dataPointIndex] + '</span>' +

                  '<span style="font-size:1.3rem">' +
                  " : " + String(parseInt(series[seriesIndex][dataPointIndex])).replace(
                    /(.)(?=(\d{3})+$)/g,
                    "$1,") + '</span>' +
                  '</div>'
              }
            }
          },
        }
        context.commit('setStock', products)
      } catch (e) {
        console.log(e);
      }
    },
    async getExpenseList(context, { date1, date2 }) {
      try {
        var res = await
          axios.get(apiURL + `expenses`, { headers: header })
        var datalist = []
        if ((res.data.length > 0) && (res.data.error) == undefined) {
          for (const idx in res.data) {
            const data = res.data[idx];
            var date = new Date(data.updatedAt).getTime()
            if ((date >= date1) && (date <= date2)) {
              var count = 1, qty = data.qty, price = data.price
              if (datalist.length > 0) {
                for (const i in datalist) {
                  const list = datalist[i];
                  if (data.raw.rawName == list.rawName) {
                    qty += list.qty
                    price += +list.price
                    count += list.count
                    datalist.splice(i, 1)
                  }
                }
              }
              var dt = {
                rawName: (data.raw == null || data.raw.rawName == undefined) ? null : data.raw.rawName,
                userName: (data.user == null || data.user.userName == undefined) ? null : data.user.userName,
                supplierName: (data.supplier == null || data.supplier.supplierName == undefined) ? null : data.supplier.supplierName,
                qty: qty,
                price: price,
                count: count,
              }
              datalist.push(dt)
            }
          }
        }
        var obj = datalist.sort((a, b) => (a.qty > b.qty) ? -1 : ((b.qty > a.qty) ? 1 : 0))
        var proName = [], total = []
        for (const idx in obj) {
          const el = obj[idx];
          var rawName = [el.rawName, el.count, el.qty, el.qty + el.price]
          proName.push(rawName)
          total.push(el.qty)
        }
        var products = {
          series: [{
            name: "ວັດຖຸດິບ-ນໍາເຂົ້າ",
            data: total
          }],
          chartOptions: {
            chart: {
              type: 'bar',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              toolbar: {
                show: false
              }
            },
            plotOptions: {
              bar: {
                barHeight: '100%',
                distributed: true,
                horizontal: true,
                dataLabels: {
                  position: 'right',
                  fontFamily: 'NoTo Sans Lao'
                },
                rangeBarGroupRows: false,
                colors: {
                  ranges: [{
                    from: 0,
                    to: 0,
                    color: undefined
                  }],
                },
              },
            },
            colors: ['#2E3192', '#3949AB', '#7986CB', '#9FA8DA'],
            dataLabels: {
              enabled: true,
              textAnchor: 'start',
              style: {
                colors: ['#000'],
                fontSize: '20px',
                fontWeight: '20',
                fontFamily: 'NoTo Sans Lao'
              },
              formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex][0] + " :  " + String(parseInt(val)).replace(
                  /(.)(?=(\d{3})+$)/g,
                  "$1,"
                ) + " ຕຸກ"
              },
              offsetX: 0,
              dropShadow: {
                enabled: false
              }
            },
            stroke: {
              width: 1,
              colors: ['#fff']
            },
            grid: {
              show: true,
              borderColor: '#d0d0d0',
              strokeDashArray: 1,
              position: 'back',
              xaxis: {
                lines: {
                  show: false
                },
              },
              yaxis: {
                lines: {
                  show: true
                },
              },
            },
            xaxis: {
              categories: proName,
            },
            yaxis: {
              labels: {
                show: false
              },
            },
            legend: {
              show: false,
              position: 'top',
              horizontalAlign: 'center',
              floating: false,
              fontSize: '14px',
              fontFamily: 'NoTo Sans Lao',
              fontWeight: 400,
              labels: {
                colors: undefined,
                useSeriesColors: true
              },
              markers: {
                width: 12,
                height: 12,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 12,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0
              },
              itemMargin: {
                horizontal: 5,
                vertical: 0
              },
              onItemClick: {
                toggleDataSeries: true
              },
              onItemHover: {
                highlightDataSeries: true
              },
            },
            tooltip: {
              custom: function ({ dataPointIndex, w }) {
                return '<div style="margin:10px">' +
                  '<p style="font-size:1.2rem"> ວັດຖຸດິບ: ' + w.globals.labels[dataPointIndex][0] + '</p>' +
                  '<p style="font-size:1.2rem"> ນໍາເຂົ້າ: ' + w.globals.labels[dataPointIndex][1] + ' ຄັ້ງ</p>' +
                  '<p style="font-size:1.2rem"> ຈໍານວນ: ' + w.globals.labels[dataPointIndex][2] + '</p>' +

                  "<p style='font-size:1.2rem'>ຍອດ : " + String(parseInt(w.globals.labels[dataPointIndex][3])).replace(
                    /(.)(?=(\d{3})+$)/g,
                    "$1,") + ' ກີບ</p>' +
                  '</div>'
              }
            }
          }
        }
        context.commit('setExpenseList', products)
        context.commit('setTopExpense', obj)
      } catch (e) {
        console.log(e);
      }
    },
    async getExportList(context, { date1, date2 }) {
      try {
        var res = await
          axios.get(apiURL + `rawdatas`, { headers: header })
        var datalist = []
        if ((res.data.length > 0) && (res.data.error) == undefined) {
          for (const idx in res.data) {
            const data = res.data[idx];
            var date = new Date(data.updatedAt).getTime()
            if ((date >= date1) && (date <= date2)) {
              var count = 1, qty = data.qty
              if (datalist.length > 0) {
                for (const i in datalist) {
                  const list = datalist[i];
                  if (data.raw.rawName == list.rawName) {
                    qty += list.qty
                    count += list.count
                    datalist.splice(i, 1)
                  }
                }
              }
              var dt = {
                rawName: (data.raw == null || data.raw.rawName == undefined) ? null : data.raw.rawName,
                userName: (data.user == null || data.user.userName == undefined) ? null : data.user.userName,
                qty: qty,
                count: count,
              }
              datalist.push(dt)
            }
          }
        }
        var obj = datalist.sort((a, b) => (a.qty > b.qty) ? -1 : ((b.qty > a.qty) ? 1 : 0))
        var proName = [], total = []
        for (const idx in obj) {
          const el = obj[idx];
          var rawName = [el.rawName, el.count, el.qty]
          proName.push(rawName)
          total.push(el.qty)
        }

        var products = {
          series: [{
            name: "ວັດຖຸດິບ-ນໍາເຂົ້າ",
            data: total
          }],
          chartOptions: {
            chart: {
              type: 'bar',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              toolbar: {
                show: false
              }
            },
            plotOptions: {
              bar: {
                barHeight: '100%',
                distributed: true,
                horizontal: true,
                dataLabels: {
                  position: 'right',
                  fontFamily: 'NoTo Sans Lao'
                },
                rangeBarGroupRows: false,
                colors: {
                  ranges: [{
                    from: 0,
                    to: 0,
                    color: undefined
                  }],
                },
              },
            },
            colors: ['#2E3192', '#3949AB', '#7986CB', '#9FA8DA'],
            dataLabels: {
              enabled: true,
              textAnchor: 'start',
              style: {
                colors: ['#000'],
                fontSize: '20px',
                fontWeight: '20',
                fontFamily: 'NoTo Sans Lao'
              }, formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex][0] + " :  " + String(parseInt(val)).replace(
                  /(.)(?=(\d{3})+$)/g,
                  "$1,"
                ) + " ຈໍານວນ"
              },
              offsetX: 0,
              dropShadow: {
                enabled: false
              }
            },
            stroke: {
              width: 1,
              colors: ['#fff']
            },
            grid: {
              show: true,
              borderColor: '#d0d0d0',
              strokeDashArray: 1,
              position: 'back',
              xaxis: {
                lines: {
                  show: false
                },
              },
              yaxis: {
                lines: {
                  show: true
                },
              },
            },
            xaxis: {
              categories: proName,
            },
            yaxis: {
              labels: {
                show: false
              },
            },
            legend: {
              show: false,
              position: 'top',
              horizontalAlign: 'center',
              floating: false,
              fontSize: '14px',
              fontFamily: 'NoTo Sans Lao',
              fontWeight: 400,
              labels: {
                colors: undefined,
                useSeriesColors: true
              },
              markers: {
                width: 12,
                height: 12,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 12,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0
              },
              itemMargin: {
                horizontal: 5,
                vertical: 0
              },
              onItemClick: {
                toggleDataSeries: true
              },
              onItemHover: {
                highlightDataSeries: true
              },
            },
            tooltip: {
              custom: function ({ dataPointIndex, w }) {
                return '<div style="margin:10px">' +
                  '<p style="font-size:1.2rem"> ວັດຖຸດິບ: ' + w.globals.labels[dataPointIndex][0] + '</p>' +
                  '<p style="font-size:1.2rem"> ນໍາອອກ: ' + w.globals.labels[dataPointIndex][1] + ' ຄັ້ງ</p>' +
                  '<p style="font-size:1.2rem"> ຈໍານວນ: ' + w.globals.labels[dataPointIndex][2] + '</p>' +
                  '</div>'
              }
            }
          }
        }
        context.commit('setExportList', products)
        context.commit('setTopExport', obj)
      } catch (e) {
        console.log(e);
      }
    },

    getOrderList(context) {
      try {
        axios.get(apiURL + `orders/report/`, { headers: header }).then((res) => {
          if ((res.data.error) != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var prolist = [];
          for (const idx in res.data) {
            const el = res.data[idx];
            var list = []
            const length = (el.items == null || el.items == undefined) ? 0 : el.items.length
            if (length > 0) {
              for (const px in el.items) {
                const pro = el.items[px];
                var Product = {
                  idx: parseInt(px) + 1,
                  _id: pro._id,
                  productName: (pro.product == null || pro.product == undefined) ? null : pro.product.productName,
                  category: (pro.product == null || pro.product == undefined) ? null : pro.product.category,
                  wholeSale: (pro.product == null || pro.product == undefined) ? 0 : pro.product.wholeSale,
                  retail: (pro.product == null || pro.product == undefined) ? 0 : pro.product.retail,
                  promotionPrice: (pro.product == null || pro.product == undefined) ? 0 : pro.product.promotionPrice,
                  qty: (pro.product == null || pro.product == undefined) ? 0 : pro.product.qty,
                  quantity: pro.quantity,
                  description: (pro.product == null || pro.product == undefined) ? 0 : pro.product.description,
                  image: (pro.product == null || pro.product == undefined) ? 0 : pro.product.image,
                  createdAt:
                    moment(String(pro.product.createdAt)).format("DD-MM-YYYY") +
                    " " +
                    pro.product.createdAt.slice(11, pro.product.createdAt.length - 5),
                  updatedAt:
                    moment(String(pro.product.updatedAt)).format("DD-MM-YYYY") +
                    " " +
                    pro.product.updatedAt.slice(11, pro.product.updatedAt.length - 5),
                };
                list.push(Product);
              }
            }
            var Products = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              product: list,
              user: (el.user == null || el.user == undefined) ? null : el.user._id,
              userName: (el.user == null || el.user == undefined) ? null : el.user.userName,
              orderStatus: el.orderStatus,
              paymentType: el.paymentType,
              totalPayment: el.totalPayment,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
              date: el.updatedAt
            };
            prolist.push(Products);
          }
          context.commit("setOrderList", prolist);
          context.commit("setLength", true);
        }).catch(function (error) {
          context.commit("setLength", false);
          console.log(error)
        });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
    getOrderByDate(context, { date1, date2 }) {
      try {
        axios.get(apiURL + `orders/report/start/${date1}/end/${date2}`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var prolist = [];
          for (const idx in res.data) {
            const el = res.data[idx];
            var list = []
            const length = (el.items == null || el.items == undefined) ? 0 : el.items.length
            if (length > 0) {
              for (const px in el.items) {
                const pro = el.items[px];
                var Product = {
                  idx: parseInt(px) + 1,
                  _id: pro._id,
                  productName: (pro.product == null || pro.product == undefined) ? null : pro.product.productName,
                  category: (pro.product == null || pro.product == undefined) ? null : pro.product.category,
                  wholeSale: (pro.product == null || pro.product == undefined) ? 0 : pro.product.wholeSale,
                  retail: (pro.product == null || pro.product == undefined) ? 0 : pro.product.retail,
                  promotionPrice: (pro.product == null || pro.product == undefined) ? 0 : pro.product.promotionPrice,
                  qty: (pro.product == null || pro.product == undefined) ? 0 : pro.product.qty,
                  quantity: pro.quantity,
                  description: (pro.product == null || pro.product == undefined) ? 0 : pro.product.description,
                  image: (pro.product == null || pro.product == undefined) ? 0 : pro.product.image,
                  createdAt:
                    moment(String(pro.product.createdAt)).format("DD-MM-YYYY") +
                    " " +
                    pro.product.createdAt.slice(11, pro.product.createdAt.length - 5),
                  updatedAt:
                    moment(String(pro.product.updatedAt)).format("DD-MM-YYYY") +
                    " " +
                    pro.product.updatedAt.slice(11, pro.product.updatedAt.length - 5),
                };
                list.push(Product);
              }
            }
            var Products = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              product: list,
              user: (el.user == null || el.user == undefined) ? null : el.user._id,
              userName: (el.user == null || el.user == undefined) ? null : el.user.userName,
              orderStatus: el.orderStatus,
              paymentType: el.paymentType,
              totalPayment: el.totalPayment,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),

            };
            prolist.push(Products);
          }
          context.commit("setOrderByDate", prolist);
          context.commit("setLength", true);
        }).catch(function (error) {
          context.commit("setLength", false);
          console.log(error)
        });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
  },
  getters: {
    expense: state => state.expense,
    paied: state => state.paied,
    export: state => state.export,
    income: state => state.income,
    topProduct: state => state.topProduct,
    topStock: state => state.topStock,
    topSalePro: state => state.topSalePro,
    topExpense: state => state.topExpense,
    topExport: state => state.topExport,
    production: state => state.production,
    stock: state => state.stock,
    orderByDate: state => state.orderByDate,
    orderList: state => state.orderList,
    exportList: state => state.exportList,
    expenseList: state => state.expenseList,
    length: state => state.length,
  }
}

export default reportModule;

