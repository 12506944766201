<template>
  <div>
    <div v-if="loading">
      <v-row no-gutters class="justify-center pb-10 mt-16 pt-16">
        <SpinnerLoader :color="'#90CAF9'" :loading="true" :size="200" />
      </v-row>
    </div>
    <div v-else>
      <v-row no-gutters class="ma-2 justify-center">
        <p style="font-size: 2rem">ລາຍງານຂໍ້ມູນວັດຖຸດິບ-ນໍາອອກ</p>
      </v-row>
      <v-row no-gutters class="ma-2">
        <v-col cols="12" md="3" class="mb-5 mb-sm-0">
          <v-card height="130" class="mx-1">
            <v-card-title>
              <span>ລາຍງານຕາມລະຫັດບາໂຄດ </span>
            </v-card-title>
             <v-text-field
              v-model="barCode"
              dense
              outlined
              class="mx-2"
              label="ລະຫັດບາໂຄດ"
              @keyup.enter="routeBarCode"
            ></v-text-field>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" class="mb-5 mb-sm-0">
          <v-card height="130" class="mx-1">
            <v-card-title>
              <span>ລາຍງານຕາມຊື່ວັດຖຸດິບ </span>
            </v-card-title>
            <v-autocomplete
              class="mx-2"
              v-model="rawByName"
              outlined
              dense
              clearable
              allow-overflow
              :items="rawName"
              @change="routerRawName"
              item-color="blue darken-1"
              no-data-text="ບໍ່ມີຂໍ້ມູນ!"
              label="ວັດຖຸດິບ"
            ></v-autocomplete>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="mb-5 mb-sm-0">
          <v-card height="130" class="mx-1">
            <v-card-title>
              <span>ລາຍງານຕາມວັນທີ </span> </v-card-title
            ><v-toolbar flat>
              <v-menu
                v-model="menu5"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    class="mx-1"
                    v-model="date5"
                    label="ວັນທີ່ເລີ່ມຕົ້ນ"
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar"
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title
                  v-model="date5"
                  :max="maxDate"
                  @input="menu5 = false"
                ></v-date-picker>
              </v-menu>
              <v-spacer></v-spacer>
              <h1 style="margin-top: -28px">-</h1>
              <v-spacer></v-spacer>
              <v-menu
                v-model="menu6"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    class="mx-1"
                    v-model="date6"
                    label="ວັນທີ່ສີ້ນສຸດ"
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date6"
                  :max="maxDate"
                  no-title
                  @input="menu6 = false"
                ></v-date-picker>
              </v-menu>
              <v-spacer></v-spacer>
              <v-btn
                @click="routerDate"
                style="margin-top: -28px"
                class="mr-2 green"
              >
                <span style="color: white">ເບີ່ງລາຍລະອຽດ</span>
              </v-btn>
            </v-toolbar>
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-2">
        <v-col cols="12" md="3" class="mb-5 mb-sm-0">
          <v-card height="210" class="mx-1">
            <v-card-title>
              <span>ຈໍານວນວັດຖຸດິບ-ນໍາອອກລວມ </span>
            </v-card-title>
            <v-row class="justify-center">
              <v-progress-circular
                :rotate="180"
                :size="150"
                :value="totals > 0 ? totals : 100"
                :width="3"
                color="red"
              >
                <span
                  :style="{
                    color: 'red',
                    fontWeight: 'bold',
                    fontSize: totals > 1000000000 ? '1rem' : '1.5rem',
                  }"
                  >{{
                    String(parseInt(totals)).replace(/(.)(?=(\d{3})+$)/g, "$1,")
                  }}</span
                >
              </v-progress-circular>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="9">
          <v-card height="210" class="mx-1">
            <v-card-title>
              <span>ວັດຖຸດິບ-ນໍາອອກເປັນຊ່ວງເວລາ</span>
            </v-card-title>
            <v-row class="justify-center mx-2">
              <v-toolbar flat>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      style="margin-top: 20px"
                      class="mx-1"
                      v-model="date1"
                      label="ວັນທີ່ເລີ່ມຕົ້ນ"
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :max="maxDate"
                    no-title
                    v-model="date1"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <h1>-</h1>
                <v-spacer></v-spacer>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      style="margin-top: 20px"
                      class="mx-1"
                      v-model="date2"
                      label="ວັນທີ່ສີ້ນສຸດ"
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date2"
                    no-title
                    :max="maxDate"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <riamExcel
                  class="btn btn-default"
                  :fetch="fetchData"
                  :fields="json_fields"
                  worksheet="ລາຍການນໍາອອກ"
                  :name="excelTitle"
                  :before-finish="finishDownload"
                >
                  <v-btn class="mt-0 mr-2 indigo">
                    <span style="color: white">ດືງຂໍ້ມູນ</span>
                  </v-btn>
                </riamExcel>
              </v-toolbar>
            </v-row>
            <v-row class="justify-space-around">
              <v-card class="" color="#3F51B5" width="70%">
                <v-row class="justify-center ma-2 mt-4">
                  <span style="font-size: 2rem; color: white"
                    >{{
                      String(totalDate).replace(/(.)(?=(\d{3})+$)/g, "$1,")
                    }}
                    ຈໍານວນ</span
                  >
                </v-row>
              </v-card>
            </v-row>
            <div></div>
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters class="justify-center my-5 mx-2">
        <v-card width="100%" class="mx-1 my-2">
          <v-toolbar flat>
            <p style="font-size: 1.2rem">ວັດຖຸດິບນິຍົມນໍາອອກ</p>
            <v-spacer></v-spacer>

            <v-toolbar flat>
              <v-menu
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    style="margin-top: 30px"
                    class="mx-1"
                    v-model="date3"
                    label="ວັນທີ່ເລີ່ມຕົ້ນ"
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar"
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title
                  v-model="date3"
                  :max="maxDate"
                  @input="menu3 = false"
                ></v-date-picker>
              </v-menu>
              <v-spacer></v-spacer>
              <h1>-</h1>
              <v-spacer></v-spacer>
              <v-menu
                v-model="menu4"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    style="margin-top: 30px"
                    class="mx-1"
                    v-model="date4"
                    label="ວັນທີ່ສີ້ນສຸດ"
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date4"
                  :max="maxDate"
                  no-title
                  @input="menu4 = false"
                ></v-date-picker>
              </v-menu>
              <v-spacer></v-spacer>

              <riamExcel
                class="btn btn-default"
                :fetch="fetchDataTop"
                :fields="json_fields"
                worksheet="ລາຍການວັດຖຸດິບນິຍົມນໍາເຂົ້າ"
                :name="excelTopTitle"
                :before-finish="finishDownload"
              >
                <v-btn class="mt-0 mr-2 indigo">
                  <span style="color: white">ດືງຂໍ້ມູນ</span>
                </v-btn>
              </riamExcel>
            </v-toolbar>
          </v-toolbar>
          <v-skeleton-loader v-if="length==false"
          type="list-item-three-line"
        ></v-skeleton-loader>
        <riamchart v-else
            type="bar"
            :height="100 * exportList.series[0].data.length"
            :options="exportList.chartOptions"
            :series="exportList.series"
          ></riamchart>
        </v-card>
      </v-row>
      <v-row no-gutters class="justify-center my-5 mx-2">
        <v-card width="100%" class="mx-1 mb-5 pt-2">
          <v-toolbar flat>
            <p style="font-size: 1.2rem">
              ປະຫວັດຈໍານວນວັດຖຸດິບ-ນໍາອອກປະຈໍາ{{ selectDate }}
            </p>
            <v-spacer></v-spacer>
            <v-card class="elevation-0 mt-1" color="transparent" width="100">
              <v-select
                v-model="selectDate"
                :items="date_list"
                single-line
                placeholder="Date"
                hint="Date"
              ></v-select>
            </v-card>
          </v-toolbar>
          <riamchart
            type="line"
            height="350"
            :options="exported.chartOptions"
            :series="exported.series"
          ></riamchart>
          <v-row class="justify-space-around my-3">
            <p style="font-size: 1.2rem">
              ລວມຈໍານວນທັງໝົດ:
              <span style="font-size: 2rem; font-weigth: blod; color: red">
                {{
                  String(parseInt(exported.total)).replace(
                    /(.)(?=(\d{3})+$)/g,
                    "$1,"
                  ) || 0
                }}</span
              >
            </p>
          </v-row>
        </v-card>
      </v-row>
    </div>
  </div>
</template><script>
import { mapGetters, mapActions } from "vuex";
import VueApexCharts from "vue-apexcharts";
import { SpinnerLoader } from "vue-spinners-css";
import JsonExcel from "vue-json-excel";
export default {
  data: () => ({
    loading: true,
    barCode:null,
    rawByName:null,
    excelTitle:
      "Used-" +
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10) +
      ".xls",
      excelTopTitle:
      "Top-data-" +
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10) +
      ".xls",
    date1: new Date(
      new Date().getFullYear() +
        "-" +
        (parseInt(new Date().getMonth()) - 2).toString() +
        "-" +
        (parseInt(new Date().getDay()) + 4).toString()
    )
      .toISOString()
      .substr(0, 10),
    menu1: false,
    date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu2: false,
    date3: new Date(
      new Date().getFullYear() +
        "-" +
        (parseInt(new Date().getMonth()) - 2).toString() +
        "-" +
        (parseInt(new Date().getDay()) + 4).toString()
    )
      .toISOString()
      .substr(0, 10),
    menu3: false,
    date4: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu4: false,
    date5: new Date(
      new Date().getFullYear() +
        "-" +
        (parseInt(new Date().getMonth()) - 2).toString() +
        "-" +
        (parseInt(new Date().getDay()) + 4).toString()
    )
      .toISOString()
      .substr(0, 10),
    menu5: false,
    date6: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu6: false,
    maxDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date_list: ["ວັນທີ່", "ເດືອນ", "ປີ"],
    selectDate: "ວັນທີ່",
    json_fields: {
      ລໍາດັບ: "idx",
      ຊື່ຖຸດິບ: "rawName",
      ຈໍານວນ: "qty",
      ຜູ້ໃຊ້: "userName",
      ວັນທີ: "updatedAt",
    },
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
  }),
  components: {
    riamchart: VueApexCharts,
    riamExcel: JsonExcel,
    SpinnerLoader,
  },
  computed: {
    ...mapGetters({
      rawdatas: "rawdataMod/rawdatas",
      exported: "reportMod/export",
      exportList: "reportMod/exportList",
      topExport: "reportMod/topExport",
      raws: "rawMod/raws",
      rawName: "rawMod/rawName",
      length: "rawdataMod/length",
    }),
    totals() {
      var total = 0;
      if (this.rawdatas.length > 0) {
        for (const key in this.rawdatas) {
          const element = this.rawdatas[key];
          if (element.qty != null && element.qty != undefined) {
            total += parseFloat(element.qty);
          }
        }
      }
      return total;
    },
    totalDate() {
      var total = 0;
      var date1 = new Date(this.date1).getTime();
      var date2 = new Date(this.date2).getTime();
      if (this.rawdatas.length > 0) {
        for (const key in this.rawdatas) {
          const element = this.rawdatas[key];
          var date = new Date(element.date).getTime();
          if (
            date >= date1 &&
            date <= date2 &&
            element.qty != null &&
            element.qty != undefined
          ) {
            total += parseFloat(element.qty);
          }
        }
      }
      return total;
    },
  },
  watch: {
    length(val){
      if (val==false) {
        this.loading=false
      }
    },
    rawdatas(val) {
      if (val.length > 0) {
        this.loading = false;
      }
    },
    selectDate(date) {
      if (date == "ວັນທີ່") {
        this.getExportDay();
      }
      if (date == "ເດືອນ") {
        this.getExportMonth();
      }
      if (date == "ປີ") {
        this.getExportYear();
      }
    },
    date3(val) {
      if (new Date(val).getTime() < new Date(this.date4).getTime()) {
        this.getExportList({
          date1: new Date(val).getTime(),
          date2: new Date(this.date4).getTime(),
        });
      } else {
        this.getExportList({
          date1: new Date(val).getTime(),
          date2: new Date(val).getTime(),
        });
      }
    },

    date4(val) {
      if (new Date(val).getTime() > new Date(this.date3).getTime()) {
        this.getExportList({
          date1: new Date(this.date3).getTime(),
          date2: new Date(val).getTime(),
        });
      } else {
        this.getExportList({
          date1: new Date(val).getTime(),
          date2: new Date(val).getTime(),
        });
      }
    },
  },
  mounted() {
    this.getRawdatas();
    this.getRaws();
    this.getExportDay();
    this.getExportList({
      date1: new Date(this.date1).getTime(),
      date2: new Date(this.date2).getTime(),
    });
    this.Loading()
  },
  methods: {
    ...mapActions("rawdataMod", ["getRawdatas"]),
    ...mapActions("rawMod", ["getRaws"]),
    ...mapActions("reportMod", [
      "getExportDay",
      "getExportMonth",
      "getExportYear",
      "getExportList",
    ]),
    Loading() {
      if (this.length == false) {
        this.loading = false;
      }
    },
    routeBarCode() {
      if (this.barCode == null || this.barCode == undefined) {
      return
      }
      var raw = null;
      for (const key in this.raws) {
        const el = this.raws[key];
        if (el.barCodeNumber == this.barCode) {
          raw = el._id;
        }
      }
      if (raw == "" || raw == null || raw == undefined) {
        this.showToast(
          "warning",
          `<div style='font-family:"Noto Sans Lao";color:red' >ວັດຖຸດິບລາຍການນີ້ຍັງບໍທັນນໍາເຂົ້າເທື່ອ</div>`
        );
        return;
      }
      this.$router.push({ path: `/admin/report/raw/name/${raw}` });
    },
    routerRawName() {
      if (
        this.rawByName == null ||
        this.rawByName == undefined ||
        this.rawByName == ""
      ) {
        return;
      }
      var raw = null;
      for (const key in this.raws) {
        const el = this.raws[key];
        if (el.rawName == this.rawByName) {
          raw = el._id;
        }
      }
      if (raw == "" || raw == null || raw == undefined) {
        this.showToast(
          "warning",
          `<div style='font-family:"Noto Sans Lao";color:red' >ວັດຖຸດິບລາຍການນີ້ຍັງບໍທັນນໍາເຂົ້າເທື່ອ</div>`
        );
        return;
      }
      this.$router.push({ path: `/admin/report/raw/name/${raw}` });
    },

    routerDate() {
      var date1 = new Date(this.date5).getTime();
      var date2 = new Date(this.date6).getTime();
      if (date1 >= date2) {
        this.showToast(
          "warning",
          `<div style='font-family:"Noto Sans Lao";' >ກະລຸນາເລືອກວັນທີໃນຊ່ວງທີ່ຕ້ອງການລາຍງານໃຫ້ຖຶກຕ້ອງ!</div>`
        );
        return;
      }
      this.$router.push({
        path: "/admin/report/raw/date",
        query: { date1: this.date5, date2: this.date6 },
      });
    },
    fetchData() {
      try {
        var date1 = new Date(this.date1).getTime();
        var date2 = new Date(this.date2).getTime();
        if (date1 >= date2) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";' >ກະລຸນາເລືອກວັນທີໃນຊ່ວງທີ່ຕ້ອງການລາຍງານໃຫ້ຖຶກຕ້ອງ!</div>`
          );
          return;
        }
        if (this.rawdatas.length < 1) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";' >ກະລຸນາວັດຖຸດິບ-ນໍາເຂົ້າກ່ອນ!</div>`
          );
          return;
        }
        var expenselist = [];
        for (const idx in this.rawdatas) {
          const el = this.rawdatas[idx];
          var date = new Date(el.date).getTime();
          if (date >= date1 && date <= date2) {
            expenselist.push(el);
          }
        }
        if (expenselist.length < 1) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";' >ບໍ່ມີຂໍ້ມູນວັດຖຸດິບ-ນໍາເຂົ້າໃນຊ່ວງໄລຍະນີ້</div>`
          );
        }
        return expenselist;
      } catch (error) {
        console.log(error);
      }
    },
    fetchDataTop() {
      try {
        var date1 = new Date(this.date1).getTime();
        var date2 = new Date(this.date2).getTime();
        if (date1 >= date2) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";' >ກະລຸນາເລືອກວັນທີໃນຊ່ວງທີ່ຕ້ອງການລາຍງານໃຫ້ຖຶກຕ້ອງ!</div>`
          );
          return;
        }
        if (this.topExpense.length < 1) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";' >ກະລຸນາວັດຖຸດິບ-ນໍາເຂົ້າກ່ອນ!</div>`
          );
          return;
        }
        var list = [];
        for (const key in this.topExpense) {
          const data = this.topExpense[key];
          var dt = {
            idx: parseInt(key) + 1,
            rawName: data.rawName,
            userName: data.userName,
            supplierName: data.supplierName,
            qty: data.qty,
            price: data.price,
            count: data.count,
          };
          list.push(dt);
        }
        return list;
      } catch (error) {
        console.log(error);
      }
    },
    finishDownload() {
      this.showToast(
        "success",
        `<div style='font-family:"Noto Sans Lao";' >ດາວໂລດສໍາເລັດແລ້ວ</div>`
      );
    },
  },
};
</script>