import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";
import { apiURL, header } from "../../config/constant";

Vue.use(Vuex);

const supplierModule = {
  namespaced: true,
  state: {
    suppliers: [],
    supplier: [],
    supplierName: [],
    length: true
  },
  mutations: {
    setSuppliers(state, supplier) {
      state.suppliers = supplier;
    },
    setSupplier(state, supplier) {
      state.supplier = supplier;
    },
    setSupplierName(state, supplier) {
      state.supplierName = supplier;
    },
    setLength(state, supplier) {
      state.length = supplier;
    },
  },

  actions: {
    async getSuppliers(context) {
      try {
        var provinceList = await axios.get(apiURL + `provinces`, { headers: header });
        var districtList = await axios.get(apiURL + `districts`, { headers: header });
        var villageList = await axios.get(apiURL + `villages`, { headers: header });
        axios.get(apiURL + `suppliers`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }

          var supplierlist = [], supplierNameList = [];
          for (const idx in res.data) {
            const el = res.data[idx];
            var provinceName = null, provinceID = null, districtName = null, districtID = null;
            if (villageList.data.length > 0) {
              for (const i in villageList.data) {
                const vl = villageList.data[i], v = (el.village == null || el.village == undefined) ? null : el.village._id;
                if (v == vl._id) {
                  districtID = (vl.district == null || vl.district == undefined) ? null : vl.district._id
                  provinceID = (vl.district == null || vl.district == undefined) ? null : vl.district.province
                  for (const j in districtList.data) {
                    const dt = districtList.data[j];
                    if (districtID == dt._id) {
                      districtName = dt.name
                    }

                  }
                  for (const x in provinceList.data) {
                    const pro = provinceList.data[x];
                    if (provinceID == pro._id) {
                      provinceName = pro.name
                    }

                  }
                }

              }
            }
            var supplier = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              supplierName: el.supplierName,
              phoneNumber: el.phoneNumber,
              email: el.email,
              village: (el.village == null || el.village == undefined) ? null : el.village.name,
              district: districtName,
              province: provinceName,
              joinDate:
                (el.joinDate == null || el.joinDate == undefined) ? null : moment(String(el.joinDate)).format("DD-MM-YYYY") + " " + el.joinDate.slice(11, el.joinDate.length - 5),
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
            };
            supplierlist.push(supplier);
            supplierNameList.push(el.supplierName);
          }
          context.commit("setSuppliers", supplierlist);
          context.commit("setSupplierName", supplierNameList);
          context.commit("setLength", true);
        }).catch(function (error) {
          context.commit("setLength", false);
          console.log(error)
        });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
    async getSupplier(context, id) {
      try {
        var provinceList = await axios.get(apiURL + `provinces`, { headers: header });
        var districtList = await axios.get(apiURL + `districts`, { headers: header });
        var villageList = await axios.get(apiURL + `villages`, { headers: header });
        axios.get(apiURL + `suppliers/${id}`, { headers: header }).then((res) => {
          if (res.data.error != undefined) return alert("Not data");
          const el = res.data;
          var provinceName = null, provinceID = null, districtName = null, districtID = null;
          for (const i in villageList.data) {
            const vl = villageList.data[i], v = (el.village == null || el.village == undefined) ? null : el.village._id;
            if (v == vl._id) {
              districtID = (vl.district == null || vl.district == undefined) ? null : vl.district._id
              provinceID = (vl.district == null || vl.district == undefined) ? null : vl.district.province
              for (const j in districtList.data) {
                const dt = districtList.data[j];
                if (districtID == dt._id) {
                  districtName = dt.name
                }

              }
              for (const x in provinceList.data) {
                const pro = provinceList.data[x];
                if (provinceID == pro._id) {
                  provinceName = pro.name
                }

              }
            }

          }
          var supplier = {
            _id: el._id,
            supplierName: el.supplierName,
            phoneNumber: el.phoneNumber,
            email: el.email,
            villageName: (el.village == null || el.village == undefined) ? null : el.village.name,
            village: '',
            districtName: districtName,
            district: '',
            provinceName: provinceName,
            province: '',
            joinDate:
              (el.joinDate == null || el.joinDate == undefined) ? null : moment(String(el.joinDate)).format("DD-MM-YYYY"),
            createdAt:
              moment(String(el.createdAt)).format("DD-MM-YYYY") +
              " " +
              el.createdAt.slice(11, el.createdAt.length - 5),
            updatedAt:
              moment(String(el.updatedAt)).format("DD-MM-YYYY") +
              " " +
              el.updatedAt.slice(11, el.updatedAt.length - 5),
          };
          context.commit("setSupplier", supplier);
        })
          .catch(function (error) {
            if (error.message == 'Network Error') {
              console.log("ບໍ່ມີອີນເທີເນັດ")
            }
          });
      } catch (error) {
        console.log(error);
      }
    },

    delSupplier(context, item) {
      try {
        return axios
          .delete(apiURL + `suppliers/${item._id}`, { headers: header })

      } catch (e) {
        console.log(e);
      }
    },
    insertSupplier(context, item) {
      try {
        return axios
          .post(apiURL + `suppliers`, item, { headers: header })
      } catch (e) {
        console.log(e);
      }
    },
    updateSupplier(context, item) {
      try {
        return axios
          .put(apiURL + `suppliers/${item._id}`, item, { headers: header })

      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    suppliers: (state) => state.suppliers,
    supplier: (state) => state.supplier,
    supplierName: (state) => state.supplierName,
    length: (state) => state.length,
  },
};

export default supplierModule;
