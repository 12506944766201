import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";
import { apiURL, header } from "../../config/constant";

Vue.use(Vuex);

const addressMolue = {
  namespaced: true,
  state: {
    provinces: [],
    provinceName: [],
    districts: [],
    district: [],
    districtName: [],
    villages: [],
    village: [],
    villageName: [],
    proviceLength: true,
    districtLength: true,
    villageLength: true,
  },
  mutations: {
    setProvinces(state, pv) {
      state.provinces = pv;
    },
    setProvinceName(state, pv) {
      state.provinceName = pv;
    },
    setDistricts(state, dt) {
      state.districts = dt;
    },
    setDistrict(state, dt) {
      state.district = dt;
    },
    setDistrictName(state, dt) {
      state.districtName = dt;
    },
    setVillages(state, vl) {
      state.villages = vl;
    },
    setVillage(state, vl) {
      state.village = vl;
    },
    setVillageName(state, vl) {
      state.villageName = vl;
    },
    setProviceLength(state, vl) {
      state.proviceLength = vl;
    },
    setDistrictLength(state, vl) {
      state.districtLength = vl;
    },
    setVillageLength(state, vl) {
      state.villageLength = vl;
    },
  },
  actions: {
    getProvinces(context) {
      try {
        axios.get(apiURL + `provinces`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setProviceLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setProviceLength", false);
            return
          }
          var pvlist = [], pvNamelist = [];
          for (const idx in res.data) {
            const el = res.data[idx];
            var province = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              name: el.name,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
            };
            pvlist.push(province);
            pvNamelist.push(el.name)
          }
          context.commit("setProvinces", pvlist);
          context.commit("setProvinceName", pvNamelist);
          context.commit("setProviceLength", true);
        })
          .catch(function (error) {
            context.commit("setProviceLength", false);
            if (error.message == 'Network Error') {
              console.log("ບໍ່ມີອີນເທີເນັດ")
            }
          });
      } catch (error) {
        context.commit("setProviceLength", false);
        console.log(error);
      }
    },
    delProvince(context, item) {
      try {
        return axios
          .delete(apiURL + `provinces/${item._id}`, { headers: header })
      } catch (e) {
        this.errors.push(e);
      }
    },
    insertProvince(context, item) {
      try {
        return axios
          .post(apiURL + `provinces`, item, { headers: header })
      } catch (e) {
        this.errors.push(e);
      }
    },
    updateProvince(context, item) {
      try {
        return axios
          .put(apiURL + `provinces/${item._id}`, item, { headers: header })
      } catch (e) {
        this.errors.push(e);
      }
    },
    // _____________________________|| Districts ||___________________________
    getDistricts(context) {
      try {
        axios.get(apiURL + `districts`, { headers: header })
          .then((res) => {
            if (res.data.error != undefined) {
              context.commit("setDistrictLength", false);
              return
            }
            if (res.data.length < 1) {
              context.commit("setDistrictLength", false);
              return
            }
            var dtlist = [], dtNameList = [];
            for (const idx in res.data) {
              const el = res.data[idx];
              var district = {
                idx: parseInt(idx) + 1,
                _id: el._id,
                name: el.name,
                province_id: (el.province == null || el.province == undefined || el.province._id == null || el.province._id == undefined) ? null : el.province._id,
                provinceName: (el.province == null || el.province == undefined || el.province.name == null || el.province.name == undefined) ? null : el.province.name,
                createdAt:
                  moment(String(el.createdAt)).format("DD-MM-YYYY") +
                  " " +
                  el.createdAt.slice(11, el.createdAt.length - 5),
                updatedAt:
                  moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                  " " +
                  el.updatedAt.slice(11, el.updatedAt.length - 5),
              };
              dtlist.push(district);
              dtNameList.push(el.name)
            }
            context.commit("setDistricts", dtlist);
            context.commit("setDistrictName", dtNameList);
            context.commit("setDistrictLength", true);

          }).catch(function (error) {
            context.commit("setDistrictLength", false);
            console.log(error);
          });
      } catch (error) {
        context.commit("setDistrictLength", false);
        console.log(error);
      }
    },
    getDistrict(context, id) {
      try {
        axios.get(apiURL + `districts/${id}`, { headers: header }).then((res) => {
          // alert(JSON.stringify(res));
          if (res.data.error != undefined) {
            context.commit("setDistrictLength", false);
            return
          }
          const el = res.data;
          var district = {
            _id: el._id,
            name: el.name,
            province: '',
            provinceName: (el.province == null || el.province == undefined || el.province.name == null || el.province.name == undefined) ? null : el.province.name,
            createdAt:
              moment(String(el.createdAt)).format("DD-MM-YYYY") +
              " " +
              el.createdAt.slice(11, el.createdAt.length - 5),
            updatedAt:
              moment(String(el.updatedAt)).format("DD-MM-YYYY") +
              " " +
              el.updatedAt.slice(11, el.updatedAt.length - 5),
          };
          context.commit("setDistrict", district);
        })
      } catch (error) {
        console.log(error);
      }
    },
    delDistrict(context, item) {
      try {
        return axios
          .delete(apiURL + `districts/${item._id}`, { headers: header })
      } catch (e) {
        this.errors.push(e);
      }
    },
    insertDistrict(context, item) {
      try {
        return axios
          .post(apiURL + `districts`, item, { headers: header })
      } catch (e) {
        this.errors.push(e);
      }
    },
    updateDistrict(context, item) {
      try {
        return axios
          .put(apiURL + `districts/${item._id}`, item, { headers: header })

      } catch (e) {
        this.errors.push(e);
      }
    },
    // _____________________________|| Villages ||___________________________
    async getVillages(context) {
      try {
        var provinceList = await axios.get(apiURL + `provinces`, { headers: header });
        var vllist = [], vlNameList = []
          , provinceName = null;

        axios.get(apiURL + `villages`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setVillageLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setVillageLength", false);
            return
          }
          for (const idx in res.data) {
            const el = res.data[idx]
            provinceName = null;
            var p = (el.district == null || el.district == undefined || el.district.province == null || el.district.province == undefined) ? null : el.district.province
            for (const id in provinceList.data) {
              const pro = provinceList.data[id];
              if (p == pro._id) {
                provinceName = pro.name
              }
            }
            var village = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              name: el.name,
              district_id: (el.district == null || el.district == undefined || el.district._id == null || el.district._id == undefined) ? null : el.district._id,
              districtName: (el.district == null || el.district == undefined || el.district.name == null || el.district.name == undefined) ? null : el.district.name,
              province_id: (el.district == null || el.district == undefined || el.district.province == null || el.district.province == undefined) ? null : el.district.province,
              provinceName: provinceName,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
            };
            vllist.push(village);
            vlNameList.push(el.name);
          }
          context.commit("setVillages", vllist);
          context.commit("setVillageName", vlNameList);
          context.commit("setVillageLength", true);
        }).catch(function (error) {
          context.commit("setVillageLength", false);
          console.log(error);
        });
      } catch (error) {
        context.commit("setVillageLength", false);
        console.log(error);
      }
    },
    async getVillage(context, id) {
      try {
        var provinceList = await axios.get(apiURL + `provinces`, { headers: header });
        axios.get(apiURL + `villages/${id}`, { headers: header }).then((res) => {
          if (res.data.error != undefined) return
          const el = res.data;
          var provinceName = null, p = (el.district == null || el.district == undefined || el.district.province == null || el.district.province == undefined) ? null : el.district.province;

          for (const idx in provinceList.data) {
            const pro = provinceList.data[idx];
            if (p == pro._id) {
              provinceName = pro.name
            }

          }
          var village = {
            _id: el._id,
            name: el.name,
            district: '',
            districtName: (el.district == null || el.district == undefined || el.district.name == null || el.district.name == undefined) ? null : el.district.name,
            province: '',
            provinceName: provinceName,
            createdAt:
              moment(String(el.createdAt)).format("DD-MM-YYYY") +
              " " +
              el.createdAt.slice(11, el.createdAt.length - 5),
            updatedAt:
              moment(String(el.updatedAt)).format("DD-MM-YYYY") +
              " " +
              el.updatedAt.slice(11, el.updatedAt.length - 5),
          };
          context.commit("setVillage", village);
        })

      } catch (error) {
        console.log(error);
      }
    },
    delVillage(context, item) {
      try {
        return axios
          .delete(apiURL + `villages/${item._id}`, { headers: header })
      } catch (e) {
        this.errors.push(e);
      }
    },
    insertVillage(context, item) {
      try {
        return axios
          .post(apiURL + `villages`, item, { headers: header })
      } catch (e) {
        this.errors.push(e);
      }
    },
    updateVillage(context, item) {
      try {
        return axios
          .put(apiURL + `villages/${item._id}`, item, { headers: header })

      } catch (e) {
        this.errors.push(e);
      }
    },
  },
  getters: {
    provinces: (state) => state.provinces,
    provinceName: (state) => state.provinceName,
    districts: (state) => state.districts,
    district: (state) => state.district,
    districtName: (state) => state.districtName,
    villages: (state) => state.villages,
    village: (state) => state.village,
    villageName: (state) => state.villageName,
    proviceLength: (state) => state.proviceLength,
    districtLength: (state) => state.districtLength,
    villageLength: (state) => state.villageLength,
  },
};

export default addressMolue;
