<template>
  <v-card flat class="about mx-sm-3 mx-lg-16 mt-7">
    <v-row no-gutters class="justify-center justify-md-start mt-0 mt-md-3" >
      <p style="font-size:1.7rem" v-if="event.length>0">ກິດຈະກໍາ</p>
      <p style="font-size:1.7rem" v-else>ບໍ່ມີຂໍ້ມູນກິດຈະກໍາ</p>
    </v-row>
    <v-card class=" mx-sm-2 mx-lg-3">
    <v-row no-gutters class="ma-5 mt-0" v-if="event.length>0">
      <v-col
        v-for="(vt, idx) in event"
        :key="idx"
        class="d-flex child-flex"
        cols="12"
      >
           <v-card flat>
            <v-row class="justify-center justify-md-start ma-3  mx-sm-0 mx-lg-3 "
              ><span style="font-size: 1.5rem;">{{ vt.title }}</span></v-row
            >
              <v-row no-gutters class="ma-5  mx-sm-0 mx-lg-5  justify-space-around">
              <v-col cols="12" md="12">
                <v-card class="mr-4 elevation-0">
                  <v-carousel
                    v-if="vt.eventPictures.length > 0"
                    height="400"
                    hide-delimiter-background
                    :show-arrows="false"
                  >
                    <v-carousel-item
                      v-for="(img, i) in vt.eventPictures"
                      :key="i"
                    >
                      <v-img
                        height="400"
                        width="100%"
                        contain
                        :src="img.img"
                        :lazy-src="img.img"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
              color="#90CAF9"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-carousel-item>
                  </v-carousel>
                 
                </v-card>
              </v-col>
            </v-row>
            <v-row class="justify-center justify-md-start ma-3  mx-sm-0 mx-lg-3 "
              ><p>{{ vt.description }}</p></v-row
            >
            <v-divider></v-divider>
           </v-card>
      </v-col>
    </v-row>
     <v-card flat class="mb-10 mx-4"  v-else>
       <v-skeleton-loader
          class=""
          type="card-heading,image,list-item-three-line"
        ></v-skeleton-loader>
     </v-card>
    </v-card>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      tagImg:0
    };
  },
  computed: {
    ...mapGetters({
      event: "eventMod/events",
    }),
  },
  mounted() {
    this.getEvents();
  },
  methods: {
    ...mapActions("eventMod", ["getEvents"]),
  },
};
</script>

<style scoped>
span{
  font-size: 1.2rem;
  margin: 7px;
}
.s{
  font-weight: 700;
  font-size: 1.5rem;
}
</style>
