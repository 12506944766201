<template>
  <div>
    <div v-if="loading">
      <v-row no-gutters class="justify-center pb-10 mt-16 pt-16">
        <SpinnerLoader :color="'#90CAF9'" :loading="true" :size="200" />
      </v-row>
    </div>
    <div v-else-if="length == false">
      <v-row no-gutters class="ma-2 justify-center">
        <p style="font-size: 2rem">ລາຍງານຂໍ້ມູນວັດຖຸດິບ</p>
      </v-row>
      <v-row no-gutters class="ma-2">
        <v-col cols="12" md="3">
          <v-card height="270" class="mx-1">
            <v-card-title>
              <span>ລາຍການວັດຖຸດິບທັງໝົດ</span>
            </v-card-title>
            <v-row class="justify-center">
              <v-progress-circular
                :rotate="270"
                :size="200"
                :value="100"
                :width="5"
                color="#2E3192"
              >
                <span
                  :style="{
                    color: 'blue',
                    fontSize: stocks.length > 100000 ? '1rem' : '3rem',
                  }"
                  >{{
                    String(parseInt(stocks.length)).replace(
                      /(.)(?=(\d{3})+$)/g,
                      "$1,"
                    )
                  }}</span
                >
              </v-progress-circular>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="9">
          <v-card height="270" class="mx-1">
            <v-card-title class="justify-space-between">
              <span>ວັດຖຸດິບທີ່ຍັງຫຼາຍ</span>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mt-5 tcenter"
                  label="ຈໍານວນຊາທີ່ຕ້ອງການ"
                  outlined
                  dense
                  rounded
                  prepend-inner-icon="mdi-minus"
                  append-icon="mdi-plus"
                  autofocus
                  v-model="count"
                  @click:append="count = parseInt(count) + 1"
                  @click:prepend-inner="
                    count = parseInt(count) < 1 ? 0 : parseInt(count) - 1
                  "
                  @keypress="InputCount($event)"
                >
                  <template slot="append">
                    <v-btn
                      style="margin-top: -7px; margin-right: -20px"
                      color="#004D40"
                      icon
                      @click="count = parseInt(count) + 1"
                    >
                      <v-icon large>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <template slot="prepend-inner">
                    <v-btn
                      style="margin-top: -7px; margin-left: -20px"
                      color="#004D40"
                      icon
                      @click="
                        count = parseInt(count) < 1 ? 0 : parseInt(count) - 1
                      "
                    >
                      <v-icon large>mdi-minus</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-toolbar>
              <riamExcel
                class="btn btn-default"
                :fetch="fetchDataTop"
                :fields="top_json_fields"
                worksheet="ວັດຖຸດິບທີ່ຍັງຫຼາຍ"
                :name="stockTopTitle"
                :before-finish="finishDownloadTop"
              >
                <v-btn
                  class="mr-2 indigo"
                  :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
                >
                  <span style="color: white">ດືງຂໍ້ມູນ</span>
                </v-btn>
              </riamExcel>
            </v-card-title>
            <riamchart
              type="bar"
              height="200"
              :options="topStock.chartOptions"
              :series="topStock.series"
            ></riamchart>
          </v-card>
        </v-col>
      </v-row>
      <v-card class="mx-2">
        <v-card-title class="justify-space-between">
          <span>ວັດຖຸດິບໃນສະຕ໋ອກທັງໝົດ</span>

          <riamExcel
            class="btn btn-default"
            :fetch="fetchData"
            :fields="json_fields"
            worksheet="ລາຍການວັດຖຸດິບ"
            :name="stockTitle"
            :before-finish="finishDownloadTop"
          >
            <v-btn
              class="mr-2 indigo"
              :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
            >
              <span style="color: white">ດືງຂໍ້ມູນ</span>
            </v-btn>
          </riamExcel>
        </v-card-title>

        <div>
          <v-skeleton-loader
            class=""
            type="list-item-three-line"
          ></v-skeleton-loader>
        </div>
      </v-card>
    </div>
    <div v-else>
      <v-row no-gutters class="ma-2 justify-center">
        <p style="font-size: 2rem">ລາຍງານຂໍ້ມູນວັດຖຸດິບ</p>
      </v-row>
      <v-row no-gutters class="ma-2">
        <v-col cols="12" md="3">
          <v-card height="270" class="mx-1">
            <v-card-title>
              <span>ລາຍການວັດຖຸດິບທັງໝົດ</span>
            </v-card-title>
            <v-row class="justify-center">
              <v-progress-circular
                :rotate="270"
                :size="200"
                :value="100"
                :width="5"
                color="#2E3192"
              >
                <span
                  :style="{
                    color: 'blue',
                    fontSize: stocks.length > 100000 ? '1rem' : '3rem',
                  }"
                  >{{
                    String(parseInt(stocks.length)).replace(
                      /(.)(?=(\d{3})+$)/g,
                      "$1,"
                    )
                  }}</span
                >
              </v-progress-circular>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="9">
          <v-card height="270" class="mx-1">
            <v-card-title class="justify-space-between">
              <span>ວັດຖຸດິບທີ່ຍັງຫຼາຍ</span>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mt-5 tcenter"
                  label="ຈໍານວນຊາທີ່ຕ້ອງການ"
                  outlined
                  dense
                  rounded
                  prepend-inner-icon="mdi-minus"
                  append-icon="mdi-plus"
                  autofocus
                  v-model="count"
                  @click:append="count = parseInt(count) + 1"
                  @click:prepend-inner="
                    count = parseInt(count) < 1 ? 0 : parseInt(count) - 1
                  "
                  @keypress="InputCount($event)"
                >
                  <template slot="append">
                    <v-btn
                      style="margin-top: -7px; margin-right: -20px"
                      color="#004D40"
                      icon
                      @click="count = parseInt(count) + 1"
                    >
                      <v-icon large>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <template slot="prepend-inner">
                    <v-btn
                      style="margin-top: -7px; margin-left: -20px"
                      color="#004D40"
                      icon
                      @click="
                        count = parseInt(count) < 1 ? 0 : parseInt(count) - 1
                      "
                    >
                      <v-icon large>mdi-minus</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-toolbar>
              <riamExcel
                class="btn btn-default"
                :fetch="fetchDataTop"
                :fields="top_json_fields"
                worksheet="ວັດຖຸດິບທີ່ຍັງຫຼາຍ"
                :name="stockTopTitle"
                :before-finish="finishDownloadTop"
              >
                <v-btn
                  class="mr-2 indigo"
                  :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
                >
                  <span style="color: white">ດືງຂໍ້ມູນ</span>
                </v-btn>
              </riamExcel>
            </v-card-title>
            <riamchart
              type="bar"
              height="200"
              :options="topStock.chartOptions"
              :series="topStock.series"
            ></riamchart>
          </v-card>
        </v-col>
      </v-row>
      <v-card class="mx-2">
        <v-card-title class="justify-space-between">
          <span>ວັດຖຸດິບໃນສະຕ໋ອກທັງໝົດ</span>

          <riamExcel
            class="btn btn-default"
            :fetch="fetchData"
            :fields="json_fields"
            worksheet="ລາຍການວັດຖຸດິບ"
            :name="stockTitle"
            :before-finish="finishDownloadTop"
          >
            <v-btn
              class="mr-2 indigo"
              :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
            >
              <span style="color: white">ດືງຂໍ້ມູນ</span>
            </v-btn>
          </riamExcel>
        </v-card-title>

        <div>
          <riamchart
            type="bar"
            :height="100 * stock.series[0].data.length"
            :options="stock.chartOptions"
            :series="stock.series"
          ></riamchart>
        </div>
      </v-card>
    </div>
  </div>
</template><script>
import { SpinnerLoader } from "vue-spinners-css";
import { mapGetters, mapActions } from "vuex";
import VueApexCharts from "vue-apexcharts";
import JsonExcel from "vue-json-excel";
export default {
  data: () => ({
    loading: true,
    count: 3,
    stockTopTitle:
      "Top-sock-" +
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10) +
      ".xls",
    stockTitle:
      "Stock-" +
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10) +
      ".xls",
    json_fields: {
      ລໍາດັບ: "idx",
      ຊື່ວັດຖຸດິບ: "rawName",
      ຈໍານວນ: "qty",
      ຫົວໜ່ວຍ: "unitName",
      ຜູ້ສະໜອງ: "supplierName",
    },
    top_json_fields: {
      ລໍາດັບ: "idx",
      ຊື່ວັດຖຸດິບ: "rawName",
      ຈໍານວນ: "qty",
      ຫົວໜ່ວຍ: "unitName",
    },
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
  }),
  components: {
    riamchart: VueApexCharts,
    riamExcel: JsonExcel,
    SpinnerLoader,
  },
  computed: {
    ...mapGetters({
      stocks: "stockMod/stocks",
      length: "stockMod/length",
      stock: "reportMod/stock",
      topStock: "reportMod/topStock",
    }),
  },
  watch: {
    length(val) {
      if (val == false) {
        this.loading = false;
      }
    },
    stocks(val) {
      if (val.length > 0) {
        this.loading = false;
      }
    },
    stock(val) {
      if (val.length > 0) {
        this.loading = false;
      }
    },

    count(val) {
      if (val == null || val == "" || val == undefined) {
        this.getTopStock(0);
      } else if (parseInt(val) > 0 && parseInt(val) <= this.stocks.length) {
        this.getTopStock(val);
      } else if (parseInt(val) > this.stocks.length) {
        console.log(val);
      } else {
        this.getTopStock(0);
      }
    },
  },
  mounted() {
    this.getTopStock(this.count);
    this.getStock();
    this.getStocks();
    this.getProducts();
    this.Loading();
  },
  methods: {
    ...mapActions("reportMod", ["getStock", "getTopStock"]),
    ...mapActions("stockMod", ["getStocks"]),
    ...mapActions("productMod", ["getProducts"]),
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    fetchData() {
      try {
        var stocklist = [];
        var obj = this.stocks.sort((a, b) =>
          a.qty > b.qty ? 1 : b.qty > a.qty ? -1 : 0
        );
        for (const idx in obj) {
          const el = obj[idx];
          var stock = {
            idx: parseInt(idx) + 1,
            rawName: el.rawName,
            unitName: el.unitName,
            supplierName: el.supplierName,
            qty: el.qty,
          };
          stocklist.push(stock);
        }
        return stocklist;
      } catch (error) {
        console.log(error);
      }
    },
    Loading() {
      if (this.length == false) {
        this.loading = false;
      }
    },
    finishDownload() {
      this.showToast(
        "success",
        `<div style='font-family:"Noto Sans Lao";' >ດາວໂລດສໍາເລັດແລ້ວ</div>`
      );
    },
    fetchDataTop() {
      try {
        if (this.stocks.length < 1) {
          return;
        }
        var obj = this.stocks.sort((a, b) =>
          a.qty > b.qty ? -1 : b.qty > a.qty ? 1 : 0
        );
        if (obj.length < this.count) {
          this.count = obj.length;
        }
        var prolist = [];
        var idx = 0;
        for (idx = 0; idx < this.count; idx++) {
          const el = obj[idx];
          var stock = {
            idx: parseInt(idx) + 1,
            rawName: el.rawName,
            unitName: el.unitName,
            supplierName: el.supplierName,
            qty: el.qty,
          };
          prolist.push(stock);
        }
        return prolist;
      } catch (error) {
        console.log(error);
      }
    },
    finishDownloadTop() {
      this.showToast(
        "success",
        `<div style='font-family:"Noto Sans Lao";' >ດາວໂລດສໍາເລັດແລ້ວ</div>`
      );
    },
  },
};
</script>
<style scoped>
.tcenter {
  width: 200px;
  min-width: 100px;
}
.tcenter >>> input {
  text-align: center;
}
</style>