<template>
  <div>
    <div >
    <v-row justify="center">
      <v-card class="elevation-3 ma-3" width="100%">
        <v-card-title class="justify-center">
          <span style="font-size: 1.2rem">ຂໍ້ມູນແຂວງທັງໝົດ</span>
        </v-card-title>
          <v-row no-gutters>
            <v-col cols="12" sm="9" class="px-2">
              <v-text-field
                label="ແຂວງ"
                append-icon="mdi-close"
                v-model="edit.name"
                @click:append="clear"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" class="px-1 pt-sm-3" :align="$vuetify.breakpoint.width < 600 ? 'center' : 'end'">
              <v-btn
                v-if="showEdit"
                @click="editComfirm"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
                class="mr-2 green"
              >
                <v-icon color="white">mdi-pencil</v-icon
                ><span style="color: white; font-weight: bold">ແກ້ໄຂ</span>
              </v-btn>
              <v-btn
                v-else
                @click="provinceAdd"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
                class=" mr-2 green"
              >
                <v-icon color="white" large>mdi-plus</v-icon
                ><span style="color: white; font-weight: bold">ເພີ່ມໃໝ່</span>
              </v-btn>
            </v-col>
          </v-row>
        <div v-if="loading">
          <v-data-table 
          :headers="headers"
          :search="search"
          :footer-props="{itemsPerPageText: 'ສະແດງຈໍານວນແຖວ :'}"
          sort-by="idx"
          class="elevation-0"
          hide-default-footer
          no-data-text=""
        ></v-data-table>
          <v-row no-gutters class="justify-center mb-10">
         <SpinnerLoader :color="'#90CAF9'" :loading="true" :size="100"/>
          </v-row>
        </div>
         <v-card flat v-else-if="proviceLength == false">
            <v-data-table
              :headers="headers"
              :search="search"
              sort-by="idx"
              class="elevation-0"
              hide-default-footer
              no-data-text=""
            ></v-data-table>
            <v-row class="justify-center mx-4">
              <v-card
                flat
                class=""
                color="transparent"
                style="font-size: 2rem; color: grey; margin-top: 40px"
                >ບໍ່ມີຂໍ້ມູນແຂວງ
              </v-card>
            </v-row>
            <v-skeleton-loader
              style="margin-top: -125px"
              class=""
              type="image"
            >
            </v-skeleton-loader>
          </v-card>
        <v-data-table v-else
            :headers="headers"
            :search="search"
            :items="provinces"
            sort-by="idx"
            class="elevation-0"
          :footer-props="{itemsPerPageText: 'ສະແດງຈໍານວນແຖວ :'}"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer class="hidden-sm-and-down"></v-spacer>
                <v-text-field
                  dense
                  rounded
                  outlined
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="ຄົ້ນຫາ"
                  single-line
                  hide-details
                ></v-text-field>
                <v-dialog v-model="Delete" max-width="500px" class="font">
                  <v-card class="font">
                    <v-card-title
                      ><v-icon size="50" color="error"
                        >mdi-exclamation-thick</v-icon
                      >
                      <span
                        >ທ່ານແນ່ໃຈບໍ? ທີ່ຈະລົບລາຍການນີ້ອອກ</span
                      ></v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="error" text @click="close"
                        >ຍົກເລີກ</v-btn
                      >
                      <v-btn
                        color="green"
                        text
                        @click="deleteProvinceConfirm"
                        >ຕົກລົງ</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>

              <v-divider class=""></v-divider>
              <v-divider class=""></v-divider>
            </template>
          
          </v-data-table>
      </v-card>
    </v-row>
  </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
  import { SpinnerLoader } from 'vue-spinners-css';
export default {
  data: () => ({
    loading:true,
    showEdit: false,
    search: "",
    headers: [
      { text: "ລໍາດັບ", value: "idx", class: "indigo white--text" },
      { text: "ຊື່ແຂວງ", value: "name", class: "indigo white--text" },
     
    ],
    edit: {
      _id: "",
      name: "",
      createdAt: null,
      updatedAt: null,
    },
    deleteItem: [],
  }),
  components: {
				SpinnerLoader,
			},
  computed: {
    ...mapGetters({
      provinces: "addressMod/provinces",
      proviceLength: "addressMod/proviceLength",
    }),
  },
  watch:{
proviceLength(val){
if(val==false){
this.loading=false
}
},
provinces(val){
if(val.length>0){
this.loading=false
}
}
  },
  mounted() {
    this.getProvinces();
    this.Loading()
  },
  methods: {
    ...mapActions("addressMod", [
      "getProvinces",
      "insertProvince",
      "updateProvince",
      "delProvince",
    ]),
    showAlert() {
      this.$swal({
        position: "center",
        icon: "warning",
        title: `<div style='font-family:"Noto Sans Lao";color:red' >ເຕືອນ</div>`,
        html: `<div style='font-family:"Noto Sans Lao"; font-size:1.5rem' >ທ່ານຕ້ອງການລົບອອກບໍ?</div>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: `<div style='font-family:"Noto Sans Lao";color:white' >ຕົກລົງ</div>`,
        cancelButtonText: `<div style='font-family:"Noto Sans Lao";color:white' >ຍົກເລີກ</div>`,
        confirmButtonColor: "green",
        cancelButtonColor: "red",
        reverseButtons: true,
        buttonsStyling: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deleteProvinceConfirm();
        }
      });
    },
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    Loading() {
      if (this.proviceLength == false) {
        this.loading = false;
      }
    },
    clear() {
      this.edit = {
        idx: 0,
        _id: null,
        name: "",
        createdAt: null,
        updatedAt: null,
      };
      this.deleteItem=[]
    },

    provinceAdd() {
      try {
        if (this.edit.name == null || this.edit.name == "") {
           this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາໃສ່ຊື່ແຂວງ</div>`,)
          return;
        }
          this.insertProvince(this.edit).then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
            this.getProvinces();
           this.showToast("success",`<div style='font-family:"Noto Sans Lao";' >ບັນທຶກຂໍ້ມູນສໍາເລັດແລ້ວ</div>`,)
              this.clear();
            } else {
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            }
          })
          .catch( (error) =>{
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            console.log(error);
          });
      } catch (error) {
        console.log(error);
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
      }
    },
    editeProvince(item) {
      this.edit = item;
      this.showEdit = true;
    },
    editComfirm() {
      try{
      if (this.edit.name == null || this.edit.name == "") {
        return;
      }
          this.updateProvince(this.edit).then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
            this.showToast("success",`<div style='font-family:"Noto Sans Lao";' >ແກ້ໄຂຂໍ້ມູນສໍາເລັດ</div>`,)
            this.getProvinces();
            this.showEdit = false;
              this.clear();
            } else {
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            }
          })
          .catch( (error) =>{
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            console.log(error);
          });
      } catch (error) {
        console.log(error);
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
      }
    },
    deleteProvince(item) {
      this.deleteItem = item;
      this.showAlert()
    },
    deleteProvinceConfirm() {
      try{
      this.delProvince(this.deleteItem).then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
            this.showToast("success",`<div style='font-family:"Noto Sans Lao";' >ລົບຂໍ້ມູນສໍາເລັດແລ້ວ</div>`,)
            this.getProvinces();
              this.clear();
            } else {
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            }
          })
          .catch( (error) =>{
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            console.log(error);
          });
      } catch (error) {
        console.log(error);
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
      }
    },
  },
};
</script>
<style scoped>
</style>
