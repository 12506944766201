<template>
  <div class="font">
    <v-row no-gutters class="justify-end">
              <v-btn
                v-print="'#barcode'"
                class="ma-2  green white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-printer</v-icon>print
              </v-btn>
            </v-row>
          <v-card id="barcode" flat  style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
             <p style="font-size:2rem;">ບາໂຄດວັດຖຸດິບ : {{raw.barCode}}</p>
            </v-row>
            <v-row no-gutters class="ma-5">
             <div  v-for="(item, index) in raw.qty" :key="index">
                <v-card flat class="ma-5">
                  <barcode
                :value="raw.barCode"
              >
              </barcode>
                </v-card>
             </div>
            </v-row>
          </v-card>
  </div>
</template>
<script>
import VueBarcode from "vue-barcode";
import print from 'vue-print-nb'
export default {
  data: () => ({
    tag: "svg",
    options: {
      lineColor: "#0f70f9",
      font: "NoTo San Lao",
      format: "MSI",
      background: "#ccffff",
    },
    raw:{
      id:0,
      barCode:'barCode2021',
      qty:100,
    }
  }),

directives: {
    print   
},
  computed: {},
  components: {
    barcode: VueBarcode,
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
    this.raw.id=this.$route.params.id
    this.raw.barCode=this.$route.params.id
    this.raw.qty=parseInt(this.$route.query.qty)
    },
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    number(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        this.showToast(
          "warning",
          `<div style='font-family:"Noto Sans Lao";color:red' >ປ້ອນສະເພາະຕົວເທົ່ານັ້ນ (0-9)</div>`
        );
        evt.preventDefault();
      } else {
        return true;
      }
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style lang="css" scoped>
  
.font {
  font-family: "NotoSansLao";
}
</style>