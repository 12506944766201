<template>
  <div>
    <v-row no-gutters class="justify-center justify-md-start mt-3 mx-sm-2 mx-lg-10 " >
      <p style="font-size:1.7rem" v-if="products.length>0">ຜະລິດຕະພັນ</p>
      <p style="font-size:1.7rem" v-else>ບໍ່ມີຂໍ້ມູນຜະລິດຕະພັນ</p>
    </v-row>
     <v-row no-gutters class="mx-sm-2 mx-lg-10" v-if="products.length>0">
                <v-col
                  v-for="(pro, idx) in products"
                  :key="idx"
                  class="d-flex child-flex"
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-hover
                    class="grey lighten-5  ma-2"
                    v-slot="{ hover }"
                    open-delay="200"
                    v-ripple="{ class: `white--text` }"
                  >
                    <v-card
                      :elevation="hover ? 10 : 0"
                      :class="{ 'on-hover': hover}"
                    >
                      <v-card
                            class="mx-auto grey lighten-4 elevation-0"
                            height="400"
                            :img="pro.image"
                          ></v-card>

                      <v-card class="grey lighten-5 px-5 mt-5" flat>
                        <v-row >
                          <div>{{ pro.productName }}</div>
                          </v-row>
                        <v-row>
                          <div>ລາຄາຂາຍສົ່ງ: {{ String(parseInt(pro.wholeSale)).replace(/(.)(?=(\d{3})+$)/g,'$1,') }} ກີບ</div>
                        </v-row>
                        <v-row class="mb-2">
                          <div>ລາຄາຂາຍຍ່ອຍ: {{ String(parseInt(pro.retail)).replace(/(.)(?=(\d{3})+$)/g,'$1,') }} ກີບ</div>
                        </v-row>
                        
                      </v-card>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
                <v-row no-gutters class="mx-sm-2 mx-lg-10" v-else>
      <v-col
        v-for=" idx in 3"
        :key="idx"
        class="d-flex child-flex"
        cols="12"
        sm="6"
        md="4"
      >
        <v-skeleton-loader  class="mx-3"
          type="image,list-item-three-line"
        ></v-skeleton-loader>
      </v-col>
    </v-row> 
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        model: 0,
      }
    },
  computed: {
    ...mapGetters({
      products:'proCMod/products'
    }),
    
  }, mounted(){
    this.getProduct()
  },
  methods: {
    ...mapActions("proCMod", [
      "getProduct",
    ]),
  },
  }
</script>