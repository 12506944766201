<template>
  <div>
    <div>
      <v-row justify="center">
        <v-card class="elevation-3 ma-3" width="100%">
          <v-card-title>
            <v-row no-gutters>
              <v-col
                cols="12"
                md="3"
                :align="$vuetify.breakpoint.width < 960 ? 'center' : 'start'"
              >
                <span style="font-size: 1.2rem">ຂໍ້ມູນບ້ານທັງໝົດ</span>
                <v-divider vertical class="mx-2 hidden-sm-and-down"></v-divider>
              </v-col>
              <v-col cols="12" md="9">
                <v-row>
                  <v-spacer class="hidden-sm-and-down"></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="ຄົ້ນຫາ"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <router-link to="/admin/address/village/add">
                    <v-btn class="mt-3 mx-2 green">
                      <v-icon color="white" large>mdi-plus</v-icon
                      ><span style="color: white; font-weight: bold"
                        >ເພີ່ມໃໝ່</span
                      >
                    </v-btn>
                  </router-link>
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>
          <div v-if="loading">
            <v-data-table
              :headers="headers"
              :search="search"
              sort-by="idx"
              class="elevation-0"
              hide-default-footer
              no-data-text=""
            ></v-data-table>
            <v-row no-gutters class="justify-center mb-10">
              <SpinnerLoader :color="'#90CAF9'" :loading="true" :size="100" />
            </v-row>
          </div>
          <v-card flat v-else-if="villageLength == false">
            <v-data-table
              :headers="headers"
              :search="search"
              sort-by="idx"
              class="elevation-0"
              hide-default-footer
              no-data-text=""
            ></v-data-table>
            <v-row class="justify-center mx-4">
              <v-card
                flat
                class=""
                color="transparent"
                style="font-size: 2rem; color: grey; margin-top: 40px"
                >ບໍ່ມີຂໍ້ມູນບ້ານ
              </v-card>
            </v-row>
            <v-skeleton-loader style="margin-top: -125px" class="" type="image">
            </v-skeleton-loader>
          </v-card>
          <v-data-table
            v-else
            :headers="headers"
            :search="search"
            :items="villages"
            :footer-props="{ itemsPerPageText: 'ສະແດງຈໍານວນແຖວ :' }"
            sort-by="idx"
            class="elevation-0"
          >
            <template v-slot:item.actions="{ item }">
              <v-row no-gutters class="justify-center">
                <v-icon
                  color="light-blue lighten-1"
                  class="mr-2"
                  @click="editeVillage(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon color="error" @click="deleteVillage(item)">
                  mdi-delete
                </v-icon>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { SpinnerLoader } from "vue-spinners-css";
export default {
  data: () => ({
    loading: true,
    search: "",
    headers: [
      { text: "ລໍາດັບ", value: "idx", class: "indigo white--text" },
      { text: "ຊື່ບ້ານ", value: "name", class: "indigo white--text" },
      { text: "ຊື່ເມືອງ", value: "districtName", class: "indigo white--text" },
      { text: "ຊື່ແຂວງ", value: "provinceName", class: "indigo white--text" },
      {
        text: "Actions",
        align: "center",
        value: "actions",
        sortable: false,
        class: "indigo white--text",
      },
    ],
    deleteItem: [],
  }),
  components: {
    SpinnerLoader,
  },
  computed: {
    ...mapGetters({
      villages: "addressMod/villages",
      villageLength: "addressMod/villageLength",
    }),
  },
  watch: {
    villages(val) {
      if (val.length > 0) {
        this.loading = false;
      }
    },
    villageLength(val) {
      if (val == false) {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getVillages();
    this.Loading();
  },
  methods: {
    ...mapActions("addressMod", ["getVillages", "delVillage"]),
    showAlert() {
      this.$swal({
        position: "center",
        icon: "warning",
        title: `<div style='font-family:"Noto Sans Lao";' >ເຕືອນ</div>`,
        html: `<div style='font-family:"Noto Sans Lao"; font-size:1.5rem' >ທ່ານຕ້ອງການລົບອອກບໍ?</div>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: `<div style='font-family:"Noto Sans Lao";' >ຕົກລົງ</div>`,
        cancelButtonText: `<div style='font-family:"Noto Sans Lao";' >ຍົກເລີກ</div>`,
        confirmButtonColor: "green",
        cancelButtonColor: "red",
        reverseButtons: true,
        buttonsStyling: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteVillageConfirm();
        }
      });
    },
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    Loading() {
      if (this.villageLength == false) {
        this.loading = false;
      }
    },
    clear() {
      this.deleteItem = [];
    },
    closeVillage() {
      this.Delete = false;
    },
    editeVillage(item) {
      this.$router.push(`/admin/address/village/edit/${item._id}`);
    },

    deleteVillage(item) {
      this.deleteItem = item;
      this.showAlert();
    },
    deleteVillageConfirm() {
      try {
        this.delVillage(this.deleteItem)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
              this.showToast(
                "success",
                `<div style='font-family:"Noto Sans Lao";' >ລົບຂໍ້ມູນສໍາເລັດແລ້ວ</div>`
              );
              this.getVillages();
              this.clear();
            } else {
              this.showToast(
                "error",
                `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "error",
              `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
            );
            console.log(error);
          });
      } catch (error) {
        console.log(error);
        this.showToast(
          "error",
          `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
        );
      }
    },
  },
};
</script>
<style scoped>
.font {
  font-family: "NotoSansLao";
}
</style>
