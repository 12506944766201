<template>
  <div>
    <v-card flat color="transparent">
      <v-row no-gutters class="justify-center my-5 mx-2">
        <v-col cols="12" sm="6" md="4" lg="2">
          <v-card
            class="ma-2"
            height="100"
            width="96%"
            color="white"
            ripple
            @click="linkTo('/admin/manage/product')"
          >
            <v-card-title class="ma-0">
              <v-row no-gutters class="mx-1 justify-center"
                ><span :style="{fontSize:(products.length>10000000)?'1rem':'2rem'}">
                  {{ String(parseInt(products.length)).replace(
                        /(.)(?=(\d{3})+$)/g,
                        "$1,"
                      ) }}</span></v-row
              >
            </v-card-title>
            <v-row no-gutters class="mx-1 justify-center">
              <v-card flat color="transparent" height="30" width="96%">
                <v-row no-gutters class="justify-center"
                  ><p>ຜະລິດຕະພັນທັງໝົດ</p></v-row
                >
              </v-card>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="2">
          <v-card
            class="ma-2"
            height="100"
            width="96%"
            color="white"
            ripple
            @click="linkTo('/admin/stock')"
          >
            <v-card-title class="ma-0">
              <v-row no-gutters class="mx-1 justify-center"
                ><span :style="{fontSize:(stocks.length>10000000)?'1rem':'2rem'}">
                  {{ String(parseInt(stocks.length || 0)).replace(
                        /(.)(?=(\d{3})+$)/g,
                        "$1,"
                      ) }}</span></v-row
              >
            </v-card-title>
            <v-row no-gutters class="mx-1 justify-center">
              <v-card flat color="transparent" height="30" width="96%">
                <v-row no-gutters class="justify-center"
                  ><p>ຂໍ້ມູນວັດຖຸດິບທັງໝົດ</p></v-row
                >
              </v-card>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="2">
          <v-card
            class="ma-2"
            height="100"
            width="96%"
            color="white"
            ripple
            @click="linkTo('/admin/order')"
          >
            <v-card-title class="ma-0">
              <v-row no-gutters class="mx-1 justify-center"
                ><span :style="{fontSize:(orders.length>10000000)?'1rem':'2rem'}">{{
                      String(parseInt(orders.length)).replace(
                        /(.)(?=(\d{3})+$)/g,
                        "$1,"
                      )
                    }}</span></v-row
              >
            </v-card-title>
            <v-row no-gutters class="mx-1 justify-center">
              <v-card flat color="transparent" height="30" width="96%">
                <v-row no-gutters class="justify-center"
                  ><p>
                    ສັ່ງຊື້ທັງໝົດ
                  </p></v-row
                >
              </v-card>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="2">
          <v-card
            class="ma-2"
            height="100"
            width="96%"
            color="white"
            ripple
            @click="linkTo('/admin/manage/customer')"
          >
            <v-card-title class="ma-0">
              <v-row no-gutters class="mx-1 justify-center"
                ><span :style="{fontSize:(customers.length>10000000)?'1rem':'2rem'}">
                  {{String(parseInt(customers.length || 0)).replace(
                        /(.)(?=(\d{3})+$)/g,
                        "$1,"
                      )   }}</span></v-row
              >
            </v-card-title>
            <v-row no-gutters class="mx-1 justify-center">
              <v-card flat color="transparent" height="30" width="96%">
                <v-row no-gutters class="justify-center"
                  ><p>ສາຂາທັງໝົດ</p></v-row
                >
              </v-card>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="2">
          <v-card
            class="ma-2"
            height="100"
            width="96%"
            color="white"
            ripple
            @click="linkTo('/admin/manage/supplier')"
          >
            <v-card-title class="ma-0">
              <v-row no-gutters class="mx-1 justify-center"
                ><span :style="{fontSize:(suppliers.length>10000000)?'1rem':'2rem'}">
                  {{ String(parseInt(suppliers.length || 0)).replace(
                        /(.)(?=(\d{3})+$)/g,
                        "$1,"
                      )  }}</span></v-row
              >
            </v-card-title>
            <v-row no-gutters class="mx-1 justify-center">
              <v-card flat color="transparent" height="30" width="96%">
                <v-row no-gutters class="justify-center"
                  ><p>ຜູ້ສະໜອງທັງໝົດ</p></v-row
                >
              </v-card>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="2">
          <v-card
            class="ma-2"
            height="100"
            width="96%"
            color="white"
            ripple
            @click="linkTo('/admin/manage/warehouse')"
          >
            <v-card-title class="ma-0">
              <v-row no-gutters class="mx-1 justify-center"
                ><span :style="{fontSize:(warehouses.length>10000000)?'1rem':'2rem'}">
                  {{ String(parseInt(warehouses.length || 0)).replace(
                        /(.)(?=(\d{3})+$)/g,
                        "$1,"
                      ) }}</span></v-row
              >
            </v-card-title>
            <v-row no-gutters class="mx-1 justify-center">
              <v-card flat color="transparent" height="30" width="96%">
                <v-row no-gutters class="justify-center"
                  ><p>ສາງທັງໝົດ</p></v-row
                >
              </v-card>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="6" lg="6">
          <v-card
            class="ma-2"
            height="100"
            width="96%"
            color="#045736"
            ripple
            @click="linkTo('/admin/order')"
          >
            <v-card-title class="ma-0">
              <v-row no-gutters class="mx-1 justify-center"
                ><span :style="{fontSize:(ordersTotals>1000000000000)?'1rem':'2rem',color:'white'}">{{
                      String(parseInt(ordersTotals)).replace(
                        /(.)(?=(\d{3})+$)/g,
                        "$1,"
                      ) 
                    }} </span></v-row
              >
            </v-card-title>
            <v-row no-gutters class="mx-1 justify-center">
              <v-card flat color="transparent" height="30" width="96%">
                <v-row no-gutters class="justify-center"
                  ><p style="color:white">
                    ລາຍຮັບທັງໝົດ(ກີບ)
                  </p></v-row
                >
              </v-card>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="6" lg="6">
          <v-card
            class="ma-2"
            height="100"
            width="96%"
            color="#901623"
            ripple
            @click="linkTo('/admin/expense')"
          >
            <v-card-title class="ma-0">
              <v-row no-gutters class="mx-1 justify-center"
                ><span :style="{fontSize:(expensesTotals>1000000000000)?'1rem':'2rem',color:'white'}">{{
                      String(parseInt(expensesTotals)).replace(
                        /(.)(?=(\d{3})+$)/g,
                        "$1,"
                      ) 
                    }} </span></v-row
              >
            </v-card-title>
            <v-row no-gutters class="mx-1 justify-center">
              <v-card flat color="transparent" height="30" width="96%">
                <v-row no-gutters class="justify-center"
                  ><p style="color:white">
                    ລາຍຈ່າຍທັງໝົດ(ກີບ)
                  </p></v-row
                >
              </v-card>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters class="justify-center my-5 mx-2">
        <v-card width="100%" class="mx-0 mx-md-3 mb-5 pt-2">
          <v-toolbar flat>
            <h3>ປະຫວັດລາຍຮັບ/ລາຍຈ່າຍປະຈໍາ{{ selectDate }}</h3>
            <v-spacer></v-spacer>
            <v-card class="elevation-0 mt-1" color="transparent" width="100">
              <v-select
                v-model="selectDate"
                :items="date_list"
                single-line
                placeholder="Date"
                hint="Date"
              ></v-select>
            </v-card>
          </v-toolbar>
          <riamchart
            height="350"
            :options="(  expense==null||expense==undefined)?[]:expense.chartOptions"
            :series="(  expense==null||expense==undefined)?[]:expense.series"
          ></riamchart>
          <v-row class="justify-space-around my-3">
            <div>
              ລວມລາຍຮັບທັງໝົດ: <span style="color:#045736">
              {{
                String(parseInt(expense.totalIncome)||0).replace(/(.)(?=(\d{3})+$)/g, "$1,") || 0
              }}
            </span> 
              ກີບ</div>
            <div>
              ລວມລາຍຈ່າຍທັງໝົດ: <span style="color:#901623">
              {{
                String(parseInt(expense.totalPay)||0).replace(/(.)(?=(\d{3})+$)/g, "$1,") || 0
              }}
            </span> 
              ກີບ</div>
          </v-row>
        </v-card>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import VueApexCharts from "vue-apexcharts";
export default {
  data: () => ({
    date_list: ["ວັນທີ່", "ເດືອນ", "ປີ"],
    selectDate: "ວັນທີ່",
  }),
  components: {
    riamchart: VueApexCharts,
  },
  computed: {
    ...mapGetters({
      customers: "customerMod/customers",
      products: "productMod/products",
      stocks: "stockMod/stocks",
      rawdatas: "rawdataMod/rawdatas",
      suppliers: "supplierMod/suppliers",
      warehouses: "warehouseMod/warehouses",
      orders: "orderMod/orders",
      expense: "reportMod/expense",
      expenses: "expenseMod/expenses", 
       orderList: "reportMod/orderList",
    }),
    ordersTotals() {
      var total = 0;
      if (this.orderList.length > 0) {
        for (const key in this.orderList) {
          const element = this.orderList[key];
          if (
            element.totalPayment != null &&
            element.totalPayment != undefined
          ) {
            total += parseFloat(element.totalPayment);
          }
        }
      }
      return total;
    },
    expensesTotals() {
      var total = 0;
      if (this.expenses.length > 0) {
        for (const key in this.expenses) {
          const element = this.expenses[key];
          if (
            element.qty != null &&
            element.qty != undefined &&
            element.price != null &&
            element.price != undefined
          ) {
            total += parseFloat(element.qty) * parseFloat(element.price);
          }
        }
      }
      return total;
    },
  },
  watch: {
    selectDate(date) {
      if (date == "ວັນທີ່") {
        this.getExpenseDay();
      }
      if (date == "ເດືອນ") {
        this.getExpenseMonth();
      }
      if (date == "ປີ") {
        this.getExpenseYear();
      }
    },
  },
  created(){
    this.loadingToken()
    this.getExpenses();
  },
  mounted() {
    this.getCustomers();
    this.getProducts();
    this.getStocks();
    this.getRawdatas();
    this.getSuppliers();
    this.getWarehouses();
    this.getOrders();
    this.getExpenses();
    this.getExpenseDay();
    this.getOrderList();
  },
  methods: {
    ...mapActions("customerMod", ["getCustomers"]),
    ...mapActions("productMod", ["getProducts"]),
    ...mapActions("stockMod", ["getStocks",]),
    ...mapActions("rawdataMod", ["getRawdatas"]),
    ...mapActions("supplierMod", ["getSuppliers"]),
    ...mapActions("warehouseMod", ["getWarehouses"]),
    ...mapActions("orderMod", ["getOrders"]),
    ...mapActions("expenseMod", ["getExpenses"]),
    ...mapActions("reportMod", [
      "getExpenseDay",
      "getExpenseMonth",
      "getExpenseYear",
      "getOrderList"
    ]),
    linkTo(link) {
      this.$router.push(link);
    },
    loadingToken(){
      console.log(window.localStorage.getItem('token'))
    }
  },
};
</script>
<style scoped>
span {
  font-size: 1.7rem;
  color: blue;
}
p {
  color: black;
}
</style>