<template>
  <div>
    <div>
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <v-col
              cols="12"
              md="3"
              :align="$vuetify.breakpoint.width < 960 ? 'center' : 'start'"
            >
              <span style="font-size: 1.2rem">ຜະລິດຕະພັນທັງໝົດ</span>
              <v-divider vertical class="mx-2 hidden-sm-and-down"></v-divider>
            </v-col>
            <v-col cols="12" md="9">
              <v-row>
                <v-spacer class="hidden-sm-and-down"></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="ຄົ້ນຫາ"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <router-link to="/staff/product/add">
                  <v-btn class="mt-3 mx-2 green">
                    <v-icon color="white" large>mdi-plus</v-icon
                    ><span style="color: white">ເພີ່ມໃໝ່</span>
                  </v-btn>
                </router-link>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <div v-if="loading">
          <v-data-table
            :headers="headers"
            :search="search"
            sort-by="idx"
            class="elevation-0"
            hide-default-footer
            no-data-text=""
          ></v-data-table>
          <v-row no-gutters class="justify-center pb-10">
            <SpinnerLoader :color="'#90CAF9'" :loading="true" :size="100" />
          </v-row>
        </div>
           <v-card flat v-else-if="length == false">
            <v-data-table
              :headers="headers"
              :search="search"
              sort-by="idx"
              class="elevation-0"
              hide-default-footer
              no-data-text=""
            ></v-data-table>
            <v-row class="justify-center mx-4">
              <v-card
                flat
                class=""
                color="transparent"
                style="font-size: 2rem; color: grey; margin-top: 40px"
                >ບໍ່ມີຂໍ້ມູນຜະລິດຕະພັນ
              </v-card>
            </v-row>
            <v-skeleton-loader
              style="margin-top: -125px"
              class=""
              type="image"
            >
            </v-skeleton-loader>
          </v-card>
        <v-data-table
          v-else
          :headers="headers"
          :items="products"
          :search="search"
          item-key="id"
          sort-by="idx"
          class=""
          :footer-props="{itemsPerPageText: 'ສະແດງຈໍານວນແຖວ :'}"
        >
          <template v-slot:item="{ item }">
            <tr v-ripple="{ class: `white--text` }">
              <td align="center">{{ item.idx }}</td>
              <td align="center">
                <v-img
                  height="100"
                  width="100"
                  contain
                  :src="item.image"
                  :lazy-src="item.image"
                >
                <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="#90CAF9"
            ></v-progress-circular>
          </v-row>
        </template>
                </v-img>
              </td>
              <td>{{ item.productName }}</td>
              <!-- <td>{{ item.category }}</td> -->
              <td>
                {{ String(item.retail).replace(/(.)(?=(\d{3})+$)/g, "$1,") }}
              </td>
              <td>
                {{ String(item.wholeSale).replace(/(.)(?=(\d{3})+$)/g, "$1,") }}
              </td>
              <td>
               
                <span >{{
                  String(item.qty).replace(/(.)(?=(\d{3})+$)/g, "$1,")
                }}</span>
              </td>
              
              <td>
                {{ item.barCodeNumber }}
              </td>
             
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { SpinnerLoader } from "vue-spinners-css";
export default {
  data: () => ({
    loading: true,
    barCode:null,
    search: "",
    deleteItem: [],
    headers: [
      { text: "ລໍາດັບ", value: "idx", 
        align: "center", class: "indigo white--text" },
      {
        text: "ຮູບ",
        align: "center",
        sortable: false,
        value: "image",
        class: "indigo white--text",
      },
      { text: "ຜະລິດຕະພັນ", value: "productName", class: "indigo white--text" },
      // { text: "ປະເພດ", value: "category", class: "indigo white--text" },
      {
        text: "ລາຄາຂາຍຍ່ອຍ(ກີບ)",
        value: "retail",
        class: "indigo white--text",
      },
      {
        text: "ລາຄາຂາຍສົ່ງ(ກີບ)",
        value: "wholeSale",
        class: "indigo white--text",
      },
      { text: "ຈໍານວນ", value: "qty", class: "indigo white--text" },
       { text: " ລະຫັດບາໂຄດ", value: "barCodeNumber", class: "indigo white--text" },
     
    ],
  }),

  components: {
    SpinnerLoader,
  },
  computed: {
    ...mapGetters({
      products: "productMod/products",
      length: "productMod/length",
    }),
  },

  watch: {
    length(val) {
      if (val==false) {
        this.loading = false;
      }
    }, 
    products(val) {
      if (val.length > 0) {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.getProducts();
    this.Loading()
  },
  methods: {
    ...mapActions("productMod", ["getProduct", "getProducts", "delProduct"]),
    showAlert() {
      this.$swal({
        position: "center",
        icon: "warning",
        title: `<div style='font-family:"Noto Sans Lao";' >ເຕືອນ</div>`,
        html: `<div style='font-family:"Noto Sans Lao"; font-size:1.5rem' >ທ່ານຕ້ອງການລົບອອກບໍ?</div>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: `<div style='font-family:"Noto Sans Lao";' >ຕົກລົງ</div>`,
        cancelButtonText: `<div style='font-family:"Noto Sans Lao";' >ຍົກເລີກ</div>`,
        confirmButtonColor: "green",
        cancelButtonColor: "red",
        reverseButtons: true,
        buttonsStyling: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteProductConfirm();
        }
      });
    },
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    Loading(){
if (this.length==false) {
  this.loading=false
}
    },
    routeBarCode() {
      if (this.barCode != null || this.barCode != undefined) {
        this.$router.push({
          path: `/staff/product/edit/barcode/${this.barCode}`,
        });
      }
    },

  },
};
</script>
<style scoped></style>
