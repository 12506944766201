<template>
  <div>
    <div v-if="loading">
      <v-row no-gutters class="justify-center pb-10 mt-16 pt-16">
        <SpinnerLoader :color="'#90CAF9'" :loading="true" :size="200" />
      </v-row>
    </div>
    <div v-else-if="length == false">
      <v-row no-gutters class="ma-2 justify-center">
        <p style="font-size: 2rem">ລາຍງານຂໍ້ມູນສັ່ງຊື້</p>
      </v-row>

      <v-row no-gutters class="ma-2 justify-center">
        <v-col cols="12" md="4" class="mb-5 mb-sm-0">
          <v-card height="130" class="mx-1">
            <v-card-title>
              <span>ລາຍງານຕາມລະຫັດບາໂຄດ</span>
            </v-card-title>
            <v-text-field
              v-model="barCode"
              dense
              outlined
              class="mx-2"
              label="ລະຫັດບາໂຄດ"
              @keyup.enter="routeBarCode"
            ></v-text-field>
          </v-card>
        </v-col>
        <v-col cols="12" md="8" class="mb-5 mb-sm-0">
          <v-card height="130" class="mx-1">
            <v-card-title>
              <span>ລາຍງານຕາມວັນທີ</span>
            </v-card-title>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-menu
                v-model="menu5"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    class="mx-1"
                    v-model="date5"
                    label="ວັນທີ່ເລີ່ມຕົ້ນ"
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar"
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title
                  v-model="date5"
                  :max="maxDate"
                  @input="menu5 = false"
                ></v-date-picker>
              </v-menu>
              <v-spacer></v-spacer>
              <h1 style="margin-top: -28px">-</h1>
              <v-spacer></v-spacer>
              <v-menu
                v-model="menu6"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    class="mx-1"
                    v-model="date6"
                    label="ວັນທີ່ສີ້ນສຸດ"
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date6"
                  :max="maxDate"
                  no-title
                  @input="menu6 = false"
                ></v-date-picker>
              </v-menu>
              <v-spacer></v-spacer>
              <v-btn
                @click="routerDate"
                style="margin-top: -28px"
                class="mr-2 green"
              >
                <span style="color: white">ເບີ່ງລາຍລະອຽດ</span>
              </v-btn>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-2">
        <v-col cols="12" md="3">
          <v-card height="210" class="mx-1">
            <v-card-title>
              <span>ຍອດມູນຄ່າສັ່ງຊື້ທັງໝົດ</span>
            </v-card-title>
            <v-row class="justify-center">
              <v-progress-circular
                :rotate="180"
                :size="150"
                :value="ordersTotals > 0 ? ordersTotals : 100"
                :width="3"
                color="green"
              >
                <span
                  :style="{
                    color: 'green',
                    fontWeight: 'bold',
                    fontSize: ordersTotals > 1000000000 ? '1rem' : '1.5rem',
                  }"
                  >{{
                    String(parseInt(ordersTotals)).replace(
                      /(.)(?=(\d{3})+$)/g,
                      "$1,"
                    )
                  }}</span
                >
              </v-progress-circular>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="9">
          <v-card height="210" class="mx-1">
            <v-card-title>
              <span>ສັ່ງຊື້ເປັນຊ່ວງເວລາ</span>
            </v-card-title>
            <v-row class="justify-center mx-2">
              <v-toolbar flat>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      style="margin-top: 20px"
                      class="mx-1"
                      v-model="date1"
                      label="ວັນທີ່ເລີ່ມຕົ້ນ"
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    v-model="date1"
                    :max="maxDate"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <h1>-</h1>
                <v-spacer></v-spacer>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      style="margin-top: 20px"
                      class="mx-1"
                      v-model="date2"
                      label="ວັນທີ່ສີ້ນສຸດ"
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date2"
                    no-title
                    :max="maxDate"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <riamExcel
                  class="btn btn-default"
                  :fetch="fetchData"
                  :fields="json_fields"
                  worksheet="ລາຍການສັ່ງຊື້"
                  :name="excelTitle"
                  :before-finish="finishDownload"
                >
                  <v-btn class="mt-0 mr-2 indigo">
                    <span style="color: white">ດືງຂໍ້ມູນ</span>
                  </v-btn>
                </riamExcel>
              </v-toolbar>
            </v-row>
            <v-row class="justify-space-around">
              <v-card class="" color="#2E3192" width="70%">
                <v-row class="justify-center ma-2 mt-4">
                  <span style="font-size: 2rem; color: white"
                    >{{
                      String(parseInt(totalDate)).replace(
                        /(.)(?=(\d{3})+$)/g,
                        "$1,"
                      )
                    }}
                    ກີບ</span
                  >
                </v-row>
              </v-card>
            </v-row>
            <div></div>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters class="justify-center my-5 mx-2">
        <v-card width="100%" class="mx-1 mb-5 pt-2">
          <v-toolbar flat>
            <p p style="font-size: 1.2rem">ປະຫວັດລາຍຮັບປະຈໍາ{{ selectDate }}</p>
            <v-spacer></v-spacer>
            <v-card class="elevation-0 mt-1" color="transparent" width="100">
              <v-select
                v-model="selectDate"
                :items="date_list"
                single-line
                placeholder="Date"
                hint="Date"
              ></v-select>
            </v-card>
          </v-toolbar>
          <riamchart
            type="line"
            height="350"
            :options="income.chartOptions"
            :series="income.series"
          ></riamchart>
          <v-row class="justify-space-around my-3">
            <p p style="font-size: 1.2rem">
              ລວມລາຍຮັບທັງໝົດ:
              <span style="font-size: 2rem; color: green">
                {{
                  String(income.total).replace(/(.)(?=(\d{3})+$)/g, "$1,") || 0
                }}</span
              >
              ກີບ
            </p>
          </v-row>
        </v-card>
      </v-row>
    </div>

    <div v-else>
      <v-row no-gutters class="ma-2 justify-center">
        <p style="font-size: 2rem">ລາຍງານຂໍ້ມູນສັ່ງຊື້</p>
      </v-row>

      <v-row no-gutters class="ma-2 justify-center">
        <v-col cols="12" md="12" class="mb-5 mb-sm-0">
          <v-card height="130" class="mx-1">
            <v-card-title>
              <span>ລາຍງານຕາມວັນທີ </span>
            </v-card-title>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-menu
                v-model="menu5"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    class="mx-1"
                    v-model="date5"
                    label="ວັນທີ່ເລີ່ມຕົ້ນ"
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar"
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title
                  v-model="date5"
                  :max="maxDate"
                  @input="menu5 = false"
                ></v-date-picker>
              </v-menu>
              <v-spacer></v-spacer>
              <h1 style="margin-top: -28px">-</h1>
              <v-spacer></v-spacer>
              <v-menu
                v-model="menu6"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    class="mx-1"
                    v-model="date6"
                    label="ວັນທີ່ສີ້ນສຸດ"
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date6"
                  :max="maxDate"
                  no-title
                  @input="menu6 = false"
                ></v-date-picker>
              </v-menu>
              <v-spacer></v-spacer>
              <v-btn
                @click="routerDate"
                style="margin-top: -28px"
                class="mr-2 green"
              >
                <span style="color: white">ເບີ່ງລາຍລະອຽດ</span>
              </v-btn>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-2">
        <v-col cols="12" md="3">
          <v-card height="210" class="mx-1">
            <v-card-title>
              <span>ຍອດມູນຄ່າສັ່ງຊື້ທັງໝົດ</span>
            </v-card-title>
            <v-row class="justify-center">
              <v-progress-circular
                :rotate="180"
                :size="150"
                :value="ordersTotals > 0 ? ordersTotals : 100"
                :width="3"
                color="green"
              >
                <span
                  :style="{
                    color: 'green',
                    fontWeight: 'bold',
                    fontSize: ordersTotals > 1000000000 ? '1rem' : '1.5rem',
                  }"
                  >{{
                    String(parseInt(ordersTotals)).replace(
                      /(.)(?=(\d{3})+$)/g,
                      "$1,"
                    )
                  }}</span
                >
              </v-progress-circular>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="9">
          <v-card height="210" class="mx-1">
            <v-card-title>
              <span>ສັ່ງຊື້ເປັນຊ່ວງເວລາ</span>
            </v-card-title>
            <v-row class="justify-center mx-2">
              <v-toolbar flat>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      style="margin-top: 20px"
                      class="mx-1"
                      v-model="date1"
                      label="ວັນທີ່ເລີ່ມຕົ້ນ"
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    v-model="date1"
                    :max="maxDate"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <h1>-</h1>
                <v-spacer></v-spacer>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      style="margin-top: 20px"
                      class="mx-1"
                      v-model="date2"
                      label="ວັນທີ່ສີ້ນສຸດ"
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date2"
                    no-title
                    :max="maxDate"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <riamExcel
                  class="btn btn-default"
                  :fetch="fetchData"
                  :fields="json_fields"
                  worksheet="ລາຍການສັ່ງຊື້"
                  :name="excelTitle"
                  :before-finish="finishDownload"
                >
                  <v-btn class="mt-0 mr-2 indigo">
                    <span style="color: white">ດືງຂໍ້ມູນ</span>
                  </v-btn>
                </riamExcel>
              </v-toolbar>
            </v-row>
            <v-row class="justify-space-around">
              <v-card class="" color="#2E3192" width="70%">
                <v-row class="justify-center ma-2 mt-4">
                  <span style="font-size: 2rem; color: white"
                    >{{
                      String(parseInt(totalDate)).replace(
                        /(.)(?=(\d{3})+$)/g,
                        "$1,"
                      )
                    }}
                    ກີບ</span
                  >
                </v-row>
              </v-card>
            </v-row>
            <div></div>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters class="justify-center my-5 mx-2">
        <v-card width="100%" class="mx-1 mb-5 pt-2">
          <v-toolbar flat>
            <p p style="font-size: 1.2rem">ປະຫວັດລາຍຮັບປະຈໍາ{{ selectDate }}</p>
            <v-spacer></v-spacer>
            <v-card class="elevation-0 mt-1" color="transparent" width="100">
              <v-select
                v-model="selectDate"
                :items="date_list"
                single-line
                placeholder="Date"
                hint="Date"
              ></v-select>
            </v-card>
          </v-toolbar>
          <riamchart
            type="line"
            height="350"
            :options="income.chartOptions"
            :series="income.series"
          ></riamchart>
          <v-row class="justify-space-around my-3">
            <p p style="font-size: 1.2rem">
              ລວມລາຍຮັບທັງໝົດ:
              <span style="font-size: 2rem; color: green">
                {{
                  String(income.total).replace(/(.)(?=(\d{3})+$)/g, "$1,") || 0
                }}</span
              >
              ກີບ
            </p>
          </v-row>
        </v-card>
      </v-row>
    </div>
  </div>
</template><script>
import { mapGetters, mapActions } from "vuex";
import { SpinnerLoader } from "vue-spinners-css";
import VueApexCharts from "vue-apexcharts";
import JsonExcel from "vue-json-excel";
export default {
  data: () => ({
    loading: true,
    barCode: null,
    excelTitle:
      "Order" +
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10) +
      ".xls",
    date1: new Date(
      new Date().getFullYear() +
        "-" +
        (parseInt(new Date().getMonth()) - 2).toString() +
        "-" +
        (parseInt(new Date().getDay()) + 4).toString()
    )
      .toISOString()
      .substr(0, 10),
    menu1: false,
    date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu2: false,
    date5: new Date(
      new Date().getFullYear() +
        "-" +
        (parseInt(new Date().getMonth()) - 2).toString() +
        "-" +
        (parseInt(new Date().getDay()) + 4).toString()
    )
      .toISOString()
      .substr(0, 10),
    menu5: false,
    date6: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu6: false,
    maxDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date_list: ["ວັນທີ່", "ເດືອນ", "ປີ"],
    selectDate: "ວັນທີ່",
    json_fields: {
      ຜະລິດຕະພັນ: "productName",
      ຈໍານວນ: "quantity",
      ລາຄາ: "wholeSale",
      ຊື່ຜູ້ສັ່ງ: "userName",
      ຮູບແບບຊໍາລະ: "paymentType",
      ຄໍາອະທິບາຍ: "description",
      ວັນທີ່ສັ່ງ: "updatedAt",
    },
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
  }),
  components: {
    riamchart: VueApexCharts,
    riamExcel: JsonExcel,
    SpinnerLoader,
  },
  computed: {
    ...mapGetters({
      orderList: "reportMod/orderList",
      length: "reportMod/length",
      income: "reportMod/income",
    }),
    ordersTotals() {
      var total = 0;
      if (this.orderList.length > 0) {
        for (const key in this.orderList) {
          const element = this.orderList[key];
          if (
            element.totalPayment != null &&
            element.totalPayment != undefined
          ) {
            total += parseFloat(element.totalPayment);
          }
        }
      }
      return total;
    },
    totalDate() {
      var total = 0;
      var date1 = new Date(this.date1).getTime();
      var date2 = new Date(this.date2).getTime();
      if (this.orderList.length > 0) {
        for (const key in this.orderList) {
          const element = this.orderList[key];
          var date = new Date(element.date).getTime();
          if (
            date >= date1 &&
            date <= date2 &&
            element.totalPayment != null &&
            element.totalPayment != undefined
          ) {
            total += parseFloat(element.totalPayment);
          }
        }
      }
      return total;
    },
  },
  watch: {
    length(val) {
      if (val == false) {
        this.loading = false;
      }
    },
    orderList(val) {
      if (val.length > 0) {
        this.loading = false;
      }
    },
    income(val) {
      if (val.length > 0) {
        this.loading = false;
      }
    },
    selectDate(date) {
      if (date == "ວັນທີ່") {
        this.getOrderDay();
      }
      if (date == "ເດືອນ") {
        this.getOrderMonth();
      }
      if (date == "ປີ") {
        this.getOrderYear();
      }
    },
  },
  mounted() {
    this.getOrderList();
    this.getOrderDay();
    this.Loading();
  },
  methods: {
    ...mapActions("reportMod", [
      "getOrderDay",
      "getOrderMonth",
      "getOrderYear",
      "getOrderList",
    ]),
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    routeBarCode() {
      if (this.barCode != null || this.barCode != undefined) {
        this.$router.push({
          path: `/admin/report/order/barcode/${this.barCode}`,
        });
      }
    },
    Loading() {
      if (this.length == false) {
        this.loading = false;
      }
    },
    routerDate() {
      var date1 = new Date(this.date5).getTime();
      var date2 = new Date(this.date6).getTime();
      if (date1 >= date2) {
        this.showToast(
          "warning",
          `<div style='font-family:"Noto Sans Lao";' >ກະລຸນາເລືອກວັນທີໃນຊ່ວງທີ່ຕ້ອງການລາຍງານໃຫ້ຖຶກຕ້ອງ!</div>`
        );
        return;
      }
      this.$router.push({
        path: "/admin/report/order/date",
        query: { date1: this.date5, date2: this.date6 },
      });
    },
    fetchData() {
      try {
        var date1 = new Date(this.date1).getTime();
        var date2 = new Date(this.date2).getTime();
        if (date1 >= date2) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";' >ກະລຸນາເລືອກວັນທີໃນຊ່ວງທີ່ຕ້ອງການລາຍງານໃຫ້ຖຶກຕ້ອງ!</div>`
          );
          return;
        }
        if (this.orderList.length < 1) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";' >ບໍ່ມີຂໍ້ມູນສັ່ງຊື້ຜະລິດຕະພັນ!</div>`
          );
          return;
        }
        var expenselist = [];
        for (const idx in this.orderList) {
          const el = this.orderList[idx];
          var date = new Date(el.date).getTime();
          if (date >= date1 && date <= date2) {
            if (el.product != null) {
              for (const x in el.product) {
                const p = el.product[x];
                var pro = {
                  productName: p.productName,
                  quantity: p.quantity,
                  wholeSale: p.wholeSale,
                  description: p.description,
                  userName: el.userName,
                  paymentType: el.paymentType,
                  totalPayment: el.totalPayment,
                  createdAt: el.createdAt,
                  updatedAt: el.updatedAt,
                  orderStatus: el.orderStatus,
                };
                expenselist.push(pro);
              }
            }
          }
        }
        if (expenselist.length < 1) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";' >ບໍ່ມີຂໍ້ມູນວັດຖຸດິບ-ນໍາເຂົ້າໃນຊ່ວງໄລຍະນີ້</div>`
          );
        }
        return expenselist;
      } catch (error) {
        console.log(error);
      }
    },
    finishDownload() {
      this.showToast(
        "success",
        `<div style='font-family:"Noto Sans Lao";' >ດາວໂລດສໍາເລັດແລ້ວ</div>`
      );
    },
  },
};
</script>