import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";
import { apiURL, header } from "../../config/constant";

Vue.use(Vuex);

const orderModule = {
  namespaced: true,
  state: {
    orders: [],
    order: [],
    length: true
  },
  mutations: {
    setOrders(state, order) {
      state.orders = order;
    },
    setOrder(state, order) {
      state.order = order;
    },
    setLength(state, order) {
      state.length = order;
    },
  },

  actions: {
    getOrders(context) {
      try {
        axios.get(apiURL + `orders`, { headers: header })
          .then((res) => {
            if (res.data.error != undefined) {
              context.commit("setLength", false);
              return
            }
            if (res.data.length < 1) {
              context.commit("setLength", false);
              return
            }
            var orderlist = [];
            for (const idx in res.data) {
              const el = res.data[idx];

              var order = {
                idx: parseInt(idx) + 1,
                _id: el._id,
                user: (el.user == null || el.user._id == undefined) ? null : el.user._id,
                userName: (el.user == null || el.user.userName == undefined) ? null : el.user.userName,
                role: (el.user == null || el.user.role == undefined) ? null : el.user.role,
                paymentType: el.paymentType,
                totalPayment: el.totalPayment,
                orderStatus: el.orderStatus,
                items: el.items,
                createdAt:
                  moment(String(el.createdAt)).format("DD-MM-YYYY") +
                  " " +
                  el.createdAt.slice(11, el.createdAt.length - 5),
                updatedAt:
                  moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                  " " +
                  el.updatedAt.slice(11, el.updatedAt.length - 5),
                date: el.updatedAt
              };
              orderlist.push(order);
            }
            context.commit("setOrders", orderlist);
            context.commit("setLength", true);
          }).catch(function (error) {
            context.commit("setLength", false);
            console.log(error)
          });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
    getOrder(context, id) {
      try {
        axios.get(apiURL + `orders/${id}`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          const el = res.data;
          var order = {
            _id: el._id,
            user: (el.user == null || el.user._id == undefined) ? null : el.user._id,
            userName: (el.user == null || el.user.userName == undefined) ? null : el.user.userName,
            role: (el.user == null || el.user.role == undefined) ? null : el.user.role,
            paymentType: el.paymentType,
            totalPayment: el.totalPayment,
            orderStatus: el.orderStatus,
            items: el.items,
            createdAt:
              moment(String(el.createdAt)).format("DD-MM-YYYY") +
              " " +
              el.createdAt.slice(11, el.createdAt.length - 5),
            date: el.updatedAt
          };
          context.commit("setOrder", order);
          if (el.items == null || el.items == undefined || el.items.length < 1) {
            context.commit("setLength", false);
          } else {
            context.commit("setLength", true);
          }
        }).catch(function (error) {
          context.commit("setLength", false);
          console.log(error)
        });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
    getOrderByDateList(context, { date1, date2 }) {
      try {
        axios.get(apiURL + `orders/start/${date1}/end/${date2}`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var orderlist = [];
          if (res.data.length < 1) {
            context.commit("setOrders", orderlist);
            return
          }
          for (const idx in res.data) {
            const el = res.data[idx];

            var order = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              user: (el.user == null || el.user._id == undefined) ? null : el.user._id,
              userName: (el.user == null || el.user.userName == undefined) ? null : el.user.userName,
              role: (el.user == null || el.user.role == undefined) ? null : el.user.role,
              paymentType: el.paymentType,
              totalPayment: el.totalPayment,
              orderStatus: el.orderStatus,
              items: el.items,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
              date: el.updatedAt
            };
            orderlist.push(order);
          }

          context.commit("setOrders", orderlist);
          context.commit("setLength", true);
        }).catch(function (error) {
          context.commit("setLength", false);
          console.log(error)
        });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
    getOrderByDate(context, { date1, date2 }) {
      try {
        return axios.get(apiURL + `orders/start/${date1}/end/${date2}`, { headers: header })

      } catch (error) {
        console.log(error);
      }
    },
    delOrder(context, item) {
      try {
        return axios
          .delete(apiURL + `orders/${item._id}`, { headers: header })
      } catch (e) {
        console.log(e);
      }
    },
    insertOrder(context, item) {
      try {
        return axios
          .post(apiURL + `orders`, item, { headers: header })
      } catch (e) {
        console.log(e);
      }
    },
    updateOrder(context, item) {
      try {
        return axios
          .put(apiURL + `orders/${item._id}`, item, { headers: header })
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    orders: (state) => state.orders,
    order: (state) => state.order,
    length: (state) => state.length,
  },
};

export default orderModule;
