import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";
import { apiURL, header } from "../../config/constant";

Vue.use(Vuex);

const rawModule = {
  namespaced: true,
  state: {
    raws: [],
    raw: [],
    rawName: [],
    maxBarCode: '000000001',
    length: true
  },
  mutations: {
    setRaws(state, raw) {
      state.raws = raw;
    },
    setRaw(state, raw) {
      state.raw = raw;
    },
    setRawName(state, raw) {
      state.rawName = raw;
    },
    setRawMaxBarCode(state, raw) {
      state.maxBarCode = raw;
    },
    setLength(state, raw) {
      state.length = raw;
    },
  },
  actions: {
    getRaws(context) {
      try {
        axios.get(apiURL + `raws`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var rawlist = [], rawName = [];
          for (const idx in res.data) {
            const el = res.data[idx];
            var raw = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              rawName: el.rawName,
              barCodeNumber: (el.barCodeNumber == null || el.barCodeNumber == undefined) ? null : el.barCodeNumber,
              unit: (el.unit == null || el.unit == undefined || el.unit._id == null || el.unit._id == undefined) ? null : el.unit._id,
              unitName: (el.unit == null || el.unit == undefined || el.unit.name == null || el.unit.name == undefined) ? null : el.unit.name,
              qty: el.qty,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
            };
            rawName.push(el.rawName)
            rawlist.push(raw);
          }
          context.commit("setRawName", rawName);
          context.commit("setRaws", rawlist);
          context.commit("setLength", true);
        }).catch(function (error) {
          context.commit("setLength", false);
          console.log(error)
        });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
    getRaw(context, id) {
      try {
        axios
          .get(apiURL + `raws/${id}`, { headers: header })
          .then((res) => {
            if (res.data.error != undefined) {
              context.commit("setLength", false);
              return
            }
            if (res.data.length < 1) {
              context.commit("setLength", false);
              return
            }
            const el = res.data;
            var raw = {
              _id: el._id,
              rawName: el.rawName,
              barCodeNumber: (el.barCodeNumber == null || el.barCodeNumber == undefined) ? null : el.barCodeNumber,
              unit: '',
              unitName: (el.unit == null || el.unit == undefined || el.unit.name == null || el.unit.name == undefined) ? null : el.unit.name,
              qty: el.qty,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
            };
            context.commit("setRaw", raw);
            context.commit("setLength", true);
          }).catch(function (error) {
            context.commit("setLength", false);
            console.log(error)
          });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
    getRawBarCode(context, barcode) {
      try {
        axios.get(apiURL + `raws`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var rawlist = []
          for (const idx in res.data) {
            const el = res.data[idx];
            var barCode = (el.barCodeNumber == null || el.barCodeNumber == undefined) ? null : el.barCodeNumber
            if (barCode == barcode) {
              var raw = {
                _id: el._id,
                rawName: el.rawName,
                barCodeNumber: barCode,
                unit: (el.unit == null || el.unit == undefined || el.unit._id == null || el.unit._id == undefined) ? null : el.unit._id,
                unitName: (el.unit == null || el.unit == undefined || el.unit.name == null || el.unit.name == undefined) ? null : el.unit.name,
                qty: el.qty,
                createdAt:
                  moment(String(el.createdAt)).format("DD-MM-YYYY") +
                  " " +
                  el.createdAt.slice(11, el.createdAt.length - 5),
                updatedAt:
                  moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                  " " +
                  el.updatedAt.slice(11, el.updatedAt.length - 5),
              };
              rawlist.push(raw);
            }
          }
          context.commit("setRaw", rawlist[0]);
        }).catch(function (error) {
          console.log(error)
        });
      } catch (error) {
        console.log(error);
      }
    },
    getRawMaxBarCode(context) {
      try {
        axios.get(apiURL + `raws`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            return
          }
          if (res.data.length < 1) {
            return
          }

          var max = 0, maxbarcode = ""
          for (const idx in res.data) {
            const el = res.data[idx];
            var barCode = (el.barCodeNumber == null || el.barCodeNumber == undefined) ? 0 : el.barCodeNumber.slice(3, el.barCodeNumber.length)
            if (max < barCode) {
              max = parseInt(barCode) + 1
            }
          }
          if (max >= 100000000) {
            maxbarcode = max.toString()
          } else if ((max >= 10000000) && (max < 100000000)) {
            maxbarcode = "0" + max.toString()
          } else if ((max >= 1000000) && (max < 10000000)) {
            maxbarcode = "00" + max.toString()
          } else if ((max >= 100000) && (max < 1000000)) {
            maxbarcode = "000" + max.toString()
          } else if ((max >= 10000) && (max < 100000)) {
            maxbarcode = "0000" + max.toString()
          } else if ((max >= 1000) && (max < 10000)) {
            maxbarcode = "00000" + max.toString()
          } else if ((max >= 100) && (max < 1000)) {
            maxbarcode = "000000" + max.toString()
          } else if ((max >= 10) && (max < 100)) {
            maxbarcode = "0000000" + max.toString()
          } else if ((max >= 1) && (max < 10)) {
            maxbarcode = "00000000" + max.toString()
          } else if (max < 1) {
            maxbarcode = "000000001"
          }
          context.commit("setRawMaxBarCode", ("RD: " + maxbarcode).toString())
        }).catch(function (error) {
          console.log(error)
        });
      } catch (error) {
        console.log(error);
      }
    },
    delRaw(context, item) {
      try {
        return axios
          .delete(apiURL + `raws/${item._id}`, { headers: header })
      } catch (e) {
        this.errors.push(e);
      }
    },
    insertRaw(context, item) {
      try {
        return axios
          .post(apiURL + `raws`, item, { headers: header })
      } catch (e) {
        this.errors.push(e);
      }
    },
    updateRaw(context, item) {
      try {
        return axios
          .put(apiURL + `raws/${item._id}`, item, { headers: header })

      } catch (e) {
        this.errors.push(e);
      }
    },
  },
  getters: {
    raws: (state) => state.raws,
    raw: (state) => state.raw,
    rawName: (state) => state.rawName,
    maxBarCode: (state) => state.maxBarCode,
    length: (state) => state.length,
  },
};

export default rawModule;
