<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
              <h1 style="margin-top: 30px">ເພີ່ມຂໍ້ມູນສາງ</h1>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  <v-text-field
                    v-model="warehouse.name"
                    :error-messages="nameErrors"
                    label="ຊື່ສາງ"
                    required
                    @input="$v.warehouse.name.$touch()"
                    @blur="$v.warehouse.name.$touch()"
                  ></v-text-field>

                  <v-autocomplete
                    clearable
                    item-color="blue darken-1"
                    no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                    v-model="warehouse.provinceName"
                    :items="provinceName"
                    :error-messages="provinceNameErrors"
                    label="ແຂວງ"
                    required
                    @input="$v.warehouse.provinceName.$touch()"
                    @blur="$v.warehouse.provinceName.$touch()"
                  ></v-autocomplete>

                  <v-autocomplete
                    clearable
                    item-color="blue darken-1"
                    no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                    v-model="warehouse.districtName"
                    :error-messages="districtNameErrors"
                    :items="districtInProvince"
                    label="ເມືອງ"
                    required
                    @input="$v.warehouse.districtName.$touch()"
                    @blur="$v.warehouse.districtName.$touch()"
                  ></v-autocomplete>
                  <v-autocomplete
                    clearable
                    item-color="blue darken-1"
                    no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                    v-model="warehouse.villageName"
                    :items="villageInDistrict"
                    :error-messages="villageErrors"
                    label="ບ້ານ"
                    required
                    @input="$v.warehouse.villageName.$touch()"
                    @blur="$v.warehouse.villageName.$touch()"
                  ></v-autocomplete>
                  <v-text-field
                    v-model="warehouse.phoneNumber"
                    :error-messages="phoneNumberErrors"
                    label="ເລກໂທລະສັບ"
                    required
                    @input="$v.warehouse.phoneNumber.$touch()"
                    @blur="$v.warehouse.phoneNumber.$touch()"
                  ></v-text-field>

                  <v-text-field
                    v-model="warehouse.email"
                    :error-messages="emailErrors"
                    label="ອີເມວ"
                    required
                    @input="$v.warehouse.email.$touch()"
                    @blur="$v.warehouse.email.$touch()"
                  ></v-text-field>
                  
                  <v-autocomplete
                    clearable
                    item-color="blue darken-1"
                    no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                    class="font"
                    v-model="warehouse.userName"
                    :items="userName"
                    :error-messages="userNameErrors"
                    label="ຜູ້ໃຊ້"
                    required
                    @input="$v.warehouse.userName.$touch()"
                    @blur="$v.warehouse.userName.$touch()"
                  ></v-autocomplete>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class></v-divider>
            <v-row
              class="ma-5"
              :justify="$vuetify.breakpoint.width < 600 ? 'center' : 'start'"
            >
              <v-btn
                @click="back"
                class="ma-2 orange white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
              </v-btn>
              <v-spacer class="d-none d-sm-flex"></v-spacer>
              <v-btn
                @click="clear"
                class="ma-2 red white--text"
                :width="$vuetify.breakpoint.width<600?'100%':'auto'"
              >
                <v-icon left>mdi-close-circle</v-icon>ຍົກເລິກ
              </v-btn>

              <v-btn
                @click="submit"
                class="ma-2 green white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-check-circle</v-icon>ບັນທຶກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    warehouse: {
      name: { required, maxLength: maxLength(100) },
      provinceName: { required, maxLength: maxLength(100) },
      villageName: { required, maxLength: maxLength(100) },
      districtName: { required, maxLength: maxLength(100) },
      userName: { required, maxLength: maxLength(100) },
      phoneNumber: { required, maxLength: maxLength(100) },
      email: { required, email },
    },
  },
  data: () => ({
    show: false,
    warehouse: {
      name: "",
      lastName: "",
      provinceName: "",
      province: "",
      districtName: "",
      district: "",
      villageName: "",
      village: "",
      lat: "",
      lng: "",
      phoneNumber: "",
      email: "",
      userName:'',
      user: "",
    },
  }),

  computed: {
    ...mapGetters({
      villages: "addressMod/villages",
      villageName: "addressMod/villageName",
      districts: "addressMod/districts",
      districtName: "addressMod/districtName",
      provinces: "addressMod/provinces",
      provinceName: "addressMod/provinceName",
      users: "userMod/users",
      userName: "userMod/userName",
    }),
    nameErrors() {
      const errors = [];
      if (!this.$v.warehouse.name.$dirty) return errors;
      !this.$v.warehouse.name.maxLength && errors.push("ກະລຸນາປ້ອນຊື່ສາງ!");
      !this.$v.warehouse.name.required && errors.push("ກະລຸນາປ້ອນຊື່ສາງ!");
      return errors;
    },
    provinceNameErrors() {
      const errors = [];
      if (!this.$v.warehouse.provinceName.$dirty) return errors;
      !this.$v.warehouse.provinceName.required &&
        errors.push("ກະລຸນາເລືອກແຂວງ!");
      return errors;
    },

    districtNameErrors() {
      const errors = [];
      if (!this.$v.warehouse.districtName.$dirty) return errors;
      !this.$v.warehouse.districtName.required &&
        errors.push("ກະລຸນາເລືອກເມືອງ!");
      return errors;
    },
    villageErrors() {
      const errors = [];
      if (!this.$v.warehouse.villageName.$dirty) return errors;
      !this.$v.warehouse.villageName.required &&
        errors.push("ກະລຸນາເລືອກບ້ານ!");
      return errors;
    },
userNameErrors() {
      const errors = [];
      if (!this.$v.warehouse.userName.$dirty) return errors;
      !this.$v.warehouse.userName.maxLength &&
        errors.push("ກະລຸນາເລືອກຜູ້ໃຊ້!");
      !this.$v.warehouse.userName.required &&
        errors.push("ກະລຸນາເລືອກຜູ້ໃຊ້!");
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.warehouse.phoneNumber.$dirty) return errors;
      !this.$v.warehouse.phoneNumber.maxLength &&
        errors.push("ກະລຸນາປ້ອນເລກໂທລະສັບ!");
      !this.$v.warehouse.phoneNumber.required &&
        errors.push("ກະລຸນາປ້ອນເລກໂທລະສັບ!");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.warehouse.email.$dirty) return errors;
      !this.$v.warehouse.email.email && errors.push("ກະລຸນາປ້ອນອີເມວລູກຄ້າ!");
      !this.$v.warehouse.email.required &&
        errors.push("ກະລຸນາປ້ອນອີເມວລູກຄ້າ!");
      return errors;
    },
    districtInProvince() {
      var dlist = [];
      for (const key in this.districts) {
        const el = this.districts[key];
        if (this.warehouse.provinceName == el.provinceName) {
          dlist.push(el.name);
        }
      }
      return dlist;
    },

    villageInDistrict() {
      var vlist = [];
      for (const key in this.villages) {
        const el = this.villages[key];
        if (this.warehouse.districtName == el.districtName) {
          vlist.push(el.name);
        }
      }
      return vlist;
    },
  },
  mounted() {
    this.getProvinces();
    this.getDistricts();
    this.getVillages();
    this.getUsers();
  },
  methods: {
    ...mapActions("addressMod", [
      "getVillages",
      "getProvinces",
      "getDistricts",
    ]),
    ...mapActions("warehouseMod", ["insertWarehouse"]),
    ...mapActions("userMod", ["getUsers"]),

    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    async submit() {
      this.$v.$touch();
      try {
        if (this.warehouse.name == "" || this.warehouse.name == null) {
          this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນຊື່ສາງ</div>`,)
          return;
        }
        if (
          this.warehouse.provinceName == "" ||
          this.warehouse.provinceName == null
        ) {
          this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກແຂວງສາງ</div>`,)
          return;
        }
         if (
          this.warehouse.districtName == "" ||
          this.warehouse.districtName == null
        ) {
          this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກເມືອງສາງ</div>`,)
          return;
        }
        if (
          this.warehouse.villageName == "" ||
          this.warehouse.villageName == null
        ) {
          this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກບ້ານສາງ</div>`,)
          return;
        }
        if (
          this.warehouse.phoneNumber == "" ||
          this.warehouse.phoneNumber == null
        ) {
          this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນເລກໂທລະສັບ</div>`,)
          return;
        }
        
        if (this.warehouse.email == "" || this.warehouse.email == null) {
          this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນອີເມວສາງ</div>`,)
          return;
        }
        
        if (this.warehouse.userName == "" || this.warehouse.userName == null) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຜູ້ໃຊ້ລະບົບ</div>`,)
          return;
        }
        
        for (const key in this.users) {
          const el = this.users[key];
          if (el.userName == this.warehouse.userName) {
            this.warehouse.user = el._id;
          }
        }
        if (this.warehouse.user == "" || this.warehouse.user == null) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຜູ້ໃຊ້ລະບົບ</div>`,)
          return;
        }
        for (const key in this.villages) {
          const el = this.villages[key];
          if (el.name == this.warehouse.villageName) {
            this.warehouse.village = el._id;
          }
        }
        if (this.warehouse.village == "" || this.warehouse.village == null) {
          this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກບ້ານໃຫ້ຖຶກຕ້ອງ</div>`,)
          return;
        }
        this.insertWarehouse(this.warehouse)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
          this.showToast("success",`<div style='font-family:"Noto Sans Lao";' >ບັນທຶກຂໍ້ມູນສໍາເລັດແລ້ວ</div>`,)
              this.clear();
            } else {
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            }
          })
          .catch( (error) =>{
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            console.log(error);
          });
      } catch (error) {
        console.log(error);
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
      }
    },
    clear() {
      this.$v.$reset();
      this.warehouse = {
        name: "",
        lastName: "",
        provinceName: "",
        province: "",
        districtName: "",
        district: "",
        villageName: "",
        village: "",
        lat: "",
        lng: "",
        phoneNumber: "",
        email: "",
      userName:'',
      user: "",
      };
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped></style>
