<template>
  <div>
    <div>
      <v-row justify="center">
        <v-card class="elevation-3 ma-3" width="100%">
          <v-card-title>
            <v-row no-gutters>
              <v-col
                cols="12"
                md="3"
                :align="$vuetify.breakpoint.width < 960 ? 'center' : 'start'"
              >
                <span style="font-size: 1.2rem">ກິດຈະກໍາທັງໝົດ</span>
                <v-divider vertical class="mx-2 hidden-sm-and-down"></v-divider>
              </v-col>
              <v-col cols="12" md="9">
                <v-row>
                  <v-spacer class="hidden-sm-and-down"></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="ຄົ້ນຫາ"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <router-link to="/staff/event/add">
                    <v-btn class="mt-3 mr-2 green">
                      <v-icon color="white" large>mdi-plus</v-icon
                      ><span style="color: white; font-weight: bold"
                        >ເພີ່ມໃໝ່</span
                      >
                    </v-btn>
                  </router-link>
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>
          <div v-if="loading">
            <v-data-table
              :headers="headers"
              :search="search"
              sort-by="idx"
              class="elevation-0"
              hide-default-footer
              no-data-text=""
            ></v-data-table>
            <v-row no-gutters class="justify-center mb-10">
              <SpinnerLoader :color="'#90CAF9'" :loading="true" :size="100" />
            </v-row>
          </div>
         <v-card flat v-else-if="length == false">
            <v-data-table
              :headers="headers"
              :search="search"
              sort-by="idx"
              class="elevation-0"
              hide-default-footer
              no-data-text=""
            ></v-data-table>
            <v-row class="justify-center mx-4">
              <v-card
                flat
                class=""
                color="transparent"
                style="font-size: 2rem; color: grey; margin-top: 40px"
                >ບໍ່ມີຂໍ້ມູນກິດຈະກໍາ
              </v-card>
            </v-row>
            <v-skeleton-loader
              style="margin-top: -125px"
              class=""
              type="image"
            >
            </v-skeleton-loader>
          </v-card>
          <v-data-table
            v-else
            :headers="headers"
            :search="search"
            :items="event"
          :footer-props="{itemsPerPageText: 'ສະແດງຈໍານວນແຖວ :'}"
            sort-by="idx"
            class="elevation-0"
          >
            <template v-slot:item="{ item }">
              <tr v-ripple="{ class: `white--text` }">
                <td align="center">{{ item.idx }}</td>
                <td>
                  <v-carousel
                    v-if="item.eventPictures.length > 0"
                    cycle
                    height="100"
                    hide-delimiter-background
                    :show-arrows="false"
                    hide-delimiters
                  >
                    <v-carousel-item
                      v-for="(img, i) in item.eventPictures"
                      :key="i"
                    >
                      <v-img
                        height="100"
                        width="300"
                        contain
                        :src="img.img"
                        :lazy-src="img.img"
                      >
                <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="#90CAF9"
            ></v-progress-circular>
          </v-row>
        </template>
                      </v-img>
                    </v-carousel-item>
                  </v-carousel>
                  <v-card v-else height="100" width="100" flat>{{
                    item.eventPictures.length
                  }}</v-card>
                </td>
                <td>{{ item.title }}</td>
                <td>{{ item.createdAt }}</td>
                <td>{{ item.updatedAt }}</td>
               
              <td>
                <v-row class="justify-center">
                  <v-icon @click="viewEvent(item)" class="mr-2" color="light-blue lighten-1">
                    mdi-eye
                  </v-icon>
                </v-row>
              </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { SpinnerLoader } from "vue-spinners-css";
export default {
  data: () => ({
    loading: true,
    search: "",
    headers: [
      {
        text: "ລໍາດັບ",
        value: "idx",
        align:"center",
        class: "indigo white--text",
        width: "100",
      },
      { text: "ຮູບພາບ", value: "eventPictures", align:"center", class: "indigo white--text" },
      { text: "ຫົວຂໍ້", value: "title", class: "indigo white--text" },
      {
        text: "ວັນທີ່ສ້າງຂຶ້ນ",
        value: "createdAt",
        class: "indigo white--text",
      },
      { text: "ວັນທີ່ແກ້ໄຂ", value: "updatedAt", class: "indigo white--text" },
    {
          text: "Actions",
          value: "actions",
      align:"center",
          sortable: false, 
          class: "indigo white--text" },
    ],
  }),
  components: {
    SpinnerLoader,
  },
  computed: {
    ...mapGetters({
      event: "eventMod/events",
      length: "eventMod/length",
    }),
  },
  watch: {
    
    length(val) {
      if (val == false) {
        this.loading = false;
      }
    },
    event(val) {
      if (val.length > 0) {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getEvents();
    this.Loading()
  },
  methods: {
    ...mapActions("eventMod", ["getEvents", "delEvent"]),
    showAlert() {
      this.$swal({
        position: "center",
        icon: "warning",
        title: `<div style='font-family:"Noto Sans Lao";' >ເຕືອນ</div>`,
        html: `<div style='font-family:"Noto Sans Lao"; font-size:1.5rem' >ທ່ານຕ້ອງການລົບອອກບໍ?</div>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: `<div style='font-family:"Noto Sans Lao";' >ຕົກລົງ</div>`,
        cancelButtonText: `<div style='font-family:"Noto Sans Lao";' >ຍົກເລີກ</div>`,
        confirmButtonColor: "green",
        cancelButtonColor: "red",
        reverseButtons: true,
        buttonsStyling: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deleteEventConfirm();
        }
      });
    },
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    Loading() {
      if (this.length == false) {
        this.loading = false;
      }
    },
    viewEvent(item) {
      this.$router.push(`/staff/event/view/${item._id}`);
    },
  },
};
</script>
<style scoped>
.font {
  font-family: "NotoSansLao";
}
</style>
