import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";
import { apiURL, header } from "../../config/constant";

Vue.use(Vuex);

const eventModule = {
  namespaced: true,
  state: {
    events: [],
    event: [],
    length: true
  },
  mutations: {
    setEvents(state, event) {
      state.events = event;
    },
    setEvent(state, event) {
      state.event = event;
    },
    setLength(state, event) {
      state.length = event;
    },
  },

  actions: {
    getEvents(context) {
      try {
        axios.get(apiURL + `events`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var eventlist = [];
          for (const idx in res.data) {
            const el = res.data[idx];
            var event = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              title: el.title,
              description: el.description,
              eventPictures: el.eventPictures,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
            };
            eventlist.push(event);
          }
          context.commit("setEvents", eventlist);
          context.commit("setLength", true);
        }).catch(function (error) {
          context.commit("setLength", false);
          console.log(error)
        });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
    getEvent(context, id) {
      try {
        axios.get(apiURL + `events/${id}`, { headers: header }).then((res) => {
          if (res.data.error != undefined) return alert("ບ");
          const el = res.data;
          var event = {
            _id: el._id,
            title: el.title,
            description: el.description,
            eventPictures: el.eventPictures,
            createdAt:
              moment(String(el.createdAt)).format("DD-MM-YYYY") +
              " " +
              el.createdAt.slice(11, el.createdAt.length - 5),
            updatedAt:
              moment(String(el.updatedAt)).format("DD-MM-YYYY") +
              " " +
              el.updatedAt.slice(11, el.updatedAt.length - 5),
          };
          context.commit("setEvent", event);
        })
          .catch(function (error) {
            if (error.message == 'Network Error') {
              console.log("ບໍ່ມີອີນເທີເນັດ")
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    delEvent(context, item) {
      try {
        return axios
          .delete(apiURL + `events/${item._id}`, { headers: header })
      } catch (e) {
        console.log(e);
      }
    },
    insertEvent(context, item) {
      try {
        return axios
          .post(apiURL + `events`, item, { headers: header })
      } catch (e) {
        console.log(e);
      }
    },
    updateEvent(context, item) {
      try {
        return axios
          .put(apiURL + `events/${item._id}`, item, { headers: header })
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    events: (state) => state.events,
    event: (state) => state.event,
    length: (state) => state.length,
  },
};

export default eventModule;
