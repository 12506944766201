import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";
import { apiURL, header } from "../../config/constant";

Vue.use(Vuex);

const productModule = {
  namespaced: true,
  state: {
    products: [],
    product: [],
    productsName: [],
    maxBarCode: '000000001',
    length: true
  },
  mutations: {
    setProducts(state, pro) {
      state.products = pro;
    },
    setProduct(state, pro) {
      state.product = pro;
    },

    setProductsName(state, pro) {
      state.productsName = pro;
    },
    setProductMaxBarCode(state, pro) {
      state.maxBarCode = pro;
      // alert(pro)
    },
    setLength(state, pro) {
      state.length = pro;
    },
  },
  actions: {
    getProducts(context) {
      try {
        axios.get(apiURL + `products`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var prolist = [], productName = [];
          for (const idx in res.data) {
            const el = res.data[idx];
            var Product = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              productName: el.productName,
              barCodeNumber: (el.barCodeNumber == null || el.barCodeNumber == undefined) ? null : el.barCodeNumber,
              category: (el.category == null || el.category == undefined) ? null : el.category.name,
              wholeSale: el.wholeSale,
              retail: el.retail,
              promotionPrice: (el.promotionPrice == undefined || el.promotionPrice == null) ? 0 : el.promotionPrice,
              qty: el.qty,
              description: (el.description == null || el.description == undefined) ? null : el.description,
              image: (el.image == null || el.image == undefined) ? null : el.image,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
            };
            productName.push(el.productName)
            prolist.push(Product);
          }
          context.commit("setProducts", prolist);
          context.commit("setProductsName", productName);
          context.commit("setLength", true);
        }).catch(function (error) {
          context.commit("setLength", false);
          console.log(error)
        });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
    getProduct(context, id) {
      try {
        axios
          .get(apiURL + `products/${id}`, { headers: header })
          .then((res) => {
            if (res.data.error != undefined) return
            const el = res.data;
            var product = {
              _id: el._id,
              productName: el.productName,
              barCodeNumber: (el.barCodeNumber == null || el.barCodeNumber == undefined) ? null : el.barCodeNumber,
              categoryId: '',
              categoryName: (el.category == null || el.category == undefined) ? null : el.category.name,
              wholeSale: el.wholeSale,
              retail: el.retail,
              promotionPrice: (el.promotionPrice == undefined || el.promotionPrice == null) ? 0 : el.promotionPrice,
              qty: el.qty,
              description: (el.description == null || el.description == undefined) ? null : el.description,
              image: null,
              imageURL: el.image,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
              __v: el.__v,
            };
            context.commit("setProduct", product);
          })
          .catch(function (error) {
            if (error.message == 'Network Error') {
              console.log("ບໍ່ມີອີນເທີເນັດ")
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    getProductBarCode(context, barcode) {
      try {
        axios.get(apiURL + `products`, { headers: header }).then((res) => {
          if (res.data.error != undefined) return
          if (res.data.length < 1) {
            return
          }
          var prolist = []
          for (const idx in res.data) {
            const el = res.data[idx];
            var barCodeNumber = (el.barCodeNumber == null || el.barCodeNumber == undefined) ? null : el.barCodeNumber
            if (barcode == barCodeNumber) {
              var product = {
                _id: el._id,
                productName: el.productName,
                barCodeNumber: barCodeNumber,
                categoryId: '',
                categoryName: (el.category == null || el.category == undefined) ? null : el.category.name,
                wholeSale: el.wholeSale,
                retail: el.retail,
                promotionPrice: (el.promotionPrice == undefined || el.promotionPrice == null) ? 0 : el.promotionPrice,
                qty: el.qty,
                description: (el.description == null || el.description == undefined) ? null : el.description,
                image: null,
                imageURL: el.image,
                createdAt:
                  moment(String(el.createdAt)).format("DD-MM-YYYY") +
                  " " +
                  el.createdAt.slice(11, el.createdAt.length - 5),
                updatedAt:
                  moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                  " " +
                  el.updatedAt.slice(11, el.updatedAt.length - 5),
                __v: el.__v,
              };
              prolist.push(product);
            }
          }
          context.commit("setProduct", prolist[0]);
        })
          .catch(function (error) {
            if (error.message == 'Network Error') {
              console.log("ບໍ່ມີອີນເທີເນັດ")
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    getProductMaxBarCode(context) {
      try {
        axios.get(apiURL + `products`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            return
          }
          if (res.data.length < 1) {
            return
          }
          var max = 0, maxbarcode = ""
          for (const idx in res.data) {
            const el = res.data[idx];
            var barCode = (el.barCodeNumber == null || el.barCodeNumber == undefined) ? 0 : el.barCodeNumber.slice(3, el.barCodeNumber.length)
            if (max < barCode) {
              max = parseInt(barCode) + 1
            }
          }
          if (max >= 100000000) {
            maxbarcode = max.toString()
          } else if ((max >= 10000000) && (max < 100000000)) {
            maxbarcode = "0" + max.toString()
          } else if ((max >= 1000000) && (max < 10000000)) {
            maxbarcode = "00" + max.toString()
          } else if ((max >= 100000) && (max < 1000000)) {
            maxbarcode = "000" + max.toString()
          } else if ((max >= 10000) && (max < 100000)) {
            maxbarcode = "0000" + max.toString()
          } else if ((max >= 1000) && (max < 10000)) {
            maxbarcode = "00000" + max.toString()
          } else if ((max >= 100) && (max < 1000)) {
            maxbarcode = "000000" + max.toString()
          } else if ((max >= 10) && (max < 100)) {
            maxbarcode = "0000000" + max.toString()
          } else if ((max >= 1) && (max < 10)) {
            maxbarcode = "00000000" + max.toString()
          } else if (max < 1) {
            maxbarcode = "000000001"
          }
          context.commit("setProductMaxBarCode", ("PD: " + maxbarcode).toString())
        }).catch(function (error) {
          console.log(error)
        });
      } catch (error) {
        console.log(error);
      }
    },
    delProduct(context, item) {
      try {
        return axios
          .delete(apiURL + `products/${item._id}`, { headers: header })
      } catch (e) {
        this.errors.push(e);
      }
    },
    insertProduct(context, item) {
      try {
        return axios
          .post(apiURL + `products`, item, { headers: header })

      } catch (e) {
        this.errors.push(e);
      }
    },
    updateProduct(context, { id, item }) {
      try {
        return axios
          .put(apiURL + `products/${id}`, item, { headers: header })
      } catch (e) {
        this.errors.push(e);
      }
    },
  },
  getters: {
    products: (state) => state.products,
    product: (state) => state.product,
    productsName: (state) => state.productsName,
    maxBarCode: (state) => state.maxBarCode,
    length: (state) => state.length,
  },
};

export default productModule;
