<template>
  <div>
    <div>
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <v-col
              cols="12"
              md="3"
              :align="$vuetify.breakpoint.width < 960 ? 'center' : 'start'"
            >
              <span style="font-size: 1.2rem">ຂໍ້ມູນສາງທັງໝົດ</span>
              <v-divider vertical class="mx-2 hidden-sm-and-down"></v-divider>
            </v-col>
            <v-col cols="12" md="9">
              <v-row>
                <v-spacer class="hidden-sm-and-down"></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="ຄົ້ນຫາ"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <router-link to="/admin/warehouse/add">
                  <v-btn class="mt-3 mr-2 green">
                    <v-icon color="white" large>mdi-plus</v-icon
                    ><span style="color: white">ເພີ່ມໃໝ່</span>
                  </v-btn>
                </router-link>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <div v-if="loading">
          <v-data-table
            :headers="headers"
            :search="search"
            sort-by="idx"
            class="elevation-0"
            hide-default-footer
            no-data-text=""
          ></v-data-table>
          <v-row no-gutters class="justify-center pb-10">
            <SpinnerLoader :color="'#90CAF9'" :loading="true" :size="100" />
          </v-row>
        </div>
        <v-card flat v-else-if="length == false">
          <v-data-table
            :headers="headers"
            :search="search"
            sort-by="idx"
            class="elevation-0"
            hide-default-footer
            no-data-text=""
          ></v-data-table>
          <v-row class="justify-center mx-4">
            <v-card
              flat
              class=""
              color="transparent"
              style="font-size: 2rem; color: grey; margin-top: 40px"
              >ບໍ່ມີຂໍ້ມູນສາງ
            </v-card>
          </v-row>
          <v-skeleton-loader
            style="margin-top: -125px"
            class=""
            type="image"
          >
          </v-skeleton-loader>
        </v-card>
        <v-data-table
          v-else
          large
          class="table"
          :headers="headers"
          :items="warehouses"
          :search="search"
          :footer-props="{ itemsPerPageText: 'ສະແດງຈໍານວນແຖວ :' }"
          fixed-header
        >
          <template v-slot:item.actions="{ item }">
            <v-row no-gutters class="justify-start">
              <v-icon
                color="light-blue lighten-1"
                class="mr-2"
                @click="editeWarehouse(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon color="error" @click="deleteWarehouse(item)">
                mdi-delete
              </v-icon>
            </v-row>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { SpinnerLoader } from "vue-spinners-css";
export default {
  data() {
    return {
      loading: true,
      deleteItem: [],
      search: "",
      headers: [
        { text: "ລໍາດັບ", value: "idx", class: "indigo white--text" },
        { text: "ຊື່ສາງ", value: "name", class: "indigo white--text" },
        {
          text: "ເລກໂທລະສັບ",
          value: "phoneNumber",
          class: "indigo white--text",
        },
        { text: "ອີເມວ", value: "email", class: "indigo white--text" },
        { text: "ບ້ານ", value: "villageName", class: "indigo white--text" },
        // { text: "ເມືອງ", value: "districtName", class: "indigo white--text" },
        // { text: "ແຂວງ", value: "provinceName", class: "indigo white--text" },
        { text: "ຊື່ຜູ້ໃຊ້", value: "userName", class: "indigo white--text" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "indigo white--text",
        },
      ],
    };
  },
  components: {
    SpinnerLoader,
  },
  computed: {
    ...mapGetters({
      warehouses: "warehouseMod/warehouses",
      length: "warehouseMod/length",
    }),
  },
  watch: {
    length(val) {
      if (val == false) {
        this.loading = false;
      }
    },
    warehouses(val) {
      if (val.length > 0) {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.getWarehouses();
    this.Loading()
  },
  created() {},
  methods: {
    ...mapActions("warehouseMod", ["getWarehouses", "delWarehouse"]),
    showAlert() {
      this.$swal({
        position: "center",
        icon: "warning",
        title: `<div style='font-family:"Noto Sans Lao";' >ເຕືອນ</div>`,
        html: `<div style='font-family:"Noto Sans Lao"; font-size:1.5rem' >ທ່ານຕ້ອງການລົບອອກບໍ?</div>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: `<div style='font-family:"Noto Sans Lao";' >ຕົກລົງ</div>`,
        cancelButtonText: `<div style='font-family:"Noto Sans Lao";' >ຍົກເລີກ</div>`,
        confirmButtonColor: "green",
        cancelButtonColor: "red",
        reverseButtons: true,
        buttonsStyling: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteWarehouseConfirm();
        }
      });
    },
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    Loading(){
if (this.length==false) {
  this.loading=false
}
    },
    deleteWarehouse(item) {
      this.deleteItem = item;
      this.showAlert();
    },
    deleteWarehouseConfirm() {
      try {
        this.delWarehouse(this.deleteItem)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
              this.showToast(
                "success",
                `<div style='font-family:"Noto Sans Lao";' >ລົບຂໍ້ມູນສໍາເລັດແລ້ວ</div>`
              );
              this.getWarehouses();
            } else {
              this.showToast(
                "error",
                `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "error",
              `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
            );
            console.log(error);
          });
      } catch (error) {
        console.log(error);
        this.showToast(
          "error",
          `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
        );
      }
    },
    editeWarehouse(item) {
      this.$router.push(`/admin/warehouse/edit/${item._id}`);
    },
  },
};
</script>
<style lang="scss" scope>
</style>
