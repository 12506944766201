import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";
import { apiURL, header } from "../../config/constant";

Vue.use(Vuex);

const rawdataModule = {
  namespaced: true,
  state: {
    rawdatas: [],
    rawdata: [],
    rawName: [],
    rawByName: [],
    rawByDate: [],
    length: true
  },
  mutations: {
    setRawdatas(state, raw) {
      state.rawdatas = raw;
      // alert(JSON.stringify(raw));
    },
    setRawdata(state, raw) {
      state.rawdata = raw;
    },
    setRawdataName(state, raw) {
      state.rawName = raw;
    },
    setRawdatasByRaw(state, raw) {
      state.rawByName = raw;
    },
    setRawdatasByDate(state, raw) {
      state.rawByDate = raw;
    },

    setLength(state, raw) {
      state.length = raw;
    },
  },
  actions: {
    getRawdata(context, id) {
      try {
        axios
          .get(apiURL + `rawdatas/${id}`, { headers: header })
          .then((res) => {
            if (res.data.error != undefined) return alert("Not data");
            const el = res.data;
            var rawdata = {
              _id: el._id,
              raw: (el.raw == null || el.raw._id == undefined) ? null : el.raw._id,
              rawName: (el.raw == null || el.raw.rawName == undefined) ? null : el.raw.rawName,
              user: (el.user == null || el.user._id == undefined) ? null : el.user._id,
              userName: (el.user == null || el.user.userName == undefined) ? null : el.user.userName,
              role: (el.user == null || el.user.role == undefined) ? null : el.user.role,
              barCodeNumber: (el.barCodeNumber == null || el.barCodeNumber == undefined) ? null : el.barCodeNumber,
              qty: el.qty,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
              date: el.updatedAt
            };
            context.commit("setRawdata", rawdata);
          })
          .catch(function (error) {
            if (error.message == 'Network Error') {
              console.log("ບໍ່ມີອີນເທີເນັດ")
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    getRawdatas(context) {
      try {
        axios.get(apiURL + `rawdatas`, { headers: header }).then((res) => {

          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var rawlist = [], rawName = [];
          for (const idx in res.data) {
            const el = res.data[idx];
            var rawdata = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              raw: (el.raw == null || el.raw._id == undefined) ? null : el.raw._id,
              rawName: (el.raw == null || el.raw.rawName == undefined) ? null : el.raw.rawName,
              user: (el.user == null || el.user._id == undefined) ? null : el.user._id,
              userName: (el.user == null || el.user.userName == undefined) ? null : el.user.userName,
              barCodeNumber: (el.barCodeNumber == null || el.barCodeNumber == undefined) ? null : el.barCodeNumber,
              role: (el.user == null || el.user.role == undefined) ? null : el.user.role,
              qty: el.qty,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
              date: el.updatedAt
            };
            rawName.push(rawdata.rawName)
            rawlist.push(rawdata);
          }
          context.commit("setRawdataName", rawName);
          context.commit("setRawdatas", rawlist);
          context.commit("setLength", true);
        }).catch(function (error) {
          context.commit("setLength", false);
          console.log(error)
        });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
    getRawdatasByRaw(context, id) {
      try {
        axios.get(apiURL + `rawdatas/raws/${id}`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var rawlist = []
          for (const idx in res.data) {
            const el = res.data[idx];

            var rawdata = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              raw: (el.raw == null || el.raw._id == undefined) ? null : el.raw._id,
              rawName: (el.raw == null || el.raw.rawName == undefined) ? null : el.raw.rawName,
              barCodeNumber: (el.barCodeNumber == null || el.barCodeNumber == undefined) ? null : el.barCodeNumber,
              user: (el.user == null || el.user._id == undefined) ? null : el.user._id,
              userName: (el.user == null || el.user.userName == undefined) ? null : el.user.userName,
              role: (el.user == null || el.user.role == undefined) ? null : el.user.role,
              qty: el.qty,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
              date: el.updatedAt
            };
            rawlist.push(rawdata);
          }
          context.commit("setRawdatasByRaw", rawlist);
          context.commit("setLength", true);
        }).catch(function (error) {
          context.commit("setLength", false);
          console.log(error)
        });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },

    getRawdatasByDate(context, { date1, date2 }) {
      try {
        axios.get(apiURL + `rawdatas/start/${date1}/end/${date2}`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var rawlist = []
          for (const idx in res.data) {
            const el = res.data[idx];

            var rawdata = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              raw: (el.raw == null || el.raw._id == undefined) ? null : el.raw._id,
              rawName: (el.raw == null || el.raw.rawName == undefined) ? null : el.raw.rawName,
              barCodeNumber: (el.barCodeNumber == null || el.barCodeNumber == undefined) ? null : el.barCodeNumber,
              user: (el.user == null || el.user._id == undefined) ? null : el.user._id,
              userName: (el.user == null || el.user.userName == undefined) ? null : el.user.userName,
              role: (el.user == null || el.user.role == undefined) ? null : el.user.role,
              qty: el.qty,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
              date: el.updatedAt
            };
            rawlist.push(rawdata);
          }
          context.commit("setRawdatasByDate", rawlist);
          context.commit("setLength", true);
        }).catch(function (error) {
          context.commit("setLength", false);
          console.log(error)
        });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
    delRawdata(context, item) {
      try {
        return axios
          .delete(apiURL + `rawdatas/${item._id}`, { headers: header })

      } catch (e) {
        this.errors.push(e);
      }
    },
    insertRawdata(context, item) {
      try {
        return axios
          .post(apiURL + `rawdatas`, item, { headers: header })

      } catch (e) {
        this.errors.push(e);
      }
    },
    updateRawdata(context, item) {
      try {
        return axios
          .put(apiURL + `rawdatas/${item._id}`, item, { headers: header })

      } catch (e) {
        this.errors.push(e);
      }
    },
  },
  getters: {
    rawdatas: (state) => state.rawdatas,
    rawdata: (state) => state.rawdata,
    rawName: (state) => state.rawName,
    rawByName: (state) => state.rawByName,
    rawByDate: (state) => state.rawByDate,
    length: (state) => state.length,
  },
};

export default rawdataModule;
