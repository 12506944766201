<template>
  <div>
    <div>
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <v-col
              cols="12"
              md="3"
              :align="$vuetify.breakpoint.width < 960 ? 'center' : 'start'"
            >
              <span style="font-size: 1.2rem">ຂໍ້ມູນລູກຄ້າທັງໝົດ</span>
              <v-divider vertical class="mx-2 hidden-sm-and-down"></v-divider>
            </v-col>
            <v-col cols="12" md="9">
              <v-row>
                <v-spacer class="hidden-sm-and-down"></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="ຄົ້ນຫາ"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <router-link to="/staff/customer/add">
                  <v-btn class="mt-3 mr-2 green">
                    <v-icon color="white" large>mdi-plus</v-icon
                    ><span style="color: white">ເພີ່ມໃໝ່</span>
                  </v-btn>
                </router-link>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <div v-if="loading">
          <v-data-table
            :headers="headers"
            :search="search"
            sort-by="idx"
            class="elevation-0"
            hide-default-footer
            no-data-text=""
          ></v-data-table>
          <v-row no-gutters class="justify-center pb-10">
            <SpinnerLoader :color="'#90CAF9'" :loading="true" :size="100" />
          </v-row>
        </div>
         <v-card flat v-else-if="length == false">
            <v-data-table
              :headers="headers"
              :search="search"
              sort-by="idx"
              class="elevation-0"
              hide-default-footer
              no-data-text=""
            ></v-data-table>
            <v-row class="justify-center mx-4">
              <v-card
                flat
                class=""
                color="transparent"
                style="font-size: 2rem; color: grey; margin-top: 40px"
                >ບໍ່ມີຂໍ້ມູນສາຂາ
              </v-card>
            </v-row>
            <v-skeleton-loader
              style="margin-top: -125px"
              class=""
              type="image"
            >
            </v-skeleton-loader>
          </v-card>
        <v-data-table
          v-else
          large
          class="table"
          :headers="headers"
          :items="customers"
          :search="search"
          :footer-props="{ itemsPerPageText: 'ສະແດງຈໍານວນແຖວ :' }"
          fixed-header
        >
          <template v-slot:item="{ item }">
            <tr v-ripple="{ class: `white--text` }">
              <td align="center">{{ item.idx }}</td>
              <td align="center">
                <v-img
                  height="100"
                  width="100"
                  :src="item.imageURL"
                  :lazy-src="item.imageURL"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#90CAF9"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </td>
              <td>{{ item.firstName }}</td>
              <!-- <td>{{ item.lastName }}</td> -->
              <td>{{ item.lat }}</td>
              <td>
                {{ item.lng }}
              </td>
              <!-- <td>{{ item.villageName }}</td>
              <td>{{ item.districtName }}</td>
              <td>{{ item.provinceName }}</td> -->
              <td>{{ item.userName }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { SpinnerLoader } from "vue-spinners-css";
export default {
  data() {
    return {
      loading: true,
      deleteItem: [],
      search: "",
      headers: [
        {
          text: "ລໍາດັບ",
          value: "idx",
          align: "center",
          width: "100",
          class: "indigo white--text",
        },
        {
          text: "ຮູບພາບ",
          value: "imageURL",
          align: "center",
          sortable: false,
          class: "indigo white--text",
        },
        { text: "ຊີ່", value: "firstName", class: "indigo white--text" },
        // { text: "ນານສະກຸນ", value: "lastName", class: "indigo white--text" },
        { text: "ເສັ້ນແວງ", value: "lat", class: "indigo white--text" },
        { text: "ເສັ້ນຂະໜານ", value: "lng", class: "indigo white--text" },
        // { text: "ບ້ານ", value: "villageName", class: "indigo white--text" },
        // { text: "ເມືອງ", value: "districtName", class: "indigo white--text" },
        // { text: "ແຂວງ", value: "provinceName", class: "indigo white--text" },
        { text: "ຊື່ຜູ້ໃຊ້", value: "userName", class: "indigo white--text" },
      ],
    };
  },
  components: {
    SpinnerLoader,
  },
  computed: {
    ...mapGetters({
      customers: "customerMod/customers",
      length: "customerMod/length",
    }),
  },
  watch: {
    dialogDelete(val) {
      val || this.close();
    },
    length(val) {
      if (val == false) {
        this.loading = false;
      }
    },
    customers(val) {
      if (val.length > 0) {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getCustomers();
    this.Loading();
  },
  created() {},
  methods: {
    ...mapActions("customerMod", ["getCustomers", "delCustomer"]),
    showAlert() {
      this.$swal({
        position: "center",
        icon: "warning",
        title: `<div style='font-family:"Noto Sans Lao";' >ເຕືອນ</div>`,
        html: `<div style='font-family:"Noto Sans Lao"; font-size:1.5rem' >ທ່ານຕ້ອງການລົບອອກບໍ?</div>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: `<div style='font-family:"Noto Sans Lao";' >ຕົກລົງ</div>`,
        cancelButtonText: `<div style='font-family:"Noto Sans Lao";' >ຍົກເລີກ</div>`,
        confirmButtonColor: "green",
        cancelButtonColor: "red",
        reverseButtons: true,
        buttonsStyling: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deleteCustomerConfirm();
        }
      });
    },
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    Loading() {
      if (this.length == false) {
        this.loading = false;
      }
    },
    deleteCustomer(item) {
      this.deleteItem = item;
      this.showAlert();
    },
    deleteCustomerConfirm() {
      try {
        this.delCustomer(this.deleteItem)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
              this.showToast(
                "success",
                `<div style='font-family:"Noto Sans Lao";' >ລົບຂໍ້ມູນສໍາເລັດແລ້ວ</div>`
              );
              this.getCustomers();
            } else {
              this.showToast(
                "error",
                `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "error",
              `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
            );
            console.log(error);
          });
      } catch (error) {
        console.log(error);
        this.showToast(
          "error",
          `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
        );
      }
    },
    editeCustomer(item) {
      this.$router.push(`/staff/customer/edit/${item._id}`);
    },
  },
};
</script>
<style scope>
.font {
  font-family: "NotoSansLao";
}
</style>
