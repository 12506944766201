<template>
  <div>
    <div>
      <v-card>
        <v-card flat class="mt-2">
          <v-card-title>
            <v-row no-gutters class="justify-center">
              <span style="font-size: 2rem"
                >ລາຍລະອຽດລາຍການວັດຖຸດິບ-ນໍາເຂົ້າຂອງ{{ expense.rawName }}</span
              >
            </v-row>
          </v-card-title>
        </v-card>

              <v-row no-gutters class="ma-5 justify-space-around">
                <v-col cols="12" md="9">
                  <v-card class="mr-4 elevation-0">
        <v-text-field
          v-model="expense.rawName"
          class="mx-2"
          label="ຊື່ວັດຖຸດິບ"
          readonly
        ></v-text-field>

        <v-text-field
          v-model="expense.price"
          class="mx-2"
          label="ລາຄາ(ກີບ)"
          readonly
        ></v-text-field>

        <v-text-field
          v-model="expense.qty"
          class="mx-2"
          label="ຈໍານວນ"
          readonly
        ></v-text-field>

        <v-text-field
          v-model="total"
          class="mx-2"
          label="ລາຄາລວມ(ກີບ)"
          readonly
        ></v-text-field>
        
        <v-text-field
          v-model="expense.supplierName"
          class="mx-2"
          label="ຜູ້ສະໜອງ"
          readonly
        ></v-text-field>

        <v-text-field
          v-model="expense.userName"
          class="mx-2"
          label="ຜູ້ໃຊ້ລະບົບ"
          readonly
        ></v-text-field>
        <v-text-field
          v-model="expense.createdAt"
          class="mx-2"
          label="ວັນທີ່ນໍາເຂົ້າ"
          readonly
        ></v-text-field>

        <v-text-field
          v-model="expense.updatedAt"
          class="mx-2"
          label="ວັນທີ່ແກ້ໄຂ"
          readonly
        ></v-text-field>
                  </v-card>
                </v-col>
              </v-row>
        <v-divider class></v-divider>
        <v-row
          class="ma-5"
          :justify="$vuetify.breakpoint.width < 600 ? 'center' : 'start'"
        >
          <v-btn
            @click="back"
            class="ma-2 orange white--text"
            :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
          >
            <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
          </v-btn>
          <v-spacer class="d-none d-sm-flex"></v-spacer>
          <v-btn
            @click="clear"
            class="ma-2 cyan white--text"
            :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
          >
            <v-icon left>mdi-backup-restore</v-icon>ໂລດຂໍ້ມູນ
          </v-btn>
        </v-row>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      expense: "expenseMod/expense",
    }),
    total(){
      var total=0
      if(this.expense.price!=null||this.expense.qty!=null){
        total=this.expense.price*this.expense.qty
      }
      return total
    }
  },
  watch: {},

  mounted() {
    this.getExpense(this.$route.params.id);
  },
  created() {},
  methods: {
    ...mapActions("expenseMod", ["getExpense"]),

    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },

    clear() {
      this.getExpense(this.$route.params.id);
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
