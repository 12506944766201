<template>
  <div>
    <div>
      <v-row justify="center">
        <v-card class="elevation-3 ma-3" width="100%">
          <v-card-title class="justify-center">
            <span style="font-size: 1.2rem">ຫົວໜ່ວຍທັງໝົດ</span>
          </v-card-title>
            <v-row no-gutters>
              <v-col cols="12" sm="9" class="px-2">
                <v-text-field
                  label="ຊື່ຫົວໜ່ວຍ"
                  append-icon="mdi-close"
                  v-model="Edit.name"
                  @click:append="clear"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="3"
                class="px-1 pt-sm-3"
                :align="$vuetify.breakpoint.width < 600 ? 'center' : 'end'"
              >
                <v-btn
                  v-if="showEdit"
                  @click="editComfirm"
                  class="mx-3 green"
                  :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
                >
                  <v-icon color="white">mdi-pencil</v-icon
                  ><span style="color: white; font-weight: bold">ແກ້ໄຂ</span>
                </v-btn>
                <v-btn
                  v-else
                  @click="unitAdd"
                  class="mx-3 green"
                  :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
                >
                  <v-icon color="white" large>mdi-plus</v-icon
                  ><span style="color: white; font-weight: bold">ເພີ່ມໃໝ່</span>
                </v-btn>
              </v-col>
            </v-row>
            <div v-if="loading">
              <v-data-table
                :headers="headers"
                :search="search"
                sort-by="idx"
                class="elevation-0"
                hide-default-footer
                no-data-text=""
              ></v-data-table>
              <v-row no-gutters class="justify-center mb-10">
                <SpinnerLoader :color="'#90CAF9'" :loading="true" :size="100" />
              </v-row>
            </div>
         <v-card flat v-else-if="length == false">
            <v-data-table
              :headers="headers"
              :search="search"
              sort-by="idx"
              class="elevation-0"
              hide-default-footer
              no-data-text=""
            ></v-data-table>
            <v-row class="justify-center mx-4">
              <v-card
                flat
                class=""
                color="transparent"
                style="font-size: 2rem; color: grey; margin-top: 40px"
                >ບໍ່ມີຂໍ້ມູນຫົວໜ່ວຍ
              </v-card>
            </v-row>
            <v-skeleton-loader
              style="margin-top: -125px"
              class=""
              type="image"
            >
            </v-skeleton-loader>
          </v-card>
            <v-data-table
              v-else
              :headers="headers"
              :search="search"
              :items="unit"
              sort-by="idx"
              class="elevation-0"
          :footer-props="{itemsPerPageText: 'ສະແດງຈໍານວນແຖວ :'}"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer class="hidden-sm-and-down"></v-spacer>
                  <v-text-field
                    dense
                    rounded
                    outlined
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="ຄົ້ນຫາ"
                    single-line
                    hide-details
                  ></v-text-field>
                 
                </v-toolbar>

                <v-divider class=""></v-divider>
                <v-divider class=""></v-divider>
              </template>
            </v-data-table>
        </v-card>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { SpinnerLoader } from "vue-spinners-css";
export default {
  data: () => ({
    loading: true,
    showEdit: false,
    search: "",
    headers: [
      { text: "ລໍາດັບ", value: "idx", class: "indigo white--text  " },
      {
        text: "ຊື່ຫົວໜ່ວຍ",
        value: "name",
        class: "indigo white--text ",
      },
      {
        text: "ວັນທີ່ສ້າງຂຶ້ນ",
        value: "createdAt",
        class: "indigo white--text ",
      },
      {
        text: "ວັນທີ່ແກ້ໄຂ",
        value: "updatedAt",
        class: "indigo white--text ",
      },
     
    ],
    Edit: {
      _id: "",
      name: "",
      createdAt: null,
      updatedAt: null,
    },
    DeleteItem: [],
  }),

  components: {
    SpinnerLoader,
  },
  computed: {
    ...mapGetters({
      unit: "unitMod/unit",
      length: "unitMod/length",
    }),
  },
  watch: {
    length(val) {
      if (val == false) {
        this.loading = false;
      }
    },
    unit(val) {
      if (val.length > 0) {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getUnit();
    this.Loading()
  },
  methods: {
    ...mapActions("unitMod", [
      "getUnit",
      "insertUnit",
      "updateUnit",
      "delUnit",
    ]),
    showAlert() {
      this.$swal({
        position: "center",
        icon: "warning",
        title: `<div style='font-family:"Noto Sans Lao";' >ເຕືອນ</div>`,
        html: `<div style='font-family:"Noto Sans Lao"; font-size:1.5rem' >ທ່ານຕ້ອງການລົບອອກບໍ?</div>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: `<div style='font-family:"Noto Sans Lao";' >ຕົກລົງ</div>`,
        cancelButtonText: `<div style='font-family:"Noto Sans Lao";' >ຍົກເລີກ</div>`,
        confirmButtonColor: "green",
        cancelButtonColor: "red",
        reverseButtons: true,
        buttonsStyling: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteUnitConfirm();
        }
      });
    },
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    Loading() {
      if (this.length == false) {
        this.loading = false;
      }
    },
    clear() {
      this.Edit = {
        idx: 0,
        _id: null,
        name: "",
        createdAt: null,
        updatedAt: null,
      };
    },
    unitAdd() {
      try {
        if (this.Edit.name == null || this.Edit.name == "") {
          this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາໃສ່ຊື່ຫົວໜ່ວຍຜະລິດຕະພັນ</div>`,)
          return;
        }
        this.insertUnit(this.Edit)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
          this.showToast("success",`<div style='font-family:"Noto Sans Lao";' >ບັນທຶກຂໍ້ມູນສໍາເລັດແລ້ວ</div>`,)
              this.getUnit();
              this.clear();
              this.showEdit = false;
            } else {
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            }
          })
          .catch( (error) =>{
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            console.log(error);
          });
      } catch (error) {
        console.log(error);
          this.showToast("error","ຂໍ້ມູນຜິດພາດ!")
      }
    },
    editeUnit(item) {
      this.Edit = item;
      this.showEdit = true;
    },
    editComfirm() {
      try {
        if (this.Edit.name == null || this.Edit.name == "") {
          this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາໃສ່ຊື່ຫົວໜ່ວຍຜະລິດຕະພັນ</div>`,)
          return;
        }
        this.updateUnit(this.Edit)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
          this.showToast("success",`<div style='font-family:"Noto Sans Lao";' >ແກ້ໄຂຂໍ້ມູນສໍາເລັດ</div>`,)
              this.getUnit();
              this.clear();
              this.showEdit = false;
            } else {
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            }
          })
          .catch( (error) =>{
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            console.log(error);
          });
      } catch (error) {
        console.log(error);
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
      }
    },
    deleteUnit(item) {
      this.DeleteItem = item;
     this.showAlert()
    },
    deleteUnitConfirm() {
      try {
        this.delUnit(this.DeleteItem)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
              this.getUnit();
              this.clear();
          this.showToast("success",`<div style='font-family:"Noto Sans Lao";' >ລົບຂໍ້ມູນສໍາເລັດແລ້ວ</div>`,)
            } else {
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            }
          })
          .catch( (error) =>{
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            console.log(error);
          });
      } catch (error) {
        console.log(error);
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
      }
    },
  },
};
</script>
<style scoped>
.font {
  font-family: "NotoSansLao";
}
</style>
