<template>
  <div>
    <v-card>
      <v-row class="">
        <v-col cols="12" md="6">
          <v-toolbar flat>
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  style="margin-top: 20px"
                  class="mx-1"
                  v-model="date1"
                  label="ວັນທີ່ເລີ່ມຕົ້ນ"
                  v-bind="attrs"
                  v-on="on"
                  append-icon="mdi-calendar"
                  @click:prepend="on"
                ></v-text-field>
              </template>
              <v-date-picker
                no-title
                :max="maxDate"
                v-model="date1"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
            <v-spacer></v-spacer>
            <h1>-</h1>
            <v-spacer></v-spacer>
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  style="margin-top: 20px"
                  class="mx-1"
                  v-model="date2"
                  label="ວັນທີ່ສີ້ນສຸດ"
                  v-bind="attrs"
                  v-on="on"
                  append-icon="mdi-calendar"
                  @click:prepend="menu2 = !menu2"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date2"
                :max="maxDate"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu> </v-toolbar
        ></v-col>
        <v-col cols="12" md="6">
          <v-row no-gutters class="mx-1 mt-1 justify-center">
            <v-col
              cols="12"
              md="4"
              class="mt-3"
              :align="$vuetify.breakpoint.width < 960 ? 'center' : 'end'"
            >
              <span style="margin-right: 5px"
                >ຍອດມູນຄ່າສັ່ງຊື້ທັງໝົດ:
              </span></v-col
            >
            <v-col cols="12" md="8">
              <v-card
                flat
                tile
                style="background: #f0f0f0; width: 100%"
                class="py-2"
              >
                <v-row no-gutters class="justify-center"
                  ><span style="font-size: 1.5rem; color: blue"
                    >{{
                      totals == undefined || totals == null
                        ? 0
                        : String(parseInt(totals)).replace(
                            /(.)(?=(\d{3})+$)/g,
                            "$1,"
                          )
                    }}
                    ກີບ</span
                  ></v-row
                >
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-card flat>
        <v-row no-gutters class="ma-2">
          <v-col
            cols="12"
            md="3"
            :align="$vuetify.breakpoint.width < 960 ? 'center' : 'start'"
            class="mt-3 mt-md-10"
          >
            <span style="font-size: 1.2rem">ລາຍການສັ່ງຊື້ທັງໝົດ</span>
            <v-divider vertical class="mx-2 hidden-sm-and-down"></v-divider>
          </v-col>
          <v-col cols="12" md="9" class="mt-3 mt-md-10">
            <v-row no-gutters class="justify-center justify-md-end">
              <riamExcel
                class="btn btn-default"
                :fetch="fetchData"
                :fields="json_fields"
                worksheet="ສັ່ງຊື້"
                :name="excelTitle"
                :before-finish="finishDownload"
              >
                <v-btn
                  class="mt-0 mt-md-3 mr-2 indigo"
                  :width="$vuetify.breakpoint.width < 600 ? '300' : 'auto'"
                >
                  <span style="color: white">ດືງຂໍ້ມູນ</span>
                </v-btn>
              </riamExcel>
            </v-row>
          </v-col>
        </v-row>
        <div v-if="loading">
          <v-data-table
            :headers="headers"
            :search="search"
            sort-by="idx"
            class="elevation-0"
            hide-default-footer
            no-data-text=""
          >
          </v-data-table>
          <v-row no-gutters class="justify-center pb-10">
            <SpinnerLoader :color="'#90CAF9'" :loading="true" :size="100" />
          </v-row>
        </div>
        <v-card flat v-else-if="length == false">
          <v-data-table
            :headers="headers"
            :search="search"
            sort-by="idx"
            class="elevation-0"
            hide-default-footer
            no-data-text=""
          ></v-data-table>
          <v-row class="justify-center mx-4">
            <v-card
              flat
              class=""
              color="transparent"
              style="font-size: 2rem; color: grey; margin-top: 40px"
              >ບໍ່ມີຂໍ້ມູນສັ່ງຊື້
            </v-card>
          </v-row>
          <v-skeleton-loader style="margin-top: -125px" type="image">
          </v-skeleton-loader>
        </v-card>
        <v-data-table
          v-else
          large
          class="table"
          :headers="headers"
          :items="orders"
          :search="search"
          fixed-header
          :footer-props="{ itemsPerPageText: 'ສະແດງຈໍານວນແຖວ :' }"
        >
          <template v-slot:item="{ item }">
            <tr v-ripple="{ class: `white--text` }">
              <td>{{ item.idx }}</td>
              <td>{{ item.userName }}</td>

              <td>
                {{ item.paymentType }}
              </td>
              <td>
                {{
                  item.totalPayment == undefined || item.totalPayment == null
                    ? 0
                    : String(parseInt(item.totalPayment)).replace(
                        /(.)(?=(\d{3})+$)/g,
                        "$1,"
                      )
                }}
              </td>
              <td :style="{ color: backColor(item.orderStatus) }">
                {{ item.orderStatus }}
              </td>
              <td>
                {{ item.updatedAt }}
              </td>
              <td>
                <v-row class="">
                  <v-icon
                    @click="viewOrder(item)"
                    class="mr-2"
                    color="light-blue lighten-1"
                  >
                    mdi-eye
                  </v-icon>
                  <v-icon color="error" @click="deleteOrder(item)">
                    mdi-delete
                  </v-icon>
                </v-row>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { SpinnerLoader } from "vue-spinners-css";
import JsonExcel from "vue-json-excel";
import moment from "moment";
export default {
  data() {
    return {
      loading: true,
      date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu1: false,
      date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu2: false,
      maxDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      excelTitle:
        "order-" +
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10) +
        ".xls",
      deleteItem: [],
      search: "",
      headers: [
        { text: "ລໍາດັບ", value: "idx", class: "indigo white--text" },
        { text: "ຊື່ຜູ້ສັ່ງ", value: "userName", class: "indigo white--text" },
        {
          text: "ຮູບແບບຊໍາລະ",
          value: "paymentType",
          class: "indigo white--text",
        },
        { text: "ລາຄາລວມ", value: "totalPayment", class: "indigo white--text" },
        { text: "ສະຖານະ", value: "orderStatus", class: "indigo white--text" },
        { text: "ວັນທີ່ສັ່ງ", value: "updatedAt", class: "indigo white--text" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "indigo white--text",
        },
      ],
      json_fields: {
        ຊື່ວັດຖຸດິບ: "productName",
        ຈໍານວນ: "quantity",
        ລາຄາ: "wholeSale",
        ຄໍາອະທິບາຍ: "description",
        ຜູ້ສັ່ງ: "userName",
        ຮູບແບບຊໍາລະ: "paymentType",
        ສະຖານະ: "orderStatus",
        ວັນທີ່ສັ່ງ: "orderUpdatedAt",
      },
    };
  },
  components: {
    SpinnerLoader,
    riamExcel: JsonExcel,
  },
  computed: {
    ...mapGetters({
      orders: "orderMod/orders",
      length: "orderMod/length",
    }),
    totals() {
      var total = 0;
      if (this.orders.length > 0) {
        for (const key in this.orders) {
          const element = this.orders[key];
          if (
            element.totalPayment != null &&
            element.totalPayment != undefined
          ) {
            total += parseFloat(element.totalPayment);
          }
        }
      }
      return total;
    },
    row_color() {
      var color = "#fff";

      if (this.orders.length > 0) {
        for (const key in this.orders) {
          const element = this.orders[key];
          if (element.orderStatus != null && element.orderStatus == "NEW") {
            color = "#E3F2FD";
          }

          if (element.orderStatus != null && element.orderStatus == "PENDING") {
            color = "#E8F5E9";
          }

          if (element.orderStatus != null && element.orderStatus == "SHIPPED") {
            color = "#E0F2F1";
          }
        }
      }
      return color;
    },
  },

  watch: {
    length(val) {
      if (val == false) {
        this.loading = false;
      }
    },
    orders(val) {
      if (val.length > 0) {
        this.loading = false;
      }
    },

    date1(val) {
      if (new Date(val).getTime() < new Date(this.date2).getTime()) {
        this.getOrderByDateList({
          date1: val,
          date2: this.date2,
        });
      } else {
        this.getOrderByDateList({
          date1: val,
          date2: val,
        });
      }
    },
    date2(val) {
      if (new Date(val).getTime() > new Date(this.date1).getTime()) {
        this.getOrderByDateList({
          date1: this.date1,
          date2: val,
        });
      } else {
        this.getOrderByDateList({ date1: val, date2: val });
      }
    },
  },

  mounted() {
    this.getOrders();
    this.Loading();
  },
  created() {},
  methods: {
    ...mapActions("orderMod", [
      "getOrders",
      "getOrderByDateList",
      "getOrderByDate",
      "delOrder",
      "updateOrder",
    ]),
    showAlert() {
      this.$swal({
        position: "center",
        icon: "warning",
        title: `<div style='font-family:"Noto Sans Lao";' >ເຕືອນ</div>`,
        html: `<div style='font-family:"Noto Sans Lao"; font-size:1.5rem' >ທ່ານຕ້ອງການລົບອອກບໍ?</div>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: `<div style='font-family:"Noto Sans Lao";' >ຕົກລົງ</div>`,
        cancelButtonText: `<div style='font-family:"Noto Sans Lao";' >ຍົກເລີກ</div>`,
        confirmButtonColor: "green",
        cancelButtonColor: "red",
        reverseButtons: true,
        buttonsStyling: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteOrderConfirm();
        }
      });
    },
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    Loading() {
      if (this.length == false) {
        this.loading = false;
      }
    },
    deleteOrder(item) {
      this.deleteItem = item;
      this.showAlert();
    },
    deleteOrderConfirm() {
      try {
        this.delOrder(this.deleteItem)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
              this.showToast(
                "success",
                `<div style='font-family:"Noto Sans Lao";' >ລົບຂໍ້ມູນສໍາເລັດແລ້ວ</div>`
              );
              this.getOrders();
            } else {
              this.showToast(
                "error",
                `<div style='font-family:"Noto Sans Lao";color:red'>ຂໍ້ມູນຜິດພາດ</div>`
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "error",
              `<div style='font-family:"Noto Sans Lao";color:red'>ຂໍ້ມູນຜິດພາດ</div>`
            );
            console.log(error);
          });
      } catch (error) {
        console.log(error);
        this.showToast(
          "error",
          `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
        );
      }
    },
    editeOrder(item) {
      this.$router.push(`/admin/order/edit/${item._id}`);
    },

    viewOrder(item) {
      var list = [];
      if (item.items.length > 0) {
        for (const key in item.items) {
          const el = item.items[key];
          var item_list = {
            product:
              (el.product == null) | (el.product == undefined)
                ? null
                : el.product._id,
            quantity: el.quantity,
          };
          list.push(item_list);
        }
        if (item.orderStatus == "NEW") {
          var itemList = {
            _id: item._id,
            orderStatus: "PENDING",
            items: list,
          };

          this.updateOrder(itemList)
            .then((res) => {
              if (res.data.message == "SUCCESSFULLY") {
                console.log(res);
                // this.showToast("success",`<div style='font-family:"Noto Sans Lao";' >ແກ້ໄຂຂໍ້ມູນສໍາເລັດແລ້ວ</div>`,)
              } else {
                this.showToast(
                  "error",
                  `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
                );
              }
            })
            .catch((error) => {
              this.showToast(
                "error",
                `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
              );
              console.log(error);
            });
        }
      }

      this.$router.push(`/admin/order/view/${item._id}`);
    },
    async fetchData() {
      try {
        if (this.orders.length < 1) {
          this.showToast(
            "error",
            `<div style='font-family:"Noto Sans Lao";color:red' >ບໍ່ມີຂໍ້ມູນສັ່ງຊື້</div>`
          );
          return;
        }
        var prolist = [];
        for (const idx in this.orders) {
          const el = this.orders[idx];
          var list = [];
          const length =
            el.items == null || el.items == undefined ? 0 : el.items.length;
          if (length > 0) {
            for (const px in el.items) {
              const pro = el.items[px];
              var Product = {
                idx: parseInt(px) + 1,
                _id: pro._id,
                productName:
                  pro.product == null || pro.product == undefined
                    ? null
                    : pro.product.productName,
                category:
                  pro.product == null || pro.product == undefined
                    ? null
                    : pro.product.category,
                wholeSale:
                  pro.product == null || pro.product == undefined
                    ? 0
                    : pro.product.wholeSale,
                retail:
                  pro.product == null || pro.product == undefined
                    ? 0
                    : pro.product.retail,
                promotionPrice:
                  pro.product == null || pro.product == undefined
                    ? 0
                    : pro.product.promotionPrice,
                qty:
                  pro.product == null || pro.product == undefined
                    ? 0
                    : pro.product.qty,
                quantity: pro.quantity,
                description:
                  pro.product == null || pro.product == undefined
                    ? 0
                    : pro.product.description,
                image:
                  pro.product == null || pro.product == undefined
                    ? 0
                    : pro.product.image,
                createdAt:
                  moment(String(pro.product.createdAt)).format("DD-MM-YYYY") +
                  " " +
                  pro.product.createdAt.slice(
                    11,
                    pro.product.createdAt.length - 5
                  ),
                updatedAt:
                  moment(String(pro.product.updatedAt)).format("DD-MM-YYYY") +
                  " " +
                  pro.product.updatedAt.slice(
                    11,
                    pro.product.updatedAt.length - 5
                  ),

                userName: el.userName,
                orderStatus: el.orderStatus,
                paymentType: el.paymentType,
                totalPayment: el.totalPayment,
                orderCreatedAt:
                  moment(String(el.createdAt)).format("DD-MM-YYYY") +
                  " " +
                  el.createdAt.slice(11, el.createdAt.length - 5),
                orderUpdatedAt:
                  moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                  " " +
                  el.updatedAt.slice(11, el.updatedAt.length - 5),
              };

              list.push(Product);
              prolist.push(Product);
            }
          }
        }
        return prolist;
      } catch (error) {
        console.log(error);
      }
    },
    finishDownload() {
      this.showToast(
        "success",
        `<div style='font-family:"Noto Sans Lao";' >ດາວໂລດສໍາເລັດແລ້ວ</div>`
      );
    },
    backColor(status) {
      var color = "";
      if (status == "NEW") {
        color = "#F22F06";
      } else if (status == "PENDING") {
        color = "#F98108";
      } else if (status == "PACKED") {
        color = "#0879F9";
      } else if (status == "SHIPPED") {
        color = "#4608F9";
      } else if (status == "DELIVERED") {
        color = "#07BD44";
      } else if (status == "CANCELED") {
        color = "#F22F06";
      } else {
        color = "#fff";
      }
      return color;
    },
  },
};
</script>
