import Vue from 'vue'
import VueRouter from 'vue-router'

import signin from '../views/signin.vue'

import admin from '../views/admin/home.vue'
import adminHome from '../views/admin/dashboard.vue'

import order from '../views/admin/service/order.vue'
import orderEdit from '../views/admin/service/orderEdit.vue'
import orderView from '../views/admin/service/orderView.vue'
import expense from '../views/admin/service/expense.vue'
import expenseAdd from '../views/admin/service/expenseAdd.vue'
import expenseView from '../views/admin/service/expenseView.vue'
import stock from '../views/admin/service/stock.vue'
import stockAdd from '../views/admin/service/stockAdd.vue'
import stockEdit from '../views/admin/service/stockEdit.vue'
import rawBarCode from '../views/admin/service/rawBarCode.vue'

import productBarCodePrint from '../views/admin/barCode/productBarCode.vue'
import rawBarCodePrint from '../views/admin/barCode/rawBarCode.vue'

import supplier from '../views/admin/manage/supplier.vue'
import supplierAdd from '../views/admin/manage/supplierAdd.vue'
import supplierEdit from '../views/admin/manage/supplierEdit.vue'
import product from '../views/admin/manage/products.vue'
import productAdd from '../views/admin/manage/productAdd.vue'
import productEdit from '../views/admin/manage/productEdit.vue'
import productEditBarCode from '../views/admin/manage/productEditByBarCode.vue'
import productBarCode from '../views/admin/manage/productBarCode.vue'
import customer from '../views/admin/manage/customer.vue'
import customerAdd from '../views/admin/manage/customerAdd.vue'
import customerEdit from '../views/admin/manage/customerEdit.vue'
import user from '../views/admin/manage/user.vue'
import userAdd from '../views/admin/manage/userAdd.vue'
import userEdit from '../views/admin/manage/userEdit.vue'
import rawdata from '../views/admin/manage/rawdata.vue'
import rawdataAdd from '../views/admin/manage/rawdataAdd.vue'
import rawdataEdit from '../views/admin/manage/rawdataEdit.vue'
import warehouse from '../views/admin/manage/warehouse.vue'
import warehouseAdd from '../views/admin/manage/warehouseAdd.vue'
import warehouseEdit from '../views/admin/manage/warehouseEdit.vue'


import event from '../views/admin/manage/event.vue'
import eventAdd from '../views/admin/manage/eventAdd.vue'
import eventEdit from '../views/admin/manage/eventEdit.vue'
import eventView from '../views/admin/manage/eventView.vue'

import category from '../views/admin/setting/category.vue'
import unit from '../views/admin/setting/unit.vue'
import banner from '../views/admin/setting/banner.vue'
import bannerAdd from '../views/admin/setting/bannerAdd.vue'
import bannerEdit from '../views/admin/setting/bannerEdit.vue'
import raw from '../views/admin/setting/raw.vue'
import rawAdd from '../views/admin/setting/rawAdd.vue'
import rawEdit from '../views/admin/setting/rawEdit.vue'
import profile from '../views/admin/setting/profile.vue'

import province from '../views/admin/address/province.vue'
import district from '../views/admin/address/district.vue'
import districtAdd from '../views/admin/address/districtAdd.vue'
import districtEdit from '../views/admin/address/districtEdit.vue'
import village from '../views/admin/address/village.vue'
import villageAdd from '../views/admin/address/villageAdd.vue'
import villageEdit from '../views/admin/address/villageEdit.vue'

import productReport from '../views/admin/report/product.vue'
import rawdataReport from '../views/admin/report/rawdata.vue'
import orderReport from '../views/admin/report/order.vue'
import orderDateReport from '../views/admin/report/orderByDate.vue'
import orderBarCodeReport from '../views/admin/report/orderBarCode.vue'
import expenseReport from '../views/admin/report/expense.vue'
import stockReport from '../views/admin/report/stock.vue'
import expenseRawNameReport from '../views/admin/report/expenseByRawName.vue'
import expenseSupplierReport from '../views/admin/report/expenseBySupplier.vue'
import expenseDateReport from '../views/admin/report/expenseByDate.vue'
import rawNameReport from '../views/admin/report/rawByRawName.vue'
import rawDateReport from '../views/admin/report/rawByDate.vue'


// ___________________________Staff________________________
import staff from '../views/staff/home.vue'
import staffHome from '../views/staff/dashboard.vue'

import orderStaff from '../views/staff/service/order.vue'
import orderEditStaff from '../views/staff/service/orderEdit.vue'
import orderViewStaff from '../views/staff/service/orderView.vue'
import expenseStaff from '../views/staff/service/expense.vue'
import expenseAddStaff from '../views/staff/service/expenseAdd.vue'
import expenseViewStaff from '../views/staff/service/expenseView.vue'
import stockStaff from '../views/staff/service/stock.vue'
import stockAddStaff from '../views/staff/service/stockAdd.vue'
import stockEditStaff from '../views/staff/service/stockEdit.vue'
import rawBarCodeStaff from '../views/staff/service/rawBarCode.vue'

import supplierStaff from '../views/staff/manage/supplier.vue'
import supplierAddStaff from '../views/staff/manage/supplierAdd.vue'
import supplierEditStaff from '../views/staff/manage/supplierEdit.vue'
import productStaff from '../views/staff/manage/products.vue'
import productAddStaff from '../views/staff/manage/productAdd.vue'
import productEditStaff from '../views/staff/manage/productEdit.vue'
import productBarCodeStaff from '../views/staff/manage/productBarCode.vue'
import productEditBarCodeStaff from '../views/staff/manage/productEditByBarCode.vue'
import customerStaff from '../views/staff/manage/customer.vue'
import customerAddStaff from '../views/staff/manage/customerAdd.vue'
import customerEditStaff from '../views/staff/manage/customerEdit.vue'
import userStaff from '../views/staff/manage/user.vue'
import userAddStaff from '../views/staff/manage/userAdd.vue'
import userEditStaff from '../views/staff/manage/userEdit.vue'
import rawdataStaff from '../views/staff/manage/rawdata.vue'
import rawdataAddStaff from '../views/staff/manage/rawdataAdd.vue'
import rawdataEditStaff from '../views/staff/manage/rawdataEdit.vue'
import warehouseStaff from '../views/staff/manage/warehouse.vue'
import warehouseAddStaff from '../views/staff/manage/warehouseAdd.vue'
import warehouseEditStaff from '../views/staff/manage/warehouseEdit.vue'


import eventStaff from '../views/staff/manage/event.vue'
import eventAddStaff from '../views/staff/manage/eventAdd.vue'
import eventEditStaff from '../views/staff/manage/eventEdit.vue'
import eventViewStaff from '../views/staff/manage/eventView.vue'


import productBarCodePrintStaff from '../views/staff/barCode/productBarCode.vue'
import rawBarCodePrintStaff from '../views/staff/barCode/rawBarCode.vue'

import categoryStaff from '../views/staff/setting/category.vue'
import unitStaff from '../views/staff/setting/unit.vue'
import bannerStaff from '../views/staff/setting/banner.vue'
import bannerAddStaff from '../views/staff/setting/bannerAdd.vue'
import bannerEditStaff from '../views/staff/setting/bannerEdit.vue'
import rawStaff from '../views/staff/setting/raw.vue'
import rawAddStaff from '../views/staff/setting/rawAdd.vue'
import rawEditStaff from '../views/staff/setting/rawEdit.vue'

import provinceStaff from '../views/staff/address/province.vue'
import districtStaff from '../views/staff/address/district.vue'
import districtAddStaff from '../views/staff/address/districtAdd.vue'
import districtEditStaff from '../views/staff/address/districtEdit.vue'
import villageStaff from '../views/staff/address/village.vue'
import villageAddStaff from '../views/staff/address/villageAdd.vue'
import villageEditStaff from '../views/staff/address/villageEdit.vue'

import productReportStaff from '../views/staff/report/product.vue'
import rawdataReportStaff from '../views/staff/report/rawdata.vue'
import orderReportStaff from '../views/staff/report/order.vue'
import orderDateReportStaff from '../views/staff/report/orderByDate.vue'
import expenseReportStaff from '../views/staff/report/expense.vue'
import stockReportStaff from '../views/staff/report/stock.vue'
import expenseRawNameReportStaff from '../views/staff/report/expenseByRawName.vue'
import expenseSupplierReportStaff from '../views/staff/report/expenseBySupplier.vue'
import expenseDateReportStaff from '../views/staff/report/expenseByDate.vue'
import rawNameReportStaff from '../views/staff/report/rawByRawName.vue'
import rawDateReportStaff from '../views/staff/report/rawByDate.vue'

// __________________client_______________________________________________
import oneWater from '../views/client/oneWater.vue'
import home from '../views/client/home.vue'
import products from '../views/client/products.vue'
import contact from '../views/client/contact.vue'
import eventClient from '../views/client/event.vue'
import about from '../views/about.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'oneWater',
    component: oneWater,
    children: [
      {
        path: '/',
        name: 'home',
        component: home
      },
      {
        path: '/event',
        name: 'event',
        component: eventClient
      },
      {
        path: '/products',
        name: 'products',
        component: products
      },
      {
        path: '/contact',
        name: 'contact',
        component: contact
      },
      {
        path: '/about',
        name: 'about',
        component: about
      },

    ]
  },

  {
    path: '/signin',
    name: 'signin',
    component: signin
  },
  {
    path: '/admin',
    name: 'admin',
    component: admin,
    beforeEnter: (to, from, next) => {
      var token = window.localStorage.getItem('token') ? window.localStorage.getItem('token') : null
      if (token != null && token != undefined) next()
      else next({ path: '/signin' })
    },
    children: [
      {
        path: '/',
        name: 'adminHome',
        component: adminHome
      },
      {
        path: '/admin/order',
        name: 'adminOrder',
        component: order
      },
      {
        path: '/admin/order/edit/:id',
        name: 'adminOrderEdit',
        component: orderEdit
      },
      {
        path: '/admin/order/view/:id',
        name: 'adminOrderView',
        component: orderView
      },
      {
        path: '/admin/expense',
        name: 'adminexpense',
        component: expense
      },
      {
        path: '/admin/expense/add',
        name: 'adminExpenseAdd',
        component: expenseAdd
      },
      {
        path: '/admin/expense/view/:id',
        name: 'adminExpenseView',
        component: expenseView
      },
      {
        path: '/admin/stock',
        name: 'adminStock',
        component: stock
      },
      {
        path: '/admin/stock/add',
        name: 'adminStockAdd',
        component: stockAdd
      },
      {
        path: '/admin/stock/edit/:id',
        name: 'adminstockEdit',
        component: stockEdit
      },
      {
        path: '/admin/product/barcode/print',
        name: 'adminBarCodePrint',
        component: productBarCodePrint,
      },
      {
        path: '/admin/raw/barcode/print',
        name: 'adminBarCodePrint',
        component: rawBarCodePrint,
      },
      {
        path: '/admin/manage/supplier',
        name: 'adminManageSupplier',
        component: supplier,
      },
      {
        path: '/admin/supplier/add',
        name: 'adminSupplierAdd',
        component: supplierAdd,
      }, {
        path: '/admin/supplier/edit/:id',
        name: 'adminSupplierEdit',
        component: supplierEdit,
      },

      {
        path: '/admin/manage/product',
        name: 'adminManageProduct',
        component: product,
      },
      {
        path: '/admin/product/add',
        name: 'adminProductAdd',
        component: productAdd,
      },
      {
        path: '/admin/product/edit/:id',
        name: 'adminProductEdit',
        component: productEdit,
      },

      {
        path: '/admin/product/edit/barcode/:id',
        name: 'adminProductEditBarCode',
        component: productEditBarCode,
      },
      // {
      //   path: '/admin/product/barcode/:id',
      //   name: 'adminProductBarCode',
      //   component: productBarCode,
      // },
      {
        path: '/admin/manage/user',
        name: 'admiaManageUser',
        component: user,
      }, {
        path: '/admin/user/add',
        name: 'userAdd',
        component: userAdd,
      }, {
        path: '/admin/user/edit/:id',
        name: 'adminuserEdit',
        component: userEdit
      },
      {
        path: '/admin/manage/rawdata',
        name: 'adminManageRawdata',
        component: rawdata,
      }, {
        path: '/admin/rawdata/add',
        name: 'adminRawdataAdd',
        component: rawdataAdd,
      }, {
        path: '/admin/rawdata/edit/:id',
        name: 'adminRawdataEdit',
        component: rawdataEdit
      },

      {
        path: '/admin/manage/warehouse',
        name: 'adminManageWarehouse',
        component: warehouse,
      }, {
        path: '/admin/warehouse/add',
        name: 'adminWarehouseAdd',
        component: warehouseAdd,
      }, {
        path: '/admin/warehouse/edit/:id',
        name: 'adminWarehouseEdit',
        component: warehouseEdit
      },
      {
        path: '/admin/manage/customer',
        name: 'adminManageCustomer',
        component: customer,
      }, {
        path: '/admin/customer/add',
        name: 'adminCustomerAdd',
        component: customerAdd,
      }, {
        path: '/admin/customer/edit/:id',
        name: 'adminCustomerEdit',
        component: customerEdit
      },
      {
        path: '/admin/event',
        name: 'adminEvent',
        component: event,
      },
      {
        path: '/admin/event/add',
        name: 'adminEventAdd',
        component: eventAdd,
      },
      {
        path: '/admin/event/edit/:id',
        name: 'adminEventEdit',
        component: eventEdit,
      },
      {
        path: '/admin/event/view/:id',
        name: 'adminEventView',
        component: eventView,
      },

      {
        path: '/admin/setting/category',
        name: 'adminSettingCategory',
        component: category,
      },
      {
        path: '/admin/setting/unit',
        name: 'adminSettingUnit',
        component: unit,
      },
      {
        path: '/admin/setting/banner',
        name: 'adminSettingBanner',
        component: banner,
      }, {
        path: '/admin/banner/add',
        name: 'adminBannerAdd',
        component: bannerAdd,
      },
      {
        path: '/admin/banner/edit/:id',
        name: 'adminBannerEdit',
        component: bannerEdit,
      }, {
        path: '/admin/setting/raw',
        name: 'adminSettingRaw',
        component: raw,
      }, {
        path: '/admin/raw/add',
        name: 'adminRawAdd',
        component: rawAdd,
      },
      {
        path: '/admin/profile/edit',
        name: 'adminProfile',
        component: profile,
      },
      {
        path: '/admin/raw/edit/:id',
        name: 'adminRawEdit',
        component: rawEdit,
      },
      {
        path: '/admin/address/province',
        name: 'adminAddressProvince',
        component: province,
      },
      {
        path: '/admin/address/district',
        name: 'adminAddressDistrict',
        component: district,
      },
      {
        path: '/admin/address/district/add',
        name: 'adminAddressDistrictAdd',
        component: districtAdd,
      },
      {
        path: '/admin/address/district/edit/:id',
        name: 'adminAddressDistrictEdit',
        component: districtEdit,
      },
      {
        path: '/admin/address/village',
        name: 'adminaAddressVillage',
        component: village,
      },
      {
        path: '/admin/address/village/add',
        name: 'adminaAddressVillageAdd',
        component: villageAdd,
      },
      {
        path: '/admin/address/village/edit/:id',
        name: 'adminaAddressVillageEdit',
        component: villageEdit,
      },
      {
        path: '/admin/report/product',
        name: 'adminaReportProduct',
        component: productReport,
      },
      {
        path: '/admin/report/rawdata',
        name: 'adminaReportRawdata',
        component: rawdataReport,
      },
      {
        path: '/admin/report/raw/name/:id',
        name: 'adminaReportRawName',
        component: rawNameReport,
      },
      {
        path: '/admin/report/raw/date',
        name: 'adminaReportRawDate',
        component: rawDateReport,
      },
      {
        path: '/admin/report/order/barcode/:id',
        name: 'adminaReportOrderBarCode',
        component: orderBarCodeReport,
      },
      {
        path: '/admin/report/order/date',
        name: 'adminaReportOrderDate',
        component: orderDateReport,
      },
      {
        path: '/admin/report/order',
        name: 'adminaReportOrder',
        component: orderReport,
      },
      {
        path: '/admin/report/expense',
        name: 'adminaReportexpense',
        component: expenseReport,
      },
      {
        path: '/admin/report/expense/raw/:id',
        name: 'adminaReportexpenseRaw',
        component: expenseRawNameReport,
      },
      {
        path: '/admin/report/expense/supplier/:id',
        name: 'adminaReportExpenseSupplier',
        component: expenseSupplierReport,
      },
      {
        path: '/admin/report/expense/date',
        name: 'adminaReportExpenseDate',
        component: expenseDateReport,
      },
      {
        path: '/admin/report/stock',
        name: 'adminaReportStock',
        component: stockReport,
      },
    ]
  },

  {
    path: '/staff',
    name: 'staff',
    component: staff,
    beforeEnter: (to, from, next) => {
      var token = window.localStorage.getItem('token') ? window.localStorage.getItem('token') : null
      if (token != null && token != undefined) next()
      else next({ path: '/signin' })
    },
    children: [
      {
        path: '/',
        name: 'staffHome',
        component: staffHome
      },
      {
        path: '/staff/order',
        name: 'staffOrder',
        component: orderStaff
      },
      {
        path: '/staff/order/edit/:id',
        name: 'staffOrderEdit',
        component: orderEditStaff
      },
      {
        path: '/staff/order/view/:id',
        name: 'staffOrderView',
        component: orderViewStaff
      },
      {
        path: '/staff/expense',
        name: 'staffexpense',
        component: expenseStaff
      },
      {
        path: '/staff/expense/add',
        name: 'staffExpenseAdd',
        component: expenseAddStaff
      },
      {
        path: '/staff/expense/view/:id',
        name: 'staffExpenseView',
        component: expenseViewStaff
      },
      {
        path: '/staff/stock',
        name: 'staffStock',
        component: stockStaff
      },
      {
        path: '/staff/stock/add',
        name: 'staffStockAdd',
        component: stockAddStaff
      },
      {
        path: '/staff/stock/edit/:id',
        name: 'staffstockEdit',
        component: stockEditStaff
      }, {
        path: '/staff/product/barcode/print',
        name: 'staffBarCodePrintStaff',
        component: productBarCodePrintStaff,
      },
      {
        path: '/staff/raw/barcode/print',
        name: 'staffBarCodePrintStaff',
        component: rawBarCodePrintStaff,
      },
      {
        path: '/staff/manage/supplier',
        name: 'staffManageSupplier',
        component: supplierStaff,
      },
      {
        path: '/staff/supplier/add',
        name: 'staffSupplierAdd',
        component: supplierAddStaff,
      }, {
        path: '/staff/supplier/edit/:id',
        name: 'staffSupplierEdit',
        component: supplierEditStaff,
      },

      {
        path: '/staff/manage/product',
        name: 'staffManageProduct',
        component: productStaff,
      },
      {
        path: '/staff/product/add',
        name: 'staffProductAdd',
        component: productAddStaff,
      },
      {
        path: '/staff/profile/edit',
        name: 'staffProfile',
        component: profile,
      },
      {
        path: '/staff/product/edit/:id',
        name: 'staffProductEdit',
        component: productEditStaff,
      },
      {
        path: '/staff/product/edit/barcode/:id',
        name: 'staffProductEditBarCode',
        component: productEditBarCodeStaff,
      },
      {
        path: '/staff/manage/user',
        name: 'admiaManageUser',
        component: userStaff,
      },
      {
        path: '/staff/user/add',
        name: 'userAdd',
        component: userAddStaff,
      },
      {
        path: '/staff/user/edit/:id',
        name: 'staffuserEdit',
        component: userEditStaff,
      },
      {
        path: '/staff/manage/rawdata',
        name: 'staffManageRawdata',
        component: rawdataStaff,
      }, {
        path: '/staff/rawdata/add',
        name: 'staffRawdataAdd',
        component: rawdataAddStaff,
      }, {
        path: '/staff/rawdata/edit/:id',
        name: 'staffRawdataEdit',
        component: rawdataEditStaff
      },

      {
        path: '/staff/manage/warehouse',
        name: 'staffManageWarehouse',
        component: warehouseStaff,
      }, {
        path: '/staff/warehouse/add',
        name: 'staffWarehouseAdd',
        component: warehouseAddStaff,
      }, {
        path: '/staff/warehouse/edit/:id',
        name: 'staffWarehouseEdit',
        component: warehouseEditStaff
      },
      {
        path: '/staff/manage/customer',
        name: 'staffManageCustomer',
        component: customerStaff,
      }, {
        path: '/staff/customer/add',
        name: 'staffCustomerAdd',
        component: customerAddStaff,
      }, {
        path: '/staff/customer/edit/:id',
        name: 'staffCustomerEdit',
        component: customerEditStaff
      },
      {
        path: '/staff/event',
        name: 'staffEvent',
        component: eventStaff,
      },
      {
        path: '/staff/event/add',
        name: 'staffEventAdd',
        component: eventAddStaff,
      },
      {
        path: '/staff/event/edit/:id',
        name: 'staffEventEdit',
        component: eventEditStaff,
      },
      {
        path: '/staff/event/view/:id',
        name: 'staffEventView',
        component: eventViewStaff,
      },

      {
        path: '/staff/setting/category',
        name: 'staffSettingCategory',
        component: categoryStaff,
      },
      {
        path: '/staff/setting/unit',
        name: 'staffSettingUnit',
        component: unitStaff,
      },
      {
        path: '/staff/setting/banner',
        name: 'staffSettingBanner',
        component: bannerStaff,
      }, {
        path: '/staff/banner/add',
        name: 'staffBannerAdd',
        component: bannerAddStaff,
      },
      {
        path: '/staff/banner/edit/:id',
        name: 'staffBannerEdit',
        component: bannerEditStaff,
      }, {
        path: '/staff/setting/raw',
        name: 'staffSettingRaw',
        component: rawStaff,
      }, {
        path: '/staff/raw/add',
        name: 'staffRawAdd',
        component: rawAddStaff,
      },
      {
        path: '/staff/raw/edit/:id',
        name: 'staffRawEdit',
        component: rawEditStaff,
      },
      {
        path: '/staff/address/province',
        name: 'staffAddressProvince',
        component: provinceStaff,
      },
      {
        path: '/staff/address/district',
        name: 'staffAddressDistrict',
        component: districtStaff,
      },
      {
        path: '/staff/address/district/add',
        name: 'staffAddressDistrictAdd',
        component: districtAddStaff,
      },
      {
        path: '/staff/address/district/edit/:id',
        name: 'staffAddressDistrictEdit',
        component: districtEditStaff,
      },
      {
        path: '/staff/address/village',
        name: 'staffaAddressVillage',
        component: villageStaff,
      },
      {
        path: '/staff/address/village/add',
        name: 'staffaAddressVillageAdd',
        component: villageAddStaff,
      },
      {
        path: '/staff/address/village/edit/:id',
        name: 'staffaAddressVillageEdit',
        component: villageEditStaff,
      },
      {
        path: '/staff/report/product',
        name: 'staffaReportProduct',
        component: productReportStaff,
      },
      {
        path: '/staff/report/rawdata',
        name: 'staffaReportRawdata',
        component: rawdataReportStaff,
      },
      {
        path: '/staff/report/raw/name/:id',
        name: 'staffaReportRawName',
        component: rawNameReportStaff,
      },
      {
        path: '/staff/report/raw/date',
        name: 'staffaReportRawDate',
        component: rawDateReportStaff,
      },
      {
        path: '/staff/report/order/date',
        name: 'staffaReportOrderDate',
        component: orderDateReportStaff,
      },
      {
        path: '/staff/report/order',
        name: 'staffaReportOrder',
        component: orderReportStaff,
      },
      {
        path: '/staff/report/expense',
        name: 'staffaReportexpense',
        component: expenseReportStaff,
      },
      {
        path: '/staff/report/expense/raw/:id',
        name: 'staffaReportexpenseRaw',
        component: expenseRawNameReportStaff,
      },
      {
        path: '/staff/report/expense/supplier/:id',
        name: 'staffaReportExpenseSupplier',
        component: expenseSupplierReportStaff,
      },
      {
        path: '/staff/report/expense/date',
        name: 'staffaReportExpenseDate',
        component: expenseDateReportStaff,
      },
      {
        path: '/staff/report/stock',
        name: 'staffaReportStock',
        component: stockReportStaff,
      },
    ]
  },
  {
    path: '/admin/product/barcode/:id',
    name: 'adminProductBarCode',
    component: productBarCode,
  },
  {
    path: '/admin/raw/barcode/:id',
    name: 'adminRawBarCode',
    component: rawBarCode,
  },
  {
    path: '/staff/product/barcode/:id',
    name: 'staffProductBarCode',
    component: productBarCodeStaff,
  },
  {
    path: '/staff/raw/barcode/:id',
    name: 'staffRawBarCode',
    component: rawBarCodeStaff,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router