<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
              <p style="font-size: 2rem; margin-top: 30px">
                ເເກ້ໄຂຂໍ້ມູນກິດຈະກໍາ
              </p>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  <v-text-field
                    v-model="event.title"
                    :error-messages="titleErrors"
                    label="ຫົວຂໍ້ກິດຈະກໍາ"
                    required
                    @input="$v.event.title.$touch()"
                    @blur="$v.event.title.$touch()"
                  ></v-text-field>
                  <v-textarea
                    v-model="event.description"
                    background-color="#fafafafa"
                    color="cyan"
                    label="ລາຍລະອຽດ"
                    :error-messages="descriptionErrors"
                    required
                    @input="$v.event.description.$touch()"
                    @blur="$v.event.description.$touch()"
                  ></v-textarea>
                  <v-carousel
                    v-if="event.eventPictures.length > 0"
                    cycle
                    height="400"
                    hide-delimiter-background
                    :show-arrows="false"
                    hide-delimiters
                  >
                    <v-carousel-item
                      v-for="(img, i) in event.eventPictures"
                      :key="i"
                    >
                      <v-img
                        height="400"
                        width="100%"
                        contain
                        :src="img.img"
                        :lazy-src="img.img"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#90CAF9"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-carousel-item>
                  </v-carousel>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class></v-divider>
            <v-row
              class="ma-5"
              :justify="$vuetify.breakpoint.width < 600 ? 'center' : 'start'"
            >
              <v-btn
                @click="back"
                class="ma-2 orange white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
              </v-btn>
              <v-spacer class="d-none d-sm-flex"></v-spacer>
              <v-btn
                @click="clear"
                class="ma-2 red white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-close-circle</v-icon>ຍົກເລິກ
              </v-btn>

              <v-btn
                @click="submit"
                class="ma-2 green white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-check-circle</v-icon>ບັນທຶກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    event: {
      title: { required, maxLength: maxLength(100) },
      description: { required, maxLength: maxLength(1000) },
    },
  },
  data: () => ({}),

  computed: {
    ...mapGetters({
      event: "eventMod/event",
    }),
    titleErrors() {
      const errors = [];
      if (!this.$v.event.title.$dirty) return errors;
      !this.$v.event.title.maxLength && errors.push("ກະລຸນາປ້ອນຫົວຂໍ້ກິດຈະກໍາ");
      !this.$v.event.title.required && errors.push("ກະລຸນາປ້ອນຫົວຂໍ້ກິດຈະກໍາ");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.event.description.$dirty) return errors;
      !this.$v.event.description.maxLength &&
        errors.push("ກະລຸນາປ້ອນລາຍລະອຽດກິດຈະກໍາ");
      !this.$v.event.description.required &&
        errors.push("ກະລຸນາປ້ອນລາຍລະອຽດກິດຈະກໍາ");
      return errors;
    },
  },
  mounted() {
    this.getEvent(this.$route.params.id);
  },
  methods: {
    ...mapActions("eventMod", ["updateEvent", "getEvent"]),

    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    submit() {
      this.$v.$touch();
      try {
        if (this.event.title == "" || this.event.title == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນຫົວຂໍ້ກິດຈະກໍາ</div>`
          );
          return;
        }

        if (this.event.description == "" || this.event.description == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນລາຍລະອຽດກິດຈະກໍາ</div>`
          );
          return;
        }
        this.updateEvent(this.event)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
              this.showToast(
                "success",
                `<div style='font-family:"Noto Sans Lao";' >ແກ້ໄຂຂໍ້ມູນສໍາເລັດແລ້ວ</div>`
              );
              this.clear();
              this.back();
            } else {
              this.showToast(
                "error",
                `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "error",
              `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
            );
            console.log(error);
          });
      } catch (error) {
        console.log(error);
        this.showToast(
          "error",
          `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
        );
      }
    },
    clear() {
      this.$v.$reset();
      this.getEvent(this.$route.params.id);
    },
    back() {
      this.$router.back();
    },
  },
};
</script>