import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";
import { apiURL, header } from "../../config/constant";

Vue.use(Vuex);

const unitModule = {
  namespaced: true,
  state: {
    unit: [],
    unitName: [],
    length: true
  },
  mutations: {
    setUnit(state, unit) {
      state.unit = unit;
    },
    setUnitName(state, unit) {
      state.unitName = unit;
    },

    setLength(state, unit) {
      state.length = unit;
    },
  },

  actions: {
    getUnit(context) {
      try {
        axios.get(apiURL + `units`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var unitlist = [], unitNameList = [];
          for (const idx in res.data) {
            const el = res.data[idx];
            var unit = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              name: el.name,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
            };
            unitlist.push(unit);
            unitNameList.push(el.name);
          }
          context.commit("setUnit", unitlist);
          context.commit("setUnitName", unitNameList);
          context.commit("setLength", true);
        }).catch(function (error) {
          context.commit("setLength", false);
          console.log(error)
        });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },

    delUnit(context, item) {
      try {
        return axios
          .delete(apiURL + `units/${item._id}`, { headers: header })
      } catch (e) {
        console.log(e);
      }
    },
    insertUnit(context, item) {
      try {
        return axios
          .post(apiURL + `units`, { name: item.name }, { headers: header })
      } catch (e) {
        console.log(e);
      }
    },
    updateUnit(context, item) {
      try {
        return axios
          .put(apiURL + `units/${item._id}`, item, { headers: header })

      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    unit: (state) => state.unit,
    unitName: (state) => state.unitName,
    length: (state) => state.length,
  },
};

export default unitModule;
