<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
              <p style="font-size:2rem;margin-top: 30px">ເພີ່ມແບນເນີ</p>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  <input
                    type="file"
                    class="file-upload"
                    ref="file"
                    @change="onFileSelected"
                    label="ເລືອກຮູບພາບ"
                  />

                  <v-card flat color="#fafafafa">
                    <v-img
                      v-if="banner.imageURL"
                      class="mx-auto transparent"
                      height="300"
                      width="100%"
                      contain
                      :src="banner.imageURL ? banner.imageURL : ''"
                    >
                    </v-img>
                    <v-card
                      flat
                      exact
                      align="center"
                      v-else
                      class="mx-auto transparent"
                      height="300"
                      width="100%"
                    >
                      <div
                        style="
                          padding-top: 100px;
                          font-size: 3rem;
                          font-weight: bold;
                          color: #d0d0d0;
                        "
                      >
                        1300 X 400
                      </div>
                    </v-card>
                  </v-card>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class></v-divider>
            <v-row
              class="ma-5"
              :justify="$vuetify.breakpoint.width < 600 ? 'center' : 'start'"
            >
              <v-btn
                @click="back"
                class="ma-2 orange white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
              </v-btn>
              <v-spacer class="d-none d-sm-flex"></v-spacer>
              <v-btn
                @click="clear"
                class="ma-2 red white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-close-circle</v-icon>ຍົກເລິກ
              </v-btn>

              <v-btn
                @click="submit"
                class="ma-2 green white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-check-circle</v-icon>ບັນທຶກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    banner: {
      _id: "",
      image: "",
      imageURL: "",
    },
  }),
  computed: {},
  mounted() {},
  methods: {
    ...mapActions("bannerMod", ["insertBanner"]),
    onFileSelected(e) {
      const image = e.target.files[0];
      const imageName = image.name;
      if (imageName.lastIndexOf(".") <= 0) {
          this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຮູບ</div>`,)
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.banner.imageURL = e.target.result;
      };
      this.banner.image = image;
    },
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    clear() {
      this.banner = {
        _id: null,
        image: "",
        imageURL: "",
      };
      this.dialog = false;
    },
    submit() {
      try {
        if (this.banner.image == null || this.banner.image == "") {
          this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຮູບ</div>`,)
          return;
        }
        let formData = new FormData();
        formData.append("image", this.banner.image);
        this.insertBanner(formData)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
              this.clear();
          this.showToast("success",`<div style='font-family:"Noto Sans Lao";' >ບັນທຶກຂໍ້ມູນສໍາເລັດແລ້ວ</div>`,)
            } else {
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            }
          })
          .catch( (error) =>{
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            console.log(error);
          });
      } catch (error) {
        console.log(error);
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
      }
    },
    confirm() {
      this.dialog = false;
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped>
.font {
  font-family: "NotoSansLao";
}

.file-upload {
  margin: 7px 0px;
  font-size: 16px;
  width: 100%;
  background: white;
  border-bottom: 1px solid #999;
}
.file-upload::before {
  background: #eeeeee;
  content: "ເລືອກຮູບພາບ";
  border-radius: 0px 50px 50px 0px;
  padding: 10px;
  width: 180px;
  border: 1px solid #edffdd;
}
.file-upload:hover {
  border-bottom: 1px solid #555;
}
.file-upload:hover::before {
  background: #dedede;
}
::-webkit-file-upload-button {
  background: #eeeeee;
  visibility: hidden;
  border-radius: 0px 50px 50px 0px;
  padding: 7px;
  width: 1px;
  border: 1px solid #edffdd;
}
::-webkit-file-upload-button:hover {
  background: #dedede;
}
</style>
