<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
              <p style="font-size: 2rem; margin-top: 30px">ວັດຖຸດິບ-ນໍາອອກ</p>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  <v-autocomplete
                    clearable
                    item-color="blue darken-1"
                    no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                    v-model="rawdata.rawName"
                    :items="rawName"
                    :error-messages="rawdataNameErrors"
                    label="ວັດຖຸດິບ"
                    allow-overflow
                    required
                    @input="$v.rawdata.rawName.$touch()"
                    @blur="$v.rawdata.rawName.$touch()"
                  ></v-autocomplete>
                  <v-text-field
                    v-model="rawdata.barCodeNumber"
                    label="ລະຫັດບາໂຄດ"
                  ></v-text-field>
                  <v-text-field
                    v-model="rawdata.qty"
                    :error-messages="qtyErrors"
                    label="ຈໍານວນ"
                    required
                    @input="$v.rawdata.qty.$touch()"
                    @blur="$v.rawdata.qty.$touch()"
                    @keypress="number($event)"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class></v-divider>
            <v-row
              class="ma-5"
              :justify="$vuetify.breakpoint.width < 600 ? 'center' : 'start'"
            >
              <v-btn
                @click="back"
                class="ma-2 orange white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
              </v-btn>
              <v-spacer class="d-none d-sm-flex"></v-spacer>
              <v-btn
                @click="clear"
                class="ma-2 red white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-close-circle</v-icon>ຍົກເລິກ
              </v-btn>

              <v-btn
                @click="submit"
                class="ma-2 green white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-check-circle</v-icon>ບັນທຶກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    rawdata: {
      rawName: { required, maxLength: maxLength(100) },
      qty: { required, maxLength: maxLength(100) },
    },
  },

  data: () => ({
    rawdata: {
      barCodeNumber: null,
      rawName: "",
      raw: "",
      qty: 1,
    },
  }),

  computed: {
    ...mapGetters({
      raws: "rawMod/raws",
      rawName: "rawMod/rawName",
    }),
    rawdataNameErrors() {
      const errors = [];
      if (!this.$v.rawdata.rawName.$dirty) return errors;
      !this.$v.rawdata.rawName.maxLength &&
        errors.push("ກະລຸນາປ້ອນຊື່ວັດຖຸດິບ");
      !this.$v.rawdata.rawName.required && errors.push("ກະລຸນາປ້ອນຊື່ວັດຖຸດິບ");
      return errors;
    },
    qtyErrors() {
      const errors = [];
      if (!this.$v.rawdata.qty.$dirty) return errors;
      !this.$v.rawdata.qty.maxLength && errors.push("ກະລຸນາປ້ອນຈໍານວນວັດຖຸດິບ");
      !this.$v.rawdata.qty.required && errors.push("ກະລຸນາປ້ອນຈໍານວນວັດຖຸດິບ");
      return errors;
    },
  },
  mounted() {
    this.getRaws();
    this.init();
  },
  methods: {
    ...mapActions("rawMod", ["getRaws"]),
    ...mapActions("rawdataMod", ["insertRawdata"]),
    ...mapActions("stockMod", ["getStockRawID", "updateStock"]),

    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    number(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        this.showToast(
          "warning",
          `<div style='font-family:"Noto Sans Lao";color:red' >ປ້ອນສະເພາະຕົວເທົ່ານັ້ນ (0-9)</div>`
        );
        evt.preventDefault();
      } else {
        return true;
      }
    },
    init() {
      this.rawdata.barCodeNumber =
        this.$route.query.barCodeNumber == null ||
        this.$route.query.barCodeNumber == undefined
          ? null
          : this.$route.query.barCodeNumber;
      if (
        this.rawdata.barCodeNumber == null ||
        this.rawdata.barCodeNumber == undefined
      ) {
        return;
      }
      this.selectByBarCode();
    },
    selectByBarCode() {
      if (
        this.rawdata.barCodeNumber == null ||
        this.rawdata.barCodeNumber == undefined
      ) {
        return;
      }
      var name = null;
      for (const idx in this.raws) {
        const el = this.raws[idx];
        if (this.rawdata.barCodeNumber == el.barCodeNumber) {
          name = el.rawName;
        }
      }
      this.rawdata.rawName = name;
    },
    SelectRawName() {
      if (this.rawdata.rawName == null || this.rawdata.rawName == undefined) {
        return;
      }

      var barcode = null;
      for (const idx in this.raws) {
        const el = this.raws[idx];
        if (this.rawdata.rawName == el.rawName) {
          barcode =
            el.barCodeNumber == null || el.barCodeNumber == undefined
              ? null
              : el.barCodeNumber;
        }
      }
      this.rawdata.barCodeNumber = barcode;
    },
    submit() {
      this.$v.$touch();
      try {
        if (this.rawdata.rawName == "" || this.rawdata.rawName == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນຊື່ວັດຖຸດິບ</div>`
          );
          return;
        }
        for (const key in this.raws) {
          const el = this.raws[key];
          if (el.rawName == this.rawdata.rawName) {
            this.rawdata.raw = el._id;
          }
        }
        if (this.rawdata.raw == "" || this.rawdata.raw == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກວັດຖຸດິບໃຫ້ຖືກຕ້ອງ</div>`
          );
          return;
        }
        if (this.rawdata.qty == "" || this.rawdata.qty == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນຈໍານວນວັດຖຸ</div>`
          );
          return;
        }

        this.getStockRawID(this.rawdata.raw)
          .then((res) => {
            if (
              res.data == "null" ||
              res.data == null ||
              res.data._id == null ||
              res.data._id == undefined
            ) {
              this.showToast(
                "error",
                `<div style='font-family:"Noto Sans Lao";color:red' >ຈໍານວນວັດຖຸດິບໃນສະຕ໋ອກບໍ່ພຽນພໍ</div>`
              );
            } else if (parseInt(res.data.qty) < parseInt(this.rawdata.qty)) {
              this.showToast(
                "error",
                `<div style='font-family:"Noto Sans Lao";color:red' >ຈໍານວນວັດຖຸດິບໃນສະຕ໋ອກບໍ່ພຽນພໍ</div>`
              );
            } else if (
              res.data._id != null &&
              res.data._id != undefined &&
              parseInt(res.data.qty) >= parseInt(this.rawdata.qty)
            ) {
              this.insertRawdata(this.rawdata)
                .then((resInt) => {
                  if (resInt.data.message == "SUCCESSFULLY") {
                    var stock = {
                      _id: res.data.unit == null ? null : res.data._id,
                      raw: this.rawdata.raw,
                      qty: parseInt(res.data.qty) - parseInt(this.rawdata.qty),
                      unit: res.data.unit == null ? null : res.data.unit._id,
                      supplier:
                        res.data.supplier == null
                          ? null
                          : res.data.supplier._id,
                    };
                    this.updateStock(stock)
                      .then((resStock) => {
                        if (resStock.data.message == "SUCCESSFULLY") {
                          this.showToast(
                            "success",
                            `<div style='font-family:"Noto Sans Lao";' >ບັນທຶກຂໍ້ມູນສໍາເລັດແລ້ວ</div>`
                          );
                          this.clear();
                        } else {
                          this.showToast(
                            "error",
                            `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
                          );
                        }
                      })
                      .catch((error) => {
                        this.showToast(
                          "error",
                          `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
                        );
                        console.log(error);
                      });
                  } else {
                    this.showToast(
                      "error",
                      `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
                    );
                  }
                })
                .catch((error) => {
                  this.showToast(
                    "error",
                    `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
                  );
                  console.log(error);
                });
            } else {
              this.showToast(
                "error",
                `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "error",
              `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
            );
            console.log(error);
          });
      } catch (error) {
        console.log(error);
        this.showToast(
          "error",
          `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
        );
      }
    },
    clear() {
      this.$v.$reset();
      this.rawdata = {
        barCodeNumber: null,
        rawName: "",
        raw: null,
        qty: 1,
      };
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped>
</style>