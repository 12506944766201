<template>
  <v-app class="font grey lighten-5">
    <v-app-bar app light clipped-left  flat color="#2E3192">
        <v-tooltip bottom color="success">
          <template v-slot:activator="{ on, attrs }">
      <v-app-bar-nav-icon
        @click.stop="switchs"
        color="white"
              v-bind="attrs"
              v-on="on"
            ></v-app-bar-nav-icon>
          </template>
          <span class="font">ເປີດ/ປິດເມນູ</span>
        </v-tooltip>
      <v-spacer></v-spacer>
      <v-toolbar-title
        ><span style="color: white">ໂຮງງານນໍ້າດື່ມແສງແກ້ວ</span></v-toolbar-title
      >

      <v-spacer></v-spacer>
      <router-link to="/admin/profile/edit">
        <v-tooltip bottom color="green">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="white"
            >
              <v-icon color="white">mdi-account</v-icon>
            </v-btn>
          </template>
          <span class="font">ປ່ຽນລະຫັດຜ່ານຜູ້ໃຊ້ລະບົບ</span>
        </v-tooltip>
      </router-link>

      <router-link v-if="notify > 0" to="/admin/order">
        <v-tooltip bottom color="success">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon color="white">
              <v-icon>mdi-bell</v-icon
              ><span style="color: white">{{ notify }}</span>
            </v-btn>
          </template>
          <span class="font">ແຈ້ງເຕືອນລາຍການສັ່ງຊື້ໃໝ່</span>
        </v-tooltip>
      </router-link>
      <v-tooltip bottom v-else color="success">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon color="white">
            <v-icon>mdi-bell</v-icon><span style="color: red"></span>
          </v-btn>
        </template>
        <span class="font">ແຈ້ງເຕືອນລາຍການສັ່ງຊື້ໃໝ່</span>
      </v-tooltip>
      
      <router-link :to="{ name: 'home'}">
        <v-tooltip bottom color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="white"
              @click="doLogout"
            >
              <v-icon color="white">mdi-logout</v-icon>
            </v-btn>
          </template>
          <span class="font">ອອກຈາກລະບົບ</span>
        </v-tooltip>
      </router-link>
    </v-app-bar>

    <v-navigation-drawer app clipped
     v-model="drawer"
      :mini-variant.sync="mini"
     class="elevation-1">
      <v-img
        :height="mini?70:150"
        class="my-3"
        @click="onClickMenu('/admin')"
        contain
        v-ripple="{ class: `grey--text` }"
        :src="require('@/assets/onewater.png')"
        @click.stop="mini = !mini"
      >
      </v-img>
      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-group
          mandatory
          v-for="item in menus"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          :append-icon="item.append_icon"
          @click="onClickMenu(item.route)"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="my-1 py-1" style="font-size: 1rem">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item-group
            v-for="child in item.items"
            :key="child.title"
            :mandatory="child.active"
          >
            <v-list-item
              @click="onClickMenu(child.route)"
              @mousedown="SelectMenu(item.title, child)"
              class="ml-3"
              v-model="child.active"
            >
              <v-list-item-icon>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                class="my-1 py-1"
                style="font-size: 1rem"
                v-text="child.title"
              ></v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </v-app>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      editpass: false,
      editdg: 12,
      show: true,
      lang: window.localStorage.getItem("lang") == "en" ? "en" : "la",
      flag: window.localStorage.getItem("lang") == "en" ? "en.png" : "la.jpg",
      password: "Password",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      oldpass: "",
      newpass: "",
      cfpass: "",
      drawer: true,
      mini: true,
      user: [],
      menus: [
        {
          action: "mdi-home",
          append_icon: "",
          title: "ໜ້າຫຼັກ",
          route: "/admin",
          active: true,
        },
        {
          action: "mdi-currency-usd",
          append_icon: "",
          title: "ຮັບສັ່ງຊື້",
          route: "/admin/order",
          active: false,
        },
        {
          action: "mdi-credit-card-outline",
          append_icon: "mdi-menu-down",
          route: "/not",
          active: false,
          title: "ສະຕ໋ອກ",
          items: [
            {
              icon: "",
              title: "ສະຕ໋ອກວັດຖຸດິບ",
              route: "/admin/stock",
              active: false,
            },

            {
              icon: "",
              title: "ສະຕ໋ອກຜະລິດຕະພັນ",
              route: "/admin/manage/product",
              active: false,
            },

            {
              icon: "",
              title: "ວັດຖຸດິບ-ນໍາເຂົ້າ",
              route: "/admin/expense",
              active: false,
            },

            {
              icon: "",
              title: "ວັດຖຸດິບ-ນໍາອອກ",
              route: "/admin/manage/rawdata",
              active: false,
            },
          ],
        },
         {
          action: "mdi-barcode",
          append_icon: "mdi-menu-down",
          route: "/not",
          title: "ພິມບາໂຄດ",
          active: false,
          items: [
            {
              icon: "",
              title: "ຜະລິດຕະພັນ",
              route: "/admin/product/barcode/print",
              active: false,
            },
            {
              icon: "",
              title: "ວັດຖຸດິບ",
              route: "/admin/raw/barcode/print",
              active: false,
            },
          ],
        },
        {
          action: "mdi-calendar",
          append_icon: "",
          title: "ກິດຈະກໍາ",
          route: "/admin/event",
          active: false,
        },
        {
          action: "mdi-warehouse",
          append_icon: "mdi-menu-down",
          route: "/not",
          active: false,
          title: "ສາງ",
          items: [
            {
              icon: "",
              title: "ຂໍ້ມູນຜູ້ສະໜອງ",
              route: "/admin/manage/supplier",
              active: false,
            },
            {
              icon: "",
              title: "ຂໍ້ມູນສາຂາ ຫຼື ລູກຄ້າ",
              route: "/admin/manage/customer",
              active: false,
            },
            {
              icon: "",
              title: "ຂໍ້ມູນສາງ",
              route: "/admin/manage/warehouse",
              active: false,
            },
          ],
        },
        {
          action: "mdi-chart-bar",
          append_icon: "mdi-menu-down",
          route: "/not",
          title: "ລາຍງານ",
          active: false,
          items: [
            {
              icon: "",
              title: "ຂໍ້ມູນຜະລິດຕະພັນ",
              route: "/admin/report/product",
              active: false,
            },
            {
              icon: "",
              title: "ຂໍ້ມູນວັດຖຸດິບ",
              route: "/admin/report/stock",
              active: false,
            },
            {
              icon: "",
              title: "ຂໍ້ມູນສັ່ງຊື້",
              route: "/admin/report/order",
              active: false,
            },
            {
              icon: "",
              title: "ຂໍ້ມູນວັດຖຸດິບ-ນໍາເຂົ້າ",
              route: "/admin/report/expense",
              active: false,
            },
             {
              icon: "",
              title: "ຂໍ້ມູນວັດຖຸດິບ-ນໍາອອກ",
              route: "/admin/report/rawdata",
              active: false,
            },
          ],
        },
        {
          action: "mdi-map-marker",
          append_icon: "mdi-menu-down",
          route: "/not",
          title: "ຂໍ້ມູນທີ່ຢູ່",
          active: false,
          items: [
            {
              icon: "",
              title: "ຂໍ້ມູນແຂວງ",
              route: "/admin/address/province",
              active: false,
            },
            {
              icon: "",
              title: "ຂໍ້ມູນເມືອງ",
              route: "/admin/address/district",
              active: false,
            },
            {
              icon: "",
              title: "ຂໍ້ມູນບ້ານ",
              route: "/admin/address/village",
              active: false,
            },
          ],
        },
        {
          action: "mdi-cog",
          append_icon: "mdi-menu-down",
          route: "/not",
          title: "ຕັ້ງຄ່າລະບົບ",
          active: false,
          items: [
            {
              icon: "",
              title: "ປະເພດ",
              route: "/admin/setting/category",
              active: false,
            },
            {
              icon: "",
              title: "ຫົວໜ່ວຍ",
              route: "/admin/setting/unit",
              active: false,
            },
            {
              icon: "",
              title: "ວັດຖຸດິບ",
              route: "/admin/setting/raw",
              active: false,
            },
            {
              icon: "",
              title: "ຜູ້ໃຊ້ລະບົບ",
              route: "/admin/manage/user",
              active: false,
            },
            {
              icon: "",
              title: "ແບນເນີ",
              route: "/admin/setting/banner",
              active: false,
            },
          ],
        },
      ],
    };
  },
  components: [
    // AppBar,
    // NavigationDrawer,
  ],
  created(){
      console.log(window.localStorage.getItem('token'))
  },
  computed: {
    ...mapGetters({
      orders: "orderMod/orders",
    }),
    
    notify() {
      var noti = 0;
      for (const key in this.orders) {
        const el = this.orders[key];
        if (el.orderStatus == "NEW") {
          noti++;
        }
      }
      return noti;
    },
  },
  watch: {
    '$route' (to) {
      if (to.path === '/admin') {
        console.log(to.path)
      }
    }
  },
  mounted() {
    this.mapMenu();
    this.getOrders();
  },
  methods: {
    ...mapActions("orderMod", ["getOrders"]),
    mapMenu() {
      this.selectedMenu = this.menus.findIndex(
        (menu) => menu.route == this.$route.path
      );
    },
switchs(){
  this.mini = !this.mini
  if(this.$vuetify.breakpoint.width<960){
    this.drawer=!this.drawer
  }
},
    onClickMenu(link) {
      if (link != "/not") {
        this.$router.push(link).catch((error) => {
          console.log(error);
        });
      }
    },
    SelectMenu(title, active) {
      if (title == "ສະຕ໋ອກ") {
        this.menus[2].items.forEach((el) => {
          el.active = false;
        });
      } else if (title == "ພິມບາໂຄດ") {
        this.menus[3].items.forEach((el) => {
          el.active = false;
        });
      } else if (title == "ສາງ") {
        this.menus[5].items.forEach((el) => {
          el.active = false;
        });
      } else if (title == "ລາຍງານ") {
        this.menus[6].items.forEach((el) => {
          el.active = false;
        });
      } else if (title == "ຂໍ້ມູນທີ່ຢູ່") {
        this.menus[7].items.forEach((el) => {
          el.active = false;
        });
      } else if (title == "ຕັ້ງຄ່າລະບົບ") {
        this.menus[8].items.forEach((el) => {
          el.active = false;
        });
      }
      active.active = true;
    },
    doLogout() {
      window.localStorage.removeItem("token");
    },
  },
};
</script>
<style lang="css">
.font {
  font-family: "NotoSansLao";
}
.v-text-field label{
  font-size: 1rem;
}
.v-data-table th {
    font-size: 16px !important;
  text-shadow: none !important;
}
.v-data-table td {
    font-size: 14px !important;
}
</style>
