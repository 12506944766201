<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      oldDate: window.localStorage.getItem("date")
        ? window.localStorage.getItem("date")
        : 0,
    };
  },
  computed: {},
  mounted() {
    // this.checkDate();
  },
  methods: {
    checkDate() {
      var now = new Date().getTime();
      var hour = (parseInt(now) - parseInt(this.oldDate)) / (3600 * 1000);
      if (parseInt(hour) < 0 || parseInt(hour) > 24) {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("user");
      }
    },
  },
};
</script>
<style  lang="css">
@font-face {
  font-size: 1.8rem;
  font-family: "NotoSansLao";
  src: local("NotoSansLao"),
    url(./fonts/NotoSansLao-Regular.ttf) format("truetype");
}
.description {
  font-size: 1.8rem;
  font-family: "NotoSansLao", "Helvetica", "NotoSansLao", "Arial";
}

</style>
