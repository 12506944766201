import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";
import { apiURL, header } from "../../config/constant";

Vue.use(Vuex);

const expenseModule = {
  namespaced: true,
  state: {
    expenses: [],
    expense: [],
    expensesBySupplier: [],
    expensesByRaw: [],
    expensesByDate: [],
    length: true,
  },
  mutations: {
    setExpenses(state, expense) {
      state.expenses = expense;
    },
    setExpense(state, expense) {
      state.expense = expense;
    },
    setExpensesByRaw(state, expense) {
      state.expensesByRaw = expense;
    },
    setExpensesBySupplier(state, expense) {
      state.expensesBySupplier = expense;
    },
    setExpensesByDate(state, expense) {
      state.expensesByDate = expense;
    },
    setLength(state, expense) {
      state.length = expense;
    },
  },

  actions: {
    getExpenses(context) {
      try {
        axios.get(apiURL + `expenses`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var expenselist = [];
          for (const idx in res.data) {
            const el = res.data[idx];
            var expense = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              raw: (el.raw == null || el.raw._id == undefined) ? null : el.raw._id,
              rawName: (el.raw == null || el.raw.rawName == undefined) ? null : el.raw.rawName,
              user: (el.user == null || el.user._id == undefined) ? null : el.user._id,
              userName: (el.user == null || el.user.userName == undefined) ? null : el.user.userName,
              role: (el.user == null || el.user.role == undefined) ? null : el.user.role,
              supplierName: (el.supplier == null || el.supplier.supplierName == undefined) ? null : el.supplier.supplierName,
              supplier: (el.supplier == null || el.supplier._id == undefined) ? null : el.supplier._id,
              barCodeNumber: (el.barCodeNumber == null || el.barCodeNumber == undefined) ? null : el.barCodeNumber,
              qty: el.qty,
              price: el.price,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
              date: el.updatedAt
            };
            expenselist.push(expense);
          }
          context.commit("setExpenses", expenselist);
          context.commit("setLength", true);
        }).catch(function (error) {
          context.commit("setLength", false);
          console.log(error)
        });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
    getExpense(context, id) {
      try {
        axios.get(apiURL + `expenses/${id}`, { headers: header }).then((res) => {
          if (res.data.error != undefined) return alert("ບ");
          const el = res.data;
          var expense = {
            _id: el._id,
            raw: (el.raw == null || el.raw._id == undefined) ? null : el.raw._id,
            rawName: (el.raw == null || el.raw.rawName == undefined) ? null : el.raw.rawName,
            user: (el.user == null || el.user._id == undefined) ? null : el.user._id,
            userName: (el.user == null || el.user.userName == undefined) ? null : el.user.userName,
            role: (el.user == null || el.user.role == undefined) ? null : el.user.role,
            supplierName: (el.supplier == null || el.supplier.supplierName == undefined) ? null : el.supplier.supplierName,
            supplier: (el.supplier == null || el.supplier._id == undefined) ? null : el.supplier._id,
            barCodeNumber: (el.barCodeNumber == null || el.barCodeNumber == undefined) ? null : el.barCodeNumber,
            qty: el.qty,
            price: el.price,
            createdAt:
              moment(String(el.createdAt)).format("DD-MM-YYYY") +
              " " +
              el.createdAt.slice(11, el.createdAt.length - 5),
            updatedAt:
              moment(String(el.updatedAt)).format("DD-MM-YYYY") +
              " " +
              el.updatedAt.slice(11, el.updatedAt.length - 5),
            date: el.updatedAt
          };
          context.commit("setExpense", expense);
        })
          .catch(function (error) {
            if (error.message == 'Network Error') {
              console.log("ບໍ່ມີອີນເທີເນັດ")
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    getExpenseByRaw(context, id) {
      try {
        axios.get(apiURL + `expenses/raws/${id}`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var expenselist = [];
          for (const idx in res.data) {
            const el = res.data[idx];
            var expense = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              raw: (el.raw == null || el.raw._id == undefined) ? null : el.raw._id,
              rawName: (el.raw == null || el.raw.rawName == undefined) ? null : el.raw.rawName,
              user: (el.user == null || el.user._id == undefined) ? null : el.user._id,
              userName: (el.user == null || el.user.userName == undefined) ? null : el.user.userName,
              role: (el.user == null || el.user.role == undefined) ? null : el.user.role,
              supplierName: (el.supplier == null || el.supplier.supplierName == undefined) ? null : el.supplier.supplierName,
              supplier: (el.supplier == null || el.supplier._id == undefined) ? null : el.supplier._id,
              barCodeNumber: (el.barCodeNumber == null || el.barCodeNumber == undefined) ? null : el.barCodeNumber,
              qty: el.qty,
              price: el.price,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
              date: el.updatedAt
            };
            expenselist.push(expense);
          }
          context.commit("setExpensesByRaw", expenselist);
          context.commit("setLength", true);
        }).catch(function (error) {
          context.commit("setLength", false);
          console.log(error)
        });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
    getExpenseBySupplier(context, id) {
      try {
        axios.get(apiURL + `expenses/supplier/${id}`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var expenselist = [];
          for (const idx in res.data) {
            const el = res.data[idx];
            var expense = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              raw: (el.raw == null || el.raw._id == undefined) ? null : el.raw._id,
              rawName: (el.raw == null || el.raw.rawName == undefined) ? null : el.raw.rawName,
              user: (el.user == null || el.user._id == undefined) ? null : el.user._id,
              userName: (el.user == null || el.user.userName == undefined) ? null : el.user.userName,
              role: (el.user == null || el.user.role == undefined) ? null : el.user.role,
              supplierName: (el.supplier == null || el.supplier.supplierName == undefined) ? null : el.supplier.supplierName,
              supplier: (el.supplier == null || el.supplier._id == undefined) ? null : el.supplier._id,
              barCodeNumber: (el.barCodeNumber == null || el.barCodeNumber == undefined) ? null : el.barCodeNumber,
              qty: el.qty,
              price: el.price,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
              date: el.updatedAt
            };
            expenselist.push(expense);
          }
          context.commit("setExpensesBySupplier", expenselist);
          context.commit("setLength", true);
        }).catch(function (error) {
          context.commit("setLength", false);
          console.log(error)
        });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
    getExpenseByDate(context, { date1, date2 }) {
      try {
        axios.get(apiURL + `expenses/start/${date1}/end/${date2}`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var expenselist = [];
          for (const idx in res.data) {
            const el = res.data[idx];
            var expense = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              raw: (el.raw == null || el.raw._id == undefined) ? null : el.raw._id,
              rawName: (el.raw == null || el.raw.rawName == undefined) ? null : el.raw.rawName,
              user: (el.user == null || el.user._id == undefined) ? null : el.user._id,
              userName: (el.user == null || el.user.userName == undefined) ? null : el.user.userName,
              role: (el.user == null || el.user.role == undefined) ? null : el.user.role,
              supplierName: (el.supplier == null || el.supplier.supplierName == undefined) ? null : el.supplier.supplierName,
              supplier: (el.supplier == null || el.supplier._id == undefined) ? null : el.supplier._id,
              barCodeNumber: (el.barCodeNumber == null || el.barCodeNumber == undefined) ? null : el.barCodeNumber,
              qty: el.qty,
              price: el.price,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
              date: el.updatedAt
            };
            expenselist.push(expense);
          }
          context.commit("setExpensesByDate", expenselist);
          context.commit("setLength", true);
        }).catch(function (error) {
          context.commit("setLength", false);
          console.log(error)
        });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
    delExpense(context, item) {
      try {
        return axios
          .delete(apiURL + `expenses/${item._id}`, { headers: header })
      } catch (e) {
        console.log(e);
      }
    },
    insertExpense(context, item) {
      try {
        return axios
          .post(apiURL + `expenses`, item, { headers: header })
      } catch (e) {
        console.log(e);
      }
    },
    updatEexpense(context, item) {
      try {
        return axios
          .put(apiURL + `expenses/${item._id}`, item, { headers: header })
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    expenses: (state) => state.expenses,
    expense: (state) => state.expense,
    expensesBySupplier: (state) => state.expensesBySupplier,
    expensesByRaw: (state) => state.expensesByRaw,
    expensesByDate: (state) => state.expensesByDate,
    length: (state) => state.length,
  },
};

export default expenseModule;
