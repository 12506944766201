import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";
import { apiURL, header } from "../../config/constant";

Vue.use(Vuex);

const authModule = {
  namespaced: true,
  state: {
    users: [],
    user: [],
    userName: [],
    length: true
  },
  mutations: {
    setUsers(state, user) {
      state.users = user;
    },
    setUser(state, user) {
      state.user = user;
    },

    setUserName(state, user) {
      state.userName = user;
    },
    setLength(state, user) {
      state.length = user;
    },
  },
  actions: {
    getUser(context, id) {
      try {
        axios
          .get(apiURL + `auths/${id}`, { headers: header })
          .then((res) => {
            if (res.data.error != undefined) return alert("Not data");
            const el = res.data;
            var user = {
              _id: el._id,
              role: el.role,
              userName: el.userName,
              hash_password: el.hash_password,
              passwordOld: null,
              passwordNew: null,
              passwordNewConfirm: null,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
            };
            context.commit("setUser", user);
          })
          .catch(function (error) {
            if (error.message == 'Network Error')
              console.log("ບໍ່ມີອີນເທີເນັດ")
          });
      } catch (error) {
        console.log(error);
      }
    },
    getUsers(context) {
      try {
        axios.get(apiURL + `auths`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var userlist = [], userName = [];
          for (const idx in res.data) {
            const el = res.data[idx];
            var user = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              role: el.role,
              userName: el.userName,
              hash_password: el.hash_password,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
            };
            userName.push(el.userName)
            userlist.push(user);
          }
          context.commit("setUserName", userName);
          context.commit("setUsers", userlist);
          context.commit("setLength", true);
        }).catch(function (error) {
          context.commit("setLength", false);
          console.log(error)
        });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
    delUser(context, item) {
      try {
        return axios
          .delete(apiURL + `auths/${item._id}`, { headers: header })

      } catch (e) {
        this.errors.push(e);
      }
    },
    insertUser(context, item) {
      try {
        return axios
          .post(apiURL + `auths`, item, { headers: header })

      } catch (e) {
        this.errors.push(e);
      }
    },
    updateUser(context, item) {
      try {
        return axios
          .put(apiURL + `auths/${item._id}`, item, { headers: header })

      } catch (e) {
        this.errors.push(e);
      }
    },
    changePassword(context, item) {
      try {
        return axios
          .put(apiURL + `auths/admin/change-password`, item, { headers: header })
      } catch (e) {
        this.errors.push(e);
      }
    },
  },
  getters: {
    users: (state) => state.users,
    user: (state) => state.user,
    userName: (state) => state.userName,
    length: (state) => state.length,
  },
};

export default authModule;
