import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";
import { apiURL, header } from "../../config/constant";
Vue.use(Vuex);

const categoryModule = {
  namespaced: true,
  state: {
    category: [],
    categoryName: [],
    length: true
  },
  mutations: {
    setCategory(state, cate) {
      state.category = cate;
    },
    setCategoryName(state, cate) {
      state.categoryName = cate;
    },
    setLength(state, cate) {
      state.length = cate;
    },
  },
  actions: {
    getCategory(context) {
      try {
        axios.get(apiURL + `categories`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var catelist = [],
            cateNameList = [];
          for (const idx in res.data) {
            const el = res.data[idx];
            var category = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              name: el.name,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
            };
            catelist.push(category);
            cateNameList.push(el.name);
          }
          context.commit("setCategory", catelist);
          context.commit("setCategoryName", cateNameList);
          context.commit("setLength", true);
        })
          .catch(function (error) {
            context.commit("setLength", false);
            console.log(error)
          });
      } catch (e) {
        context.commit("setLength", false);
        console.log(e);
      }
    },

    delCategory(context, item) {
      try {
        return axios
          .delete(apiURL + `categories/${item._id}`, { headers: header })
      } catch (e) {
        console.log(e);
      }
    },
    insertCategory(context, item) {
      try {
        return axios
          .post(apiURL + `categories`, { name: item.name }, { headers: header })
      } catch (e) {
        console.log(e);
      }
    },
    updateCategory(context, item) {
      try {
        return axios
          .put(apiURL + `categories/${item._id}`, item, { headers: header })
      } catch (e) {
        alert(JSON.stringify(e));
      }
    },
  },
  getters: {
    category: (state) => state.category,
    categoryName: (state) => state.categoryName,
    length: (state) => state.length,
  },
};

export default categoryModule;
