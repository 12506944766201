
      <template>
  <div>
    <div>
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <v-col
              cols="12"
              md="3"
              :align="$vuetify.breakpoint.width < 960 ? 'center' : 'start'"
            >
              <span style="font-size: 1.2rem">ຂໍ້ມູນຜູ້ສະໜອງທັງໝົດ</span>
              <v-divider vertical class="mx-2 hidden-sm-and-down"></v-divider>
            </v-col>
            <v-col cols="12" md="9">
              <v-row>
                <v-spacer class="hidden-sm-and-down"></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="ຄົ້ນຫາ"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <router-link to="/staff/supplier/add">
                  <v-btn class="mt-3 mx-2 green">
                    <v-icon color="white" large>mdi-plus</v-icon
                    ><span style="color: white">ເພີ່ມໃໝ່</span>
                  </v-btn>
                </router-link>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <div v-if="loading">
          <v-data-table
            :headers="headers"
            :search="search"
            sort-by="idx"
            class="elevation-0"
            hide-default-footer
            no-data-text=""
          ></v-data-table>
          <v-row no-gutters class="justify-center pb-10">
            <SpinnerLoader :color="'#90CAF9'" :loading="true" :size="100" />
          </v-row>
        </div>
         <v-card flat v-else-if="length == false">
            <v-data-table
              :headers="headers"
              :search="search"
              sort-by="idx"
              class="elevation-0"
              hide-default-footer
              no-data-text=""
            ></v-data-table>
            <v-row class="justify-center mx-4">
              <v-card
                flat
                class=""
                color="transparent"
                style="font-size: 2rem; color: grey; margin-top: 40px"
                >ບໍ່ມີຂໍ້ມູນຜູ້ສະໜອງ
              </v-card>
            </v-row>
            <v-skeleton-loader
              style="margin-top: -125px"
              class=""
              type="image"
            >
            </v-skeleton-loader>
          </v-card>
        <v-data-table
          v-else
          :headers="headers"
          :items="suppliers"
          :search="search"
          item-key="idx"
          sort-by="name"
          class="elevation-2 table"
          :footer-props="{itemsPerPageText: 'ສະແດງຈໍານວນແຖວ :'}"
        >
          <template v-slot:item="{ item }">
            <tr v-ripple="{ class: `white--text` }">
              <td>{{ item.idx }}</td>
              <td>{{ item.supplierName }}</td>
              <td>{{ item.phoneNumber }}</td>
              <!-- <td>{{ item.email }}</td> -->
              <td>{{ item.village }}</td>
              <td>{{ item.district }}</td>
              <td>{{ item.province }}</td>
              <td>{{ item.joinDate }}</td>
              
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { SpinnerLoader } from "vue-spinners-css";
export default {
  data: () => ({
    loading: true,
    search: "",
    deleteItem: [],
    headers: [
      { text: "ລໍາດັບ", value: "idx", class: "indigo white--text" },
      {
        text: "ຊື່ຜູ້ສະໜອງ",
        value: "supplierName",
        class: "indigo white--text",
      },
      { text: "ເບີ້ໂທ", value: "phoneNumber", class: "indigo white--text" },
      // { text: "ອີເມວ", value: "email", class: "indigo white--text" },
      { text: "ບ້ານ", value: "village", class: "indigo white--text" },
      { text: "ເມືອງ", value: "district", class: "indigo white--text" },
      { text: "ແຂວງ", value: "province", class: "indigo white--text" },
      { text: "ວັນທີ່ເຂົ້າຮ່ວມກັນ", value: "່joinDate", class: "indigo white--text" },
      
    ],
  }),

  components: {
    SpinnerLoader,
  },
  computed: {
    ...mapGetters({
      suppliers: "supplierMod/suppliers",
      length: "supplierMod/length",
    }),
  },

  watch: {

    length(val) {
      if (val == false) {
        this.loading = false;
      }
    },
    suppliers(val) {
      if (val.length > 0) {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.getSuppliers();
    this.Loading()
  },
  methods: {
    ...mapActions("supplierMod", ["getSuppliers", "delSupplier"]),
    showAlert() {
      this.$swal({
        position: "center",
        icon: "warning",
        title: `<div style='font-family:"Noto Sans Lao";' >ເຕືອນ</div>`,
        html: `<div style='font-family:"Noto Sans Lao"; font-size:1.5rem' >ທ່ານຕ້ອງການລົບອອກບໍ?</div>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: `<div style='font-family:"Noto Sans Lao";' >ຕົກລົງ</div>`,
        cancelButtonText: `<div style='font-family:"Noto Sans Lao";' >ຍົກເລີກ</div>`,
        confirmButtonColor: "green",
        cancelButtonColor: "red",
        reverseButtons: true,
        buttonsStyling: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deleteConfirm();
        }
      });
    },
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },

    Loading() {
      if (this.length == false) {
        this.loading = false;
      }
    },
    editSupplier(item) {
      this.$router.push(`/staff/supplier/edit/${item._id}`);
    },

    deleteSupplier(item) {
      this.deleteItem = item;
      this.showAlert()
    },

    deleteConfirm() {
      try {
        this.delSupplier(this.deleteItem)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
               this.showToast("success",`<div style='font-family:"Noto Sans Lao";' >ລົບຂໍ້ມູນສໍາເລັດແລ້ວ</div>`,)
              this.getSuppliers();
            }else{
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            }
          })
          .catch( (error) =>{
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            console.log(error);
          });
      } catch (error) {
        console.log(error);
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
      }
    },
  },
};
</script>
<style scoped>
</style>