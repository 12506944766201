<template>
  <div>
    <v-row no-gutters class="mx-0 mx-md-5 my-2">
      <v-carousel
        height="400"
        hide-delimiter-background
                    :show-arrows="false"
                    v-if="images.length>0"
      >
        <v-carousel-item v-for="i in 5" :key="i">
          <v-sheet 
          height="100%" 
                    color="#e0e0e0e0">
            <v-img v-if="images.length>0" :src="images[i].image" :lazy-src="images[i].image" height="100%">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-1" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
              color="#90CAF9"
                  ></v-progress-circular>
                </v-row> </template
            ></v-img></v-sheet>
        </v-carousel-item>
      </v-carousel>
      
            <v-card v-else flat class="grey--text pt-16" height="380" width="100%"  color="#f0f0f0f0"
            style="font-size:3rem">
              <v-row class="justify-center mt-16 pt-10"  >
                ບໍ່ມີຮູບພາບສະໄລ
                </v-row>
            </v-card>
          
    </v-row>
    <v-card flat class="mx-sm-5 mx-lg-10">
      
     <v-row class="justify-start mt-7 mx-10">
      <span style="font-size: 1.5rem">ຜະລິດຕະພັນ</span>
    </v-row>
    <v-row no-gutters class="ma-5">
      <v-col
        v-for=" idx in 3"
        :key="idx"
        class="d-flex child-flex"
        cols="12"
        sm="6"
        md="4"
      >
        <v-hover v-if="products.length>0"
          class="grey lighten-5 mx-3 mb-3"
          v-slot="{ hover }"
          open-delay="200"
          v-ripple="{ class: `white--text` }"
        >
          <v-card :elevation="hover ? 10 : 0" :class="{ 'on-hover': hover }">
            <v-card
              class="mx-auto grey lighten-4 elevation-0"
              height="400"
              :img="products[idx].image"
            >
            </v-card>

            <v-card class="grey lighten-5 px-5 mt-5" flat>
              <v-row>
                <div>{{ products[idx].productName }}</div>
              </v-row>
              <v-row>
                <div>
                  ລາຄາຂາຍສົ່ງ:
                  {{
                    String(parseInt(products[idx].wholeSale)).replace(
                      /(.)(?=(\d{3})+$)/g,
                      "$1,"
                    )
                  }}
                  ກີບ
                </div>
              </v-row>
              <v-row class="mb-2">
                <div>
                  ລາຄາຂາຍຍ່ອຍ:
                  {{
                    String(parseInt(products[idx].retail)).replace(
                      /(.)(?=(\d{3})+$)/g,
                      "$1,"
                    )
                  }}
                  ກີບ
                </div>
              </v-row>
            </v-card>
          </v-card>
        </v-hover>
        <v-skeleton-loader v-else class="mx-3"
          type="image,list-item-three-line"
        ></v-skeleton-loader>
      </v-col>
    </v-row> 
    <v-row no-gutters class="mt-5 mx-sm-0 mx-lg-5">
      <v-row no-gutters class="mx-5">
      <span style="font-size: 1.5rem">ກິດຈະກໍາ</span>
      </v-row>
     <v-card class="mb-10" exact v-if="event.length>0">
        <v-col
        v-for="(vt, idx) in event"
        :key="idx"
        class="d-flex child-flex"
        cols="12"
      >
           <v-card flat >
            <v-divider style="margin-top:-12px"></v-divider>
            <v-row class="justify-start ma-3"
              ><span style="font-size: 1.5rem;">{{ vt.title }}</span></v-row
            >
              <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="12">
                <v-card class="mr-4 elevation-0">
                  <v-carousel
                    v-if="vt.eventPictures.length > 0"
                    height="400"
                    hide-delimiter-background
                    :show-arrows="false"
                  >
                    <v-carousel-item
                      v-for="(img, i) in vt.eventPictures"
                      :key="i"
                    >
                      <v-img
                        height="400"
                        width="100%"
                        contain
                        :src="img.img"
                        :lazy-src="img.img"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
              color="#90CAF9"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-carousel-item>
                  </v-carousel>
                  <!-- <v-row no-gutters class="justify-center"
                    v-if="vt.eventPictures.length > 0">
                   <v-icon @click="tagImg=(tagImg>0)?tagImg-1:tagImg=0" size="50">mdi-chevron-left</v-icon>
                    <div v-for="(img, i) in vt.eventPictures"
                      :key="i">
                      <v-card 
                      ripple
                      class="mx-2"
                    height="50"
                    width="70"
                    :img="img.img"
                    @click="tagImg=i">
                    </v-card></div>
                   <v-icon @click="tagImg=(tagImg> vt.eventPictures.length)?tagImg=vt.eventPictures.length:tagImg=tagImg+1" size="50">mdi-chevron-right</v-icon>
                  </v-row> -->
                </v-card>
              </v-col>
            </v-row>
            <v-row class="justify-center ma-3"
              ><p>{{ vt.description }}</p></v-row
            >
           </v-card>
      </v-col>
     </v-card>
     <v-card flat class="mb-10 mx-4" width="100%" v-else>
       <v-skeleton-loader
          type="card-heading,image,list-item-three-line"
        ></v-skeleton-loader>
     </v-card>
    </v-row>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      tagImg:0,
      
    };
  },
  computed: {
    ...mapGetters({
      images: "proCMod/images",
      products: "proCMod/products",
      event: "eventMod/events",
    }),
  },
  mounted() {
    this.getImage();
    this.getProduct();
    this.getEvents();
  },
  methods: {
    ...mapActions("proCMod", ["getImage", "getProduct"]),
    ...mapActions("eventMod", ["getEvents"]),
  },
};
</script>
<style scoped>
a:link {
  color: black;
}
a:active {
  color: black;
}
a:hover {
  color: black;
}
a:visited {
  color: black;
}
</style>