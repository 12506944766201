import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";
import { apiURL, header } from "../../config/constant";

Vue.use(Vuex);

const customerModule = {
  namespaced: true,
  state: {
    customers: [],
    customer: [],
    customerName: [],
    length: true
  },
  mutations: {
    setCustomers(state, customer) {
      state.customers = customer;
    },
    setCustomer(state, customer) {
      state.customer = customer;
    },
    setCustomerName(state, customer) {
      state.customerName = customer;
    },

    setLength(state, customer) {
      state.length = customer;
    },
  },
  actions: {
    async getCustomer(context, id) {
      try {

        var provinceList = await axios.get(apiURL + `provinces`, { headers: header });
        var districtList = await axios.get(apiURL + `districts`, { headers: header });
        var villageList = await axios.get(apiURL + `villages`, { headers: header });
        axios
          .get(apiURL + `customers/${id}`, { headers: header })
          .then((res) => {
            if (res.data.error != undefined) return alert("Not data");
            const el = res.data;
            var provinceName = null, provinceID = null, districtName = null, districtID = null;
            for (const i in villageList.data) {
              const vl = villageList.data[i], v = (el.village == null || el.village == undefined) ? null : el.village._id;
              if (v == vl._id) {
                districtID = (vl.district == null || vl.district == undefined) ? null : vl.district._id
                provinceID = (vl.district == null || vl.district == undefined) ? null : vl.district.province
                for (const j in districtList.data) {
                  const dt = districtList.data[j];
                  if (districtID == dt._id) {
                    districtName = dt.name
                  }

                }
                for (const x in provinceList.data) {
                  const pro = provinceList.data[x];
                  if (provinceID == pro._id) {
                    provinceName = pro.name
                  }

                }
              }

            }
            var customer = {
              _id: el._id,
              firstName: el.firstName,
              lastName: el.lastName,
              lat: el.lat,
              lng: el.lng,
              phoneNumber: el.phoneNumber,
              email: el.email,
              provinceName: provinceName,
              province: null,
              districtName: districtName,
              district: null,
              villageName: (el.village == null || el.village == undefined) ? null : el.village.name,
              village: null,
              imageURL: (el.image == null || el.image == undefined) ? '' : el.image,
              image: null,
              auth: (el.auth == null || el.auth == undefined) ? null : el.auth._id,
              role: (el.auth == null || el.auth == undefined) ? null : el.auth.role,
              userName: (el.auth == null || el.auth == undefined) ? null : el.auth.userName,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
            };
            context.commit("setCustomer", customer);
          })
          .catch(function (error) {
            if (error.message == 'Network Error') {
              console.log("ບໍ່ມີອີນເທີເນັດ")
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    async getCustomers(context) {
      try {
        var provinceList = await axios.get(apiURL + `provinces`, { headers: header });
        var districtList = await axios.get(apiURL + `districts`, { headers: header });
        var villageList = await axios.get(apiURL + `villages`, { headers: header });
        axios.get(apiURL + `customers`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var customerlist = [], namelist = [];
          for (const idx in res.data) {
            const el = res.data[idx];
            var provinceName = null, provinceID = null, districtName = null, districtID = null;
            for (const i in villageList.data) {
              const vl = villageList.data[i], v = (el.village == null || el.village == undefined) ? null : el.village._id;
              if (v == vl._id) {
                districtID = (vl.district == null || vl.district == undefined) ? null : vl.district._id
                provinceID = (vl.district == null || vl.district == undefined) ? null : vl.district.province
                for (const j in districtList.data) {
                  const dt = districtList.data[j];
                  if (districtID == dt._id) {
                    districtName = dt.name
                  }

                }
                for (const x in provinceList.data) {
                  const pro = provinceList.data[x];
                  if (provinceID == pro._id) {
                    provinceName = pro.name
                  }

                }
              }

            }
            var customer = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              firstName: el.firstName,
              lastName: el.lastName,
              lat: el.lat,
              lng: el.lng,
              phoneName: el.phoneName,
              email: el.email,
              provinceName: provinceName,
              districtName: districtName,
              villageName: (el.village == null || el.village == undefined) ? null : el.village.name,
              imageURL: (el.image == null || el.image == undefined) ? '' : el.image,
              auth: (el.auth == null || el.auth == undefined) ? null : el.auth._id,
              role: (el.auth == null || el.auth == undefined) ? null : el.auth.role,
              userName: (el.auth == null || el.auth == undefined) ? null : el.auth.userName,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
            };
            customerlist.push(customer);
            namelist.push(el.firstName + " " + el.lastName);
          }
          context.commit("setCustomerName", namelist);
          context.commit("setCustomers", customerlist);
          context.commit("setLength", true);
        })
          .catch(function (error) {
            context.commit("setLength", false);
            if (error.message == 'Network Error') {
              console.log("ບໍ່ມີອີນເທີເນັດ")
            }
          });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
    delCustomer(context, item) {
      try {
        return axios
          .delete(apiURL + `customers/${item._id}`, { headers: header })

      } catch (e) {
        this.errors.push(e);
      }
    },
    insertCustomer(context, item) {
      try {
        return axios
          .post(apiURL + `customers`, item, { headers: header })

      } catch (e) {
        this.errors.push(e);
      }
    },
    updateCustomer(context, { id, item }) {
      try {
        return axios
          .put(apiURL + `customers/${id}`, item, { headers: header })

      } catch (e) {
        this.errors.push(e);
      }
    },
  },
  getters: {
    customers: (state) => state.customers,
    customer: (state) => state.customer,
    customerName: (state) => state.customerName,
    length: (state) => state.length,
  },
};

export default customerModule;
