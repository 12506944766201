import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
Vue.use(Vuex)
import { apiURL} from '../config/constant';
const authModule = {
  namespaced: true,
  state: {
    user: window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : { _id: null, userName: null, role: null },
    token: window.localStorage.getItem('token') ? window.localStorage.getItem('token') : null,
  },
  mutations: {
    setToken(state, tken) {
      state.token = tken
      window.localStorage.setItem('token', tken)
    },
  },
  actions: {
    signIn(context, user) {
      try {
        return axios.post(apiURL + `auths/admin/sign-in`, user)
      } catch (error) {
        console.log(error)
        if (error.response.status == 422) {
          alert("We couldn't verify your account details")
        } else {
          alert('ຊື່ຜູ້ໃຊ້ ແລະ ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ')
        }
      }
    },
  },
  getters: {
    token: state => state.token,
  }
};
export default authModule;
