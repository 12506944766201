import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import { apiURL } from '../../config/constant';

Vue.use(Vuex)

const productModule = {
  namespaced: true,
  state: {
    images: [],
    products: [],
  },
  mutations: {
    setImage(state, image) {
      state.images = image
    },
    setProducts(state, product) {
      state.products = product
    },
  },

  actions: {
    getImage(context) {
      try {

        axios.get(apiURL + `banners`).then(res => {
          var img = []
          for (const idx in res.data) {
            const el = res.data[idx];
            var imgobj = {
              image: el.image
            }
            img.push(imgobj)
          }
          context.commit('setImage', img)
        })

      } catch (e) {
        console.log(e)
      }
    },
    getProduct(context) {
      try {
        axios.get(apiURL + `products`).then(res => {
          context.commit('setProducts', res.data)
        })
      } catch (e) {
        console.log(e)
      }
    },
  },
  getters: {
    images: state => state.images,
    products: state => state.products,
  }
}

export default productModule;

