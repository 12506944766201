<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
              <h1 style="margin-top: 30px">ແກ້ໄຂຂໍ້ມູນຜູ້ສະໜອງ</h1>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  <v-text-field
                    v-model="supplier.supplierName"
                    :error-messages="supplierNameErrors"
                    label="ຊື່ຜູ້ສະໜອງ"
                    required
                    @input="$v.supplier.supplierName.$touch()"
                    @blur="$v.supplier.supplierName.$touch()"
                  ></v-text-field>
                  <v-autocomplete
                    clearable
                    item-color="blue darken-1"
                    no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                    v-model="supplier.provinceName"
                    :items="provinceName"
                    :error-messages="provinceNameErrors"
                    label="ແຂວງ"
                    required
                    @input="$v.supplier.provinceName.$touch()"
                    @blur="$v.supplier.provinceName.$touch()"
                  ></v-autocomplete>

                  <v-autocomplete
                    clearable
                    item-color="blue darken-1"
                    no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                    v-model="supplier.districtName"
                    :error-messages="districtNameErrors"
                    :items="districtInProvince"
                    label="ເມືອງ"
                    required
                    @input="$v.supplier.districtName.$touch()"
                    @blur="$v.supplier.districtName.$touch()"
                  ></v-autocomplete>

                  <v-autocomplete
                    clearable
                    item-color="blue darken-1"
                    no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                    v-model="supplier.villageName"
                    :error-messages="villageNameErrors"
                    :items="villageInDistrict"
                    label="ບ້ານ"
                    required
                    @input="$v.supplier.villageName.$touch()"
                    @blur="$v.supplier.villageName.$touch()"
                  ></v-autocomplete>

                  <v-text-field
                    v-model="supplier.phoneNumber"
                    :error-messages="phoneNumberErrors"
                    label="ເລກໂທລະສັບ"
                    required
                    @input="$v.supplier.phoneNumber.$touch()"
                    @blur="$v.supplier.phoneNumber.$touch()"
                  ></v-text-field>

                  <v-text-field
                    v-model="supplier.email"
                    :error-messages="emailErrors"
                    label="ອີເມວ"
                    required
                    @input="$v.supplier.email.$touch()"
                    @blur="$v.supplier.email.$touch()"
                  ></v-text-field>
                  
                  <v-toolbar flat color="transparent" class="mx-0 mt-5" width="100%">
                  <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field 
                  v-model="supplier.joinDate"
                    required
                    @input="$v.supplier.joinDate.$touch()"
                    @blur="$v.supplier.joinDate.$touch()"
                    :error-messages="joinDateErrors"
                  class="mr-1" style="margin-left:-20px"  label="ວັນທີ່ເຂົ້າຮ່ວມກັນ"
                   color="blue"></v-text-field>
                  <v-btn class="" icon v-bind="attrs" v-on="on">
                    <v-icon color="blue" large>mdi-alarm</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="supplier.joinDate" color="blue"></v-date-picker>
              </v-menu>
                  </v-toolbar>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class></v-divider>
            <v-row
              class="ma-5"
              :justify="$vuetify.breakpoint.width < 600 ? 'center' : 'start'"
            >
              <v-btn
                @click="back"
                class="ma-2 orange white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
              </v-btn>
              <v-spacer class="d-none d-sm-flex"></v-spacer>
              <v-btn
                @click="clear"
                class="ma-2 red white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-close-circle</v-icon>ຍົກເລິກ
              </v-btn>

              <v-btn
                @click="submit"
                class="ma-2 green white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-check-circle</v-icon>ບັນທຶກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    supplier: {
      supplierName: { required, maxLength: maxLength(100) },
      provinceName: { required, maxLength: maxLength(100) },
      villageName: { required, maxLength: maxLength(100) },
      districtName: { required, maxLength: maxLength(100) },
      phoneNumber: { required, maxLength: maxLength(100) },
      joinDate: { required, maxLength: maxLength(100) },
      email: { required, email },
    },
  },
  data: () => ({
    picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
  }),
  computed: {
    ...mapGetters({
      supplier: "supplierMod/supplier",
      villages: "addressMod/villages",
      villageName: "addressMod/villageName",
      districts: "addressMod/districts",
      districtName: "addressMod/districtName",
      provinces: "addressMod/provinces",
      provinceName: "addressMod/provinceName",
    }),
    supplierNameErrors() {
      const errors = [];
      if (!this.$v.supplier.supplierName.$dirty) return errors;
      !this.$v.supplier.supplierName.maxLength &&
        errors.push("ຊື່ຜູ້ສະໜອງຍາວເກີນ!");
      !this.$v.supplier.supplierName.required &&
        errors.push("ກະລຸນາປ້ອນຊື່ຜູ້ສະໜອງ!");
      return errors;
    },
    provinceNameErrors() {
      const errors = [];
      if (!this.$v.supplier.provinceName.$dirty) return errors;
      !this.$v.supplier.provinceName.required &&
        errors.push("ກະລຸນາເລືອກແຂວງ!");
      return errors;
    },

    villageNameErrors() {
      const errors = [];
      if (!this.$v.supplier.villageName.$dirty) return errors;
      !this.$v.supplier.villageName.required && errors.push("ກະລຸນາເລືອກບ້ານ!");
      return errors;
    },

    districtNameErrors() {
      const errors = [];
      if (!this.$v.supplier.districtName.$dirty) return errors;
      !this.$v.supplier.districtName.required &&
        errors.push("ກະລຸນາເລືອກເມືອງ!");
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.supplier.phoneNumber.$dirty) return errors;
      !this.$v.supplier.phoneNumber.maxLength &&
        errors.push("ກະລຸນາປ້ອນເລກໂທລະສັບ!");
      !this.$v.supplier.phoneNumber.required &&
        errors.push("ກະລຸນາປ້ອນເລກໂທລະສັບ!");
      return errors;
    },
    
     joinDateErrors() {
      const errors = [];
      if (!this.$v.supplier.joinDate.$dirty) return errors;
      !this.$v.supplier.joinDate.maxLength &&
        errors.push("ກະລຸນາປ້ອນວັນທີ່ເຂົ້າຮ່ວມກັນ!");
      !this.$v.supplier.joinDate.required &&
        errors.push("ກະລຸນາປ້ອນວັນທີ່ເຂົ້າຮ່ວມກັນ!");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.supplier.email.$dirty) return errors;
      !this.$v.supplier.email.email && errors.push("ກະລຸນາປ້ອນອີເມວ!");
      !this.$v.supplier.email.required && errors.push("ກະລຸນາປ້ອນອີເມວ!");
      return errors;
    },

    districtInProvince() {
      var dlist = [];
      for (const key in this.districts) {
        const el = this.districts[key];
        if (this.supplier.provinceName == el.provinceName) {
          dlist.push(el.name);
        }
      }
      return dlist;
    },

    villageInDistrict() {
      var vlist = [];
      for (const key in this.villages) {
        const el = this.villages[key];
        if (this.supplier.districtName == el.districtName) {
          vlist.push(el.name);
        }
      }
      return vlist;
    },
  },
  mounted() {
    this.getProvinces();
    this.getDistricts();
    this.getVillages();
    this.getSupplier(this.$route.params.id);
  },
  methods: {
    ...mapActions("supplierMod", ["getSupplier", "updateSupplier"]),
    ...mapActions("addressMod", [
      "getVillages",
      "getProvinces",
      "getDistricts",
    ]),

    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },
    async submit() {
      this.$v.$touch();
      try {
        if (
          this.supplier.supplierName == "" ||
          this.supplier.supplierName == null
        ) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນຊື່ຜູ້ສະໜອງ</div>`,)
          return;
        }
        if (
          this.supplier.provinceName == "" ||
          this.supplier.provinceName == null
        ) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກແຂວງ</div>`,)
          return;
        }
        if (
          this.supplier.districtName == "" ||
          this.supplier.districtName == null
        ) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກເມືອງ</div>`,)
          return;
        }
        if (
          this.supplier.villageName == "" ||
          this.supplier.villageName == null
        ) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກບ້ານ</div>`,)
          return;
        }
        
        if (
          this.supplier.phoneNumber == "" ||
          this.supplier.phoneNumber == null
        ) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນເລກໂທລະສັບ</div>`,)
          return;
        }
        
        if (this.supplier.email == "" || this.supplier.email == null) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນອີເມວ</div>`,)
          return;
        }
        if (this.supplier.joinDate == "" || this.supplier.joinDate == null) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນວັນທີ່ເຂົ້າຮ່ວມກັນ</div>`,)
          return;
        }

        for (const key in this.villages) {
          const el = this.villages[key];
          if (el.name == this.supplier.villageName) {
            this.supplier.village = el._id;
          }
        }
        if (this.supplier.village == "" || this.supplier.village == null) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກບ້ານ</div>`,)
          return;
        }
        this.updateSupplier(this.supplier)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
              this.showToast("success",`<div style='font-family:"Noto Sans Lao";' >ແກ້ໄຂຂໍ້ມູນສໍາເລັດ</div>`,)
              this.clear();
              this.back()
            } else {
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            }
          })
          .catch( (error) =>{
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            console.log(error);
          });
      } catch (error) {
        console.log(error);
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
      }
    },
    clear() {
      this.$v.$reset();
      this.getSupplier(this.$route.params.id);
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped></style>
