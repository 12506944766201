<template>
  <div>
    <div>
      <v-card>
        <v-card
          flat
          class="mt-1 mb-5"
          height="100"
          width="100%"
          color="white"
          exact
        >
          <v-card-title class="ma-0"> <v-btn
                @click="back"
                class="ma-2 orange white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
              </v-btn>
            <v-row no-gutters class="mx-1 justify-center"
              ><span>ຈໍານວນວັດຖຸດິບ-ນໍາອອກທັງໝົດ</span></v-row
            >
          </v-card-title>
          <v-row no-gutters class="mx-1 justify-center">
            <v-card flat color="transparent" height="30" width="96%">
              <v-row no-gutters class="justify-center">
                <p style="font-size: 2rem; color: red">
                  {{
                    totals == undefined || totals == null
                      ? 0
                      : String(parseInt(totals)).replace(
                          /(.)(?=(\d{3})+$)/g,
                          "$1,"
                        )
                  }}
                </p></v-row
              >
            </v-card>
          </v-row>
        </v-card>
        <v-card flat class="mt-2">
          <v-card-title>
            <v-row no-gutters>
              <v-col
                cols="12"
                md="12"
                :align="$vuetify.breakpoint.width < 960 ? 'center' : 'start'"
                class="hidden-sm-and-down"
              >
                <span style="font-size: 1.2rem"
                  >ລາຍການວັດຖຸດິບ-ນໍາອອກທັງໝົດ</span
                >
                <v-divider vertical class="mx-2 hidden-sm-and-down"></v-divider>
              </v-col>
            </v-row>
          </v-card-title>
          <div v-if="loading">
            <v-data-table
              :headers="headers"
              :search="search"
              sort-by="idx"
              class="elevation-0"
              hide-default-footer
              no-data-text=""
            ></v-data-table>
            <v-row no-gutters class="justify-center pb-10">
              <SpinnerLoader :color="'#90CAF9'" :loading="true" :size="100" />
            </v-row>
          </div>
           <v-card flat v-else-if="length == false">
            <v-data-table
              :headers="headers"
              :search="search"
              sort-by="idx"
              class="elevation-0"
              hide-default-footer
              no-data-text=""
            ></v-data-table>
            <v-row class="justify-center mx-4">
              <v-card
                flat
                class=""
                color="transparent"
                style="font-size: 2rem; color: grey; margin-top: 40px"
                >ບໍ່ມີຂໍ້ມູນວັດຖຸດິບ-ນໍາອອກ
              </v-card>
            </v-row>
            <v-skeleton-loader
              style="margin-top: -125px"
              class=""
              type="image"
            >
            </v-skeleton-loader>
          </v-card>
          <v-data-table
            v-else
            large
            class="table"
            :headers="headers"
            :items="rawByName"
            :search="search"
          :footer-props="{itemsPerPageText: 'ສະແດງຈໍານວນແຖວ :'}"
            fixed-header >
            <template v-slot:item="{ item }">
              <tr v-ripple="{ class: `white--text` }">
                <td>{{ item.idx }}</td>
                <td>{{ item.rawName }}</td>
                <td>
                  <span
                    >{{
                      String(item.qty).replace(/(.)(?=(\d{3})+$)/g, "$1,")
                    }}</span >
                </td>
                <td>
                  {{ String(item.userName).replace(/(.)(?=(\d{3})+$)/g, "$1,") }}
                </td> 
                <td>
                  {{ item.updatedAt }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { SpinnerLoader } from "vue-spinners-css";
export default {
  data() {
    return {
      loading: true,
      date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu1: false,
      date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu2: false,
      headers: [
        { text: "ລໍາດັບ", value: "idx", class: "indigo white--text" },
        { text: "ຊື່ວັດຖຸດິບ", value: "rawName", class: "indigo white--text" },
        { text: "ຈໍານວນ", value: "qty", class: "indigo white--text" },
        { text: "ຜູ້ສັ່ງ", value: "userName", class: "indigo white--text" },
        {
          text: "ວັນທີ່ນໍາອອກ",
          value: "createdAt",
          class: "indigo white--text",
        }
      ],
    };
  },
  components: {
    SpinnerLoader,
  },
  computed: {
    ...mapGetters({
      rawByName: "rawdataMod/rawByName",
      length: "rawdataMod/length",
    }),
    totals() {
      var total = 0;
      if (this.rawByName.length > 0) {
        for (const key in this.rawByName) {
          const element = this.rawByName[key];
          if (
            element.qty != null &&
            element.qty != undefined 
          ) {
            total += parseFloat(element.qty);
          }
        }
      }
      return total;
    },
  },
  watch: {
    length(val) {
      if (val==false) {
        this.loading = false;
      }
    },
    rawByName(val) {
      if (val.length > 0) {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.getRawdatasByRaw(this.$route.params.id);
    this.Loading()
  },
  created() {},
  methods: {
    ...mapActions("rawdataMod", ["getRawdatasByRaw"]),
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    Loading(){
      if (this.length==false) {
        this.loading=false
      }
    },
    back() {
      this.$router.back();
    },
  
  },
};
</script>
