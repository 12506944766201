<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="6" lg="6">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
              <p style="font-size: 2rem; margin-top: 30px">
                ເເກ້ໄຂລະຫັດຜ່ານຜູ້ໃຊ້
              </p>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  <v-text-field
                    v-model="user.userName"
                    :error-messages="userNameErrors"
                    label="ຊື່ຜູ້ໃຊ້"
                    required
                    @input="$v.user.userName.$touch()"
                    @blur="$v.user.userName.$touch()"
                  ></v-text-field>
                  <v-text-field
                    label="ຢືນຢົນລະຫັດຜ່ານເກົ່າ"
                    :type="show1 ? 'text' : 'password'"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    v-model="user.passwordOld"
                    :error-messages="passwordOldErrors"
                    required
                    @input="$v.user.passwordOld.$touch()"
                    @blur="$v.user.passwordOld.$touch()"
                    @click:append="show1 = !show1"
                  ></v-text-field>
                  <v-text-field
                    label="ສ້າງລະຫັດຜ່ານໃໝ່"
                    :type="show2 ? 'text' : 'password'"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    v-model="user.passwordNew"
                    :error-messages="passwordNewErrors"
                    required
                    @input="$v.user.passwordNew.$touch()"
                    @blur="$v.user.passwordNew.$touch()"
                    @click:append="show2 = !show2"
                  ></v-text-field>
                  <v-text-field
                    label="ຢືນຢົນລະຫັດຜ່ານໃໝ່"
                    :type="show3 ? 'text' : 'password'"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    v-model="user.passwordNewConfirm"
                    :error-messages="passwordNewConfirmErrors"
                    required
                    @input="$v.user.passwordNewConfirm.$touch()"
                    @blur="$v.user.passwordNewConfirm.$touch()"
                    @click:append="show3 = !show3"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class></v-divider>
            <v-row
              class="ma-5"
              :justify="$vuetify.breakpoint.width < 600 ? 'center' : ''"
            >
              <v-btn
                @click="back"
                class="ma-2 orange white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
              </v-btn>
              <v-spacer class="d-none d-sm-flex"></v-spacer>
              <v-btn
                @click="clear"
                class="ma-2 red white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-close-circle</v-icon>ຍົກເລິກ
              </v-btn>

              <v-btn
                @click="submit"
                class="ma-2 green white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-check-circle</v-icon>ບັນທຶກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    user: {
      userName: { required, maxLength: maxLength(100) },
      passwordOld: { required, maxLength: maxLength(100) },
      passwordNew: { required, maxLength: maxLength(100) },
      passwordNewConfirm: { required, maxLength: maxLength(100) },
      role: { required },
    },
  },
  data: () => ({
    show1: true,
    show2: true,
    show3: true,
  }),

  computed: {
    ...mapGetters({
      user: "userMod/user",
    }),
    userNameErrors() {
      const errors = [];
      if (!this.$v.user.userName.$dirty) return errors;
      !this.$v.user.userName.maxLength && errors.push("ຊືຜູ້ໃຊ້ຍາວເກີນ!");
      !this.$v.user.userName.required &&
        errors.push("ກະລຸນາປ້ອນຊື່ຜູ້ໃຊ້ລະບົບ.");
      return errors;
    },
    passwordOldErrors() {
      const errors = [];
      if (!this.$v.user.passwordOld.$dirty) return errors;
      !this.$v.user.passwordOld.maxLength && errors.push("ລະຫັດຜ່ານຍາວເກີນ!");
      !this.$v.user.passwordOld.required &&
        errors.push("ກະລຸນາປ້ອນລະຫັດຜ່ານເກົ່າ!");
      return errors;
    },
    passwordNewErrors() {
      const errors = [];
      if (!this.$v.user.passwordNew.$dirty) return errors;
      !this.$v.user.passwordNew.maxLength && errors.push("ລະຫັດຜ່ານຍາວເກີນ!");
      !this.$v.user.passwordNew.required &&
        errors.push("ກະລຸນາປ້ອນລະຫັດຜ່ານໃໝ່!");
      return errors;
    },
    passwordNewConfirmErrors() {
      const errors = [];
      if (!this.$v.user.passwordNewConfirm.$dirty) return errors;
      !this.$v.user.passwordNewConfirm.maxLength &&
        errors.push("ລະຫັດຜ່ານຍາວເກີນ!");
      !this.$v.user.passwordNewConfirm.required &&
        errors.push("ກະລຸນາປ້ອນລະຫັດຜ່ານໃໝ່!");
      return errors;
    },
    roleErrors() {
      const errors = [];
      if (!this.$v.user.role.$dirty) return errors;
      !this.$v.user.role.required &&
        errors.push("ກະລຸນາກໍານົດຕໍາແໜ່ງຜູ້ໃຊ້ລະບົບ.");
      return errors;
    },
  },
  mounted() {
    this.checkAuth();
  },
  methods: {
    ...mapActions("userMod", ["getUser", "changePassword"]),

    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },
    checkAuth() {
      var auth = JSON.parse(window.localStorage.getItem("user"));
      if (
        auth == null ||
        auth == undefined ||
        auth._id == null ||
        auth._id == undefined
      ) {
        this.user.userName = null;
        this.user.passwordOld = null;
        this.user.passwordNew = null;
        this.passwordNewConfirm = null;
        this.user.role = null;
        return;
      }
      this.getUser(auth._id);
    },
    async submit() {
      this.$v.$touch();
      try {
        if (this.user.userName == "" || this.user.userName == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ບໍ່ມີຊື່ຜູ້ໃຊ້ລະບົບ</div>`
          );
          return;
        }
        if (this.user.passwordOld == "" || this.user.passwordOld == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນລະຫັດຜ່ານເກົ່າ</div>`
          );
          return;
        }
        if (this.user.passwordNew == "" || this.user.passwordNew == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນລະຫັດຜ່ານໃໝ່</div>`
          );
          return;
        }
        if (
          this.user.passwordNewConfirm == "" ||
          this.user.passwordNewConfirm == null
        ) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາຢືນຢັນລະຫັດຜ່ານໃໝ່</div>`
          );
          return;
        }
        if (this.user.passwordNew != this.user.passwordNewConfirm) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາຢືນຢັນລະຫັດຜ່ານໃໝ່ໃຫ້ຖຶກຕ້ອງ</div>`
          );
          return;
        }
        var user = {
          userName: this.user.userName,
          old_password: this.user.passwordOld,
          new_password: this.user.passwordNew,
        };
        this.changePassword(user)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
              this.showToast(
                "success",
                `<div style='font-family:"Noto Sans Lao";' >ແກ້ໄຂຂໍ້ມູນສໍາເລັດ</div>`
              );
              this.clear();
              this.back();
            } else {
              this.showToast(
                "error",
                `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "error",
              `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
            );
            console.log(error);
          });
      } catch (error) {
        console.log(error);
        this.showToast(
          "error",
          `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
        );
      }
    },
    clear() {
      this.$v.$reset();
      this.user.userName = null;
      this.user.passwordOld = null;
      this.user.passwordNew = null;
      this.passwordNewConfirm = null;
      this.user.role = null;
      this.checkAuth();
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped></style>
