<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
              <h1 style="margin-top: 30px">ແກ້ໄຂຂໍ້ມູນລາຍຮັບ</h1>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  <v-text-field
                    v-model="order.userName"
                    label="ຊື່ຜູ້ສັ່ງ"
                  ></v-text-field>
                  <span>ສະຖານະ</span>
                  <v-card outlined width="100%" class="my-1 px-5">
                    <v-radio-group v-model="order.orderStatus">
                      <v-radio label="PACKED" value="PACKED"></v-radio>
                      <v-radio label="SHIPPED" value="SHIPPED"></v-radio>
                      <v-radio label="DELIVERED" value="DELIVERED"></v-radio>
                    </v-radio-group>
                  </v-card>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class></v-divider>
            <v-row
              class="ma-5"
              :justify="$vuetify.breakpoint.width < 600 ? 'center' : 'start'"
            >
              <v-btn
                @click="back"
                class="ma-2 orange white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
              </v-btn>
              <v-spacer class="d-none d-sm-flex"></v-spacer>
              <v-btn
                @click="clear"
                class="ma-2 red white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-close-circle</v-icon>ຍົກເລິກ
              </v-btn>

              <v-btn
                @click="submit"
                class="ma-2 green white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-check-circle</v-icon>ບັນທຶກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({}),

  computed: {
    ...mapGetters({
      order: "orderMod/order",
    }),
  },
  mounted() {
    this.getOrder(this.$route.params.id);
  },
  methods: {
    ...mapActions("orderMod", ["getOrder", "updateOrder"]),

    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },
    submit() {
      this.$v.$touch();
      try {
        if (this.order.userName == "" || this.order.userName == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຊື່ຜູ້ສັ່ງ</div>`
          );
          return;
        }

        if (this.order.orderStatus == "" || this.order.orderStatus == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຊື່ສະຖານະ</div>`
          );
          return;
        }
        var ord = {
          _id: this.order._id,
          orderStatus: this.order.orderStatus,
        };
        this.updateOrder(ord)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
              this.showToast(
                "success",
                `<div style='font-family:"Noto Sans Lao";' >ແກ້ໄຂຂໍ້ມູນສໍາເລັດ</div>`
              );
              this.clear();
              this.back();
            } else {
              this.showToast(
                "error",
                `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "error",
              `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
            );
            console.log(error);
          });
      } catch (error) {
        console.log(error);
        this.showToast(
          "error",
          `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
        );
      }
    },
    clear() {
      this.$v.$reset();
      this.getOrder(this.$route.params.id);
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped></style>
