import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";
import { apiURL, header } from "../../config/constant";

Vue.use(Vuex);

const bannerModule = {
  namespaced: true,
  state: {
    banners: [],
    banner: [],
    length: true
  },
  mutations: {
    setBanners(state, banner) {
      state.banners = banner;
    },
    setBanner(state, banner) {
      state.banner = banner;
    },
    setLength(state, banner) {
      state.length = banner;
    },
  },

  actions: {
    getBanners(context) {
      try {
        axios.get(apiURL + `banners`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var bannerlist = [];
          for (const idx in res.data) {
            const el = res.data[idx];
            var banner = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              image: el.image,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
            };
            bannerlist.push(banner);
          }
          context.commit("setBanners", bannerlist);
          context.commit("setLength", true);
        }).catch(function (error) {
          context.commit("setLength", false);
          console.log(error)
        });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
    getBanner(context, id) {
      try {
        axios.get(apiURL + `banners/${id}`, { headers: header }).then((res) => {
          if (res.data.error != undefined) return alert("ບ");
          const el = res.data;
          var banner = {
            _id: el._id,
            image: null,
            imageURL: el.image,
            createdAt:
              moment(String(el.createdAt)).format("DD-MM-YYYY") +
              " " +
              el.createdAt.slice(11, el.createdAt.length - 5),
            updatedAt:
              moment(String(el.updatedAt)).format("DD-MM-YYYY") +
              " " +
              el.updatedAt.slice(11, el.updatedAt.length - 5),
          };
          context.commit("setBanner", banner);
        })
          .catch(function (error) {
            if (error.message == 'Network Error') {
              console.log("ບໍ່ມີອີນເທີເນັດ")
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    delBanner(context, item) {
      try {
        return axios
          .delete(apiURL + `banners/${item._id}`, { headers: header })
      } catch (e) {
        console.log(e);
      }
    },
    insertBanner(context, item) {
      try {
        return axios
          .post(apiURL + `banners`, item, { headers: header })
      } catch (e) {
        console.log(e);
      }
    },
    updateBanner(context, { id, item }) {
      try {
        return axios
          .put(apiURL + `banners/${id}`, item, { headers: header })
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    banners: (state) => state.banners,
    banner: (state) => state.banner,
    length: (state) => state.length,
  },
};

export default bannerModule;
