<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
              <p style="font-size:2rem;margin-top: 30px">ລາຍລະອຽດຂໍ້ມູນວັດຖຸດິບ</p>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  <v-text-field
                  readonly
                    v-model="rawdata.rawName"
                    :error-messages="rawdataNameErrors"
                    label="ຊື່ວັດຖຸດິບ"
                    required
                    @input="$v.rawdata.rawName.$touch()"
                    @blur="$v.rawdata.rawName.$touch()"
                  ></v-text-field>
                  <v-autocomplete
                  readonly
                    item-color="blue darken-1"
                    no-data-text="ບໍ່ມີຂໍ້ມູນ"
                    v-model="rawdata.unitName"
                    :items="unitName"
                    :error-messages="unitErrors"
                    label="ຫົວໜ່ວຍ"
                    allow-overflow
                    required
                    @input="$v.rawdata.unitName.$touch()"
                    @blur="$v.rawdata.unitName.$touch()"
                  ></v-autocomplete>
                  <v-autocomplete
                  readonly
                    item-color="blue darken-1"
                    no-data-text="ບໍ່ມີຂໍ້ມູນ"
                    v-model="rawdata.supplierName"
                    :items="supplierName"
                    :error-messages="supplierErrors"
                    label="ຜຸ້ສະໜອງ"
                    allow-overflow
                    required
                    @input="$v.rawdata.supplierName.$touch()"
                    @blur="$v.rawdata.supplierName.$touch()"
                  ></v-autocomplete>
                  <v-text-field
                  readonly
                    v-model="rawdata.qty"
                    :error-messages="qtyErrors"
                    label="ຈໍານວນ"
                    required
                    @input="$v.rawdata.qty.$touch()"
                    @blur="$v.rawdata.qty.$touch()"
                    @keypress="number($event)"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class></v-divider>
            <v-row
              class="ma-5"
              :justify="$vuetify.breakpoint.width < 600 ? 'center' : 'start'"
            >
              <v-btn
                @click="back"
                class="ma-2 orange white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
              </v-btn>
              <v-spacer class="d-none d-sm-flex"></v-spacer>
              <v-btn
                @click="clear"
                class="ma-2 cyan white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
            <v-icon left>mdi-backup-restore</v-icon>ໂລດຂໍ້ມູນ
              </v-btn>
<!-- 
              <v-btn
                @click="submit"
                class="ma-2 green white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-check-circle</v-icon>ບັນທຶກ
              </v-btn> -->
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    rawdata: {
      rawName: { required, maxLength: maxLength(100) },
      unitName: { required, maxLength: maxLength(100) },
      supplierName: { required, maxLength: maxLength(100) },
      qty: { required, maxLength: maxLength(100) },
    },
  },

  data: () => ({
  }),

  computed: {
    ...mapGetters({
      rawdata: "rawdataMod/rawdata",
      unit: "unitMod/unit",
      unitName: "unitMod/unitName",
      suppliers: "supplierMod/suppliers",
      supplierName: "supplierMod/supplierName",
    }),
    rawdataNameErrors() {
      const errors = [];
      if (!this.$v.rawdata.rawName.$dirty) return errors;
      !this.$v.rawdata.rawName.maxLength &&
        errors.push("ກະລຸນາປ້ອນຊື່ຜະລິດຕະພັນດິບ!");
      !this.$v.rawdata.rawName.required &&
        errors.push("ກະລຸນາປ້ອນຊື່ຜະລິດຕະພັນດິບ!");
      return errors;
    },
    unitErrors() {
      const errors = [];
      if (!this.$v.rawdata.unitName.$dirty) return errors;
      !this.$v.rawdata.unitName.maxLength &&
        errors.push("ກະລຸນາເລືອກຫົວໜ່ວຍ");
      !this.$v.rawdata.unitName.required &&
        errors.push("ກະລຸນາເລືອກຫົວໜ່ວຍ");
      return errors;
    },
    qtyErrors() {
      const errors = [];
      if (!this.$v.rawdata.qty.$dirty) return errors;
      !this.$v.rawdata.qty.maxLength && errors.push("ກະລຸນາປ້ອນຈໍານວນຜະລິດຕະພັນ");
      !this.$v.rawdata.qty.required && errors.push("ກະລຸນາປ້ອນຈໍານວນຜະລິດຕະພັນ");
      return errors;
    },

    supplierErrors() {
      const errors = [];
      if (!this.$v.rawdata.supplierName.$dirty) return errors;
      !this.$v.rawdata.supplierName.maxLength &&
        errors.push("ກະລຸນາເລືອກຊື່ຜູ້ສະໜອງ");
      !this.$v.rawdata.supplierName.required &&
        errors.push("ກະລຸນາເລືອກຊື່ຜູ້ສະໜອງ");
      return errors;
    },
  },
  mounted() {
    this.getUnit();
    this.getSuppliers();
    this.getRawdata(this.$route.params.id);
  },
  methods: {
    ...mapActions("unitMod", ["getUnit"]),
    ...mapActions("supplierMod", ["getSuppliers"]),
    ...mapActions("rawdataMod", ["updateRawdata", "getRawdata"]),

    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },
    number(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ປ້ອນສະເພາະຕົວເທົ່ານັ້ນ (0-9)</div>`,)
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async submit() {
      this.$v.$touch();
      try {
      if (this.rawdata.rawName == "" || this.rawdata.rawName == null) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນຊື່ຜະລິດຕະພັນດິບ</div>`,)
          return;
        }
        if (this.rawdata.unitName == "" || this.rawdata.unitName == null) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຫົວໜ່ວຍ</div>`,)
          return;
        }
        if (
          this.rawdata.supplierName == "" ||
          this.rawdata.supplierName == null
        ) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຊື່ຜູ້ສະໜອງ</div>`,)
          return;
        }
        if (this.rawdata.qty == "" || this.rawdata.qty == null) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນຈໍານວນຜະລິດຕະພັນ</div>`,)
          return;
        }
        for (const key in this.unit) {
          const el = this.unit[key];
          if (el.name == this.rawdata.unitName) {
            this.rawdata.unit = el._id;
          }
        }
        if (this.rawdata.unit == "") {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຫົວໜ່ວຍຜະລິດຕະພັນ</div>`,)
          return;
        }

        for (const key in this.suppliers) {
          const el = this.suppliers[key];
          if (el.supplierName == this.rawdata.supplierName) {
            this.rawdata.supplier = el._id;
          }
        }
        if (this.rawdata.supplier == "") {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນຊື່ຜູ້ສະໜອງ</div>`,)
          return;
        }
        this.updateRawdata(this.rawdata)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
              this.showToast("success",`<div style='font-family:"Noto Sans Lao";' >ແກ້ໄຂຂໍ້ມູນສໍາເລັດ</div>`,)
              this.clear();
              this.back()
            } else {
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            }
          })
          .catch( (error) =>{
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            console.log(error);
          });
      } catch (error) {
        console.log(error);
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
      }
    },
    clear() {
      this.$v.$reset();
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped>
</style>