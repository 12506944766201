<template>
  <div>
        <v-card >
          <v-card-title>
            <v-row no-gutters>
              <v-col
                cols="12"
                md="3"
                :align="$vuetify.breakpoint.width < 960 ? 'center' : 'start'"
                class="hidden-sm-and-down"
              >
                <span style="font-size: 1.2rem">ຂໍ້ມູນສະຕ໋ອກວັດຖຸດິບທັງໝົດ</span>
                <v-divider vertical class="mx-2 hidden-sm-and-down"></v-divider>
              </v-col>
              <v-col cols="12" md="9">
                <v-row> <v-text-field
              v-model="barCode"
              dense
              outlined
              class="mx-2 mt-3"
              label="ເພີ່ມໃໝ່ໂດຍໃຊ້ລະຫັດບາໂຄດ"
              @keyup.enter="routeBarCode"
            ></v-text-field>
                  <v-spacer class="hidden-sm-and-down"></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="ຄົ້ນຫາ"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                  <v-btn
                    class="mt-3 mr-2 green"
                    @click="linkAdd"
                    :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
                  >
                    <v-icon color="white" large>mdi-plus</v-icon
                    ><span style="color: white; font-weight: bold"
                      >ເພີ່ມໃໝ່</span
                    >
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>
          <div v-if="loading">
            <v-data-table
              :headers="headers"
              :search="search"
              sort-by="idx"
              class="elevation-0"
              hide-default-footer
              no-data-text=""
            ></v-data-table>
            <v-row no-gutters class="justify-center pb-10">
              <SpinnerLoader :color="'#90CAF9'" :loading="true" :size="100" />
            </v-row>
          </div>
           <v-card flat v-else-if="length == false">
            <v-data-table
              :headers="headers"
              :search="search"
              sort-by="idx"
              class="elevation-0"
              hide-default-footer
              no-data-text=""
            ></v-data-table>
            <v-row class="justify-center mx-4">
              <v-card
                flat
                class=""
                color="transparent"
                style="font-size: 2rem; color: grey; margin-top: 40px"
                >ບໍ່ມີຂໍ້ມູນວັດຖຸດິບ
              </v-card>
            </v-row>
            <v-skeleton-loader
              style="margin-top: -125px"
              class=""
              type="image"
            >
            </v-skeleton-loader>
          </v-card>
          <v-data-table
            v-else
            large
            class="table"
            :headers="headers"
            :items="stocks"
            :search="search"
          :footer-props="{itemsPerPageText: 'ສະແດງຈໍານວນແຖວ :'}"
            fixed-header
          >
            <template v-slot:item="{ item }">
              <tr v-ripple="{ class: `white--text` }" v-if="item.qty>0">
                <td>{{ item.idx }}</td>
                <td>{{ item.rawName }}</td>
                <td>
                  <span v-if="item.rawQty>item.qty" style="color:red; font-size:1.2rem"
                    >{{
                      String(item.qty).replace(/(.)(?=(\d{3})+$)/g, "$1,")
                    }}</span
                  >
                  <span v-else
                    >{{
                      String(item.qty).replace(/(.)(?=(\d{3})+$)/g, "$1,")
                    }}</span
                  >
                </td>
                <td>
                  {{ item.unitName }}
                </td>
                <td>
                  {{ item.supplierName }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { SpinnerLoader } from "vue-spinners-css";
export default {
  data() {
    return {
      loading: true,
      barCode:null,
      date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu1: false,
      date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu2: false,
      deleteItem: [],
      search: "",
      headers: [
        { text: "ລໍາດັບ", value: "idx", class: "indigo white--text" },
        { text: "ຊື່ວັດຖຸດິບ", value: "rawName", class: "indigo white--text" },
        { text: "ຈໍານວນ", value: "qty", class: "indigo white--text" },
        { text: "ຫົວໜ່ວຍ", value: "unitName", class: "indigo white--text" },
        { text: "ຜູ້ສະໜອງ", value: "supplierName", class: "indigo white--text" },
      ],
    };
  },
  components: {
    SpinnerLoader,
  },
  computed: {
    ...mapGetters({
      stocks: "stockMod/stocks",
      length: "stockMod/length",
    }),
    
  },
  watch: {
    length(val) {
      if (val==false) {
        this.loading = false;
      }
    },
      stocks(val) {
      if (val.length > 0) {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.getStocks();
    this.Loading()
  },
  created() {},
  methods: {
    ...mapActions("stockMod", ["getStocks", "delStock"]),
    showAlert() {
      this.$swal({
        position: "center",
        icon: "warning",
        title: `<div style='font-family:"Noto Sans Lao";' >ເຕືອນ</div>`,
        html: `<div style='font-family:"Noto Sans Lao"; font-size:1.5rem' >ທ່ານຕ້ອງການລົບອອກບໍ?</div>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: `<div style='font-family:"Noto Sans Lao";' >ຕົກລົງ</div>`,
        cancelButtonText: `<div style='font-family:"Noto Sans Lao";' >ຍົກເລີກ</div>`,
        confirmButtonColor: "green",
        cancelButtonColor: "red",
        reverseButtons: true,
        buttonsStyling: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteStockConfirm();
        }
      });
    },
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    Loading(){
if (this.length==false) {
  this.loading=false
}
    },
    deleteStock(item) {
      this.deleteItem = item;
      this.showAlert();
    },
    deleteStockConfirm() {
      try {
        this.delStock(this.deleteItem)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
              this.showToast(
                "success",
                `<div style='font-family:"Noto Sans Lao";' >ລົບຂໍ້ມູນສໍາເລັດແລ້ວ</div>`
              );
              this.getStocks();
            } else {
              this.showToast(
                "error",
                `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "error",
              `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
            );
            console.log(error);
          });
      } catch (error) {
        console.log(error);
        this.showToast(
          "error",
          `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
        );
      }
    },
    
    routeBarCode() {
      if (this.barCode != null || this.barCode != undefined) {
        this.$router.push({
          path: `/staff/stock/add`,query: { barCodeNumber: this.barCode },
        });
      }
    },
    linkAdd() {
      this.$router.push(`/staff/stock/add`);
    },
    editStock(item) {
      this.$router.push(`/staff/stock/edit/${item._id}`);
    },
  },
};
</script>
