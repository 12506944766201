<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
              <p style="font-size: 2rem; margin-top: 30px">
                ເພີ່ມຂໍ້ມູນຜະລິດຕະພັນ
              </p>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  <v-text-field
                    v-model="product.productName"
                    :error-messages="productNameErrors"
                    label="ຊື່ຜະລິດຕະພັນ"
                    required
                    @input="$v.product.productName.$touch()"
                    @blur="$v.product.productName.$touch()"
                  ></v-text-field>
                  <v-text-field
                    readonly
                    v-model="maxBarCode"
                    label="ລະຫັດບາໂຄດ"
                    required
                  ></v-text-field>
                  <v-autocomplete
                    clearable
                    item-color="blue darken-1"
                    no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                    v-model="product.categoryName"
                    :items="categoryName"
                    :error-messages="categoryErrors"
                    label="ປະເພດ"
                    allow-overflow
                    required
                    @input="$v.product.categoryName.$touch()"
                    @blur="$v.product.categoryName.$touch()"
                  ></v-autocomplete>
                  <v-text-field
                    v-model="product.retail"
                    :error-messages="retailErrors"
                    label="ລາຄາຂາຍຍ່ວຍ"
                    required
                    @input="$v.product.retail.$touch()"
                    @blur="$v.product.retail.$touch()"
                    @keypress="number($event)"
                  ></v-text-field>
                  <v-text-field
                    v-model="product.wholeSale"
                    :error-messages="wholeSaleErrors"
                    label="ລາຄາຂາຍສົ່ງ"
                    required
                    @input="$v.product.wholeSale.$touch()"
                    @blur="$v.product.wholeSale.$touch()"
                    @keypress="number($event)"
                  ></v-text-field>

                  <v-text-field
                    v-model="product.promotionPrice"
                    label="ລາຄາໂປຣໂມຊັນ"
                  ></v-text-field>
                  <v-text-field
                    v-model="product.qty"
                    :error-messages="qtyErrors"
                    label="ຈໍານວນ"
                    required
                    @input="$v.product.qty.$touch()"
                    @blur="$v.product.qty.$touch()"
                    @keypress="number($event)"
                  ></v-text-field>

                  <v-textarea
                    v-model="product.description"
                    background-color="#fafafafa"
                    color="cyan"
                    label="ລາຍລະອຽດ"
                    :error-messages="descriptionErrors"
                    required
                    @input="$v.product.description.$touch()"
                    @blur="$v.product.description.$touch()"
                  ></v-textarea>

                  <input
                    type="file"
                    class="file-upload"
                    ref="file"
                    @change="onFileSelected"
                  />

                  <v-img
                    v-if="product.imageURL"
                    class="mx-auto"
                    height="400"
                    width="100%"
                    contain
                    :src="product.imageURL ? product.imageURL : ''"
                  >
                  </v-img>
                  <v-card
                    flat
                    exact
                    align="center"
                    v-else
                    class="mx-auto"
                    color="#fafafafa"
                    height="400"
                    width="100%"
                  >
                    <div
                      style="
                        padding-top: 160px;
                        font-size: 3rem;
                        font-weight: bold;
                        color: #d0d0d0;
                      "
                    >
                      520 X 300
                    </div>
                  </v-card>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class></v-divider>
            <v-row
              class="ma-5"
              :justify="$vuetify.breakpoint.width < 600 ? 'center' : 'start'"
            >
              <v-btn
                @click="back"
                class="ma-2 orange white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
              </v-btn>
              <v-spacer class="d-none d-sm-flex"></v-spacer>
              <v-btn
                @click="clear"
                class="ma-2 red white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-close-circle</v-icon>ຍົກເລິກ
              </v-btn>

              <v-btn
                @click="submit"
                class="ma-2 green white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-check-circle</v-icon>ບັນທຶກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    product: {
      productName: { required, maxLength: maxLength(100) },
      categoryName: { required, maxLength: maxLength(100) },
      retail: { required, maxLength: maxLength(100) },
      wholeSale: { required, maxLength: maxLength(100) },
      barCodeNumber: { required, maxLength: maxLength(1000) },
      qty: { required, maxLength: maxLength(100) },
      description: { required, maxLength: maxLength(1000) },
    },
  },
  data: () => ({
    product: {
      productName: "",
      barCodeNumber: null,
      categoryName: "",
      category: "",
      wholeSale: 0,
      retail: 0,
      promotionPrice: null,
      qty: 1,
      description: "",
      image: null,
      imageURL: null,
    },
  }),

  computed: {
    ...mapGetters({
      category: "categoryMod/category",
      categoryName: "categoryMod/categoryName",
      maxBarCode: "productMod/maxBarCode",
    }),

    productNameErrors() {
      const errors = [];
      if (!this.$v.product.productName.$dirty) return errors;
      !this.$v.product.productName.maxLength &&
        errors.push("ກະລຸນາປ້ອນຊື່ຜະລິດຕະພັນ");
      !this.$v.product.productName.required &&
        errors.push("ກະລຸນາປ້ອນຊື່ຜະລິດຕະພັນ");
      return errors;
    },
    categoryErrors() {
      const errors = [];
      if (!this.$v.product.categoryName.$dirty) return errors;
      !this.$v.product.categoryName.maxLength &&
        errors.push("ກະລຸນາເລືອກປະເພດຜະລິດຕະພັນ");
      !this.$v.product.categoryName.required &&
        errors.push("ກະລຸນາເລືອກປະເພດຜະລິດຕະພັນ");
      return errors;
    },
    qtyErrors() {
      const errors = [];
      if (!this.$v.product.qty.$dirty) return errors;
      !this.$v.product.qty.maxLength &&
        errors.push("ກະລຸນາປ້ອນຈໍານວນຜະລິດຕະພັນ");
      !this.$v.product.qty.required &&
        errors.push("ກະລຸນາປ້ອນຈໍານວນຜະລິດຕະພັນ");
      return errors;
    },

    retailErrors() {
      const errors = [];
      if (!this.$v.product.retail.$dirty) return errors;
      !this.$v.product.retail.maxLength &&
        errors.push("ກະລຸນາປ້ອນລາຄາຂາຍຍ່ວຍຜະລິດຕະພັນ");
      !this.$v.product.retail.required &&
        errors.push("ກະລຸນາປ້ອນລາຄາຂາຍຍ່ວຍຜະລິດຕະພັນ");
      return errors;
    },
    wholeSaleErrors() {
      const errors = [];
      if (!this.$v.product.wholeSale.$dirty) return errors;
      !this.$v.product.wholeSale.required &&
        errors.push("ກະລຸນາປ້ອນລາຄາຂາຍສົ່ງຜະລິດຕະພັນ");
      !this.$v.product.wholeSale.required &&
        errors.push("ກະລຸນາປ້ອນລາຄາຂາຍສົ່ງຜະລິດຕະພັນ");
      return errors;
    },

    descriptionErrors() {
      const errors = [];
      if (!this.$v.product.description.$dirty) return errors;
      !this.$v.product.description.maxLength &&
        errors.push("ກະລຸນາປ້ອນລາຍລະອຽດຜະລິດຕະພັນ");
      !this.$v.product.description.required &&
        errors.push("ກະລຸນາປ້ອນລາຍລະອຽດຜະລິດຕະພັນ");
      return errors;
    },
  },
  mounted() {
    this.getProductMaxBarCode();
    this.getCategory();
    this.barCodeNumber();
  },
  methods: {
    ...mapActions("categoryMod", ["getCategory"]),
    ...mapActions("productMod", ["insertProduct", "getProductMaxBarCode"]),
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    number(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        this.showToast(
          "warning",
          `<div style='font-family:"Noto Sans Lao";color:red' >ປ້ອນສະເພາະຕົວເທົ່ານັ້ນ (0-9)</div>`
        );
        evt.preventDefault();
      } else {
        return true;
      }
    },

    onFileSelected(e) {
      const image = e.target.files[0];
      const imageName = image.name;
      if (imageName.lastIndexOf(".") <= 0) {
        this.showToast(
          "warning",
          `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜູ້ໃຊ້ລະບົບບໍ່ຖຶກຕ້ອງ</div>`
        );
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.product.imageURL = e.target.result;
      };
      this.product.image = image;
    },
    barCodeNumber() {
      var barCodeNumber = 1;
      var max =
        this.maxBarCode == undefined || this.maxBarCode == null
          ? barCodeNumber
          : this.maxBarCode;
      if (parseInt(max) > 1) {
        this.product.barCodeNumber = "PD: " + max;
      }
    },
    async submit() {
      this.$v.$touch();
      try {
        if (
          this.product.productName == "" ||
          this.product.productName == null
        ) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນຊື່ຜະລິດຕະພັນ</div>`
          );
          return;
        }
        if (
          this.maxBarCode == "" ||
          this.maxBarCode == null ||
          this.maxBarCode == undefined
        ) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ບໍ່ມີລະຫັດບາໂຄດ</div>`
          );
          return;
        }
        this.product.barCodeNumber = this.maxBarCode;
        if (
          this.product.categoryName == "" ||
          this.product.categoryName == null
        ) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກປະເພດຜະລິດຕະພັນ</div>`
          );
          return;
        }
        if (this.product.retail == "" || this.product.retail == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນລາຄາຂາຍຍ່ອຍຜະລິດຕະພັນ</div>`
          );
          return;
        }
        if (this.product.wholeSale == "" || this.product.wholeSale == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນລາຄາຂາຍສົ່ງຜະລິດຕະພັນ</div>`
          );
          return;
        }
        if (
          parseFloat(this.product.retail) < parseFloat(this.product.wholeSale)
        ) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາກວດຄືນ? (ລາຄາຂາຍຍ່ອຍຕ້ອງເທົ່າກັບ ຫຼື ສູງກວ່າລາຄາຂາຍສົ່ງ)</div>`
          );
          return;
        }
        //  if (this.product.promotionPrice == "" || this.product.promotionPrice == null) {
        //      this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນລາຄາໂປຣໂມຊັນສິນຄ້າ</div>`,)
        //   return;
        // }
        if (this.product.qty == "" || this.product.qty == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນຈໍານວນຜະລິດຕະພັນ</div>`
          );
          return;
        }
        if (
          this.product.description == "" ||
          this.product.description == null
        ) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນລາຍລະອຽດຜະລິດຕະພັນ</div>`
          );
          return;
        }
        for (const key in this.category) {
          const el = this.category[key];
          if (el.name == this.product.categoryName) {
            this.product.category = el._id;
          }
        }
        if (this.product.category == "") {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກປະເພດຜະລິດຕະພັນ</div>`
          );
          return;
        }
        if (this.product.image == "" || this.product.image == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຮູບພາບຜະລິດຕະພັນ</div>`
          );
          return;
        }

        let formData = new FormData();
        formData.append("productName", this.product.productName);
        formData.append("category", this.product.category);
        formData.append("wholeSale", this.product.wholeSale);
        formData.append("barCodeNumber", this.product.barCodeNumber);
        formData.append("retail", this.product.retail);
        formData.append("promotionPrice", this.product.promotionPrice);
        formData.append("qty", this.product.qty);
        formData.append("image", this.product.image);
        formData.append("description", this.product.description);
        this.insertProduct(formData)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
              this.showToast(
                "success",
                `<div style='font-family:"Noto Sans Lao";' >ບັນທຶກຂໍ້ມູນສໍາເລັດແລ້ວ</div>`
              );
              this.routebarCodeNumber();
              this.clear();
            } else {
              this.showToast(
                "error",
                `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "error",
              `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
            );
            console.log(error);
          });
      } catch (error) {
        console.log(error);
        this.showToast(
          "error",
          `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
        );
      }
    },
    routebarCodeNumber() {
      if (
        this.product.barCodeNumber != null ||
        this.product.barCodeNumber != undefined
      ) {
        let routeData = this.$router.resolve({
          path: `/admin/product/barcode/${this.product.barCodeNumber}`,
          query: { qty: this.product.qty },
        });
        window.open(routeData.href, "_blank");
      }
    },
    clear() {
      this.$v.$reset();
      this.getProductMaxBarCode();
      this.product = {
        productName: "",
        barCodeNumber: null,
        categoryName: "",
        category: "",
        wholeSale: 0,
        retail: 0,
        promotionPrice: null,
        qty: 1,
        description: "",
        image: null,
      };
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped>
.file-upload {
  margin: 7px 0px;
  font-size: 16px;
  width: 100%;
  background: white;
  border-bottom: 1px solid #999;
}
.file-upload::placeholder {
  content: "";
}
.file-upload::before {
  background: #eeeeee;
  content: "ເລືອກຮູບພາບ";
  text-indent: "";
  border-radius: 0px 50px 50px 0px;
  padding: 10px;
  width: 180px;
  border: 1px solid #edffdd;
}
.file-upload:hover {
  border-bottom: 1px solid #555;
}
.file-upload:hover::before {
  background: #dedede;
}
::-webkit-file-upload-button {
  background: #eeeeee;
  visibility: hidden;
  border-radius: 0px 50px 50px 0px;
  padding: 7px;
  width: 1px;
  border: 1px solid #edffdd;
}
::-webkit-file-upload-button:hover {
  background: #dedede;
}
</style>