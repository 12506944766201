<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
              <p style="font-size: 2rem; margin-top: 30px">ແກ້ໄຂຂໍ້ມູນລູກຄ້າ</p>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  <v-text-field
                    v-model="customer.firstName"
                    :error-messages="firstNameErrors"
                    label="ຊື່"
                    required
                    @input="$v.customer.firstName.$touch()"
                    @blur="$v.customer.firstName.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="customer.lastName"
                    :error-messages="lastNameErrors"
                    label="ນານສະກຸນ"
                    required
                    @input="$v.customer.lastName.$touch()"
                    @blur="$v.customer.lastName.$touch()"
                  ></v-text-field>
                  <v-autocomplete
                    clearable
                    item-color="blue darken-1"
                    no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                    v-model="customer.provinceName"
                    :items="provinceName"
                    :error-messages="provinceNameErrors"
                    label="ແຂວງ"
                    required
                    @input="$v.customer.provinceName.$touch()"
                    @blur="$v.customer.provinceName.$touch()"
                  ></v-autocomplete>

                  <v-autocomplete
                    clearable
                    item-color="blue darken-1"
                    no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                    v-model="customer.districtName"
                    :error-messages="districtNameErrors"
                    :items="districtInProvince"
                    label="ເມືອງ"
                    required
                    @input="$v.customer.districtName.$touch()"
                    @blur="$v.customer.districtName.$touch()"
                  ></v-autocomplete>
                  <v-autocomplete
                    clearable
                    item-color="blue darken-1"
                    no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                    v-model="customer.villageName"
                    :items="villageInDistrict"
                    :error-messages="villageErrors"
                    label="ບ້ານ"
                    required
                    @input="$v.customer.villageName.$touch()"
                    @blur="$v.customer.villageName.$touch()"
                  ></v-autocomplete>

                  <v-text-field
                    v-model="customer.lat"
                    :error-messages="latErrors"
                    label="ເສັ້ນແວງ"
                    required
                    @input="$v.customer.lat.$touch()"
                    @blur="$v.customer.lat.$touch()"
                  ></v-text-field>

                  <v-text-field
                    v-model="customer.lng"
                    :error-messages="lngErrors"
                    label="ເສັ້ນຂະໜານ"
                    required
                    @input="$v.customer.lng.$touch()"
                    @blur="$v.customer.lng.$touch()"
                  ></v-text-field>

                  <v-text-field
                    v-model="customer.phoneNumber"
                    :error-messages="phoneNumberErrors"
                    label="ເລກໂທລະສັບ"
                    required
                    @input="$v.customer.phoneNumber.$touch()"
                    @blur="$v.customer.phoneNumber.$touch()"
                  ></v-text-field>

                  <v-text-field
                    v-model="customer.email"
                    :error-messages="emailErrors"
                    label="ອີເມວ"
                    required
                    @input="$v.customer.email.$touch()"
                    @blur="$v.customer.email.$touch()"
                  ></v-text-field>
                  <v-autocomplete
                    clearable
                    item-color="blue darken-1"
                    no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                    class="font"
                    v-model="customer.userName"
                    :items="userName"
                    :error-messages="userNameErrors"
                    label="ຜູ້ໃຊ້"
                    required
                    @input="$v.customer.userName.$touch()"
                    @blur="$v.customer.userName.$touch()"
                  ></v-autocomplete>
                  <input
                    type="file"
                    class="file-upload"
                    ref="file"
                    @change="onFileSelected"
                    label="ເລືອກຮູບພາບ"
                  />

                  <v-img
                    v-if="customer.imageURL"
                    class="mx-auto"
                    height="400"
                    width="100%"
                    contain
                    :src="customer.imageURL ? customer.imageURL : ''"
                  >
                  </v-img>
                  <v-card
                    flat
                    exact
                    align="center"
                    v-else
                    class="mx-auto"
                    color="#fafafafa"
                    height="400"
                    width="100%"
                  >
                    <div
                      style="
                        padding-top: 160px;
                        font-size: 3rem;
                        font-weight: bold;
                        color: #d0d0d0;
                      "
                    >
                      520 X 300
                    </div>
                  </v-card>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class></v-divider>
            <v-row
              class="ma-5"
              :justify="$vuetify.breakpoint.width < 600 ? 'center' : ''"
            >
              <v-btn
                @click="back"
                class="ma-2 orange white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
              </v-btn>
              <v-spacer class="d-none d-sm-flex"></v-spacer>
              <v-btn
                @click="clear"
                class="ma-2 red white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-close-circle</v-icon>ຍົກເລິກ
              </v-btn>

              <v-btn
                @click="submit"
                class="ma-2 green white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-check-circle</v-icon>ບັນທຶກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    customer: {
      firstName: { required, maxLength: maxLength(100) },
      lastName: { required, maxLength: maxLength(100) },
      provinceName: { required, maxLength: maxLength(100) },
      villageName: { required, maxLength: maxLength(100) },
      districtName: { required, maxLength: maxLength(100) },
      lat: { required, maxLength: maxLength(100) },
      lng: { required, maxLength: maxLength(100) },
      phoneNumber: { required, maxLength: maxLength(100) },
      userName: { required, maxLength: maxLength(100) },
      email: { required, email },
    },
  },
  data: () => ({}),

  computed: {
    ...mapGetters({
      customer: "customerMod/customer",
      villages: "addressMod/villages",
      villageName: "addressMod/villageName",
      districts: "addressMod/districts",
      districtName: "addressMod/districtName",
      provinces: "addressMod/provinces",
      provinceName: "addressMod/provinceName",
      users: "userMod/users",
      userName: "userMod/userName",
    }),
    firstNameErrors() {
      const errors = [];
      if (!this.$v.customer.firstName.$dirty) return errors;
      !this.$v.customer.firstName.maxLength &&
        errors.push("ກະລຸນາປ້ອນຊື່ລູກຄ້າ!");
      !this.$v.customer.firstName.required &&
        errors.push("ກະລຸນາປ້ອນຊື່ລູກຄ້າ!");
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.customer.lastName.$dirty) return errors;
      !this.$v.customer.lastName.maxLength &&
        errors.push("ກະລຸນາປ້ອນນານສະກຸນລູກຄ້າ!");
      !this.$v.customer.lastName.required &&
        errors.push("ກະລຸນາປ້ອນນານສະກຸນລູກຄ້າ!");
      return errors;
    },
    provinceNameErrors() {
      const errors = [];
      if (!this.$v.customer.provinceName.$dirty) return errors;
      !this.$v.customer.provinceName.required &&
        errors.push("ກະລຸນາເລືອກແຂວງ!");
      return errors;
    },

    districtNameErrors() {
      const errors = [];
      if (!this.$v.customer.districtName.$dirty) return errors;
      !this.$v.customer.districtName.required &&
        errors.push("ກະລຸນາເລືອກເມືອງ!");
      return errors;
    },
    villageErrors() {
      const errors = [];
      if (!this.$v.customer.villageName.$dirty) return errors;
      !this.$v.customer.villageName.required && errors.push("ກະລຸນາເລືອກບ້ານ!");
      return errors;
    },

    latErrors() {
      const errors = [];
      if (!this.$v.customer.lat.$dirty) return errors;
      !this.$v.customer.lat.maxLength && errors.push("lກະລຸນາປ້ອນ lat !");
      !this.$v.customer.lat.required && errors.push("ກະລຸນາປ້ອນ lat !");
      return errors;
    },

    lngErrors() {
      const errors = [];
      if (!this.$v.customer.lng.$dirty) return errors;
      !this.$v.customer.lng.maxLength && errors.push("ກະລຸນາປ້ອນ lng !");
      !this.$v.customer.lng.required && errors.push("ກະລຸນາປ້ອນ lng !");
      return errors;
    },
    userNameErrors() {
      const errors = [];
      if (!this.$v.customer.userName.$dirty) return errors;
      !this.$v.customer.userName.maxLength && errors.push("ກະລຸນາເລືອກຜູ້ໃຊ້!");
      !this.$v.customer.userName.required && errors.push("ກະລຸນາເລືອກຜູ້ໃຊ້!");
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.customer.phoneNumber.$dirty) return errors;
      !this.$v.customer.phoneNumber.maxLength &&
        errors.push("ກະລຸນາປ້ອນເລກໂທລະສັບ!");
      !this.$v.customer.phoneNumber.required &&
        errors.push("ກະລຸນາປ້ອນເລກໂທລະສັບ!");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.customer.email.$dirty) return errors;
      !this.$v.customer.email.email && errors.push("ກະລຸນາປ້ອນອີເມວລູກຄ້າ!");
      !this.$v.customer.email.required && errors.push("ກະລຸນາປ້ອນອີເມວລູກຄ້າ!");
      return errors;
    },
    districtInProvince() {
      var dlist = [];
      for (const key in this.districts) {
        const el = this.districts[key];
        if (this.customer.provinceName == el.provinceName) {
          dlist.push(el.name);
        }
      }
      return dlist;
    },

    villageInDistrict() {
      var vlist = [];
      for (const key in this.villages) {
        const el = this.villages[key];
        if (this.customer.districtName == el.districtName) {
          vlist.push(el.name);
        }
      }
      return vlist;
    },
  },
  mounted() {
    this.getCustomer(this.$route.params.id);
    this.getProvinces();
    this.getDistricts();
    this.getVillages();
    this.getUsers();
  },
  methods: {
    ...mapActions("customerMod", ["updateCustomer", "getCustomer"]),
    ...mapActions("addressMod", [
      "getVillages",
      "getProvinces",
      "getDistricts",
    ]),
    ...mapActions("userMod", ["getUsers"]),

    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },
    onFileSelected(e) {
      const image = e.target.files[0];
      const imageName = image.name;
      if (imageName.lastIndexOf(".") <= 0) {
        this.showToast(
          "warning",
          `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຮູບພາບ</div>`
        );
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.customer.imageURL = e.target.result;
      };
      this.customer.image = image;
    },
    submit() {
      this.$v.$touch();
      try {
        if (this.customer.firstName == "" || this.customer.firstName == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນຊື່ລູກຄ້າ</div>`
          );
          return;
        }
        if (this.customer.lastName == "" || this.customer.lastName == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນນານສະກຸນລູກຄ້າ</div>`
          );
          return;
        }
        if (
          this.customer.provinceName == "" ||
          this.customer.provinceName == null
        ) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກເເຂວງລູກຄ້າ</div>`
          );
          return;
        }
        if (
          this.customer.districtName == "" ||
          this.customer.districtName == null
        ) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກເມືອງລູກຄ້າ</div>`
          );
          return;
        }
        if (
          this.customer.villageName == "" ||
          this.customer.villageName == null
        ) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກບ້ານລູກຄ້າ</div>`
          );
          return;
        }
        if (this.customer.lat == "" || this.customer.lat == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນເສັ້ນແວງລູກຄ້າ</div>`
          );
          return;
        }
        if (this.customer.lng == "" || this.customer.lng == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນເສັ້ນຂະໜານລູກຄ້າ</div>`
          );
          return;
        }
        if (
          this.customer.phoneNumber == "" ||
          this.customer.phoneNumber == null
        ) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນເລກໂທລະສັບ</div>`
          );
          return;
        }
        if (this.customer.email == "" || this.customer.email == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນອີເມວລູກຄ້າ</div>`
          );
          return;
        }
        if (this.customer.userName == "" || this.customer.userName == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຜູ້ໃຊ້ລະບົບ</div>`
          );
          return;
        }

        for (const key in this.users) {
          const el = this.users[key];
          if (el.userName == this.customer.userName) {
            this.customer.auth = el._id;
          }
        }
        if (this.customer.auth == "" || this.customer.auth == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຜູ້ໃຊ້ລະບົບ</div>`
          );
          return;
        }
        for (const key in this.villages) {
          const el = this.villages[key];
          if (el.name == this.customer.villageName) {
            this.customer.village = el._id;
          }
        }
        if (this.customer.village == "" || this.customer.village == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກບ້ານໃຫ້ຖຶກຕ້ອງ</div>`
          );
          return;
        }

        if (this.customer.image == "" || this.customer.image == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຮູບພາບ</div>`
          );
          return;
        }
        let formData = new FormData();
        formData.append("firstName", this.customer.firstName);
        formData.append("lastName", this.customer.lastName);
        formData.append("village", this.customer.village);
        formData.append("lat", this.customer.lat);
        formData.append("lng", this.customer.lng);
        formData.append("phoneNumber", this.customer.phoneNumber);
        formData.append("email", this.customer.email);
        formData.append("auth", this.customer.auth);
        formData.append("image", this.customer.image);
        this.updateCustomer({ id: this.customer._id, item: formData })
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
              this.showToast(
                "success",
                `<div style='font-family:"Noto Sans Lao";' >ແກ້ໄຂຂໍ້ມູນສໍາເລັດ</div>`
              );
              this.clear();
              this.back();
            } else {
              this.showToast(
                "error",
                `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "error",
              `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
            );
            console.log(error);
          });
      } catch (error) {
        console.log(error);
        this.showToast(
          "error",
          `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
        );
      }
    },
    clear() {
      this.$v.$reset();
      this.getCustomer(this.$route.params.id);
    },
    back() {
      this.$router.back();
    },
    confirm() {
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.font {
  font-family: "NotoSansLao";
}

.file-upload {
  margin: 7px 0px;
  font-size: 16px;
  width: 100%;
  background: white;
  border-bottom: 1px solid #999;
}
.file-upload::before {
  background: #eeeeee;
  content: "ເລືອກຮູບພາບ";
  border-radius: 0px 50px 50px 0px;
  padding: 10px;
  width: 180px;
  border: 1px solid #edffdd;
}
.file-upload:hover {
  border-bottom: 1px solid #555;
}
.file-upload:hover::before {
  background: #dedede;
}
::-webkit-file-upload-button {
  background: #eeeeee;
  visibility: hidden;
  border-radius: 0px 50px 50px 0px;
  padding: 7px;
  width: 1px;
  border: 1px solid #edffdd;
}
::-webkit-file-upload-button:hover {
  background: #dedede;
}
</style>
