import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";
import { apiURL, header } from "../../config/constant";

Vue.use(Vuex);

const stockModule = {
  namespaced: true,
  state: {
    stocks: [],
    stock: [],
    stockRaw: [],
    length: true
  },
  mutations: {
    setStocks(state, stock) {
      state.stocks = stock;
    },
    setStock(state, stock) {
      state.stock = stock;
    },
    setLength(state, stock) {
      state.length = stock;
    },
  },

  actions: {
    getStocks(context) {
      try {
        axios.get(apiURL + `stocks`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var stocklist = [];
          for (const idx in res.data) {
            const el = res.data[idx];
            var stock = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              rawData: (el.raw == null || el.raw._id == undefined) ? null : el.raw._id,
              rawName: (el.raw == null || el.raw.rawName == undefined) ? null : el.raw.rawName,
              rawQty: (el.raw == null || el.raw.qty == undefined) ? null : el.raw.qty,
              unit: (el.unit == null || el.unit._id == undefined) ? null : el.unit._id,
              unitName: (el.unit == null || el.unit.name == undefined) ? null : el.unit.name,
              barCodeNumber: (el.barCodeNumber == null || el.barCodeNumber == undefined) ? null : el.barCodeNumber,
              supplier: (el.supplier == null || el.supplier._id == undefined) ? null : el.supplier._id,
              supplierName: (el.supplier == null || el.supplier.supplierName == undefined) ? null : el.supplier.supplierName,
              qty: el.qty,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
              date: el.updatedAt
            };
            stocklist.push(stock);
          }
          context.commit("setStocks", stocklist);
          context.commit("setLength", true);
        }).catch(function (error) {
          context.commit("setLength", false);
          console.log(error)
        });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
    getStock(context, id) {
      try {
        axios.get(apiURL + `stocks/${id}`, { headers: header }).then((res) => {
          if (res.data.error != undefined) return alert("ບ");
          const el = res.data;
          var stock = {
            _id: el._id,
            raw: (el.raw == null || el.raw._id == undefined) ? null : el.raw._id,
            rawName: (el.raw == null || el.raw.rawName == undefined) ? null : el.raw.rawName,
            unit: (el.unit == null || el.unit._id == undefined) ? null : el.unit._id,
            unitName: (el.unit == null || el.unit.name == undefined) ? null : el.unit.name,
            barCodeNumber: (el.barCodeNumber == null || el.barCodeNumber == undefined) ? null : el.barCodeNumber,
            supplier: (el.supplier == null || el.supplier._id == undefined) ? null : el.supplier._id,
            supplierName: (el.supplier == null || el.supplier.supplierName == undefined) ? null : el.supplier.supplierName,
            qty: el.qty,
            createdAt:
              moment(String(el.createdAt)).format("DD-MM-YYYY") +
              " " +
              el.createdAt.slice(11, el.createdAt.length - 5),
            updatedAt:
              moment(String(el.updatedAt)).format("DD-MM-YYYY") +
              " " +
              el.updatedAt.slice(11, el.updatedAt.length - 5)
          };
          context.commit("setStock", stock);
        })
          .catch(function (error) {
            if (error.message == 'Network Error') {
              console.log("ບໍ່ມີອີນເທີເນັດ")
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    getStockRawID(context, id) {
      try {
        return axios.get(apiURL + `stocks/rawId/${id}`, { headers: header })

      } catch (error) {
        console.log(error);
      }
    },

    delStock(context, item) {
      try {
        return axios
          .delete(apiURL + `stocks/${item._id}`, { headers: header })
      } catch (e) {
        console.log(e);
      }
    },
    insertStock(context, item) {
      try {
        return axios
          .post(apiURL + `stocks`, item, { headers: header })
      } catch (e) {
        console.log(e);
      }
    },
    updateStock(context, item) {
      try {
        return axios
          .put(apiURL + `stocks/${item._id}`, item, { headers: header })
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    stocks: (state) => state.stocks,
    stock: (state) => state.stock,
    stockRaw: (state) => state.stockRaw,
    length: (state) => state.length,
  },
};

export default stockModule;
