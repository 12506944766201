<template>
  <v-app class="font">
    <v-card flat>
      <v-row no-gutters class="justify-center my-2 my-sm-7 my-lg-16">
        <v-col cols="12" md="3" lg="2" class="hidden-sm-and-down">
          <v-card
            tile
            color="#f0f0f0f0"
            height="350"
            class="evelation pa-2 pt-7"
          >
            <v-img :src="require('@/assets/onewater.png')" contain height="250">
            </v-img>
            <!-- <v-row no-gutters class="justify-center ma-2"
              ><span style="font-size:1.5rem">ຍິນດີເຂົ້າໃຊ້ລະບົບ</span></v-row
            > -->
          </v-card>
        </v-col>
        <v-col cols="12" class="hidden-md-and-up">
          <v-row class="justify-center my-5">
            <v-avatar color="#f0f0f0f0" size="300" class="pa-1">
              <v-img
                class="ma-1"
                height="270"
                :src="require('@/assets/onewater.png')"
                contain
              >
              </v-img>
            </v-avatar>
          </v-row>
        </v-col>
        <v-col cols="12" md="5" lg="4" class="px-sm-10 px-5 px-md-0">
          <v-card
            :tile="!$vuetify.breakpoint.smOnly"
            color="#f0f0f0f0"
            height="350"
            elevation="2"
            :class="
              $vuetify.breakpoint.width < 960 ? `rounded-xl` : `rounded-0 px-3`
            "
          >
            <v-row no-gutters class="justify-center mx-2"
              ><span style="font-size: 1.5rem" class="mt-12"
                >ຍິນດີຕ້ອນຮັບເຂົ້າສູ່ລະບົບ</span
              ></v-row
            >
            <v-row no-gutters class="justify-center mx-2">
              <v-card class="pa-2 mt-2" flat width="100%" color="transparent">
                <v-text-field
                  v-model="person.userName"
                  prepend-icon="mdi-account-search"
                  label="ຜູ້ໃຊ້"
                  :rules="[rules0.required]"
                ></v-text-field>
                <v-text-field
                  v-model="person.password"
                  prepend-icon="mdi-lock-question"
                  label="ລະຫັດຜ່ານ"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show ? 'text' : 'password'"
                  class="input-group--focused"
                  @click:append="show = !show"
                ></v-text-field>
                <div class="text-center text-md-end mt-10">
                  <v-btn large color="light-blue lighten-1" @click="logIn">
                    <span style="font-size: 1.2rem; color: white"
                      >ເຂົ້າສູ່ລະບົບ</span
                    >
                  </v-btn>
                </div>
              </v-card>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" :timeout="timeout" :color="color">
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            ປິດ
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-app>
</template>
<script>
import { mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      person: {
        userName: "",
        password: "",
        remember: false,
        rules0: {
          required: (value) => !!value || "ກະລຸນາຊື່ຜູ້ໃຊ້!",
        },
        rules: {
          required: (value) => !!value || "ກະລຸນາປ້ອນລະຫັດຜ່ານ!",
          min: (v) => v.length >= 6 || "ຢ່າງໜ້ວຍ 6 ຕົວອັກສອນ",
        },
      },
      show: false,
      snackbar: false,
      text: "",
      timeout: 2000,
      color: "",
      rules0: {
        required: (value) => !!value || "ກະລຸນາຊື່ຜູ້ໃຊ້!",
      },
      rules: {
        required: (value) => !!value || "ກະລຸນາປ້ອນລະຫັດຜ່ານ!",
        min: (v) => v.length >= 6 || "ຢ່າງໜ້ວຍ 6 ຕົວອັກສອນ",
      },
    };
  },
  computed: {},
  methods: {
    ...mapMutations({
      setToken: "authMod/setToken",
    }),
    ...mapActions("authMod", ["signIn"]),

    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },
    logIn() {
      try {
        if (this.person.userName == "") {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາໃສ່ຊື່ຜູ້ໃຊ້</div>`
          );
          return;
        } else if (this.person.password == "") {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາໃສ່ລະຫັດຜ່ານ</div>`
          );
          return;
        }
        this.signIn(this.person)
          .then((res) => {
            if (res.data.token) {
              window.localStorage.removeItem("token");
              this.setToken(res.data.token);
              window.localStorage.setItem(
                "user",
                JSON.stringify(res.data.user)
              );
              window.localStorage.setItem("token", res.data.token);
              var date = new Date().getTime();
              window.localStorage.setItem("date", date);
              console.log(window.localStorage.getItem("token"));
              if (res.data.user.role == "ADMIN") {
                this.$router.push("/admin");
                this.$router.go();
                
              } else {
                this.$router.push("/staff");
                this.$router.go();

              }
              this.showToast(
                "success",
                `<div style='font-family:"Noto Sans Lao";' >ເຂົ້າລະບົບສໍາເລັດແລ້ວ</div>`
              );
            } else {
              this.showToast(
                "warning",
                `<div style='font-family:"Noto Sans Lao";color:red' >ຊື່ຜູ້ໃຊ້ ແລະ ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ</div>`
              );
            }
            if (res.data.message == "INVALID_USER_NAME_OR_PASSWORD") {
              this.showToast(
                "warning",
                `<div style='font-family:"Noto Sans Lao";color:red' >ຊື່ຜູ້ໃຊ້ ແລະ ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ</div>`
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "warning",
              `<div style='font-family:"Noto Sans Lao";color:red' >ຊື່ຜູ້ໃຊ້ ແລະ ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ</div>`
            );
            console.log(error);
          });
      } catch (error) {
        this.showToast(
          "warning",
          `<div style='font-family:"Noto Sans Lao";color:red' >ຊື່ຜູ້ໃຊ້ ແລະ ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ</div>`
        );
        console.log(error);
      }
    },
  },
};
</script>