<template>
  <div>
    <div v-if="loading">
      <v-row no-gutters class="justify-center pb-10 mt-16 pt-16">
        <SpinnerLoader :color="'#90CAF9'" :loading="true" :size="200" />
      </v-row>
    </div>
    <div  v-else-if="length == false">
      <v-row no-gutters class="ma-2 justify-center">
        <p style="font-size: 2rem">ລາຍງານຂໍ້ມູນຜະລິດຕະພັນ</p>
      </v-row>
      <v-row no-gutters class="ma-2">
        <v-col cols="12" md="3">
          <v-card height="270" class="mx-1">
            <v-card-title>
              <span>ລາຍການຜະລິດຕະພັນທັງໝົດ</span>
            </v-card-title>
            <v-row class="justify-center">
              <v-progress-circular
                :rotate="270"
                :size="200"
                :width="5"
                :value="100"
                color="#3F51B5"
              >
                <span
                  :style="{
                    color: 'blue',
                    fontSize: products.length > 100000 ? '1rem' : '3rem',
                  }"
                  >{{
                    String(parseInt(products.length)).replace(
                      /(.)(?=(\d{3})+$)/g,
                      "$1,"
                    )
                  }}</span
                >
              </v-progress-circular>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="9">
          <v-card height="270" class="mx-1">
            <v-card-title class="justify-space-between">
              <span>ຜະລິດຕະພັນທີ່ຍັງຫຼາຍ</span>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mt-5 tcenter"
                  label="ຈໍານວນຊາທີ່ຕ້ອງການ"
                  outlined
                  dense
                  rounded
                  prepend-inner-icon="mdi-minus"
                  append-icon="mdi-plus"
                  autofocus
                  v-model="count"
                  @click:append="count = parseInt(count) + 1"
                  @click:prepend-inner="
                    count = parseInt(count) < 1 ? 0 : parseInt(count) - 1
                  "
                  @keypress="InputCount($event)"
                >
                  <template slot="append">
                    <v-btn
                      style="margin-top: -7px; margin-right: -20px"
                      color="#004D40"
                      icon
                      @click="count = parseInt(count) + 1"
                    >
                      <v-icon large>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <template slot="prepend-inner">
                    <v-btn
                      style="margin-top: -7px; margin-left: -20px"
                      color="#004D40"
                      icon
                      @click="
                        count = parseInt(count) < 1 ? 0 : parseInt(count) - 1
                      "
                    >
                      <v-icon large>mdi-minus</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-toolbar>
              <riamExcel
                class="btn btn-default"
                :fetch="fetchDataTop"
                :fields="json_fields"
                worksheet="ຜະລິດຕະພັນທີ່ຍັງຫຼາຍ"
                :name="excelTitle"
                :before-finish="finishDownloadTop"
              >
                <v-btn
                  class="mr-2 indigo"
                  :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
                >
                  <span style="color: white">ດືງຂໍ້ມູນ</span>
                </v-btn>
              </riamExcel>
            </v-card-title>
            <riamchart
              type="bar"
              height="200"
              :options="topProduct.chartOptions"
              :series="topProduct.series"
            ></riamchart>
          </v-card>
        </v-col>
      </v-row>
      <v-card class="mx-2">
        <v-card-title class="justify-space-between">
          <span>ຜະລິດຕະພັນໃນສະຕ໋ອກທັງໝົດ</span>
          <riamExcel
            class="btn btn-default"
            :fetch="fetchData"
            :fields="json_fields"
            worksheet="ຜະລິດຕະພັນ"
            :name="productTitle"
            :before-finish="finishDownload"
          >
            <v-btn
              class="mr-2 indigo"
              :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
            >
              <span style="color: white">ດືງຂໍ້ມູນ</span>
            </v-btn>
          </riamExcel>
        </v-card-title>

        <div>
          <v-skeleton-loader
          class=""
          type="list-item-three-line"
        ></v-skeleton-loader>
        </div>
      </v-card>
    </div>
    <div v-else>
      <v-row no-gutters class="ma-2 justify-center">
        <p style="font-size: 2rem">ລາຍງານຂໍ້ມູນຜະລິດຕະພັນ</p>
      </v-row>
      <v-row no-gutters class="ma-2">
        <v-col cols="12" md="3">
          <v-card height="270" class="mx-1">
            <v-card-title>
              <span>ລາຍການຜະລິດຕະພັນທັງໝົດ</span>
            </v-card-title>
            <v-row class="justify-center">
              <v-progress-circular
                :rotate="270"
                :size="200"
                :width="5"
                :value="100"
                color="#3F51B5"
              >
                <span
                  :style="{
                    color: 'blue',
                    fontSize: products.length > 100000 ? '1rem' : '3rem',
                  }"
                  >{{
                    String(parseInt(products.length)).replace(
                      /(.)(?=(\d{3})+$)/g,
                      "$1,"
                    )
                  }}</span
                >
              </v-progress-circular>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="9">
          <v-card height="270" class="mx-1">
            <v-card-title class="justify-space-between">
              <span>ຜະລິດຕະພັນທີ່ຍັງຫຼາຍ</span>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mt-5 tcenter"
                  label="ຈໍານວນຊາທີ່ຕ້ອງການ"
                  outlined
                  dense
                  rounded
                  prepend-inner-icon="mdi-minus"
                  append-icon="mdi-plus"
                  autofocus
                  v-model="count"
                  @click:append="count = parseInt(count) + 1"
                  @click:prepend-inner="
                    count = parseInt(count) < 1 ? 0 : parseInt(count) - 1
                  "
                  @keypress="InputCount($event)"
                >
                  <template slot="append">
                    <v-btn
                      style="margin-top: -7px; margin-right: -20px"
                      color="#004D40"
                      icon
                      @click="count = parseInt(count) + 1"
                    >
                      <v-icon large>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <template slot="prepend-inner">
                    <v-btn
                      style="margin-top: -7px; margin-left: -20px"
                      color="#004D40"
                      icon
                      @click="
                        count = parseInt(count) < 1 ? 0 : parseInt(count) - 1
                      "
                    >
                      <v-icon large>mdi-minus</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-toolbar>
              <riamExcel
                class="btn btn-default"
                :fetch="fetchDataTop"
                :fields="json_fields"
                worksheet="ຜະລິດຕະພັນທີ່ຍັງຫຼາຍ"
                :name="excelTitle"
                :before-finish="finishDownloadTop"
              >
                <v-btn
                  class="mr-2 indigo"
                  :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
                >
                  <span style="color: white">ດືງຂໍ້ມູນ</span>
                </v-btn>
              </riamExcel>
            </v-card-title>
            <riamchart
              type="bar"
              height="200"
              :options="topProduct.chartOptions"
              :series="topProduct.series"
            ></riamchart>
          </v-card>
        </v-col>
      </v-row>
      <v-card class="mx-2">
        <v-card-title class="justify-space-between">
          <span>ຜະລິດຕະພັນໃນສະຕ໋ອກທັງໝົດ</span>
          <riamExcel
            class="btn btn-default"
            :fetch="fetchData"
            :fields="json_fields"
            worksheet="ຜະລິດຕະພັນ"
            :name="productTitle"
            :before-finish="finishDownload"
          >
            <v-btn
              class="mr-2 indigo"
              :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
            >
              <span style="color: white">ດືງຂໍ້ມູນ</span>
            </v-btn>
          </riamExcel>
        </v-card-title>

        <div>
          <riamchart
            type="bar"
            :height="100 * production.series[0].data.length"
            :options="production.chartOptions"
            :series="production.series"
          ></riamchart>
        </div>
      </v-card>
    </div>
  </div>
</template><script>
import { mapGetters, mapActions } from "vuex";
import VueApexCharts from "vue-apexcharts";
import JsonExcel from "vue-json-excel";
import { SpinnerLoader } from "vue-spinners-css";
export default {
  data: () => ({
    loading: true,
    count: 3,
    excelTitle:
      "Top-product-" +
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10) +
      ".xls",
    productTitle:
      "Products-" +
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10) +
      ".xls",
    date1: new Date(
      new Date().getFullYear() +
        "-" +
        (parseInt(new Date().getMonth()) - 2).toString() +
        "-" +
        (parseInt(new Date().getDay()) + 4).toString()
    )
      .toISOString()
      .substr(0, 10),
    menu1: false,
    date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu2: false,
    maxDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    top_json_fields: {
      ລໍາດັບ: "idx",
      ຜະລິດຕະພັນ: "productName",
      ປະເພດ: "category",
      ຈໍານວນ: "qty",
      ລາຄາຂາຍຍ່ອຍ: "retail",
      ລາຄາຂາຍສົ່ງ: "wholeSale",
      ໂປຣໂມຊັນ: "promotionPrice",
      ລາຍລະອຽດ: "description",
    },
    json_fields: {
      ລໍາດັບ: "idx",
      ຜະລິດຕະພັນ: "productName",
      ປະເພດ: "category",
      ຈໍານວນ: "qty",
      ລາຄາຂາຍຍ່ອຍ: "retail",
      ລາຄາຂາຍສົ່ງ: "wholeSale",
      ໂປຣໂມຊັນ: "promotionPrice",
      ລາຍລະອຽດ: "description",
    },
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
  }),
  components: {
    riamchart: VueApexCharts,
    riamExcel: JsonExcel,
    SpinnerLoader,
  },
  computed: {
    ...mapGetters({
      products: "productMod/products",
      length: "productMod/length",
      topProduct: "reportMod/topProduct",
      production: "reportMod/production",
    }),
  },
  watch: {
     length(val) {
      if (val==false) {
        this.loading = false;
      }
    }, 
    products(val) {
      if (val.length > 0) {
        this.loading = false;
      }
    },
    production(val) {
      if (val.length > 0) {
        this.loading = false;
      }
    },

    topProduct(val) {
      if (val.length > 0) {
        this.loading = false;
      }
    },
    count(val) {
      if (val == null || val == "" || val == undefined) {
        this.getTopProduct(0);
      } else if (parseInt(val) > 0 && parseInt(val) <= this.products.length) {
        this.getTopProduct(val);
      } else if (parseInt(val) > this.products.length) {
        console.log(val);
      } else {
        this.getTopProduct(0);
      }
    },
  },
  mounted() {
    this.getTopProduct(this.count);
    this.getProduction();
    this.getProducts();
    this.Loading();
  },
  methods: {
    ...mapActions("reportMod", ["getTopProduct", "getProduction"]),
    ...mapActions("productMod", ["getProducts"]),
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    InputCount(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        this.showToast(
          "warning",
          `<div style='font-family:"Noto Sans Lao";color:red' >ປ້ອນສະເພາະຕົວເທົ່ານັ້ນ (0-9)</div>`
        );
        evt.preventDefault();
      } else {
        if (
          this.count == "" ||
          this.count == null ||
          this.count < 1 ||
          this.count == undefined
        ) {
          this.count = null;
        }
        return true;
      }
    },
    Loading() {
      if (this.length == false) {
        this.loading = false;
      }
    },
    fetchDataTop() {
      try {
        if (this.products.length < 1) {
          return;
        }
        var obj = this.products.sort((a, b) =>
          a.qty > b.qty ? -1 : b.qty > a.qty ? 1 : 0
        );
        if (obj.length < this.count) {
          this.count = obj.length;
        }
        var prolist = [];
        var idx = 0;
        for (idx = 0; idx < this.count; idx++) {
          const el = obj[idx];
          var Product = {
            idx: parseInt(idx) + 1,
            productName: el.productName,
            category: el.category,
            wholeSale: el.wholeSale,
            retail: el.retail,
            promotionPrice: el.promotionPrice,
            qty: el.qty,
            description: el.description,
            image: el.image == null || el.image == undefined ? null : el.image,
          };
          prolist.push(Product);
        }
        return prolist;
      } catch (error) {
        console.log(error);
      }
    },
    fetchData() {
      try {
        if (this.products.length < 1) {
          return;
        }
        var obj = this.products.sort((a, b) =>
          a.qty > b.qty ? 1 : b.qty > a.qty ? -1 : 0
        );
        var prolist = [];
        for (const idx in obj) {
          const el = obj[idx];
          prolist.push(el);
        }
        return prolist;
      } catch (error) {
        console.log(error);
      }
    },
    finishDownload() {
      this.showToast(
        "success",
        `<div style='font-family:"Noto Sans Lao";' >ດາວໂລດສໍາເລັດແລ້ວ</div>`
      );
    },
    finishDownloadTop() {
      this.showToast(
        "success",
        `<div style='font-family:"Noto Sans Lao";' >ດາວໂລດສໍາເລັດແລ້ວ</div>`
      );
    },
  },
};
</script>
<style scoped>
.tcenter {
  width: 200px;
  min-width: 100px;
}
.tcenter >>> input {
  text-align: center;
}
</style>