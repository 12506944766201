import Vue from 'vue'
import Vuex from 'vuex'
import authModule from './auth'
import userModule from './admin/user'
import customerModule from './admin/customer'
import supplierModule from './admin/supplier'
import addressModule from './admin/address'
import categoryModule from './admin/category'
import unitModule from './admin/unit'
import bannerModule from './admin/banner'
import eventModule from './admin/event'
import productModule from './admin/product'
import rawdataModule from './admin/rawdata'
import rawModule from './admin/raw'
import warehouseModule from './admin/warehouse'
import orderModule from './admin/order'
import expenseModule from './admin/expense'
import stockModule from './admin/stock'
import reportModule from './admin/reports'
import proClientModule from './client/product'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    authMod: authModule,
    userMod: userModule,
    customerMod: customerModule,
    supplierMod: supplierModule,
    categoryMod: categoryModule,
    unitMod: unitModule,
    productMod: productModule,
    rawdataMod: rawdataModule,
    rawMod: rawModule,
    warehouseMod: warehouseModule,
    addressMod: addressModule,
    bannerMod: bannerModule,
    eventMod: eventModule,
    orderMod: orderModule,
    expenseMod: expenseModule,
    stockMod: stockModule,
    reportMod: reportModule,
    proCMod: proClientModule,
  }
})

