<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
              <h1 style="margin-top: 30px">ເເກ້ໄຂຂໍ້ມູນຜູ້ໃຊ້</h1>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  <v-text-field
                    v-model="user.userName"
                    :error-messages="userNameErrors"
                    label="ຊື່ຜູ້ໃຊ້"
                    required
                    @input="$v.user.userName.$touch()"
                    @blur="$v.user.userName.$touch()"
                  ></v-text-field>

                  <v-text-field
                    v-model="user.password"
                    :error-messages="passwordErrors"
                    label="ລະຫັດຜ່ານ"
                    required
                    @input="$v.user.password.$touch()"
                    @blur="$v.user.password.$touch()"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    class="input-group--focused"
                    @click:append="show = !show"
                  ></v-text-field>

                  <v-radio-group
                    v-model="user.role"
                    row
                    label="ຕໍາແໜ່ງ"
                    required
                    :error-messages="roleErrors"
                    @change="$v.user.role.$touch()"
                    @blur="$v.user.role.$touch()"
                  >
                    <v-row class="justify-space-around">
                      <v-radio label="ADMIN" value="ADMIN"></v-radio>
                      <v-radio label="STAFF" value="STAFF"></v-radio>
                      <v-radio label="USER" value="USER"></v-radio>
                    </v-row>
                  </v-radio-group>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class></v-divider>
            <v-row
              class="ma-5"
              :justify="$vuetify.breakpoint.width < 600 ? 'center' : 'start'"
            >
              <v-btn
                @click="back"
                class="ma-2 orange white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
              </v-btn>
              <v-spacer class="d-none d-sm-flex"></v-spacer>
              <v-btn
                @click="clear"
                class="ma-2 red white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-close-circle</v-icon>ຍົກເລິກ
              </v-btn>

              <v-btn
                @click="submit"
                class="ma-2 green white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-check-circle</v-icon>ບັນທຶກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    user: {
      userName: { required, maxLength: maxLength(100) },
      password: { required, maxLength: maxLength(100) },
      role: { required },
    },
  },
  data: () => ({
  }),

  computed: {
    ...mapGetters({
      user: "userMod/user",
    }),
    userNameErrors() {
      const errors = [];
      if (!this.$v.user.userName.$dirty) return errors;
      !this.$v.user.userName.maxLength && errors.push("ຊືຜູ້ໃຊ້ຍາວເກີນ!");
      !this.$v.user.userName.required &&
        errors.push("ກະລຸນາປ້ອນຊື່ຜູ້ໃຊ້ລະບົບ.");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.user.password.$dirty) return errors;
      !this.$v.user.password.maxLength && errors.push("ລະຫັດຜ່ານຍາວເກີນ!");
      !this.$v.user.password.required && errors.push("ກະລຸນາປ້ອນລະຫັດຜ່ານ!");
      return errors;
    },
    roleErrors() {
      const errors = [];
      if (!this.$v.user.role.$dirty) return errors;
      !this.$v.user.role.required &&
        errors.push("ກະລຸນາກໍານົດຕໍາແໜ່ງຜູ້ໃຊ້ລະບົບ.");
      return errors;
    },
  },
  mounted() {
    this.getUser(this.$route.params.id);
  },
  methods: {
    ...mapActions("userMod", ["getUser", "updateUser"]),

    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },
    async submit() {
      this.$v.$touch();
      try {
       
        if (this.user.userName == "" || this.user.userName == null) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນຊື່ຜູ້ໃຊ້ລະບົບ</div>`,)
          this.dialogTitle = "!";
          this.dialog = true;
          return;
        }
        if (this.user.password == "" || this.user.password == null) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນລະຫັດຜ່ານຜູ້ໃຊ້ລະບົບ</div>`,)
          return;
        }
        if (this.user.role == "" || this.user.role == null) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຕໍາແໜ່ງຜູ້ໃຊ້ລະບົບ</div>`,)
          return;
        }

        this.updateUser(this.user)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
                this.showToast("success",`<div style='font-family:"Noto Sans Lao";' >ແກ້ໄຂຂໍ້ມູນສໍາເລັດ</div>`,)
              this.clear();
              this.back()
            } else {
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            }
          })
          .catch( (error) =>{
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            console.log(error);
          });
      } catch (error) {
        console.log(error);
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
      }
    },
    clear() {
      this.$v.$reset();
      this.getUser(this.$route.params.id);
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped></style>
