<template>
  <div>
    <div>
      <v-row justify="center">
        <v-col cols="12" md="11">
          <form @submit.prevent="submit">
            <v-card
              elevation="10"
              style="margin-top: 2px; padding-bottom: 10px"
            >
              <v-row no-gutters class="justify-center">
                <p style="font-size: 2rem; margin-top: 30px">ເພີ່ມສະຕ໋ອກວັດຖຸດິບ</p>
              </v-row>
              <v-row no-gutters class="ma-5 justify-space-around">
                <v-col cols="12" md="9">
                  <v-card class="mr-4 elevation-0">
                    <v-text-field
                      outlined
                      v-model="stock.barCodeNumber"
                      class="mt-3"
                      label="ລະຫັດບາໂຄດ"
                      @keyup="selectByBarCode"
                    ></v-text-field>
                    <v-autocomplete
                      clearable
                      item-color="blue darken-1"
                      no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                      label="ຊື່ວັດຖຸດິບ"
                      v-model="stock.rawName"
                      :items="rawName"
                      :error-messages="rawNameErrors"
                      required
                      @input="$v.stock.rawName.$touch()"
                      @blur="$v.stock.rawName.$touch()"
                      @change="SelectRawName"
                    ></v-autocomplete>
                    <v-text-field
                      v-model="stock.qty"
                      label="ຈໍານວນ"
                      :error-messages="qtyErrors"
                      required
                      @input="$v.stock.qty.$touch()"
                      @blur="$v.stock.qty.$touch()"
                      @keypress="number($event)"
                    ></v-text-field>
                    <v-autocomplete
                      clearable
                      item-color="blue darken-1"
                      no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                      label="ຫົວໜ່ອຍ"
                      v-model="stock.unitName"
                      :items="unitName"
                      :error-messages="unitNameErrors"
                      required
                      @input="$v.stock.unitName.$touch()"
                      @blur="$v.stock.unitName.$touch()"
                    ></v-autocomplete>
                    <v-autocomplete
                      clearable
                      item-color="blue darken-1"
                      no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                      label="ຜູ້ສະໜອງ"
                      v-model="stock.supplierName"
                      :items="supplierName"
                      :error-messages="supplierNameErrors"
                      required
                      @input="$v.stock.supplierName.$touch()"
                      @blur="$v.stock.supplierName.$touch()"
                    ></v-autocomplete>
                  </v-card>
                </v-col>
              </v-row>
              <v-divider class></v-divider>
              <v-row
                class="ma-5"
                :justify="$vuetify.breakpoint.width < 600 ? 'center' : 'start'"
              >
                <v-btn
                  @click="back"
                  class="ma-2 orange white--text"
                  :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
                >
                  <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
                </v-btn>
                <v-spacer class="d-none d-sm-flex"></v-spacer>
                <v-btn
                  @click="clear"
                  class="ma-2 red white--text"
                  :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
                >
                  <v-icon left>mdi-close-circle</v-icon>ຍົກເລິກ
                </v-btn>

                <v-btn
                  @click="submit"
                  class="ma-2 green white--text"
                  :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
                >
                  <v-icon left>mdi-check-circle</v-icon>ບັນທຶກ
                </v-btn>
              </v-row>
            </v-card>
          </form>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    stock: {
      rawName: { required, maxLength: maxLength(100) },
      qty: { required, maxLength: maxLength(100) },
      unitName: { required, maxLength: maxLength(100) },
      supplierName: { required, maxLength: maxLength(100) },
    },
  },
  data() {
    return {
      stock: {
        raw: null,
        barCodeNumber: null,
        rawName: null,
        qty: 1,
        unit: null,
        unitName: null,
        supplierName: null,
        supplier: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      raws: "rawMod/raws",
      rawName: "rawMod/rawName",
      unit: "unitMod/unit",
      unitName: "unitMod/unitName",
      suppliers: "supplierMod/suppliers",
      supplierName: "supplierMod/supplierName",
    }),

    rawNameErrors() {
      const errors = [];
      if (!this.$v.stock.rawName.$dirty) return errors;
      !this.$v.stock.rawName.maxLength && errors.push("ກະລຸນາເລືອກຊື່ວັດຖຸດິບ");
      !this.$v.stock.rawName.required && errors.push("ກະລຸນາເລືອກຊື່ວັດຖຸດິບ");
      return errors;
    },

    qtyErrors() {
      const errors = [];
      if (!this.$v.stock.qty.$dirty) return errors;
      !this.$v.stock.qty.maxLength && errors.push("ກະລຸນາປ້ອນຈໍານວນ");
      !this.$v.stock.qty.required && errors.push("ກະລຸນາປ້ອນຈໍານວນ");
      return errors;
    },

    unitNameErrors() {
      const errors = [];
      if (!this.$v.stock.unitName.$dirty) return errors;
      !this.$v.stock.unitName.maxLength && errors.push("ກະລຸນາເລືອກຫົວໜ່ວຍ");
      !this.$v.stock.unitName.required && errors.push("ກະລຸນາເລືອກຫົວໜ່ວຍ");
      return errors;
    },
    supplierNameErrors() {
      const errors = [];
      if (!this.$v.stock.supplierName.$dirty) return errors;
      !this.$v.stock.supplierName.maxLength &&
        errors.push("ກະລຸນາເລືອກຜູ້ສະໜອງ");
      !this.$v.stock.supplierName.required &&
        errors.push("ກະລຸນາເລືອກຜູ້ສະໜອງ");
      return errors;
    },
  },
  watch: {},

  mounted() {
    this.init();
    this.getRaws();
    this.getUnit();
    this.getSuppliers();
  },
  created() {},
  methods: {
    ...mapActions("stockMod", ["insertStock"]),
    ...mapActions("rawMod", ["getRaws"]),
    ...mapActions("unitMod", ["getUnit"]),
    ...mapActions("supplierMod", ["getSuppliers"]),

    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    number(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        this.showToast(
          "warning",
          `<div style='font-family:"Noto Sans Lao";color:red' >ປ້ອນສະເພາະຕົວເທົ່ານັ້ນ (0-9)</div>`
        );
        evt.preventDefault();
      } else {
        return true;
      }
    },
    init() {
      this.stock.barCodeNumber =
        this.$route.query.barCodeNumber == null ||
        this.$route.query.barCodeNumber == undefined
          ? null
          : this.$route.query.barCodeNumber;
      if (
        this.stock.barCodeNumber == null ||
        this.stock.barCodeNumber == undefined
      ) {
        return;
      }
      this.selectByBarCode();
    },
    selectByBarCode() {
      if (
        this.stock.barCodeNumber == null ||
        this.stock.barCodeNumber == undefined
      ) {
        return;
      }
      var name = null;
      for (const idx in this.raws) {
        const el = this.raws[idx];
        if (this.stock.barCodeNumber == el.barCodeNumber) {
          name = el.rawName;
        }
      }
      this.stock.rawName = name;
    },

    SelectRawName() {
      if (this.stock.rawName == null || this.stock.rawName == undefined) {
        return;
      }

      var barcode = null;
      for (const idx in this.raws) {
        const el = this.raws[idx];
        if (this.stock.rawName == el.rawName) {
          barcode =
            el.barCodeNumber == null || el.barCodeNumber == undefined
              ? null
              : el.barCodeNumber;
        }
      }
      this.stock.barCodeNumber = barcode;
    },
    submit() {
      this.$v.$touch();
      try {
        if (this.stock.rawName == "" || this.stock.rawName == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຊື່ວັດຖຸດິບ</div>`
          );
          return;
        }
        for (const key in this.raws) {
          const el = this.raws[key];
          if (el.rawName == this.stock.rawName) {
            this.stock.raw = el._id;
          }
        }
        if (this.stock.qty == "" || this.stock.qty == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນຈໍານວນ</div>`
          );
          return;
        }
        if (this.stock.unitName == null || this.stock.unitName == "") {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກວັດຖຸດິບ</div>`
          );
          return;
        }
        for (const key in this.unit) {
          const el = this.unit[key];
          if (el.name == this.stock.unitName) {
            this.stock.unit = el._id;
          }
        }
        if (this.stock.unit == null || this.stock.unit == "") {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກວັດຖຸດິບໃຫ້ຖືກຕ້ອງ</div>`
          );
          return;
        }
        if (this.stock.supplierName == null || this.stock.supplierName == "") {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຜູ້ສະໜອງ</div>`
          );
          return;
        }
        for (const key in this.suppliers) {
          const el = this.suppliers[key];
          if (el.supplierName == this.stock.supplierName) {
            this.stock.supplier = el._id;
          }
        }
        if (this.stock.supplier == null || this.stock.supplier == "") {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຜູ້ສະໜອງໃຫ້ຖືກຕ້ອງ</div>`
          );
          return;
        }
        this.insertStock(this.stock)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
              this.showToast(
                "success",
                `<div style='font-family:"Noto Sans Lao";' >ບັນທຶກຂໍ້ມູນສໍາເລັດແລ້ວ</div>`
              );
              this.routebarCodeNumber();
              this.clear();
            } else {
              this.showToast(
                "error",
                `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "error",
              `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
            );
            console.log(error);
          });
      } catch (error) {
        console.log(error);
        this.showToast(
          "error",
          `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
        );
      }
    },
    routebarCodeNumber() {
      if (
        this.stock.barCodeNumber != null &&
        this.stock.barCodeNumber != undefined
      ) {
        let routeData = this.$router.resolve({
          path: `/admin/raw/barcode/${this.stock.barCodeNumber}`,
          query: { qty: this.stock.qty },
        });
        window.open(routeData.href, "_blank");
      }
    },
    clear() {
      this.$v.$reset();
      this.stock = {
        raw: null,
        barCodeNumber: null,
        rawName: null,
        qty: 1,
        unit: null,
        unitName: null,
        supplierName: null,
        supplier: null,
      };
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
