<template>
  <div>
    <div>
      <v-card>
        <v-btn
                @click="back"
                class="ma-2 orange white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
              </v-btn>
        <v-card-title> 
          <v-row no-gutters>
            <v-col
              cols="12"
              md="12"
              :align="$vuetify.breakpoint.width < 960 ? 'center' : 'start'"
            >
              <span style="font-size: 1.2rem">ຜະລິດຕະພັນທັງໝົດ</span>
              <v-divider vertical class="mx-2 hidden-sm-and-down"></v-divider>
            </v-col>
          </v-row>
        </v-card-title>
        <div v-if="loading">
          <v-data-table
            :headers="headers"
            :search="search"
            sort-by="idx"
            class="elevation-0"
            hide-default-footer
            no-data-text=""
          ></v-data-table>
          <v-row no-gutters class="justify-center pb-10">
            <SpinnerLoader :color="'#90CAF9'" :loading="true" :size="100" />
          </v-row>
        </div>
           <v-card flat v-else-if="length == false">
            <v-data-table
              :headers="headers"
              :search="search"
              sort-by="idx"
              class="elevation-0"
              hide-default-footer
              no-data-text=""
            ></v-data-table>
            <v-row class="justify-center mx-4">
              <v-card
                flat
                class=""
                color="transparent"
                style="font-size: 2rem; color: grey; margin-top: 40px"
                >ບໍ່ມີຂໍ້ມູນຜະລິດຕະພັນ
              </v-card>
            </v-row>
            <v-skeleton-loader
              style="margin-top: -125px"
              class=""
              type="image"
            >
            </v-skeleton-loader>
          </v-card>
        <v-data-table
          v-else
          :headers="headers"
          :items="orderByDate"
          :search="search"
          :single-expand="true"
    :expanded.sync="expanded"
          item-key="idx"
          :footer-props="{itemsPerPageText: 'ສະແດງຈໍານວນແຖວ :'}"
    show-expand
    class="elevation-1"
        >
           <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        
        <v-data-table
        v-if="item._id!=null"
          :headers="proheaders"
          :items="item.product"
          :search="search"
          hide-default-footer
          sort-by="idx"
          class=""
        >
          <template v-slot:item="{ item }">
            <tr v-ripple="{ class: `white--text` }">
              <td>{{ item.idx }}</td>
              <td align="start">
                <v-img
                  height="100"
                  width="100"
                  contain
                  :src="item.image"
                  :lazy-src="item.image"
                >
                <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="#90CAF9"
            ></v-progress-circular>
          </v-row>
        </template>
                </v-img>
              </td>
              <td>{{ item.productName }}</td>
              <td>
                {{ String(item.retail).replace(/(.)(?=(\d{3})+$)/g, "$1,") }}
              </td>
              <td>
                {{ String(item.wholeSale).replace(/(.)(?=(\d{3})+$)/g, "$1,") }}
              </td>
              <td>
               
                <span >{{
                  String(item.qty).replace(/(.)(?=(\d{3})+$)/g, "$1,")
                }}</span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </td>
    </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { SpinnerLoader } from "vue-spinners-css";
export default {
  data: () => ({
    loading: true,
    search: "",
        expanded: [],

    headers: [
      { text: "ລໍາດັບ", value: "idx", class: "indigo white--text" },
      {
        text: "ຜູ້ໃຊ້",
        value: "userName",
        class: "indigo white--text",
      },
      { text: "ຮູບແບຊໍາລະ", value: "paymentType", class: "indigo white--text" },
      {
        text: "ມູນຄ່າ(ກີບ)",
        value: "totalPayment",
        class: "indigo white--text",
      },
      { text: "ສະຖານະ", value: "orderStatus", class: "indigo white--text" },
      { text: "ວັນທີ່", value: "updatedAt", class: "indigo white--text" },
      { text: "ລາຍລະອຽດ", value: 'data-table-expand', class: "indigo white--text" },
     
    ],
     proheaders: [
      { text: "ລໍາດັບ", value: "idx", },
      {
        text: "ຮູບ",
        align: "center",
        sortable: false,
        value: "image",
        
      },
      { text: "ຜະລິດຕະພັນ", value: "productName", },
      // { text: "ປະເພດ", value: "category", class: "indigo white--text" },
      {
        text: "ລາຄາຂາຍຍ່ອຍ(ກີບ)",
        value: "retail",
      },
      {
        text: "ລາຄາຂາຍສົ່ງ(ກີບ)",
        value: "wholeSale",
      },
      { text: "ຈໍານວນ", value: "qty",  },
     
    ],
  }),

  components: {
    SpinnerLoader,
  },
  computed: {
    ...mapGetters({
      orderByDate: "reportMod/orderByDate",
   length:"reportMod/length"
    }),
  },

  watch: {
    length(val) {
      if (val==false) {
        this.loading = false;
      }
    },
    orderByDate(val) {
      if (val.length > 0) {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.getOrderByDate({date1:this.$route.query.date1,date2:this.$route.query.date2})
    this.Loading()
  },
  methods: {
    ...mapActions("reportMod", ["getOrderByDate"]),
  
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
   Loading(){
     if (this.length==false) {
       this.loading=false
     }
   },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped></style>
