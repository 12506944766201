
      <template>
  <div>
    <div>
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <v-col
              cols="12"
              md="3"
              :align="$vuetify.breakpoint.width < 960 ? 'center' : 'start'"
            >
              <span style="font-size: 1.2rem">ວັດຖຸດິບ-ນໍາອອກ</span>
              <v-divider vertical class="mx-2 hidden-sm-and-down"></v-divider>
            </v-col>
            <v-col cols="12" md="9">
              <v-row>
                <v-text-field
                  v-model="barCode"
                  dense
                  outlined
                  class="mx-2 mt-3"
                  label="ລະຫັດບາໂຄດ"
                  @keyup.enter="routeBarCode"
                ></v-text-field>
                <v-spacer class="hidden-sm-and-down"></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="ຄົ້ນຫາ"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <router-link to="/admin/rawdata/add">
                  <v-btn class="mt-3 mx-2 green">
                    <v-icon color="white" large>mdi-plus</v-icon
                    ><span style="color: white">ເພີ່ມໃໝ່</span>
                  </v-btn>
                </router-link>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <div v-if="loading">
          <v-data-table
            :headers="headers"
            :search="search"
            sort-by="idx"
            class="elevation-0"
            hide-default-footer
            no-data-text=""
          ></v-data-table>
          <v-row no-gutters class="justify-center pb-10">
            <SpinnerLoader :color="'#90CAF9'" :loading="true" :size="100" />
          </v-row>
        </div>

        <v-card flat v-else-if="length == false">
          <v-data-table
            :headers="headers"
            :search="search"
            sort-by="idx"
            class="elevation-0"
            hide-default-footer
            no-data-text=""
          ></v-data-table>
          <v-row class="justify-center mx-4">
            <v-card
              flat
              class=""
              color="transparent"
              style="font-size: 2rem; color: grey; margin-top: 40px"
              >ບໍ່ມີຂໍ້ມູນວັດຖຸດິບ-ນໍາອອກ
            </v-card>
          </v-row>
          <v-skeleton-loader style="margin-top: -125px" class="" type="image">
          </v-skeleton-loader>
        </v-card>
        <v-data-table
          v-else
          :headers="headers"
          :items="rawdatas"
          :search="search"
          :footer-props="{ itemsPerPageText: 'ສະແດງຈໍານວນແຖວ :' }"
          item-key="id"
          sort-by="idx"
        >
          <template v-slot:item="{ item }">
            <tr v-ripple="{ class: `white--text` }">
              <td>{{ item.idx }}</td>
              <td>{{ item.rawName }}</td>
              <td>
                <span>{{
                  String(item.qty).replace(/(.)(?=(\d{3})+$)/g, "$1,")
                }}</span>
              </td>
              <td>{{ item.userName }}</td>
              <td>
                {{ item.barCodeNumber }}
              </td>
              <td>{{ item.updatedAt }}</td>
              <td>
                <v-row class="justify-start">
                  <v-icon
                    @click="editRawdata(item)"
                    class="mr-2"
                    color="light-blue lighten-1"
                  >
                    mdi-eye
                  </v-icon>
                  <v-icon color="error" @click="deleteRawdata(item)">
                    mdi-delete
                  </v-icon>
                </v-row>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { SpinnerLoader } from "vue-spinners-css";
export default {
  data: () => ({
    loading: true,
    barCode: null,
    search: "",
    dialogDelete: false,
    deleteItem: [],
    snackbar: false,
    text: "",
    timeout: 1000,
    color: "",
    headers: [
      { text: "ລໍາດັບ", value: "idx", class: "indigo white--text" },
      {
        text: "ຊື່ວັດຖຸດິບ",
        value: "rawdataName",
        class: "indigo white--text",
      },
      { text: "ຈໍານວນ", value: "qty", class: "indigo white--text" },
      { text: "ຜູ້ໃຊ້", value: "supplierName", class: "indigo white--text" },
      { text: " ບາໂຄດ", value: "barCodeNumber", class: "indigo white--text" },
      {
        text: "ວັນທີ່ນໍາອອກ",
        value: "updatedAt",
        class: "indigo white--text",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "indigo white--text",
      },
    ],
  }),

  components: {
    SpinnerLoader,
  },
  computed: {
    ...mapGetters({
      rawdatas: "rawdataMod/rawdatas",
      length: "rawdataMod/length",
    }),
  },

  watch: {
    length(val) {
      if (val == false) {
        this.loading = false;
      }
    },
    rawdatas(val) {
      if (val.length > 0) {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.getRawdatas();
    this.Loading();
  },
  methods: {
    ...mapActions("rawdataMod", ["getRawdata", "getRawdatas", "delRawdata"]),
    showAlert() {
      this.$swal({
        position: "center",
        icon: "warning",
        title: `<div style='font-family:"Noto Sans Lao";' >ເຕືອນ</div>`,
        html: `<div style='font-family:"Noto Sans Lao"; font-size:1.5rem' >ທ່ານຕ້ອງການລົບອອກບໍ?</div>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: `<div style='font-family:"Noto Sans Lao";' >ຕົກລົງ</div>`,
        cancelButtonText: `<div style='font-family:"Noto Sans Lao";' >ຍົກເລີກ</div>`,
        confirmButtonColor: "green",
        cancelButtonColor: "red",
        reverseButtons: true,
        buttonsStyling: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteRawdataConfirm();
        }
      });
    },
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },

    Loading() {
      if (this.length == false) {
        this.loading = false;
      }
    },
    routeBarCode() {
      if (this.barCode != null || this.barCode != undefined) {
        this.$router.push({
          path: `/admin/rawdata/add`,
          query: { barCodeNumber: this.barCode },
        });
      }
    },
    editRawdata(item) {
      this.$router.push(`/admin/rawdata/edit/${item._id}`);
    },

    deleteRawdata(item) {
      this.deleteItem = item;
      this.showAlert();
    },

    deleteRawdataConfirm() {
      try {
        this.delRawdata(this.deleteItem)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
              this.showToast(
                "success",
                `<div style='font-family:"Noto Sans Lao";' >ລົບຂໍ້ມູນສໍາເລັດແລ້ວ</div>`
              );
              this.getRawdatas();
            } else {
              this.showToast(
                "error",
                `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "error",
              `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
            );
            console.log(error);
          });
      } catch (error) {
        console.log(error);
        this.showToast(
          "error",
          `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
        );
      }
    },
  },
};
</script>
<style scoped>
</style>