<template>
  <div>
    <v-card>
      <v-row no-gutters class="justify-center ma-2 pt-3">
      
          <v-icon left 
          @click="back">mdi-arrow-left-circle</v-icon> <v-spacer></v-spacer> <span style="font-size: 1.5rem">ລາຍລະອຽດຮັບສັ່ງຊື້</span> <v-spacer></v-spacer> </v-row
      >
      <v-row no-gutters class="justify-center mx-2">
        <v-col cols="12" md="4" class="mt-10 mt-md-0">
          <p style="font-size: 1.2rem; margin-top: 20px">
            ຜູ້ສັ່ງ : {{ order.userName }}
          </p>
          <p style="font-size: 1.2rem">ວັນທີ່ສັ່ງ : {{ order.createdAt }}</p>
          <p style="font-size: 1.2rem">
            ລາຄາລວມ :
            {{
              String(parseInt(order.totalPayment || 0)).replace(
                /(.)(?=(\d{3})+$)/g,
                "$1,"
              )
            }}
            ກີບ
          </p>
        </v-col>
        <v-col cols="12" md="7" class="mb-2">
          <v-row class="justify-space-between">
          <p style="margin-bottom: -20px; margin-top: 20px">ເລືອກສະຖານະ</p>
            <v-btn
          @click="clear"
          class="ma-2 white indigo--text"
          :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
        >
          <v-icon left>mdi-backup-restore</v-icon>ໂລດຂໍ້ມູນ
        </v-btn></v-row>
          <v-radio-group
            v-model="order.orderStatus"
            row
            style="border: dotted 2px #d0d0d0"
          >
            <v-radio
              :readonly="order.orderStatus != 'NEW'"
              class="mt-3 mx-3"
              label="ໃໝ່"
              value="NEW"
            ></v-radio>
            <v-radio
              :readonly="
                order.orderStatus == 'SHIPPED' ||
                order.orderStatus == 'DELIVERED'
              "
              class="mt-3 mx-3"
              label="ກໍາລັງກວດສອບ"
              value="PENDING"
            ></v-radio>
            <v-radio
              :readonly="
                order.orderStatus == 'SHIPPED' ||
                order.orderStatus == 'DELIVERED'
              "
              class="mt-3 mx-3"
              label="ກໍງລັງຈັດສົ່ງ"
              value="PACKED"
            ></v-radio>
            <v-radio
              :readonly="order.orderStatus == 'DELIVERED'"
              class="mt-3 mx-3"
              label="ຈັດສົ່ງແລ້ວ"
              value="SHIPPED"
            ></v-radio>
            <v-radio
              class="mt-3 mx-3"
              label="ສົ່ງສໍາເລັດ"
              value="DELIVERED"
            ></v-radio>
            <v-radio
              :readonly="
                order.orderStatus == 'SHIPPED' ||
                order.orderStatus == 'DELIVERED'
              "
              class="mt-3 mx-3"
              label="ຍົກເລີກ"
              value="CANCELED"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <div v-if="loading">
        <v-data-table
          :headers="headers"
          :search="search"
          sort-by="idx"
          class="elevation-0"
          hide-default-footer
          no-data-text=""
        ></v-data-table>
        <v-row no-gutters class="justify-center pb-10">
          <SpinnerLoader :color="'#90CAF9'" :loading="true" :size="100" />
        </v-row>
      </div>
           <v-card flat v-else-if="length == false">
            <v-data-table
              :headers="headers"
              :search="search"
              sort-by="idx"
              class="elevation-0"
              hide-default-footer
              no-data-text=""
            ></v-data-table>
            <v-row class="justify-center mx-4">
              <v-card
                flat
                class=""
                color="transparent"
                style="font-size: 2rem; color: grey; margin-top: 40px"
                >ບໍ່ມີຂໍ້ມູນສັ່ງຊື້
              </v-card>
            </v-row>
            <v-skeleton-loader
              style="margin-top: -125px"
              class=""
              type="image"
            >
            </v-skeleton-loader>
          </v-card>
      <v-data-table
        v-else
        large
        class="table mb-7"
        :headers="headers"
        :items="order.items"
        :search="search"
          :footer-props="{itemsPerPageText: 'ສະແດງຈໍານວນແຖວ :'}"
        fixed-header
        hide-default-footer
      >
        <template v-slot:item="{ item }">
          <tr v-ripple="{ class: `white--text` }">
            <!-- <td>{{ item.idx }}</td> -->
            <td 
      align="center">
              <v-img
                height="100"
                max-width="100"
                contain
                :src="item.product.image"
                :lazy-src="item.product.image"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </td>

            <td>
              {{ item.product.productName }}
            </td>
            <td>
              {{
                item.product == undefined || item.product == null
                  ? 0
                  : String(parseInt(item.product.wholeSale)).replace(
                      /(.)(?=(\d{3})+$)/g,
                      "$1,"
                    )
              }}
            </td>
            <td>
              {{
                item.quantity == undefined || item.quantity == null
                  ? 0
                  : String(parseInt(item.quantity)).replace(
                      /(.)(?=(\d{3})+$)/g,
                      "$1,"
                    )
              }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-divider class></v-divider>
      <v-row
        class="ma-5"
        :justify="$vuetify.breakpoint.width < 600 ? 'center' : 'start'"
      >
        <v-spacer class="d-none d-sm-flex"></v-spacer>
        
        <v-btn
          @click="updateOrderStatus"
          class="ma-2 green white--text"
          :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
        >
          <v-icon left>mdi-check-circle</v-icon>ຢືນຢັນ
        </v-btn>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { SpinnerLoader } from "vue-spinners-css";
export default {
  data() {
    return {
      loading: true,
      date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu1: false,
      date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu2: false,
      deleteItem: [],
      search: "",
      headers: [
        // { text: "ລໍາດັບ", value: "idx", class: "indigo white--text" },
        {
          text: "ຮູບ",
          sortable: false,
          value: "image",
      align:"center",
          class: "indigo white--text",
        },
        {
          text: "ຜະລິດຕະພັນ",
          sortable: false,
          value: "productName",
          class: "indigo white--text",
        },
        { text: "ລາຄາ(ກີບ)", value: "qty", class: "indigo white--text" },
        { text: "ຈໍານວນ", value: "qty", class: "indigo white--text" },
      ],
    };
  },
  components: {
    SpinnerLoader,
  },
  computed: {
    ...mapGetters({
      order: "orderMod/order",
      products: "productMod/products",
      length: "orderMod/length",
    }),
  },
  watch: {
    length(val) {
      if (val == false) {
        this.loading = false;
      }
    },
    order(val) {
      const item =
        val.items == null || val.items == undefined || val.items.length < 1
          ? 0
          : 1;
      if (item == 1) {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getOrder(this.$route.params.id);
    this.getProducts();
    this.Loading()
  },
  created() {},
  methods: {
    ...mapActions("productMod", ["getProducts", "updateProduct"]),
    ...mapActions("orderMod", ["getOrder", "updateOrder"]),
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },

    updateOrderStatus() {
      try {
        if (this.order.orderStatus == "" || this.order.orderStatus == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກສະຖານະ</div>`
          );
          return;
        }

        if (this.order.items.length < 1 || this.order.items == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ບໍ່ມີຂໍ້ມູນຜະລິດຕະພັນ</div>`
          );
          return;
        }
        var list = []
        for (const key in this.order.items) {
          const el = this.order.items[key];
          var item_list = {
            product:
              (el.product == null) | (el.product == undefined)
                ? null
                : el.product._id,
            quantity: el.quantity,
          };
          list.push(item_list);
        }
        var order = {
          _id: this.order._id,
          orderStatus: this.order.orderStatus,
          items: list,
        };
          this.updateStatus(order);
      } catch (error) {
        console.log(error);
        this.showToast(
          "error",
          `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
        );
      }
    },
    Loading(){
      if (this.length==false) {
        this.loading=false
      }
    },
    updateStatus(order) {
      this.updateOrder(order)
        .then((res) => {
          if (res.data.message == "SUCCESSFULLY") {
            this.showToast(
              "success",
              `<div style='font-family:"Noto Sans Lao";' >ແກ້ໄຂຂໍ້ມູນສໍາເລັດ</div>`
            );
             if (this.order.orderStatus=="DELIVERED") {
          this.back()
        }
            this.clear();
          } else {
            this.showToast(
              "error",
              `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
            );
          }
        })
        .catch((error) => {
          this.showToast(
            "error",
            `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
          );
          console.log(error);
        });
    },
    clear() {
      this.getOrder(this.$route.params.id);
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style lang="css">
.font {
  font-family: "NotoSansLao";
}
</style>

