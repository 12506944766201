<template>
  <div class="font">
    <v-row no-gutters class="justify-center my-3">
        <p style="font-size: 2rem"> ພິມລະຫັດບາໂຄດຂອງຜະລິດຕະພັນ</p>
      </v-row>
    <v-row no-gutters class="justify-end">
      <v-text-field
        v-model="product.barCodeNumber"
        dense
        outlined
        class="mx-2"
        label="ລະຫັດບາໂຄດ"
        @keyup="selectByBarCode"
      ></v-text-field>
      <v-autocomplete
        v-model="product.productName"
        dense
        outlined
        clearable
        class="mx-2"
        item-color="blue darken-1"
        no-data-text="ບໍ່ມີຂໍ້ມູນ!"
        :items="productsName"
        label="ຜະລິດຕະພັນ"
        allow-overflow
        @change="SelectProduct"
      ></v-autocomplete>
      <v-text-field
        class="mx-0 mx-md-5 px-2 tcenter"
        label="ຈໍານວນ"
        dense
        outlined
        rounded
        prepend-inner-icon="mdi-minus"
        append-icon="mdi-plus"
        autofocus
        v-model="product.qty"
        @click:append="product.qty = parseInt(product.qty) + 1"
        @click:prepend-inner="
          product.qty =
            parseInt(product.qty) < 1 ? 0 : parseInt(product.qty) - 1
        "
        @keypress="number($event)"
        @keyup="product.qty = parseInt(product.qty)"
      >
        <template slot="append">
          <v-btn
            style="margin-top: -7px; margin-right: -20px"
            color="#004D40"
            icon
            @click="product.qty = parseInt(product.qty) + 1"
          >
            <v-icon large>mdi-plus</v-icon>
          </v-btn>
        </template>
        <template slot="prepend-inner">
          <v-btn
            style="margin-top: -7px; margin-left: -20px"
            color="#004D40"
            icon
            @click="
              product.qty =
                parseInt(product.qty) < 1 ? 0 : parseInt(product.qty) - 1
            "
          >
            <v-icon large>mdi-minus</v-icon>
          </v-btn>
        </template>
      </v-text-field>
      <v-btn
        v-print="'#barcode'"
        class="mx-2 green white--text"
        :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
      >
        <v-icon left>mdi-printer</v-icon>ພິມລະຫັດບາໂຄດ
      </v-btn>
    </v-row>
    <v-card v-if="product.barCodeNumber!=null &&product.barCodeNumber!=undefined &&product.barCodeNumber!=''" id="barcode" flat style="margin-top: 2px; padding-bottom: 10px">
      <v-row no-gutters class="justify-center">
        <p style="font-size: 1.2rem">ບາໂຄດຜະລິດຕະພັນ {{ product.productName }}</p>
      </v-row>
      <v-row no-gutters class="ma-5">
        <div v-for="(item, index) in product.qty" :key="index">
          <v-card flat class="ma-5">
            <barcode :value="product.barCodeNumber"> </barcode>
          </v-card>
        </div>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import VueBarcode from "vue-barcode";
import print from "vue-print-nb";
export default {
  data: () => ({
    tag: "svg",
    options: {
      lineColor: "#0f70f9",
      font: "NoTo San Lao",
      format: "MSI",
      background: "#ccffff",
    },
    product: {
      id: 0,
      barCodeNumber: null,
      productName: null,
      qty: 10,
    },
  }),

  directives: {
    print,
  },
  computed: {
    ...mapGetters({
      products: "productMod/products",
      productsName: "productMod/productsName",
      length: "productMod/length",
    }),
  },
  components: {
    barcode: VueBarcode,
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    ...mapActions("productMod", ["getProducts"]),
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    selectByBarCode() {
      if (
        this.product.barCodeNumber == null ||
        this.product.barCodeNumber == undefined
      ) {
        return;
      }
      if (
        this.products == null ||
        this.products == undefined ||
        this.products.length < 1
      ) {
        return;
      }
      var name = null;
      for (const idx in this.products) {
        const el = this.products[idx];
        if (this.product.barCodeNumber == el.barCodeNumber) {
          name = el.productName
        }
      }
      this.product.productName = name;
    },

    SelectProduct() {
      if (
        this.product.productName == null ||
        this.product.productName == undefined
      ) {
        return;
      }
      if (
        this.products == null ||
        this.products == undefined ||
        this.products.length < 1
      ) {
        return;
      }
      var barcode = null;
      for (const idx in this.products) {
        const el = this.products[idx];
        if (this.product.productName == el.productName) {
          barcode =
            el.barCodeNumber == null || el.barCodeNumber == undefined
              ? null
              : el.barCodeNumber;
        }
      }
      this.product.barCodeNumber = barcode;
    },

    number(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        this.showToast(
          "warning",
          `<div style='font-family:"Noto Sans Lao";color:red' >ປ້ອນສະເພາະຕົວເທົ່ານັ້ນ (0-9)</div>`
        );
        evt.preventDefault();
      } else {
        if (
          this.product.qty == "" ||
          this.product.qty == null ||
          this.product.qty < 1 ||
          this.product.qty == undefined
        ) {
          this.product.qty = null;
        }
        return true;
      }
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped>
.tcenter {
  width: 200px;
  min-width: 100px;
}
.tcenter >>> input {
  text-align: center;
}
.font {
  font-family: "NotoSansLao";
}
</style>