<template>
  <div class="font">
    <v-row no-gutters class="justify-center my-3">
      <p style="font-size: 2rem">ພິມລະຫັດບາໂຄດຂອງວັດຖຸດິບ</p>
    </v-row>
    <v-row no-gutters class="justify-end">
      <v-text-field
        v-model="raw.barCodeNumber"
        dense
        outlined
        class="mx-2"
        label="ລະຫັດບາໂຄດ"
        @keyup="selectByBarCode"
      ></v-text-field>
      <v-autocomplete
        v-model="raw.rawName"
        dense
        outlined
        clearable
        class="mx-2"
        item-color="blue darken-1"
        no-data-text="ບໍ່ມີຂໍ້ມູນ!"
        :items="rawName"
        label="ວັດຖຸດິບ"
        allow-overflow
        @change="SelectRawName"
      ></v-autocomplete>
      <v-text-field
        class="mx-0 mx-md-5 px-2 tcenter"
        label="ຈໍານວນ"
        dense
        outlined
        rounded
        prepend-inner-icon="mdi-minus"
        append-icon="mdi-plus"
        autofocus
        v-model="raw.qty"
        @click:append="raw.qty = parseInt(raw.qty) + 1"
        @click:prepend-inner="
          raw.qty = parseInt(raw.qty) < 1 ? 0 : parseInt(raw.qty) - 1
        "
        @keypress="number($event)"
        @keyup="raw.qty = parseInt(raw.qty)"
      >
        <template slot="append">
          <v-btn
            style="margin-top: -7px; margin-right: -20px"
            color="#004D40"
            icon
            @click="raw.qty = parseInt(raw.qty) + 1"
          >
            <v-icon large>mdi-plus</v-icon>
          </v-btn>
        </template>
        <template slot="prepend-inner">
          <v-btn
            style="margin-top: -7px; margin-left: -20px"
            color="#004D40"
            icon
            @click="raw.qty = parseInt(raw.qty) < 1 ? 0 : parseInt(raw.qty) - 1"
          >
            <v-icon large>mdi-minus</v-icon>
          </v-btn>
        </template>
      </v-text-field>
      <v-btn
        v-print="'#barcode'"
        class="mx-2 green white--text"
        :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
      >
        <v-icon left>mdi-printer</v-icon>ພິມລະຫັດບາໂຄດ
      </v-btn>
    </v-row>
    <v-card
      v-if="
        raw.barCodeNumber != null &&
        raw.barCodeNumber != undefined &&
        raw.barCodeNumber != ''
      " 
      id="barcode"
      flat
      style="margin-top: 2px; padding-bottom: 10px"
    >
      <v-row no-gutters class="justify-center">
        <p style="font-size: 2rem">ບາໂຄດວັດຖຸດິບ {{ raw.rawName }}</p>
      </v-row>
      <v-row no-gutters class="ma-5">
        <div v-for="(item, index) in raw.qty" :key="index">
          <v-card flat class="ma-5">
            <barcode :value="raw.barCodeNumber"> </barcode>
          </v-card>
        </div>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import VueBarcode from "vue-barcode";
import print from "vue-print-nb";
export default {
  data: () => ({
    tag: "svg",
    options: {
      lineColor: "#0f70f9",
      font: "NoTo San Lao",
      format: "MSI",
      background: "#ccffff",
    },
    raw: {
      id: 0,
      barCodeNumber: null,
      rawName: null,
      qty: 10,
    },
  }),

  directives: {
    print,
  },
  computed: {
    ...mapGetters({
      raws: "rawMod/raws",
      rawName: "rawMod/rawName",
      length: "rawMod/length",
    }),
  },
  components: {
    barcode: VueBarcode,
  },
  mounted() {
    this.getRaws();
  },
  methods: {
    ...mapActions("rawMod", ["getRaws"]),
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    number(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        this.showToast(
          "warning",
          `<div style='font-family:"Noto Sans Lao";color:red' >ປ້ອນສະເພາະຕົວເທົ່ານັ້ນ (0-9)</div>`
        );
        evt.preventDefault();
      } else {
        if (
          this.raw.qty == "" ||
          this.raw.qty == null ||
          this.raw.qty < 1 ||
          this.raw.qty == undefined
        ) {
          this.raw.qty = null;
        }
        return true;
      }
    },

    selectByBarCode() {
      if (
        this.raw.barCodeNumber == null ||
        this.raw.barCodeNumber == undefined
      ) {
        return;
      }
      var name = null;
      for (const idx in this.raws) {
        const el = this.raws[idx];
        if (this.raw.barCodeNumber == el.barCodeNumber) {
          name = el.rawName;
        }
      }
      this.raw.rawName = name;
    },
    SelectRawName() {
      if (this.raw.rawName == null || this.raw.rawName == undefined) {
        return;
      }
      var barcode = null;
      for (const idx in this.raws) {
        const el = this.raws[idx];
        if (this.raw.rawName == el.rawName) {
          barcode =
            el.barCodeNumber == null || el.barCodeNumber == undefined
              ? null
              : el.barCodeNumber;
        }
      }
      this.raw.barCodeNumber = barcode;
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped>
.tcenter {
  width: 200px;
  min-width: 100px;
}
.tcenter >>> input {
  text-align: center;
}
.font {
  font-family: "NotoSansLao";
}
</style>