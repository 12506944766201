<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
              <p style="font-size:2rem;margin-top: 30px">ແກ້ໄຂຂໍ້ມູນວັດຖຸດິບ</p>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  <v-text-field
                    v-model="raw.rawName"
                    :error-messages="rawNameErrors"
                    label="ຊື່ວັດຖຸດິບ"
                    required
                    @input="$v.raw.rawName.$touch()"
                    @blur="$v.raw.rawName.$touch()"
                  ></v-text-field>
                  <v-text-field
                  readonly
                    v-model="raw.barCodeNumber"
                    :error-messages="barCodeNumberErrors"
                    label="ລະຫັດບາໂຄດ"
                    required
                    @input="$v.raw.barCodeNumber.$touch()"
                    @blur="$v.raw.barCodeNumber.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="raw.qty"
                    :error-messages="qtyErrors"
                    label="ຈໍານວນ"
                    required
                    @input="$v.raw.qty.$touch()"
                    @blur="$v.raw.qty.$touch()"
                    @keypress="number($event)"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class></v-divider>
            <v-row
              class="ma-5"
              :justify="$vuetify.breakpoint.width < 600 ? 'center' : 'start'"
            >
              <v-btn
                @click="back"
                class="ma-2 orange white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
              </v-btn>
              <v-spacer class="d-none d-sm-flex"></v-spacer>
              <v-btn
                @click="clear"
                class="ma-2 red white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-close-circle</v-icon>ຍົກເລິກ
              </v-btn>

              <v-btn
                @click="submit"
                class="ma-2 green white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-check-circle</v-icon>ບັນທຶກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    raw: {
      rawName: { required, maxLength: maxLength(100) },
      barCodeNumber: { required, maxLength: maxLength(100) },
      qty: { required, maxLength: maxLength(100) },
    },
  },

  data: () => ({
    
  }),

  computed: {
    ...mapGetters({
    raw:"rawMod/raw"
    }),
    rawNameErrors() {
      const errors = [];
      if (!this.$v.raw.rawName.$dirty) return errors;
      !this.$v.raw.rawName.maxLength &&
        errors.push("ກະລຸນາປ້ອນຊື່ວັດຖຸດິບ");
      !this.$v.raw.rawName.required &&
        errors.push("ກະລຸນາປ້ອນຊື່ວັດຖຸດິບ");
      return errors;
    },
     barCodeNumberErrors() {
      const errors = [];
      if (!this.$v.raw.barCodeNumber.$dirty) return errors;
      !this.$v.raw.barCodeNumber.maxLength &&
        errors.push("ກະລຸນາປ້ອນລະຫັດບາໂຄດ");
      !this.$v.raw.barCodeNumber.required &&
        errors.push("ກະລຸນາປ້ອນລະຫັດບາໂຄດ");
      return errors;
    },
    qtyErrors() {
      const errors = [];
      if (!this.$v.raw.qty.$dirty) return errors;
      !this.$v.raw.qty.maxLength && errors.push("ກະລຸນາປ້ອນຈໍານວນວັດຖຸດິບ");
      !this.$v.raw.qty.required && errors.push("ກະລຸນາປ້ອນຈໍານວນວັດຖຸດິບ");
      return errors;
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions("rawMod", ["getRaw","updateRaw","getRawBarCode"]),

    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    number(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ປ້ອນສະເພາະຕົວເທົ່ານັ້ນ (0-9)</div>`,)
        evt.preventDefault();
      } else {
        return true;
      }
    },
    init(){
      if ((this.$route.params.id).length>12&&((this.$route.params.id).slice(0,2)=="RD")){
    this.getRawBarCode(this.$route.params.id)
      }else{
    this.getRaw(this.$route.params.id)
      }
    },
    async submit() {
      this.$v.$touch();
      try {
        if (this.raw.rawName == "" || this.raw.rawName == null) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນຊື່ວັດຖຸດິບ</div>`,)
          return;
        }
        if (this.raw.qty == "" || this.raw.qty == null) {
             this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນຈໍານວນວັດຖຸ</div>`,)
          return;
        }
        this.updateRaw(this.raw)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
              this.showToast("success",`<div style='font-family:"Noto Sans Lao";' >ບັນທຶກຂໍ້ມູນສໍາເລັດແລ້ວ</div>`,)
              this.clear();
              this.back()
            } else {
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            }
          })
          .catch( (error) =>{
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            console.log(error);
          });
      } catch (error) {
        console.log(error);
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
      }
    },
    clear() {
      this.$v.$reset();
    this.getRaw(this.$route.params.id)
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped>
</style>