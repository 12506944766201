<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
              <h1 style="margin-top: 30px">ແກ້ໄຂຂໍ້ມູນເມືອງ</h1>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  <v-autocomplete
                    v-model="district.provinceName"
                    :items="provinceName"
                    :error-messages="provinceNameErrors"
                    label="ຊື່ແຂວງ"
                    required
                    @input="$v.district.provinceName.$touch()"
                    @blur="$v.district.provinceName.$touch()"
                  ></v-autocomplete>
                  <v-text-field
                    v-model="district.name"
                    :error-messages="nameErrors"
                    label="ຊື່ເມືອງ"
                    required
                    @input="$v.district.name.$touch()"
                    @blur="$v.district.name.$touch()"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class></v-divider>
            <v-row
              class="ma-5"
              :justify="$vuetify.breakpoint.width < 600 ? 'center' : 'start'"
            >
              <v-btn
                @click="back"
                class="ma-2 orange white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
              </v-btn>
              <v-spacer class="d-none d-sm-flex"></v-spacer>

              <v-btn
                @click="clear"
                class="ma-2 red white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-close-circle</v-icon>ຍົກເລິກ
              </v-btn>

              <v-btn
                @click="submit"
                class="ma-2 green white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-check-circle</v-icon>ບັນທຶກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    district: {
      provinceName: { required, maxLength: maxLength(100) },
      name: { required, maxLength: maxLength(100) },
    },
  },
  data: () => ({
  }),

  computed: {
    ...mapGetters({
      provinces: "addressMod/provinces",
      provinceName: "addressMod/provinceName",
      district: "addressMod/district",
    }),
    provinceNameErrors() {
      const errors = [];
      if (!this.$v.district.provinceName.$dirty) return errors;
      !this.$v.district.provinceName.maxLength &&
        errors.push("ກະລຸນາເລືອກແຂວງ!");
      !this.$v.district.provinceName.required &&
        errors.push("ກະລຸນາເລືອກແຂວງ!");
      return errors;
    },

    nameErrors() {
      const errors = [];
      if (!this.$v.district.name.$dirty) return errors;
      !this.$v.district.name.maxLength && errors.push("ກະລຸນາປ້ອນຊື່ເມືອງ!");
      !this.$v.district.name.required && errors.push("ກະລຸນາປ້ອນຊື່ເມືອງ!");
      return errors;
    },
  },
  mounted() {
    this.getProvinces();
    this.getDistrict(this.$route.params.id);
  },
  methods: {
    ...mapActions("addressMod", [
      "updateDistrict",
      "getProvinces",
      "getDistrict",
    ]),
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },
    
     submit() {
      this.$v.$touch();
      try {
       if (
          this.district.provinceName == "" ||
          this.district.provinceName == null
        ) {
          this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກແຂວງ</div>`,)
          return;
        }
        if (this.district.name == "" || this.district.name == null) {
          this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນຊື່ເມືອງ</div>`,)
          return;
        }
        for (const key in this.provinces) {
          const el = this.provinces[key];
          if (el.name == this.district.provinceName) {
            this.district.province = el._id;
          }
        }
        if (this.district.province == "" || this.district.province == null) {
          this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກແຂວງໃຫ້ຖຶກຕ້ອງ</div>`,)
          return;
        }
        this.updateDistrict(this.district)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
             this.showToast("success",`<div style='font-family:"Noto Sans Lao";' >ແກ້ໄຂຂໍ້ມູນສໍາເລັດ</div>`,)
              this.clear();
              this.back()
            } else {
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            }
          })
          .catch( (error) =>{
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            console.log(error);
          });
      } catch (error) {
        console.log(error);
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";' >ຂໍ້ມູນຜິດພາດ</div>`,)
      }
    },
    clear() {
      this.$v.$reset();
      this.getDistrict(this.$route.params.id);
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped></style>
