<template>
  <v-app app class="font">
    <v-app-bar
      app
      flat
      height="150"
      clipped-left
      class="hidden-sm-and-down align-end pl-1"
      color="#f9f9f9f9"
      
    >
      <router-link :to="{ name: 'home'}">
    <v-card flat color="transparent" width="40" height="150" class="pt-16">
        <v-img
          height="40"
          width="40"
          class="my-2 mt-11"
          contain
          :src="require('@/assets/onewater.png')"
        >
        </v-img>
    </v-card>
      </router-link>
      <v-toolbar-title class="mx-2">
        
        <router-link :to="{ name: 'home'}">
          <div  class="ml-5 mt-16 pt-10" style="color: #2E3192;  font-size:24px">ໂຮງງານນໍ້າດື່ມແສງແກ້ວ</div>
        </router-link> 
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <router-link :to="{ name: 'home'}">
        <div class="ml-4 mt-16 pt-10 txt">ໜ້າຫຼັກ</div>
      </router-link>
      <router-link to="/about">
        <div class="ml-4 mt-16 pt-10  txt">ກ່ຽວກັບພວກເຮົາ</div>
      </router-link>
      <router-link to="/products">
        <div class="ml-4 mt-16 pt-10  txt">ຜະລິດຕະພັນ</div>
      </router-link>
      <router-link to="/event">
        <div class="ml-4 mt-16 pt-10  txt">ກິດຈະກໍາ</div>
      </router-link>
      <router-link to="/contact">
        <div class="ml-4 mt-16 pt-10  txt">ຕິດຕໍ່ພວກເຮົາ</div>
      </router-link>

      <router-link to="/signin">
        <div class="ml-4 mt-16 pt-10  txt">ເຂົ້າສູ່ລະບົບ</div>
      </router-link>
    </v-app-bar>
    <v-app-bar
      app
      flat
      clipped-left
      class="hidden-md-and-up"
      color="#f9f9f9f9"
    >
      <v-app-bar-nav-icon @click.stop="togle" color="#2E3192"></v-app-bar-nav-icon>
      <v-toolbar-title class="mx-2">
        <router-link :to="{ name: 'home'}">
          <div style="color: #2E3192; font-size:1.2rem">ໂຮງງານນໍ້າດື່ມແສງແກ້ວ</div>
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <router-link to="/signin">
        <v-btn icon>
          <v-icon color="#2E3192">mdi-login</v-icon>
        </v-btn>
      </router-link>
    </v-app-bar>
    <v-navigation-drawer
      app
      disable-resize-watcher
      v-model="togledrawer"
      class="elevation-1"
      clipped
    >
      <router-link :to="{ name: 'home'}">
        <v-img
          height="100"
          class="my-2"
          contain
          v-ripple="{ class: `grey--text` }"
          :src="require('@/assets/onewater.png')"
        >
        </v-img>
      </router-link>
      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item link class="ml-3" @click="togle">
          <router-link :to="{ name: 'home'}">
            <v-list-item-title class="my-1 py-1" style="font-size: 1rem"
              >ໜ້າຫຼັກ</v-list-item-title
            >
          </router-link>
        </v-list-item>

        
        <v-list-item link class="ml-3" @click="togle">
          <router-link to="/about">
            <v-list-item-title class="my-1 py-1" style="font-size: 1rem"
              >ກ່ຽກັບພວກເຮົາ</v-list-item-title
            >
          </router-link>
        </v-list-item>


        <v-list-item link class="ml-3" @click="togle">
          <router-link to="/products">
            <v-list-item-title class="my-1 py-1" style="font-size: 1rem"
              >ຜະລິດຕະພັນ</v-list-item-title
            >
          </router-link>
        </v-list-item>
<v-list-item link class="ml-3" @click="togle">
          <router-link to="/event">
            <v-list-item-title class="my-1 py-1" style="font-size: 1rem"
              >ກິດຈະກໍາ</v-list-item-title
            >
          </router-link>
        </v-list-item>

        <v-list-item link class="ml-3" @click="togle">
          <router-link to="/contact">
            <v-list-item-title class="my-1 py-1" style="font-size: 1rem"
              >ຕິດຕໍ່ພວກເຮົາ</v-list-item-title
            >
          </router-link>
        </v-list-item>

        <v-list-item link class="ml-3" @click="togle">
          <router-link to="/signin">
            <v-list-item-title class="my-1 py-1" style="font-size: 1rem"
              >ເຂົ້າສູ່ລະບົບ</v-list-item-title
            >
          </router-link>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <router-view class="mt-0 mt-md-16 pt-0 pt-md-10"> </router-view>

    <v-card app flat tile
    style="backgound:#2E3192"
      color="#2E3192">
      <v-card class="my-2" flat  color="transparent">
        <v-divider></v-divider>

        <v-card-text>
          <v-row no-gutters class="justify-center">
            <nav> ສະຫງວນລິຂະສິດ</nav>
          </v-row>
          <v-row no-gutters class="justify-center">
              <nav>ສຳນັກງານຕັ້ງຢູ່: ບ້ານ ທົ່ງນ້ອຍ, ເມືອງ ປາກຊັນ, ແຂວງ ບໍລິຄຳໄຊ</nav>
          </v-row>
          <v-row no-gutters class="justify-center">
              <nav>ເບີໂທ: 030 5711999, 030 5799888</nav>
          </v-row>
          <v-row no-gutters class="justify-center">
              <nav>ອີເມລ: <a class="email" href="https://mail.google.com/mail/u/0/?hl=sk#search/sk.onewater%40gmail.com" target="_blank">sk.onewater@gmail.com</a></nav>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    drawer: true,
      oldDate: window.localStorage.getItem("date")
        ? window.localStorage.getItem("date")
        : 0,
  }),
  
  computed: {
    togledrawer() {
      var tg = false;
      if (window.innerWidth < 960) {
        tg = this.drawer;
      }
      return tg;
    },
  },
  methods: {
    togle() {
      this.drawer = !this.drawer;
    },
  },
};
</script>
<style >
.font {
  font-family: "NotoSansLao";
}
.email{
  text-decoration: none;
  color: white;
}

.email:visited {
  color: white;
}

.email:hover {
  text-decoration: underline;
  color: white;
}

.email:active {
  color: white;
}
a:link {
  text-decoration: none;
  color: black;
}
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #2E3192;
}

a:active {
  color: #2E3192;
}
.txt {
  font-size: 1rem;
  color: #2E3192;
  font-weight: bold;
}
.txt:hover {
  color: orange;
}
nav{
  color:white;
  margin: 0.3rem;
  font-size:1.2rem
}
</style>