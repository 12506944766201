<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
              <h1 style="margin-top: 30px">ແກ້ໄຂຂໍ້ມູນບ້ານ</h1>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  <v-autocomplete
                    clearable
                    item-color="blue darken-1"
                    no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                    v-model="village.provinceName"
                    :items="provinceName"
                    :error-messages="provinceNameErrors"
                    label="ຊື່ແຂວງ"
                    required
                    @input="$v.village.provinceName.$touch()"
                    @blur="$v.village.provinceName.$touch()"
                  ></v-autocomplete>
                  <v-autocomplete
                    clearable
                    item-color="blue darken-1"
                    no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                    v-model="village.districtName"
                    :items="districtInProvince"
                    :error-messages="districtNameErrors"
                    label="ຊື່ເມືອງ"
                    required
                    @input="$v.village.districtName.$touch()"
                    @blur="$v.village.districtName.$touch()"
                  ></v-autocomplete>
                  <v-text-field
                    v-model="village.name"
                    :error-messages="nameErrors"
                    label="ຊື່ບ້ານ"
                    required
                    @input="$v.village.name.$touch()"
                    @blur="$v.village.name.$touch()"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class></v-divider>
            <v-row
              class="ma-5"
              :justify="$vuetify.breakpoint.width < 600 ? 'center' : 'start'"
            >
              <v-btn
                @click="back"
                class="ma-2 orange white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
              </v-btn>
              <v-spacer class="d-none d-sm-flex"></v-spacer>
              <v-btn
                @click="clear"
                class="ma-2 red white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-close-circle</v-icon>ຍົກເລິກ
              </v-btn>

              <v-btn
                @click="submit"
                class="ma-2 green white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-check-circle</v-icon>ບັນທຶກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="500px" class="font">
      <v-card class="font">
        <v-card-title
          ><v-icon size="50" color="error">mdi-exclamation-thick</v-icon>
          <span style="color: #ff1744">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="confirm">ຕົກລົງ</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar centered v-model="snackbar" :timeout="timeout" :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          ປິດ
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    village: {
      provinceName: { required, maxLength: maxLength(100) },
      districtName: { required, maxLength: maxLength(100) },
      name: { required, maxLength: maxLength(100) },
    },
  },
  data: () => ({
  }),

  computed: {
    ...mapGetters({
      village: "addressMod/village",
      districts: "addressMod/districts",
      districtName: "addressMod/districtName",
      provinces: "addressMod/provinces",
      provinceName: "addressMod/provinceName",
    }),
    provinceNameErrors() {
      const errors = [];
      if (!this.$v.village.provinceName.$dirty) return errors;
      !this.$v.village.provinceName.maxLength &&
        errors.push("Name must be at most 100 characters long");
      !this.$v.village.provinceName.required &&
        errors.push("ກະລຸນາປ້ອນຊື່ເເຂວງ.");
      return errors;
    },
    districtNameErrors() {
      const errors = [];
      if (!this.$v.village.districtName.$dirty) return errors;
      !this.$v.village.districtName.maxLength &&
        errors.push("Name must be at most 100 characters long");
      !this.$v.village.districtName.required &&
        errors.push("ກະລຸນາປ້ອນຊື່ເມືອງ.");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.village.name.$dirty) return errors;
      !this.$v.village.name.maxLength &&
        errors.push("name must be at most 100 characters long");
      !this.$v.village.name.required && errors.push("ກະລຸນາປ້ອນຊື່ບ້ານ.");
      return errors;
    },
    districtInProvince() {
      var dlist = [];
      for (const key in this.districts) {
        const el = this.districts[key];
        if (this.village.provinceName == el.provinceName) {
          dlist.push(el.name);
        }
        // alert(el.name);
      }
      return dlist;
    },
  },
  mounted() {
    this.getProvinces();
    this.getDistricts();
    this.getVillage(this.$route.params.id);
  },
  methods: {
    ...mapActions("addressMod", [
      "updateVillage",
      "getProvinces",
      "getDistricts",
      "getVillage",
    ]),
    
    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },
    submit() {
      this.$v.$touch();
      try {
        if (
          this.village.provinceName == "" ||
          this.village.provinceName == null
        ) {
          this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກແຂວງ</div>`,)
          return;
        }

        if (
          this.village.districtName == "" ||
          this.village.districtName == null
        ) {
          this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກເມືອງ</div>`,)
          return;
        }
        if (this.village.name == "" || this.village.name == null) {
          this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນຊື່ບ້ານ</div>`,)
          return;
        }
        for (const key in this.provinces) {
          const el = this.provinces[key];
          if (el.name == this.village.provinceName) {
            this.village.province = el._id;
          }
        }
        for (const key in this.districts) {
          const el = this.districts[key];
          if (el.name == this.village.districtName) {
            this.village.district = el._id;
          }
        }
        if (this.village.province == "" || this.village.province == null) {
          this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກແຂວງໃຫ້ຖຶກຕ້ອງ</div>`,)
          return;
        }
        if (this.village.district == "" || this.village.district == null) {
          this.showToast("warning",`<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກເມືອງໃຫ້ຖຶກຕ້ອງ</div>`,)
          return;
        }
        this.updateVillage(this.village)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
              this.showToast("success",`<div style='font-family:"Noto Sans Lao";' >ແກ້ໄຂຂໍ້ມູນສໍາເລັດ</div>`,)
              this.clear();
              this.back()
            } else {
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            }
          })
          .catch( (error) =>{
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
            console.log(error);
          });
      } catch (error) {
        console.log(error);
          this.showToast("error",`<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`,)
      }
    },
    clear() {
      this.$v.$reset();
      this.getVillage(this.$route.params.id);
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped></style>
