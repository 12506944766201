<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
              <h1 style="margin-top: 30px">ເພີ່ມຂໍ້ມູນກິດຈະກໍາ</h1>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  <v-text-field
                    v-model="event.title"
                    :error-messages="titleErrors"
                    label="ຫົວຂໍ້ກິດຈະກໍາ"
                    required
                    @input="$v.event.title.$touch()"
                    @blur="$v.event.title.$touch()"
                  ></v-text-field>
                  <v-textarea
                    v-model="event.description"
                    background-color="#fafafafa"
                    color="cyan"
                    label="ລາຍລະອຽດ"
                    :error-messages="descriptionErrors"
                    required
                    @input="$v.event.description.$touch()"
                    @blur="$v.event.description.$touch()"
                  ></v-textarea>

                  <input
                    type="file"
                    class="file-upload"
                    ref="file"
                    @change="onFileSelected"
                    multiple
                  />

                  <v-carousel
                  v-model="tagImg"
                    v-if="event.imageURL.length > 0"
    cycle
    height="400"
    hide-delimiter-background
    hide-delimiters
                    :show-arrows="false"
                  >
                    <v-carousel-item
                      v-for="(img, i) in event.imageURL"
                      :key="i"
                    >
                      <v-img 
                    height="400"
                    width="100%"
                    contain
                    :src="img">
                    <template v-slot>
                          <div>
                            <v-row no-gutters class="justify-end ma-3">
                              <v-icon large color="red"
                              @click="event.imageURL.splice(i,1)">mdi-delete</v-icon>
                            </v-row>
                          </div>
                        </template>
                    </v-img>
                    </v-carousel-item>
                  </v-carousel>
                  <v-card
                    flat
                    exact
                    align="center"
                    v-else
                    class="mx-auto"
                    color="#fafafafa"
                    height="400"
                    width="100%"
                  >
                    <div
                      style="
                        padding-top: 160px;
                        font-size: 3rem;
                        font-weight: bold;
                        color: #d0d0d0;
                      "
                    >
                      520 X 300
                    </div>
                  </v-card>
                  <v-row no-gutters class="justify-center"
                    v-if="event.imageURL.length > 0"
                   >
                   <v-icon @click="tagImg=(tagImg>0)?tagImg-1:tagImg=0" size="50">mdi-chevron-left</v-icon>
                    <div v-for="(img, i) in event.imageURL"
                      :key="i">
                      <v-card 
                      ripple
                      class="mx-2"
                    height="50"
                    width="70"
                    :img="img"
                    @click="switchImg(i)">
                    </v-card></div>
                   <v-icon @click="tagImg=(tagImg> event.imageURL.length)?tagImg=event.imageURL.length:tagImg=tagImg+1" size="50">mdi-chevron-right</v-icon>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class></v-divider>
            <v-row
              class="ma-5"
              :justify="$vuetify.breakpoint.width < 600 ? 'center' : 'start'"
            >
              <v-btn
                @click="back"
                class="ma-2 orange white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
              </v-btn>
              <v-spacer class="d-none d-sm-flex"></v-spacer>
              <v-btn
                @click="clear"
                class="ma-2 red white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-close-circle</v-icon>ຍົກເລິກ
              </v-btn>

              <v-btn
                @click="submit"
                class="ma-2 green white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-check-circle</v-icon>ບັນທຶກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {  mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    event: {
      title: { required, maxLength: maxLength(100) },
      description: { required, maxLength: maxLength(1000) },
    },
  },
  data: () => ({
    tagImg:0,
    event: {
      title: "",
      description: "",
      eventPictures: [],
      imageURL: [],
    },
  }),

  computed: {
    titleErrors() {
      const errors = [];
      if (!this.$v.event.title.$dirty) return errors;
      !this.$v.event.title.maxLength && errors.push("ກະລຸນາປ້ອນຫົວຂໍ້ກິດຈະກໍາ");
      !this.$v.event.title.required && errors.push("ກະລຸນາປ້ອນຫົວຂໍ້ກິດຈະກໍາ");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.event.description.$dirty) return errors;
      !this.$v.event.description.maxLength &&
        errors.push("ກະລຸນາປ້ອນລາຍລະອຽດກິດຈະກໍາ");
      !this.$v.event.description.required &&
        errors.push("ກະລຸນາປ້ອນລາຍລະອຽດກິດຈະກໍາ");
      return errors;
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions("eventMod", ["insertEvent"]),

    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    onFileSelected(e) {
      var list = [];
      if (e.target.files.length < 1) return;
      for (let idx = 0; idx < e.target.files.length; idx++) {
        const el = e.target.files[idx];
        const reader = new FileReader();
        reader.readAsDataURL(el);
        reader.onload = (e) => {
          this.event.imageURL.push(e.target.result);
        };
        this.event.eventPictures.push(el);
        list.push(el);
      }
    },
    switchImg(img){
      this.tagImg=img
    },
    submit() {
      this.$v.$touch();
      try {
        if (this.event.title == "" || this.event.title == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນຫົວຂໍ້ກິດຈະກໍາ</div>`
          );
          return;
        }
       
        if (this.event.description == "" || this.event.description == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນລາຍລະອຽດກິດຈະກໍາ</div>`
          );
          return;
        }
        if (this.event.eventPictures.length<1 || this.event.eventPictures == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຮູບພາບ</div>`
          );
          return;
        }
        
                let formData = new FormData();
        formData.append("title", this.event.title);
        formData.append("description", this.event.description);
        for (const i of Object.keys(this.event.eventPictures)) {
        formData.append("eventPictures", this.event.eventPictures[i]);
        }
        this.insertEvent(formData)
          .then((res) => {
            if (res.data.message == "SUCCESSFULLY") {
              this.showToast(
                "success",
                `<div style='font-family:"Noto Sans Lao";' >ບັນທຶກຂໍ້ມູນສໍາເລັດແລ້ວ</div>`
              );
              this.clear();
            } else {
              this.showToast(
                "error",
                `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "error",
              `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
            );
            console.log(error);
          });
      } catch (error) {
        console.log(error);
        this.showToast(
          "error",
          `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
        );
      }
    },
    clear() {
      this.$v.$reset();
      this.event = {
        title: "",
        description: "",
        eventPictures: [],
        imageURL:[]
      };
    },
    back() {
      this.$router.back();
    },
    
  },
};
</script>
<style scoped>
.file-upload {
  margin: 7px 0px;
  font-size: 16px;
  width: 100%;
  background: white;
  border-bottom: 1px solid #999;
}
.file-upload::placeholder {
  content: "";
}
.file-upload::before {
  background: #eeeeee;
  content: "ເລືອກຮູບພາບ";
  text-indent: "";
  border-radius: 0px 50px 50px 0px;
  padding: 10px;
  width: 180px;
  border: 1px solid #edffdd;
}
.file-upload:hover {
  border-bottom: 1px solid #555;
}
.file-upload:hover::before {
  background: #dedede;
}
::-webkit-file-upload-button {
  background: #eeeeee;
  visibility: hidden;
  border-radius: 0px 50px 50px 0px;
  padding: 7px;
  width: 1px;
  border: 1px solid #edffdd;
}
::-webkit-file-upload-button:hover {
  background: #dedede;
}
</style>