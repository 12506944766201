import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";
import { apiURL, header } from "../../config/constant";

Vue.use(Vuex);

const warehouseModule = {
  namespaced: true,
  state: {
    warehouses: [],
    warehouse: [],
    warehouseName: [],
    length: true
  },
  mutations: {
    setWarehouses(state, ware) {
      state.warehouses = ware;
    },
    setWarehouse(state, ware) {
      state.warehouse = ware;
    },
    setWarehouseName(state, ware) {
      state.warehouseName = ware;
    },
    setLength(state, ware) {
      state.length = ware;
    },
  },
  actions: {
    async getWarehouse(context, id) {
      try {
        var provinceList = await axios.get(apiURL + `provinces`, { headers: header });
        var districtList = await axios.get(apiURL + `districts`, { headers: header });
        var villageList = await axios.get(apiURL + `villages`, { headers: header });
        axios
          .get(apiURL + `warehouses/${id}`, { headers: header })
          .then((res) => {
            if (res.data.error != undefined) {
              context.commit("setLength", false);
              return
            }
            if (res.data.length < 1) {
              context.commit("setLength", false);
              return
            }
            const el = res.data;
            var provinceName = null, provinceID = null, districtName = null, districtID = null;
            for (const i in villageList.data) {
              const vl = villageList.data[i], v = (el.village == null || el.village == undefined) ? null : el.village._id;
              if (v == vl._id) {
                districtID = (vl.district == null || vl.district == undefined) ? null : vl.district._id
                provinceID = (vl.district == null || vl.district == undefined) ? null : vl.district.province
                for (const j in districtList.data) {
                  const dt = districtList.data[j];
                  if (districtID == dt._id) {
                    districtName = dt.name
                  }

                }
                for (const x in provinceList.data) {
                  const pro = provinceList.data[x];
                  if (provinceID == pro._id) {
                    provinceName = pro.name
                  }

                }
              }

            }
            var warehouse = {
              _id: el._id,
              name: el.name, provinceName: provinceName,
              province: '',
              districtName: districtName,
              district: '',
              villageName: (el.village == null || el.village == undefined) ? null : el.village.name,
              village: '',
              phoneNumber: el.phoneNumber,
              email: el.email,
              user: (el.user == null || el.user == undefined) ? null : el.user._id,
              role: (el.user == null || el.user == undefined) ? null : el.user.role,
              userName: (el.user == null || el.user == undefined) ? null : el.user.userName,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
              __v: el.__v,
            };
            context.commit("setWarehouse", warehouse);
          })
          .catch(function (error) {
            if (error.message == 'Network Error') {
              console.log("ບໍ່ມີອີນເທີເນັດ")
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    async getWarehouses(context) {
      try {
        var provinceList = await axios.get(apiURL + `provinces`, { headers: header });
        var districtList = await axios.get(apiURL + `districts`, { headers: header });
        var villageList = await axios.get(apiURL + `villages`, { headers: header });
        axios.get(apiURL + `warehouses`, { headers: header }).then((res) => {
          if (res.data.error != undefined) {
            context.commit("setLength", false);
            return
          }
          if (res.data.length < 1) {
            context.commit("setLength", false);
            return
          }
          var warelist = [], namelist = [];
          for (const idx in res.data) {
            const el = res.data[idx];
            var provinceName = null, provinceID = null, districtName = null, districtID = null;
            for (const i in villageList.data) {
              const vl = villageList.data[i], v = (el.village == null || el.village == undefined) ? null : el.village._id;
              if (v == vl._id) {
                districtID = (vl.district == null || vl.district == undefined) ? null : vl.district._id
                provinceID = (vl.district == null || vl.district == undefined) ? null : vl.district.province
                for (const j in districtList.data) {
                  const dt = districtList.data[j];
                  if (districtID == dt._id) {
                    districtName = dt.name
                  }

                }
                for (const x in provinceList.data) {
                  const pro = provinceList.data[x];
                  if (provinceID == pro._id) {
                    provinceName = pro.name
                  }

                }
              }

            }
            var warehouse = {
              idx: parseInt(idx) + 1,
              _id: el._id,
              name: el.name,
              provinceName: provinceName,
              districtName: districtName,
              villageName: (el.village == null || el.village == undefined) ? null : el.village.name,
              phoneNumber: el.phoneNumber,
              email: el.email,
              user: (el.user == null || el.user == undefined) ? null : el.user._id,
              role: (el.user == null || el.user == undefined) ? null : el.user.role,
              userName: (el.user == null || el.user == undefined) ? null : el.user.userName,
              createdAt:
                moment(String(el.createdAt)).format("DD-MM-YYYY") +
                " " +
                el.createdAt.slice(11, el.createdAt.length - 5),
              updatedAt:
                moment(String(el.updatedAt)).format("DD-MM-YYYY") +
                " " +
                el.updatedAt.slice(11, el.updatedAt.length - 5),
            };
            warelist.push(warehouse);
            namelist.push(el.name);
          }
          context.commit("setWarehouses", warelist);
          context.commit("setWarehouseName", namelist);
          context.commit("setLength", true);
        }).catch(function (error) {
          context.commit("setLength", false);
          console.log(error)
        });
      } catch (error) {
        context.commit("setLength", false);
        console.log(error);
      }
    },
    delWarehouse(context, item) {
      try {
        return axios
          .delete(apiURL + `warehouses/${item._id}`, { headers: header })

      } catch (e) {
        this.errors.push(e);
      }
    },
    insertWarehouse(context, item) {
      try {
        return axios
          .post(apiURL + `warehouses`, item, { headers: header })

      } catch (e) {
        this.errors.push(e);
      }
    },
    updateWarehouse(context, item) {
      try {
        return axios
          .put(apiURL + `warehouses/${item._id}`, item, { headers: header })

      } catch (e) {
        this.errors.push(e);
      }
    },
  },
  getters: {
    warehouses: (state) => state.warehouses,
    warehouse: (state) => state.warehouse,
    warehouseName: (state) => state.warehouseName,
    length: (state) => state.length,
  },
};

export default warehouseModule;
