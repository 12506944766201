<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="12">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
              <h1 style="margin-top: 30px">ຂໍ້ມູນກິດຈະກໍາ</h1>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="12">
                <v-card class="mr-4 elevation-0">
                  <v-carousel
                    v-if="event.eventPictures.length > 0"
                    v-model="tagImg"
                    cycle
                    height="400"
                    hide-delimiter-background
                    :show-arrows="false"
                  >
                    <v-carousel-item
                      v-for="(img, i) in event.eventPictures"
                      :key="i"
                    >
                      <v-img
                        height="400"
                        width="100%"
                        contain
                        :src="img.img"
                        :lazy-src="img.img"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
              color="#90CAF9"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-carousel-item>
                  </v-carousel>
                  
                  <v-row no-gutters class="justify-center"
                    v-if="event.eventPictures.length > 0"
                   >
                   <v-icon @click="tagImg=(tagImg>0)?tagImg-1:tagImg=0" size="50">mdi-chevron-left</v-icon>
                    <div v-for="(img, i) in event.eventPictures"
                      :key="i">
                      <v-card 
                      ripple
                      class="mx-2"
                    height="50"
                    width="70"
                    :img="img.img"
                    @click="tagImg=i">
                    </v-card></div>
                   <v-icon @click="tagImg=(tagImg> event.eventPictures.length)?tagImg=event.eventPictures.length:tagImg=tagImg+1" size="50">mdi-chevron-right</v-icon>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="justify-center ma-3"
              ><span style="font-size: 1.2rem">{{ event.title }}</span></v-row
            >
            <v-row class="justify-center ma-3 mx-6"
              ><p>{{ event.description }}</p></v-row
            >
            <v-divider class></v-divider>
            <v-row
              class="ma-5"
              :justify="$vuetify.breakpoint.width < 600 ? 'center' : 'start'"
            >
              <v-btn
                @click="back"
                class="ma-2 orange white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    tagImg:0
  }),

  computed: {
    ...mapGetters({
      event: "eventMod/event",
    }),
  },
  mounted() {
    this.getEvent(this.$route.params.id);
  },
  methods: {
    ...mapActions("eventMod", ["updateEvent", "getEvent"]),

    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    back() {
      this.$router.back();
    },
  },
};
</script>