<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
              <p style="font-size:2rem;margin-top: 30px">ວັດຖຸດິບ-ນໍາເຂົ້າ</p>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  
                  <v-autocomplete
                    clearable
                    item-color="blue darken-1"
                    no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                    v-model="raw.rawName"
                    :items="rawName"
                    :error-messages="rawNameErrors"
                    label="ວັດຖຸດິບ"
                    allow-overflow
                    required
                    @input="$v.raw.rawName.$touch()"
                    @blur="$v.raw.rawName.$touch()"
                  ></v-autocomplete>
                  <v-text-field
              v-model="raw.barCodeNumber"
              label="ລະຫັດບາໂຄດ"
            ></v-text-field>
                  <v-autocomplete
                    clearable
                    item-color="blue darken-1"
                    no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                    label="ຜູ້ສະໜອງ"
                    v-model="raw.supplierName"
                    :items="supplierName"
                    :error-messages="supplierNameErrors"
                    required
                    @input="$v.raw.supplierName.$touch()"
                    @blur="$v.raw.supplierName.$touch()"
                  ></v-autocomplete>

                  <v-text-field
                    v-model="raw.qty"
                    :error-messages="qtyErrors"
                    label="ຈໍານວນ"
                    required
                    @input="$v.raw.qty.$touch()"
                    @blur="$v.raw.qty.$touch()"
                    @keypress="number($event)"
                  ></v-text-field>
                  <v-autocomplete
                    clearable
                    item-color="blue darken-1"
                    no-data-text="ບໍ່ມີຂໍ້ມູນ!"
                    label="ຫົວໜ່ອຍ"
                    v-model="raw.unitName"
                    :items="unitName"
                    :error-messages="unitNameErrors"
                    required
                    @input="$v.raw.unitName.$touch()"
                    @blur="$v.raw.unitName.$touch()"
                  ></v-autocomplete>
                  <v-text-field
                    v-model="raw.price"
                    :error-messages="priceErrors"
                    label="ລາຄາ"
                    required
                    @input="$v.raw.price.$touch()"
                    @blur="$v.raw.price.$touch()"
                    @keypress="number($event)"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class></v-divider>
            <v-row
              class="ma-5"
              :justify="$vuetify.breakpoint.width < 600 ? 'center' : 'start'"
            >
              <v-btn
                @click="back"
                class="ma-2 orange white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າຫຼັກ
              </v-btn>
              <v-spacer class="d-none d-sm-flex"></v-spacer>
              <v-btn
                @click="clear"
                class="ma-2 red white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-close-circle</v-icon>ຍົກເລິກ
              </v-btn>

              <v-btn
                @click="submit"
                class="ma-2 green white--text"
                :width="$vuetify.breakpoint.width < 600 ? '100%' : 'auto'"
              >
                <v-icon left>mdi-check-circle</v-icon>ບັນທຶກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    raw: {
      rawName: { required, maxLength: maxLength(100) },
      supplierName: { required, maxLength: maxLength(100) },
      unitName: { required, maxLength: maxLength(100) },
      price: { required, maxLength: maxLength(100) },
      qty: { required, maxLength: maxLength(100) },
    },
  },
  data: () => ({
    raw: {
      barCodeNumber:null,
      rawName: "",
      raw: "",
      supplierName: null,
      supplier: null,
      unit: null,
      unitName: null,
      price: 0,
      qty: 1,
    },
  }),

  computed: {
    ...mapGetters({
      raws: "rawMod/raws",
      rawName: "rawMod/rawName",
      unit: "unitMod/unit",
      unitName: "unitMod/unitName",
      suppliers: "supplierMod/suppliers",
      supplierName: "supplierMod/supplierName",
    }),
    rawNameErrors() {
      const errors = [];
      if (!this.$v.raw.rawName.$dirty) return errors;
      !this.$v.raw.rawName.maxLength && errors.push("ກະລຸນາເລືອກຊື່ວັດຖຸດິບ");
      !this.$v.raw.rawName.required && errors.push("ກະລຸນາເລືອກຊື່ວັດຖຸດິບ");
      return errors;
    },

    supplierNameErrors() {
      const errors = [];
      if (!this.$v.raw.supplierName.$dirty) return errors;
      !this.$v.raw.supplierName.maxLength && errors.push("ກະລຸນາເລືອກຜູ້ສະໜອງ");
      !this.$v.raw.supplierName.required && errors.push("ກະລຸນາເລືອກຜູ້ສະໜອງ");
      return errors;
    },
    unitNameErrors() {
      const errors = [];
      if (!this.$v.raw.unitName.$dirty) return errors;
      !this.$v.raw.unitName.maxLength && errors.push("ກະລຸນາເລືອກຫົວໜ່ວຍ");
      !this.$v.raw.unitName.required && errors.push("ກະລຸນາເລືອກຫົວໜ່ວຍ");
      return errors;
    },
    qtyErrors() {
      const errors = [];
      if (!this.$v.raw.qty.$dirty) return errors;
      !this.$v.raw.qty.maxLength && errors.push("ກະລຸນາປ້ອນຈໍານວນ");
      !this.$v.raw.qty.required && errors.push("ກະລຸນາປ້ອນຈໍານວນ");
      return errors;
    },
    priceErrors() {
      const errors = [];
      if (!this.$v.raw.price.$dirty) return errors;
      !this.$v.raw.price.required && errors.push("ກະລຸນາປ້ອນລາຄາ");
      !this.$v.raw.price.required && errors.push("ກະລຸນາປ້ອນລາຄາ");
      return errors;
    },
  },
  mounted() {
    this.getRaws();
    this.getUnit();
    this.getSuppliers();
    this.init()
  },
  methods: {
    ...mapActions("rawMod", ["getRaws"]),
    ...mapActions("expenseMod", ["insertExpense"]),
    ...mapActions("supplierMod", ["getSuppliers"]),
    ...mapActions("unitMod", ["getUnit"]),
    ...mapActions("stockMod", ["getStockRawID", "insertStock", "updateStock"]),

    showToast(icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    number(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        this.showToast(
          "warning",
          `<div style='font-family:"Noto Sans Lao";color:red' >ປ້ອນສະເພາະຕົວເທົ່ານັ້ນ (0-9)</div>`
        );
        evt.preventDefault();
      } else {
        return true;
      }
    },  
    init() {
      this.raw.barCodeNumber =
        this.$route.query.barCodeNumber == null ||
        this.$route.query.barCodeNumber == undefined
          ? null
          : this.$route.query.barCodeNumber;
      if (
        this.raw.barCodeNumber == null ||
        this.raw.barCodeNumber == undefined
      ) {
        return;
      }
      this.selectByBarCode();
    },
     selectByBarCode() {
      if (
        this.raw.barCodeNumber == null ||
        this.raw.barCodeNumber == undefined
      ) {
        return;
      }
      var name = null;
      for (const idx in this.raws) {
        const el = this.raws[idx];
        if (this.raw.barCodeNumber == el.barCodeNumber) {
          name = el.rawName;
        }
      }
      this.raw.rawName = name;
    },

    SelectRawName() {
      if (this.raw.rawName == null || this.raw.rawName == undefined) {
        return;
      }

      var barcode = null;
      for (const idx in this.raws) {
        const el = this.raws[idx];
        if (this.raw.rawName == el.rawName) {
          barcode =
            el.barCodeNumber == null || el.barCodeNumber == undefined
              ? null
              : el.barCodeNumber;
        }
      }
      this.raw.barCodeNumber = barcode;
    },
    submit() {
      this.$v.$touch();
      try {
        if (this.raw.rawName == "" || this.raw.rawName == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຊື່ວັດຖຸດິບ</div>`
          );
          return;
        }
        for (const key in this.raws) {
          const el = this.raws[key];
          if (el.rawName == this.raw.rawName) {
            this.raw.raw = el._id;
          }
        }
        if (this.raw.raw == "") {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກວັດຖຸດິບໃຫ້ຖືກຕ້ອງ</div>`
          );
          return;
        }
        if (this.raw.supplierName == null || this.raw.supplierName == "") {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຜູ້ສະໜອງ</div>`
          );
          return;
        }
        for (const key in this.suppliers) {
          const el = this.suppliers[key];
          if (el.supplierName == this.raw.supplierName) {
            this.raw.supplier = el._id;
          }
        }
        if (this.raw.supplier == null || this.raw.supplier == "") {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກຜູ້ສະໜອງໃຫ້ຖືກຕ້ອງ</div>`
          );
          return;
        }
        if (this.raw.unitName == null || this.raw.unitName == "") {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກວັດຖຸດິບ</div>`
          );
          return;
        }
        for (const key in this.unit) {
          const el = this.unit[key];
          if (el.name == this.raw.unitName) {
            this.raw.unit = el._id;
          }
        }
        if (this.raw.unit == null || this.raw.unit == "") {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາເລືອກວັດຖຸດິບໃຫ້ຖືກຕ້ອງ</div>`
          );
          return;
        }
        if (this.raw.qty == "" || this.raw.qty == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນຈໍານວນ</div>`
          );
          return;
        }
        if (this.raw.price == "" || this.raw.price == null) {
          this.showToast(
            "warning",
            `<div style='font-family:"Noto Sans Lao";color:red' >ກະລຸນາປ້ອນລາຄາ</div>`
          );
          return;
        }

        this.getStockRawID(this.raw.raw)
          .then((res) => {
            if (
              res.data == "null" ||
              res.data == null ||
              res.data._id == null ||
              res.data._id == undefined
            ) {
              this.insertExpense(this.raw)
                .then((resInt) => {
                  if (resInt.data.message == "SUCCESSFULLY") {
                    var stock = {
                      raw: this.raw.raw,
                      barCodeNumber:this.raw.barCodeNumber,
                      qty: this.raw.qty,
                      unit: this.raw.unit, // design unit name
                      supplier: this.raw.supplier,
                    };
                    this.insertStock(stock)
                      .then((resStock) => {
                        if (resStock.data.message == "SUCCESSFULLY") {
                          this.showToast(
                            "success",
                            `<div style='font-family:"Noto Sans Lao";' >ບັນທຶກຂໍ້ມູນສໍາເລັດແລ້ວ</div>`
                          );
                          this.clear();
                        } else {
                          this.showToast(
                            "error",
                            `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
                          );
                        }
                      })
                      .catch((error) => {
                        this.showToast(
                          "error",
                          `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
                        );
                        console.log(error);
                      });
                  } else {
                    this.showToast(
                      "error",
                      `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
                    );
                  }
                })
                .catch((error) => {
                  this.showToast(
                    "error",
                    `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
                  );
                  console.log(error);
                });
            } else if (res.data._id != null && res.data._id != undefined) {
              this.insertExpense(this.raw)
                .then((resInt) => {
                  if (resInt.data.message == "SUCCESSFULLY") {
                    var stock = {
                      _id: res.data._id,
                      raw: this.raw.raw,
                      barCodeNumber:this.raw.barCodeNumber,
                      qty: parseInt(res.data.qty) + parseInt(this.raw.qty),
                      unit: res.data.unit._id, 
                      supplier: this.raw.supplier,
                    };
                    this.updateStock(stock)
                      .then((resStock) => {
                        if (resStock.data.message == "SUCCESSFULLY") {
                          this.showToast(
                            "success",
                            `<div style='font-family:"Noto Sans Lao";' >ບັນທຶກຂໍ້ມູນສໍາເລັດແລ້ວ</div>`
                          );
                          this.clear();
                        } else {
                          this.showToast(
                            "error",
                            `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
                          );
                        }
                      })
                      .catch((error) => {
                        this.showToast(
                          "error",
                          `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
                        );
                        console.log(error);
                      });
                  } else {
                    this.showToast(
                      "error",
                      `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
                    );
                  }
                })
                .catch((error) => {
                  this.showToast(
                    "error",
                    `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
                  );
                  console.log(error);
                });
            } else {
              this.showToast(
                "error",
                `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "error",
              `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
            );
            console.log(error);
          });
      } catch (error) {
        console.log(error);
        this.showToast(
          "error",
          `<div style='font-family:"Noto Sans Lao";color:red' >ຂໍ້ມູນຜິດພາດ</div>`
        );
      }
    },
    
  routebarCodeNumber() {
      if (
        this.raw.barCodeNumber != null &&
        this.raw.barCodeNumber != undefined
      ) {
        let routeData = this.$router.resolve({
          path: `/admin/raw/barcode/${this.raw.barCodeNumber}`,
          query: { qty: this.raw.qty },
        });
        window.open(routeData.href, "_blank");
      }
    },
    clear() {
      this.$v.$reset();
      this.raw = {
      barCodeNumber:null,
        rawName: "",
        raw: "",
        supplierName: null,
        supplier: null,
        price: 0,
        qty: 1,
      };
    },
    back() {
      this.$router.back();
    },
  },
};
</script>